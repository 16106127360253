import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import useStore from "../../../helpers/useStore";
import { DocumentationContext } from "../../../stores/Documentation/Documentation.provider";
import { TimeSlotDto } from "../../../api-client";
import TreatmentNoteCard from "./TreatmentNoteCard";

interface TreatmentNoteListProps {
  patientId: number;
  limit: number;
  infiniteLoading?: boolean;
}

export default function TreatmentNoteList({ patientId, limit }: TreatmentNoteListProps) {
  const DocumentationStore = useStore(DocumentationContext);
  const { getTreatmentNotes, updateTreatmentNote, createTreatmentNote } = DocumentationStore;

  const [treatmentNotes, setTreatmentNotes] = useState<TimeSlotDto[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (patientId) {
      generateItems();
    }
  }, [patientId]);

  const generateItems = async () => {
    try {
      setLoading(true);
      const timeSlots: TimeSlotDto[] = await getTreatmentNotes(patientId, limit, page);
      if (timeSlots.length) {
        setTreatmentNotes((prev) => [...prev, ...timeSlots]);
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching treatment notes:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{width: "100%", padding: 1}}>
      <List sx={{ width: "100%" }} >
        {treatmentNotes.map((timeSlot) => (
          <ListItem key={timeSlot.id} disableGutters>
            <TreatmentNoteCard
              timeSlot={timeSlot}
              onUpdateTreatmentNote={updateTreatmentNote}
              onCreateTreatmentNote={createTreatmentNote}
              patientId={patientId}
            />
          </ListItem>
        ))}
      </List>

      {loading && (
        <Box display="flex" justifyContent="center" py={2}>
          <CircularProgress />
        </Box>
      )}

      {hasMore && !loading && (
        <Box display="flex" justifyContent="center" py={2}>
          <Typography
            variant="body2"
            onClick={generateItems}
            style={{
              cursor: "pointer",
              color: "#3f51b5",
              textDecoration: "underline",
            }}
          >
            Load more
          </Typography>
        </Box>
      )}

      {!hasMore && treatmentNotes.length > 0 && (
        <Box display="flex" justifyContent="center" py={2}>
          <Typography variant="body2" color="textSecondary">
            No more treatment notes available.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
