import React, { FC, useState } from "react";
import { MasterTablesContext } from "../../../../../stores/MasterTables/MasterTables.provider";
import useStore from "../../../../../helpers/useStore";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import Controls from "../../../../../atoms/Controls";
import FullScreenBill from "../../IndirectBilling/fullScreen/IndirectFullScreenBill";
import { FullScreenDialog } from "../../../../../atoms/FullScreenDialog";
import NewFullScreenBill from "../../DirectBilling/NewBill/pages/NewFullScreenBill";
import { BillFullScreenDialog } from "./BillFullScreenDialog";

interface AccountSelectionDialogProps {
  paymentType: string;
  isOpen: boolean;
  onCancel: () => void;
  userName: string;
  userId: string;
  userAccounts: any[];
}

const AccountSelectionDialog: FC<AccountSelectionDialogProps> = ({
  paymentType,
  isOpen,
  onCancel,
  userName,
  userId,
  userAccounts,
}) => {
  const masterTableStore = useStore(MasterTablesContext);
  const { masterTablesData } = masterTableStore;

  // Extract insurance companies
  const payers = masterTablesData.filter((obj) => "payer" in obj).map((obj) => obj.payer);
  const [selectedAccount, setSelectedAccount] = useState<string>("");
  const [selectedPayer, setSelectedPayer] = useState<string>("");

  // Event handlers for the dropdowns
  const handleAccountChange = (event: any) => {
    const selectedValue = event.target.value;
    const isValid = userAccounts.some((option: any) => option.id === selectedValue);

    if (isValid) {
      setSelectedAccount(selectedValue);
    } else {
      console.warn("Invalid selection");
    }
  };
  const handleInsuranceChange = (event: any) => {
    const selectedValue = event.target.value;
    const isValid = payers[0]?.some((option: any) => option.ik === selectedValue);

    if (isValid) {
      setSelectedPayer(selectedValue);
    } else {
      console.warn("Invalid selection");
    }
  };

  const [isFullScreenDialogOpen, setIsFullScreenDialogOpen] = useState(false);

  console.log("Should Full Screen Open", isFullScreenDialogOpen);

  const handleSubmit = () => {
    setIsFullScreenDialogOpen(true);
  };

  const handleFullScreenDialogClose = () => {
    setIsFullScreenDialogOpen(false);
    onCancel();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ textAlign: "center" }}>{"Mandant und Kostenträger"}</DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          <Grid
            container
            display={"flex"}
            spacing={2}
            justifyContent={paymentType === "direct" ? "center" : "space-evenly"}
          >
            <Grid
              item
              xs={paymentType === "direct" ? 6 : 4}
              style={{ textAlign: "center" }}
            >
              <Controls.Select
                required
                name="account"
                label="Mandant"
                id={1}
                value={selectedAccount}
                onChange={handleAccountChange}
                options={userAccounts?.map((option: any) => ({
                  label: option.id.toString(),
                  value: option.id,
                  id: option.id,
                }))}
              />
            </Grid>
            <Grid
              item
              xs={8}
            >
              {" "}
              {paymentType === "indirect" && (
                <Controls.Select
                  required
                  name="payer"
                  label="Zahler"
                  id={2}
                  value={selectedPayer}
                  onChange={handleInsuranceChange}
                  options={payers[0]?.map((option: any) => ({
                    label: `${option.name} (IK: ${option.ik})`,
                    value: option.ik,
                    id: option.id,
                  }))}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCancel}
            color="secondary"
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={
              paymentType === "indirect" ? !selectedAccount || !selectedPayer : !selectedAccount
            }
          >
            Einreichen
          </Button>
        </DialogActions>
      </Dialog>
      {isFullScreenDialogOpen && paymentType === "indirect" && (
        <FullScreenDialog
          open={isFullScreenDialogOpen}
          fullScreen
          titleComp={"Versicherungsrechnungen"}
          actions={
            <>
              <Button
                variant="text"
                onClick={handleFullScreenDialogClose}
              >
                Abbrechen
              </Button>
            </>
          }
          children={
            <FullScreenBill
              handleFullScreenDialogClose={handleFullScreenDialogClose}
              selectedAccount={selectedAccount}
              selectedInsurance={selectedPayer}
              userName={userName}
              userId={userId}
            />
          }
        />
      )}
      {isFullScreenDialogOpen && paymentType === "direct" && (
        <BillFullScreenDialog
          open={isFullScreenDialogOpen}
          fullScreen
          titleComp={"VERSICHERUNGSRECHNUNGEN"}
          actions={
            <>
              <Button
                variant="text"
                onClick={handleFullScreenDialogClose}
              >
                Abbrechen
              </Button>
            </>
          }
          children={
            <NewFullScreenBill
              handleFullScreenDialogClose={handleFullScreenDialogClose}
              selectedAccount={selectedAccount}
              userName={userName}
              userId={userId}
            />
          }
        />
      )}
      {/* Warning Dialog */}
    </>
  );
};

export default AccountSelectionDialog;
