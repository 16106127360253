import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de"
import { addDays, addMonths } from "date-fns";

interface DateRangePickerProps {
  startDate: Date,
  endDate: Date,
  onChange: (dates: {startDate: Date, endDate: Date}) => void
  binType?: 'day' | 'week'
}

const BIDateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onChange, binType }) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([startDate, endDate]);

  useEffect(() => {
    let newEndDate: Date | null;
    if (binType === 'day') {
      newEndDate = addDays(startDate, 30) // add 30 days
    } else if (binType === 'week') {
      newEndDate = addMonths(startDate, 3) // add 3 months
    } else {
      newEndDate = endDate
    }

    setDateRange([startDate || null, newEndDate || null]);
  }, [startDate, endDate, binType]);

  const handleDateChange = (update: [Date | null, Date | null]) => {
    setDateRange(update);

    // If both start and end dates are selected, pass them to the parent
    if (update[0] && update[1]) {
      onChange({ startDate: update[0], endDate: update[1] });
    }
  };

  return (
    <DatePicker
      selectsRange={true}
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      onChange={handleDateChange}
      calendarStartDay={1}
      locale={de}
      dateFormat='dd.MM.yyyy'
    />
  );
};

export default BIDateRangePicker;