import React from 'react';

interface BinTypeSelectorProps<T> {
  binType: T; 
  onChange: (value: T) => void;
  availableOptions: T[];
}

const BinTypeSelector = <T extends string>({ binType, onChange, availableOptions }: BinTypeSelectorProps<T>) => {
  return (
    <div className="dropdown-container">
      <select value={binType} onChange={(e) => onChange(e.target.value as T)}>
        {availableOptions.includes('week' as T) && <option value="week">Week</option>}
        {availableOptions.includes('month' as T) && <option value="month">Month</option>}
        {availableOptions.includes('day' as T) && <option value="day">Day</option>}
      </select>
    </div>
  );
};

export default BinTypeSelector;