import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import "../../common/helpers/css/direct-bill-info.css";

interface TaxierenConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const TaxierenConfirmationDialog: FC<TaxierenConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="taxieren-dialog-title"
    >
      <DialogTitle
        id="taxieren-dialog-title"
        className="dialog-title"
      >
        Taxieren bestätigen
      </DialogTitle>
      <DialogContent className="dialog-content">
        <DialogContentText id="taxieren-dialog-description">
          Möchten Sie das Taxiieren dieses RX wirklich abbrechen?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={onConfirm}>Untaxieren</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaxierenConfirmationDialog;
