import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  Paper,
  Tooltip,
  IconButton,
  Avatar,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { OpenInNew, Edit, Send, EventBusy } from "@mui/icons-material";
import { TherapyChip } from "../../../../atoms/Chip";
import Controls from "../../../../atoms/Controls";
import { mobiliTheme } from "../../../../themes/mobiliTheme";
import { getStatusColor } from "../../../PatientOverview/RxInfo/RxForms/statusColor";
import { observer } from "mobx-react";
import { DoctorReportDto, DoctorReportStausDtoStatusEnum, RxDtoProcessingStatusEnum } from "../../../../api-client";
import { DocumentationContext } from "../../../../stores/Documentation/Documentation.provider";
import useStore from "../../../../helpers/useStore";
import { update } from "lodash";
import moment from "moment";
import { MobiliAvatar } from "../../../../atoms/MobiliAvatar";
import { formatDateDisplay } from "../../../../helpers/dateFormat";

interface DoctorReportSelectorProps {
  doctorReports: DoctorReportDto[];
  selectedRow: any;
  handleRowClick: (report: DoctorReportDto) => void;
  handleEditClick: (report: DoctorReportDto) => void;
  handleOpenClick: (report: string) => void;
  handleSendClick: (report: DoctorReportDto) => void;
  actionLoading:boolean;
}

const DoctorReportSelector: React.FC<DoctorReportSelectorProps> = observer(
  ({ doctorReports, selectedRow, handleRowClick, handleOpenClick, handleSendClick,handleEditClick,actionLoading})=>{
    const DocumentationStore=useStore(DocumentationContext);
    const {updateDoctorReportStatus}=DocumentationStore;


   // Local state to manage statuses
   const [statuses, setStatuses] = useState<Record<string, DoctorReportStausDtoStatusEnum | null>>(
    () =>
      doctorReports.reduce((acc, report:any)=>{
        acc[report.id] = report.status || null;
        return acc;
      }, {} as Record<string, DoctorReportStausDtoStatusEnum | null>)
  );

  const handleCheckboxChange = async (report: DoctorReportDto, newStatus: DoctorReportStausDtoStatusEnum) => {
    const updatedStatus = statuses[report.id] === newStatus ? null : newStatus;

    // Update the local state
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [report.id]: updatedStatus,
    }));

    // Update the backend
    await updateDoctorReportStatus(report.id, { ...report, status: updatedStatus as any})
  };
    return (
      <List component="nav" aria-label="therapy list">
        {doctorReports.map((report: any, index) => (
          <>
            <ListItem key={index} sx={{ padding: "4px 0px" }}>
              <ListItemButton
                onClick={() => handleRowClick(report)}
                selected={selectedRow.id === report.id}
                sx={{ padding: "0px" }}
              >
                <Paper
                  sx={{
                    padding: "8px",
                    display: "flex",
                    justifyContent: "flex-start",
                    flex: "1",
                    alignItems: "center",
                    backgroundColor:
                      selectedRow.id === report.id
                        ? "secondary.light"
                        : mobiliTheme.lowLevelContainerBackground,
                    elevation: 3,
                  }}
                >
                  {/* RX Details Section */}
                  <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                    <MobiliAvatar
                      user={report.createdByUser}
                      sx={{
                        width: 30,
                        height: 30,
                      }}
                    />
                    <Controls.Chip
                      sx={{
                        fontSize: "0.75em",
                        padding: "0px 0px",
                        borderRadius: "0.5em",
                        alignItems: "center",
                        display: "flex",
                      }}
                      label={report.rx.rxNumber}
                    />
                    {report.rx.date ? (
                      <Tooltip title={formatDateDisplay(report.rx?.date)}>
                        <Controls.Chip
                          sx={{
                            fontSize: "0.75em",
                            padding: "0px 0px",
                            borderRadius: "0.5em",
                            alignItems: "center",
                            display: "flex",
                          }}
                          label={moment(report.createdAt).format("DD.MM.YYYY")}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="No date">
                        <Controls.Chip
                          sx={{
                            fontSize: "1em",
                            padding: "0px 0px",
                            borderRadius: "0.5em",
                            alignItems: "center",
                            display: "flex",
                          }}
                          label={<EventBusy sx={{ color: "grey" }} />}
                        />
                      </Tooltip>
                    )}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 0fr)",
                        padding: "0px",
                      }}
                    >
                      {report.rx.therapyRxs
                        ?.slice()
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((therapyRx: any, index: number) => (
                          <TherapyChip
                            key={index}
                            therapyName={therapyRx.therapy.abbreviation}
                            amount={therapyRx.amount}
                          />
                        ))}
                    </div>
                    <Controls.Chip
                      sx={{
                        fontSize: "0.7em",
                        padding: "0px 0px",
                      }}
                      label={
                        report.rx.isHomeVisit === true
                          ? "Haus/Heim"
                          : report.rx.isHomeVisit === false
                          ? "Praxis"
                          : "-"
                      }
                    />
                    {report.rx.isPerpetual ? (
                      <Controls.Chip
                        sx={{
                          fontSize: "40px",
                          color: mobiliTheme.palette.secondary.dark,
                          marginLeft: "auto",
                        }}
                        label="∞"
                      />
                    ) : (
                      <Tooltip title={`${report.rx?.processingStatus}`}>
                        <Avatar
                          sx={{
                            width: 25,
                            height: 25,
                            marginLeft: "auto",
                            backgroundColor: getStatusColor(report.rx?.processingStatus),
                            "& > *": {
                              display: "none !important", // Hide default content inside the Avatar
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>

                  {/* Action Icons Section */}
                  <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <Tooltip title="Bericht herunterladen">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenClick(report.id);
                        }}
                        disabled={actionLoading}
                      >
                        <OpenInNew />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Bearbeiten">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleEditClick(report);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Senden">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSendClick(report);
                        }}
                        disabled={actionLoading}
                      >
                        <Send />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Paper>
              </ListItemButton>
            </ListItem>
            <div style={{ display: "flex", gap: "1px", alignItems: "center" }}>
              {" "}
              <Controls.Chip
                sx={{
                  fontSize: "0.75em",
                  padding: "0px 0px",
                  borderRadius: "0.5em",
                  alignItems: "center",
                  display: "flex",
                  minWidth:"80px"
                }}
                label={report.status || DoctorReportStausDtoStatusEnum.Unknown}
              />
            </div>
            {/* <div style={{ display: "flex", gap: "1px", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statuses[report.id] === DoctorReportStausDtoStatusEnum.Unknown}
                    onChange={() =>
                      handleCheckboxChange(report, DoctorReportStausDtoStatusEnum.Unknown)
                    }
                  />
                }
                label={
                  <span style={{ fontSize: "0.6rem", whiteSpace: "nowrap" }}>Not Started</span>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statuses[report.id] === DoctorReportStausDtoStatusEnum.Requested}
                    onChange={() =>
                      handleCheckboxChange(report, DoctorReportStausDtoStatusEnum.Requested)
                    }
                  />
                }
                label={
                  <span style={{ fontSize: "0.6rem", whiteSpace: "nowrap" }}>In Progress</span>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statuses[report.id] === DoctorReportStausDtoStatusEnum.Complete}
                    onChange={() =>
                      handleCheckboxChange(report, DoctorReportStausDtoStatusEnum.Complete)
                    }
                  />
                }
                label={
                  <span style={{ fontSize: "0.6rem", whiteSpace: "nowrap" }}>
                    Completed Not Sent
                  </span>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statuses[report.id] === DoctorReportStausDtoStatusEnum.Sent}
                    onChange={() =>
                      handleCheckboxChange(report, DoctorReportStausDtoStatusEnum.Sent)
                    }
                  />
                }
                label={<span style={{ fontSize: "0.6rem", whiteSpace: "nowrap" }}>Sent</span>}
              />
            </div> */}
          </>
        ))}
      </List>
    );
  }
);

export default DoctorReportSelector;
