import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { Layout } from "../Layout";
import { DoctorReportStausDto, DoctorReportStausDtoStatusEnum } from "../../../api-client";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import useStore from "../../../helpers/useStore";
import DoctorReportForm, { DoctorReportFormRef } from "../doctorReport/DoctorReportForm/DoctorReportForm";
import { DocumentationContext } from "../../../stores/Documentation/Documentation.provider";
import InitialEvaluations from "../initialEvaluation/InitialEvaluations";
import TreatmentNotes from "../treatmentNote/TreatmentNotes";
import { RxStoreContext } from "../../../stores/Rx/rx.provider";
import { Download } from "@mui/icons-material";
import notificationStore from "../../../stores/Notification/notificationStore";
   

interface DocumentationProps {
  patientId: string;
}

const Documentation: React.FC<DocumentationProps> = ({ patientId }) => {

const RxStore=useStore(RxStoreContext);
const {getAllRx}=RxStore;
const [rxId,setRxId]=useState(null)
  const [isOpen, setIsOpen] = useState(false);
  const [doctorReportStatus, setDoctorReportStatus] = useState<DoctorReportStausDto>();

  const doctorReportFormRef = useRef<DoctorReportFormRef>(null);


  const DocumentationStore = useStore(DocumentationContext);
  const { getDoctorReportStatus, requestDoctorReport } = DocumentationStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
    const response=    await getAllRx(patientId as unknown as number);
    //  set the latest prescription for the patient
    const latestRx = response.sort((a:any, b:any) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())[0];
    setRxId(latestRx.id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData(); // Call the async function
  
  }, [patientId]);
  

  useEffect(() => {
    if (rxId) {
      getDoctorReportStatus(rxId)
        .then((response: DoctorReportStausDto) => {
          setDoctorReportStatus(response);
        })
        .catch((error) => {
          console.log("error fetching doctor report status :", error);
        });
    }
  }, [rxId]);

  const onFormSave = () => {
    if (doctorReportFormRef.current) {
      doctorReportFormRef.current.handleSubmitDoctorReport();
      setIsOpen(false);
    }
  };

  const isDoctorReportCompleteOrSent = () => {
    let status = doctorReportStatus?.status;
    if (
      status === DoctorReportStausDtoStatusEnum.Complete ||
      status === DoctorReportStausDtoStatusEnum.Sent
    )
      return true;
    return false;
  };

  const getReportIcon = () => {
    if (!doctorReportStatus?.doctorReportExists) return <AddIcon />;
    if (isDoctorReportCompleteOrSent()) return <VisibilityIcon />;
    return <EditIcon />;
  };



 


  if (!patientId) return <Layout title="Documentation"></Layout>;

  return (
    <Grid container spacing={2}>
          {/* Doctor Initial Evaluation Section */}
          <Grid item xs={12} md={6}>

 <InitialEvaluations patientId={patientId} />


       </Grid>

        <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Behandlungs dokumentation"/>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding:"0px !important"
            }}
          >
        <TreatmentNotes patientId={patientId}/>
        </CardContent>
        </Card>
        </Grid>
     </Grid>
  );
};

export default Documentation;


