import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Controls from "../../atoms/Controls";
import useStore from "../../helpers/useStore";
import { MasterTablesContext } from "../../stores/MasterTables/MasterTables.provider";
import { toJS } from "mobx";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import {
  AddressDto,
  AppointmentDto,
  AppointmentTemplateDto,
  PatientAvailabilityDto,
  RxDto,
  TherapyDto,
  TherapyRxDto,
  TimeSlotDto,
} from "../../api-client";
import styled from "styled-components";
import TextArea from "../../atoms/TextArea";
import { mobiliTheme } from "../../themes/mobiliTheme";
import FlatCard from "../../atoms/FlatCard";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import { visitTypeToIcon } from "../../helpers/visitTypeMapping";
import { visitTypeReverseMapping } from "../../helpers/parseInput";
import { UserContext } from "../../stores/User/User.provider";
import { Label } from "@mui/icons-material";
import notificationStore from "../../stores/Notification/notificationStore";

// import './table.css';

interface NewRxProps {
  masterData: any[];
  rxnoAppointments: RxDto[]; //RxDto type
  setDataRefreshFlag: any;
  dataRefreshFlag: any;
  patientId: number;
  blockedData: TimeSlotDto[];
  setblockedData: any;
  patientAvailablityData: PatientAvailabilityDto[];
  setPatientAvailablityData: any;
}

interface SelectedVisitFeeObj {
  therapyRxId: string;
  visitFee: string | null;
}

const StyledTableContainer = styled(TableContainer)`
  overflow-x: auto !important;
  max-width: 100% !important;
`;

const StyledTable = styled(Table)`
  table-layout: auto !important;
`;

const StyledTableCell = styled(TableCell)`
  padding: 2px 4px !important;
  height: 10px !important;
`;

const StyledTableRow = styled(TableRow)`
  height: 10px !important;
`;
const StyledTextField = styled(TextArea)`
  width: 100% !important;
  height: 20px !important;
`;

const StyledButtonContainer = styled(Grid)`
  margin-top: 1px !important;
`;

// Define more styled components as needed...

const NewRx: React.FC<NewRxProps> = ({
  masterData,
  rxnoAppointments,
  setDataRefreshFlag,
  dataRefreshFlag,
  patientId,
  blockedData,
  setblockedData,
  patientAvailablityData,
  setPatientAvailablityData,
}) => {
  const [rxs, setRxs] = useState<RxDto[]>(rxnoAppointments);
  const [selectedTherapies, setSelectedTherapies] = useState<any[]>([]); // Type annotation added
  const [selectedPerpetualTherapies, setSelectedPerpetualTherapies] = useState<any[]>([]); // Type annotation added
  const [showCrossMark, setShowCrossMark] = useState<boolean>(false); // Track whether to show the cross mark
  const initialFrequency: any = rxnoAppointments?.[0]?.therapyRxs?.[0]?.frequency || "";
  const PatientStore = useStore(PatientsContext);
  const { patientDataRefreshFlag } = PatientStore;
  const initialperpetualFrequency: any =
    rxnoAppointments.filter((rx) => rx.isPerpetual)?.[0]?.therapyRxs?.[0]?.frequency || "";

  const notPerpetualRxs = rxnoAppointments.filter((rx) => !rx.isPerpetual);
  const initialAmount =
    (notPerpetualRxs?.[0]?.therapyRxs?.[0]?.amount || 0) -
    (notPerpetualRxs?.[0]?.therapyRxs?.[0]?.scheduledCount || 0);

  console.log(
    "initialAmount",
    initialAmount,
    "scheduled ",
    rxnoAppointments?.[0]?.therapyRxs?.[0]?.scheduledCount,
    rxnoAppointments?.[0]?.therapyRxs?.[0]?.rx?.rxNumber
  );

  const UserStore = useStore(UserContext);
  const { user } = UserStore;

  const [amount, setAmount] = useState<number>(initialAmount);
  const [frequency, setFrequency] = useState(initialFrequency);
  // const [perpetualAmount, setperpetualAmount] = useState<number>(-1);
  const [perpetualfrequency, setperpetualFrequency] = useState(initialperpetualFrequency);
  const [isSuggestionDialogOpen, setsuggestionDialogOpen] = useState(false);
  const [addresses, setAddresses] = useState<AddressDto[]>([]);
  const [addressId, setAddressId] = useState<string>();
  const [perpetualAddressId, setPerpetualAddressId] = useState<string>();
  const [selectedVisitFeeObj, setSelectedVisitFeeObj] = useState<SelectedVisitFeeObj | null>();
  const [selectedPerpetualVisitFeeObj, setPerpetualSelectedVisitFeeObj] =
    useState<SelectedVisitFeeObj | null>();
  const [visitFeeValueToFeeCodeMap, setVisitFeeValueToFeeCodeMap] = useState<{
    [key: string]: string | null;
  }>();
  const [newData, setNewData] = useState<AppointmentTemplateDto>({
    therapyRx: [],
    amount: 0,
    frequency: {},
  });
  const [perpetualData, setPerpetualData] = useState<AppointmentTemplateDto>({
    therapyRx: [],
    // amount: 0,
    frequency: {},
  });
  const AppointmentStore = useStore(AppointmentContext);
  const { createForTreatments, getSolutionsForContinuous } = AppointmentStore;

  const masterTablesStore = useStore(MasterTablesContext);
  const { getTherapyId } = masterTablesStore;
  console.log("RXNOAPPA", rxnoAppointments, initialAmount, initialFrequency);
  const [suggestionData, setSuggestionData] = useState<any>(null); // Initialize with null or appropriate initial data

  //Not Perpetual
  let prevAddressIdRef = useRef<string | undefined>();
  let prevVisitFeeObjRef = useRef<Object | undefined | null>();
  let prevSelectedTherapies = useRef<any[]>();
  //Perpetual
  let prevPerpetualAddressIdRef = useRef<string | undefined>();
  let prevPerpetualVisitFeeObjRef = useRef<Object | undefined | null>();
  let prevPerpetualSelectedTherapies = useRef<any[]>();

  const PatientsStore = useStore(PatientsContext);
  const { getPatient } = PatientsStore;

  useEffect(() => {
    console.log("rxnoAppointments:", rxnoAppointments); // Debug log to check rxnoAppointments
    setRxs(rxnoAppointments); // Update rxs with rxnoAppointments
    // Initialize selectedTherapies with all therapies
    const notPerpetualTherapies = rxnoAppointments.flatMap((rx) => {
      return rx.isPerpetual ? [] : rx.therapyRxs;
    });
    const PerpetualTherapies = rxnoAppointments.flatMap((rx) => {
      return rx.isPerpetual ? rx.therapyRxs : [];
    });
    setSelectedTherapies(notPerpetualTherapies);
    setSelectedPerpetualTherapies(PerpetualTherapies);
    // setperpetualAmount(-1)
    setperpetualFrequency(initialperpetualFrequency?.text);
    setAmount(initialAmount);
    setFrequency(initialFrequency?.text);
  }, [rxnoAppointments]);

  useEffect(() => {
    const frequencyObject = toJS(masterData)
      .filter((obj) => "therapyFrequency" in obj)
      .map((obj) => obj.therapyFrequency) // Access the therapyFrequency array
      .flat() // Flatten the array if needed
      .find((obj) => obj.text === frequency);
    const perpetualfrequencyObject = toJS(masterData)
      .filter((obj) => "therapyFrequency" in obj)
      .map((obj) => obj.therapyFrequency) // Access the therapyFrequency array
      .flat() // Flatten the array if needed
      .find((obj) => obj.text === perpetualfrequency);

    let newData: AppointmentTemplateDto = {
      therapyRx: selectedTherapies,
      amount: amount,
      frequency: frequencyObject ? frequencyObject : frequency,
      addressId: addressId,
    };

    let perpetualData: AppointmentTemplateDto = {
      therapyRx: selectedPerpetualTherapies,
      frequency: perpetualfrequencyObject ? perpetualfrequencyObject : perpetualfrequency,
      addressId: perpetualAddressId,
    };

    // Initial Address assignment
    if (!newData.addressId) {
      const newAddressId = getAddressForAppointmentTemplate(newData, notPerpetualRxs);
      newData.addressId = newAddressId;
      setAddressId(newData.addressId);
    }

    if (!perpetualData.addressId) {
      const newAddressId = getAddressForAppointmentTemplate(perpetualData, perpetaulRx);
      perpetualData.addressId = newAddressId;
      setPerpetualAddressId(newAddressId);
    }

    //Initial VisitFee assignment
    if (!selectedVisitFeeObj) {
      newData = assignVisitFeeToAppointmentTemplate(newData, notPerpetualRxs, false,selectedTherapies);
    }

    if (!selectedPerpetualVisitFeeObj) {
      perpetualData = assignVisitFeeToAppointmentTemplate(perpetualData, perpetaulRx, true,selectedPerpetualTherapies);
    }

    //Not Perpetual ;
    if (
      prevVisitFeeObjRef.current !== undefined &&
      (selectedVisitFeeObj !== prevVisitFeeObjRef.current || selectedVisitFeeObj === null)
    ) {
      newData = onAfterVisitFeeChange(selectedVisitFeeObj!, newData, notPerpetualRxs, false,selectedTherapies);
    }

    if (
      (prevAddressIdRef.current !== undefined && addressId !== prevAddressIdRef.current) ||
      (prevSelectedTherapies.current !== undefined &&
        selectedTherapies !== prevSelectedTherapies.current)
    ) {
      newData = onAfterAddressChange(addressId!, newData, notPerpetualRxs, false,selectedTherapies);
    }
    //Perpetual

    if (
      (prevPerpetualAddressIdRef.current !== undefined &&
        perpetualAddressId !== prevPerpetualAddressIdRef.current) ||
      (prevPerpetualSelectedTherapies.current !== undefined &&
        selectedPerpetualTherapies !== prevPerpetualSelectedTherapies.current)
    ) {
      perpetualData = onAfterAddressChange(perpetualAddressId!, perpetualData, perpetaulRx, true,selectedPerpetualTherapies);
    }

    if (
      prevPerpetualVisitFeeObjRef.current !== undefined &&
      (selectedPerpetualVisitFeeObj !== prevPerpetualVisitFeeObjRef.current ||
        selectedPerpetualVisitFeeObj === null)
    ) {
      perpetualData = onAfterVisitFeeChange(
        selectedPerpetualVisitFeeObj!,
        perpetualData,
        perpetaulRx,
        true,
        selectedPerpetualTherapies
      );
    }

    setNewData(newData);
    setPerpetualData(perpetualData);

    prevAddressIdRef.current = addressId;
    prevVisitFeeObjRef.current = selectedVisitFeeObj;
    prevSelectedTherapies.current = selectedTherapies;

    prevPerpetualAddressIdRef.current = perpetualAddressId;
    prevPerpetualVisitFeeObjRef.current = selectedPerpetualVisitFeeObj;
    prevPerpetualSelectedTherapies.current = selectedPerpetualTherapies;
  }, [
    amount,
    frequency,
    selectedTherapies,
    selectedPerpetualTherapies,
    perpetualfrequency,
    addressId,
    perpetualAddressId,
    selectedVisitFeeObj,
    selectedPerpetualVisitFeeObj,
  ]);

  const doesPraxisTherapyRxSelected = (templateData: AppointmentTemplateDto,selectedTherapyData:TherapyRxDto[]) => {
    let selected = false;

    for (let i = 0; i < templateData.therapyRx.length; i++) {
      if (!getDefaultVisitFeeByRxOrt(templateData.therapyRx[i].id!, rxs,selectedTherapyData)) {
        selected = true;
        break;
      }
    }
    return selected;
  };
  // const onAfterAddressChange = (
  //   addressId: string,
  //   templateData: AppointmentTemplateDto,
  //   selectedTherapies: TherapyRxDto[],
  //   isContinuous: boolean
  // ): AppointmentTemplateDto => {
  //   let _newData = { ...templateData };
  
  //   if (addressId) {
  //     if (isSelectedAddressIsPraxis(addressId)) {
  //      //If address is praxis, making Visit Fee null and in selected therapies Haus/ Heim and Praxis are allowed
  //      _newData.therapyRx.forEach((thrxObj) => (thrxObj.visitFee = null));
  //      // setSelectedVisitFeeObj(null);
  //     } else {
  //         //If selected therapies has only selected praxis therapies then patient address cant be selected then assign praxis address

  //       // let allTherapyRxIsPraxis = _newData.therapyRx.length && true;
  //       // let praxisTherapyIds: string[] = [];
  //       // _newData.therapyRx.forEach((thrxObj) => {
  //       //   //If default visit fee value is null then it means the Ort is Praxis

  //       //   if (getDefaultVisitFeeByRxOrt(thrxObj.id!, rxs)) {
  //       //     allTherapyRxIsPraxis = false;
  //       //   } else {
  //       //     praxisTherapyIds.push(thrxObj.id!);
  //       //   }
  //       // });
  //       // Check if any selected therapy has a Praxis address
  //       const hasPraxisTherapy = _newData?.therapyRx?.some((therapyRxObj: any) => {
  //         const address = getAddressOfRxByTherapyRx(therapyRxObj.id, rxs);
  //         return address?.id ? isSelectedAddressIsPraxis(address.id) : false;
  //       });
  
  //       if (hasPraxisTherapy) {
  //         // Get previous address reference based on mode
  //         let prevAddressRef = isContinuous ? prevPerpetualAddressIdRef : prevAddressIdRef;
  
  //         // If a therapy has a Praxis address but user selects a non-Praxis address, show error
  //         if (prevAddressRef.current && addressId !== prevAddressRef.current) {
  //           notificationStore.showMessage(
  //             "If Praxis is selected then the selected address can only be Praxis address",
  //             "error"
  //           );
  //         }
  
  //         // Assign Praxis address if needed
  //         const locationAddress = addresses?.find(
  //           (addressObj) =>
  //             !addressObj.isVisit && !addressObj.isPrimary && !addressObj.isAlternativeBilling
  //         );
  
  //         let setAddressFunc = isContinuous ? setPerpetualAddressId : setAddressId;
  //         setAddressFunc(locationAddress?.id);
  //       } else {
  //         // Assign visit fee normally
  //         _newData = assignVisitFeeToAppointmentTemplate(_newData, rxs, isContinuous);
  //       }
  //     }
  //     _newData.addressId = addressId;
  //   }
  //   return _newData;
  // };
  
  const onAfterAddressChange = (
    addressId: string,
    templateData: AppointmentTemplateDto,
    rxs:RxDto[],
    isContinuous: boolean,
    selectedTherapyData: TherapyRxDto[]
  ): AppointmentTemplateDto => {
    let _newData = { ...templateData };

    if (addressId) {
      if (isSelectedAddressIsPraxis(addressId)) {
        //If address is praxis, making Visit Fee null and in selected therapies Haus/ Heim and Praxis are allowed
        _newData.therapyRx.forEach((thrxObj) => (thrxObj.visitFee = null));
        // setSelectedVisitFeeObj(null);
      } else {
        //If selected therapies has only selected praxis therapies then patient address cant be selected then assign praxis address

        // let allTherapyRxIsPraxis = _newData.therapyRx.length && true;
        // let praxisTherapyIds: string[] = [];
        // _newData.therapyRx.forEach((thrxObj) => {
        //   //If default visit fee value is null then it means the Ort is Praxis

        //   if (getDefaultVisitFeeByRxOrt(thrxObj.id!, rxs)) {
        //     allTherapyRxIsPraxis = false;
        //   } else {
        //     praxisTherapyIds.push(thrxObj.id!);
        //   }
        // });

        if (doesPraxisTherapyRxSelected(_newData,selectedTherapyData)) {
          // assign praxis address
          let prevAddressRef = isContinuous ? prevPerpetualAddressIdRef : prevAddressIdRef;
          if (
            !isSelectedAddressIsPraxis(addressId) &&
            prevAddressRef.current &&
            addressId !== prevAddressRef.current
          ) {
          
            notificationStore.showMessage(
              "If Praxis is selected then the selected address can only be Praxis address",
              "error"
            );
          }

          const locationAddress = addresses?.filter(
            (addressObj) =>
              !addressObj.isVisit && !addressObj.isPrimary && !addressObj.isAlternativeBilling
          )[0];
          let setAddressFunc = isContinuous ? setPerpetualAddressId : setAddressId;
          // if (locationAddress) setAddressId(locationAddress.id);
          setAddressFunc(locationAddress?.id);

          // setSelectedTherapies([]);
        } else {
          //

          //assigning visitFee
          _newData = assignVisitFeeToAppointmentTemplate(_newData, rxs, isContinuous,selectedTherapyData);
        }
      }
      _newData.addressId = addressId;
    }
    return _newData;
  };
  const onAfterVisitFeeChange = (
    visitFeeObj: SelectedVisitFeeObj,
    templateData: AppointmentTemplateDto,
    rxs: RxDto[],
    isContinuous: boolean,
    selectedTherapyData:TherapyRxDto[]
  ): AppointmentTemplateDto => {
    let _newData = templateData;
    console.log("AddressId change visitfee", addressId);
    if (visitFeeObj) {
      if (isSelectedAddressIsPraxis((isContinuous ? perpetualAddressId : addressId)!)) {
        const patientAddress = addresses?.filter(
          (addressObj) => addressObj.isVisit || addressObj.isPrimary
        )[0];

        //if Praxis therapRxObj is selected then we cannot change address to patient's address instead clear visit fee
        if (doesPraxisTherapyRxSelected(templateData,selectedTherapyData)) {
          const setVisitFeeFunc = isContinuous
            ? setPerpetualSelectedVisitFeeObj
            : setSelectedVisitFeeObj;
          _newData.therapyRx.forEach((thrxObj) => (thrxObj.visitFee = null));
          setVisitFeeFunc(null);
          notificationStore.showMessage("Visit Fee not allowed if address is Praxis", "error");
        } else {
          let setAddressFunc = isContinuous ? setPerpetualAddressId : setAddressId;
          setAddressFunc(patientAddress?.id ? patientAddress.id : "");
        }
      } else {
        _newData = assignVisitFeeToAppointmentTemplate(_newData, rxs, isContinuous,selectedTherapyData);
      }
    }

    return _newData;
  };

  //Setting patient addresses in addresses state array
  // Fetch patient addresses
  useEffect(() => {
    if (patientId) {
      getPatient(patientId)
        .then((patient) => {
          setAddresses((prevAddresses: any) => {
            // Get the patient's addresses and filter based on addressLine1 and zip
            const filteredNewAddresses = (patient.addresses || []).filter(
              (address) => address.contactInfo?.addressLine1 && address.contactInfo.zip
            );

            // Get IDs of the filtered new addresses
            const newAddressIds = filteredNewAddresses.map((addr) => addr.id);

            // Filter out old addresses that have the same IDs as the new filtered addresses
            const filteredPrevAddresses = prevAddresses?.filter(
              (addr: any) => !newAddressIds.includes(addr.id)
            );

            // Combine filtered previous addresses and new filtered addresses
            return [...filteredPrevAddresses, ...filteredNewAddresses];
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [patientId, patientDataRefreshFlag]);

  // Set user address in addresses state arrray
  useEffect(() => {
    if (user && user.location?.address) {
      let address = user.location.address;

      if (address.contactInfo?.addressLine1 && address.contactInfo.zip) {
        setAddresses((prevAddresses) => {
          const newAddress = address ?? null;

          if (newAddress) {
            // If newAddress is not null, update the address list by removing any existing address with the same ID
            const updatedAddresses = prevAddresses.filter((addr) => addr.id !== newAddress.id);
            return [...updatedAddresses, newAddress];
          } else {
            // If newAddress is null, filter out null addresses
            return prevAddresses.filter((addr) => addr !== null);
          }
        });
      }
    }
  }, [user]);

  const fetchAndSetAddress = async () => {
    let filteredAddresses: AddressDto[] = [];
    if (patientId) {
      const patient = await getPatient(patientId);
      const filteredNewAddresses = (patient.addresses || []).filter(
        (address) => address.contactInfo?.addressLine1 && address.contactInfo.zip
      );
      filteredAddresses = filteredAddresses.concat(filteredNewAddresses);
    }

    if (user && user?.location?.address) {
      let address = user.location.address;
      if (address.contactInfo?.addressLine1 && address.contactInfo.zip) {
        filteredAddresses = filteredAddresses.concat([user?.location?.address]);
      }
    }
    setAddresses((prevAddresses) => [
      ...(prevAddresses ? prevAddresses : []),
      ...(filteredAddresses ? filteredAddresses : []),
    ]);
  };
  // useEffect(() => {
  //   fetchAndSetAddress();
  // }, [patientId, user, patientDataRefreshFlag]);

  console.log("usesrtg", addresses, user);

  const handleRowSelect = (therapyId: any) => {
    console.log("Selected therapyId", selectedTherapies, checkedMap);
    const selectedTherapy: any = rxs
      .map((rx) => rx?.therapyRxs?.find((therapy: any) => therapy.id === therapyId))
      .filter(Boolean)[0];

    console.log("THERAPY WHEN IT WAS X-ED", checkedMap[selectedTherapy.id]);
    if (selectedTherapy) {
      if (!checkedMap[selectedTherapy.id]) {
        selectedTherapy.visitFee = null;
      } else {
        selectedTherapy.visitFee = getTherapyId(
          selectedTherapy.therapy.tarifGroup?.id,
          checkedMap[selectedTherapy.id] as any
        );
      }
    }
    console.log("selectedTherapy", selectedTherapy);
    if (selectedTherapy) {
      setSelectedTherapies([...selectedTherapies, selectedTherapy]);
    }
  };

  const handleperpetualRowSelect = (therapyId: any) => {
    const selectedTherapy: any = rxs
      .map((rx) => rx?.therapyRxs?.find((therapy: any) => therapy.id === therapyId))
      .filter(Boolean)[0];
    console.log("selectedTherapy", selectedTherapy);

    if (selectedTherapy) {
      if (!checkedMapPerpetual[selectedTherapy.id]) {
        selectedTherapy.visitFee = null;
      } else {
        selectedTherapy.visitFee = getTherapyId(
          selectedTherapy.therapy.tarifGroup?.id,
          checkedMapPerpetual[selectedTherapy.id] as any
        );
      }
    }
    if (selectedTherapy) {
      setSelectedPerpetualTherapies([...selectedPerpetualTherapies, selectedTherapy]);
    }
  };
  const handleperpetualRemove = (therapyId: any) => {
    const updatedRxs = rxs.map((rx) => {
      const updatedTherapies = rx?.therapyRxs?.filter((therapy: any) => therapy.id !== therapyId);
      if (selectedPerpetualVisitFeeObj?.therapyRxId == therapyId)
        resetSelectedVisitFeeObj(therapyId, true);

      return { ...rx, therapies: updatedTherapies };
    });

    // setRxs(updatedRxs);
    const updatedSelectedTherapies = selectedPerpetualTherapies.filter(
      (selected) => selected.id !== therapyId
    );
    setSelectedPerpetualTherapies(updatedSelectedTherapies);

    setPerpetualData((prevData) => ({
      ...prevData,
      therapyRx: updatedSelectedTherapies,
    }));
  };

  const handleRemove = (therapyId: any) => {
    // const updatedRxs = rxs.map((rx) => {
    //   const updatedTherapies = rx?.therapyRxs?.filter((therapy: any) => therapy.id !== therapyId);
    //   return { ...rx, therapies: updatedTherapies };
    // });

    // // setRxs(updatedRxs);
    // setSelectedTherapies(selectedTherapies.filter((selected) => selected.id !== therapyId));

    const updatedSelectedTherapies = selectedTherapies.filter(
      (selected) => selected.id !== therapyId
    );

    setSelectedTherapies(updatedSelectedTherapies);

    // Update newData to reflect the changes in selectedTherapies
    setNewData((prevData) => ({
      ...prevData,
      therapyRx: updatedSelectedTherapies,
    }));

    if (selectedVisitFeeObj?.therapyRxId == therapyId) {
      resetSelectedVisitFeeObj(therapyId, false);
    }
  };

  const handleEdit = (therapyId: any, field: string, newValue: any) => {
    // Update the therapy in the state
    const updatedRxs = rxs.map((rx) => {
      const updatedTherapies = rx.therapyRxs?.map((therapy: any) => {
        if (therapy.id === therapyId) {
          return { ...therapy, [field]: newValue };
        }
        return therapy;
      });
      return { ...rx, therapies: updatedTherapies };
    });

    setRxs(updatedRxs);
  };
  const normalizeVisitType = (visitType: string | undefined) => {
    if (visitType === visitTypeReverseMapping["hb"] || visitType == visitTypeReverseMapping["hm"]) {
      return visitTypeReverseMapping["hb"] + visitTypeReverseMapping["hm"];
    }
    return visitType;
  };


  const getAddressOfRxByTherapyRx = (thearpyRxId: string, rxs: RxDto[]): AddressDto | null => {
    let address = null;
    const rxIndex = rxs.findIndex((rxObj) =>
      rxObj.therapyRxs?.some((thxobj) => thxobj.id == thearpyRxId)
    );
    if (rxIndex > -1) {
      let rxVisitType = rxs[rxIndex].isHomeVisit;
      //Hiem or Haus
      if (
        rxVisitType == true
       
      ) {
        //address with isVisit flag to true
        const addressWithVisitSet = addresses?.filter((addressObj) => addressObj.isVisit)[0];
        if (addressWithVisitSet?.id) {
          // templateData.addressId = addressWithVisitSet.id;
          address = addressWithVisitSet;
        } else {
          // we choose isPrimary otherwise null
          const addressWithPrimarySet = addresses?.filter((addressObj) => addressObj.isPrimary)[0];
          if (addressWithPrimarySet) {
            address = addressWithPrimarySet;
          } else {
            console.log("Address of patient is not present");
          }
        }
      } // Praxis
      else if (rxVisitType == false) {
        //address with isPrimary to true
        const locationAddress = addresses?.filter(
          (addressObj) =>
            !addressObj.isPrimary && !addressObj.isVisit && !addressObj.isAlternativeBilling
        )[0];
        if (locationAddress?.id) {
          //console.log("Praxis Address", primaryAddress);

          // templateData.addressId = primaryAddress.id;
          address = locationAddress;
        } else {
          console.log("Praxis location address is not present");
        }
      }
    }

    return address;
  };

  const isSelectedAddressIsPraxis = (addressId: string) => {
    let isSelectedAddressPraxisAddress = false;
    const locationAddresses = addresses?.filter(
      (addressObj) =>
        !addressObj.isPrimary && !addressObj.isVisit && !addressObj.isAlternativeBilling
    );

    console.log("Praxis Address", locationAddresses, addressId);

    if (
      locationAddresses.length &&
      locationAddresses.findIndex((add) => add.id == addressId) > -1
    ) {
      isSelectedAddressPraxisAddress = true;
    }
    return isSelectedAddressPraxisAddress;
  };

  const getAddressForAppointmentTemplate = (
    templateData: AppointmentTemplateDto,
    rxs: RxDto[]
  ): string => {
    let addressId = "";
    try {
      if (templateData) templateData = { ...templateData };
      templateData.therapyRx.map((therapyRxObj) => {
        if (therapyRxObj.id && !templateData.addressId && !addressId) {
          const address = getAddressOfRxByTherapyRx(therapyRxObj.id, rxs);
          if (address?.id) addressId = address.id;
        }
      });
    } catch (error) {
      console.log("Problem in assigning address");
    }

    return addressId;
  };
  const getDefaultVisitFeeByRxOrt = (
    therapyRxId: string,
    rxs: RxDto[],
    selectedTherapyData:TherapyRxDto[]
  ): string | null => {
    // Validate inputs
    if (!therapyRxId || !Array.isArray(selectedTherapyData) || selectedTherapyData.length === 0) {
      return null;
    }
  
    // Check if all therapyRxs are home visits
    const allAreHomeVisits = selectedTherapyData.every((therapyRx:any) =>
      therapyRx.rx.isHomeVisit === true
    );
  
    if (!allAreHomeVisits) {
      return null; // If any therapy prescription is not a home visit
    }
  
    // Extract the target address
    const targetAddress =
      addresses.find((address) => address.isVisit === true) || // Priority: isVisit === true
      addresses.find((address) => address.isPrimary === true); // Fallback: isPrimary === true
  
    if (!targetAddress) {
      return null; // No valid target address found
    }
  
    // Find the therapy prescription matching the therapyRxId
    const rxIndex = rxs.findIndex((rxObj) =>
      rxObj.therapyRxs?.some((thxObj) => thxObj.id === therapyRxId)
    );
  
    if (rxIndex === -1) {
      return null; // Therapy prescription not found
    }
  
    const rxVisitType = targetAddress?.type?.toLowerCase();
  
    // Determine the visit fee based on visit type and target address type
    if (rxVisitType ==="haus") {
      return "HB";
    } else if (
      rxVisitType === "heim"
    ) {
      return "HB2";
    }
  
    return null; // No valid match found
  };

  // const getDefaultVisitFeeByRxOrt = (thearpyRxId: string, rxs: RxDto[]): string | null => {


  //   // are all of the therapy rxs home vist?
  //   //       if not return null
  //   // extract target address 
  //   //     target address is the patients address with isVisit flag true, or if non are true, take the address with  isPrimary == true
  //   // ---> the type of this address tells you house vs heim ..
  //   let visitFee = null;

  //   const rxIndex = rxs.findIndex((rxObj) =>
  //     rxObj.therapyRxs?.some((thxobj) => thxobj.id == thearpyRxId)
  //   );

  //   if (rxIndex > -1) {
  //     let rxVisitType = rxs[rxIndex].visitType;

  //     //Haus
  //     if (rxVisitType == visitTypeReverseMapping["hb"]) {
  //       visitFee = "HB";
  //     } else if (rxVisitType == visitTypeReverseMapping["hm"]) {
  //       visitFee = "HB2";
  //     }
  //   }

  //   return visitFee;
  // };

  const resetSelectedVisitFeeObj = (therapRxId: string, isContinuous: boolean) => {
    updateCheckedMapVisitFeeObj(therapRxId, "", isContinuous);
    const setVisitFeeFunc = isContinuous ? setPerpetualSelectedVisitFeeObj : setSelectedVisitFeeObj;
    setVisitFeeFunc(undefined);
  };

  const assignVisitFeeToAppointmentTemplate = (
    templateData: AppointmentTemplateDto,
    rxs: RxDto[],
    isContinuous: boolean,
    selectedTherapyData:TherapyRxDto[]
  ): AppointmentTemplateDto => {
    if (templateData) templateData = { ...templateData };
    let visitFeeObj = isContinuous ? selectedPerpetualVisitFeeObj : selectedVisitFeeObj;
    let visitFee: string | null = visitFeeObj?.visitFee!;
    let firstTherapyRxIdAssgined = null;
    let setVisitFeeFunc = isContinuous ? setPerpetualSelectedVisitFeeObj : setSelectedVisitFeeObj;
    let visitFeeValueMapToFee: { [key: string]: string | null } = {};
    try {
      let visitFeeValue: string;

      templateData.therapyRx.map((therapyRxObj) => {
        if (therapyRxObj.id) {
          let defaultVisitFee = getDefaultVisitFeeByRxOrt(therapyRxObj.id, rxs,selectedTherapyData);

          //condition to handle ORT if Praxis where visitfee is null OR Impact in Visit Fee: If address is Praxis then remove visit fee from all therapyRxs
          if (!defaultVisitFee || visitFeeObj === null) {
            therapyRxObj.visitFee = null;
            return therapyRxObj;
          }

          //condition to assign the same visitFee to the other therapyRx as the first one in the templateData
          if (defaultVisitFee && !visitFee) visitFee = defaultVisitFee;

          // To make others null expect selected therapy

          if (
            visitFeeObj !== undefined &&
            visitFee &&
            visitFeeObj?.therapyRxId &&
            visitFeeObj.therapyRxId !== therapyRxObj.id
          ) {
            therapyRxObj.visitFee = null;
            //updateCheckedMapVisitFeeObj(therapyRxObj.id, "", isContinuous);
            return therapyRxObj;
          }

          if (visitFee && visitFeeValue) {
            therapyRxObj.visitFee = null;
            return therapyRxObj;
          }
          // condition when visitFee is assigned to the first therapyRx in the group in templateData
          if (visitFee) {
            let therapy = therapyRxObj.therapy as TherapyDto;
            if ("id" in therapy?.tarifGroup!) {
              let trafiGroupId = therapy.tarifGroup.id as string;
              visitFeeValue = getTherapyId(trafiGroupId, visitFee);
              therapyRxObj.visitFee = visitFeeValue!;
              firstTherapyRxIdAssgined = therapyRxObj.id;
              if (visitFeeValue) {
                setVisitFeeValueToFeeCodeMap((prevData) => ({
                  ...prevData,
                  [visitFeeValue]: visitFee,
                }));
                visitFeeValueMapToFee[visitFeeValue] = visitFee;
              }

              // updateCheckedMapVisitFeeObj(therapyRxObj.id, visitFee, isContinuous);
            }
          }
        }
      });
    } catch (error) {
      console.log("Problem in assigning address");
    }
    if (!visitFeeObj && firstTherapyRxIdAssgined && visitFee) {
      setVisitFeeFunc({ therapyRxId: firstTherapyRxIdAssgined, visitFee: visitFee });
    }

    return templateData;
  };

  const validateAppointmentTemplateOnSubmit = (
    templateData: AppointmentTemplateDto,
    rxs: RxDto[],
    selectedTherapyData:TherapyRxDto[]
  ): string => {
    //TherpayRx length check
    if (!templateData.therapyRx.length) return "You should select atleast on therapyRx";

    //AddresId missing check
    if (!templateData.addressId) return "Address is missing";

    //amount should be a number if amount property exists
    if (templateData?.hasOwnProperty("amount")) {
      if (typeof templateData.amount !== "number" || isNaN(templateData.amount)) {
        return "Amount should be a valid number";
      }
    }

    //Visit Fee not allowed if Praxis Address is selcted check
    if (isSelectedAddressIsPraxis(templateData.addressId)) {
      console.log("Selected Address Praxis", templateData);

      let doesVisitFeeExists = templateData.therapyRx.findIndex((thrxObj) => thrxObj.visitFee);
      if (doesVisitFeeExists > -1) return "Visit fee not allowed if Praxis address is selected";
    } else {
      //Selected address is not praxis, check for visit fee
      let visitFeeApplied = false;
      let visitFeeCount = 0;
      for (let i = 0; i < templateData.therapyRx.length; i++) {
        if (templateData.therapyRx[i].visitFee) {
          visitFeeApplied = true;
          visitFeeCount = visitFeeCount + 1;
        }
      }
      if (!visitFeeApplied) return "There should be a visit fee for home visits";
      //there is visitFee; check for visitFee count shoudl not be greater than 1
      if (visitFeeCount > 1) return "Visit fee can only be applied once";
    }

    //If Praxis therapyRx is selected then address should be only praxis address

    for (let i = 0; i < templateData.therapyRx.length; i++) {
      if (!getDefaultVisitFeeByRxOrt(templateData.therapyRx[i].id!, rxs,selectedTherapyData)) {
        //Praxis is selected

        //Check if address is praxis or not
        if (!isSelectedAddressIsPraxis(templateData.addressId)) {
          //if address is not praxis
          return "If Praxis is selected then the address can only be Praxis address";
        }
      }
      if( !templateData.therapyRx[i]?.therapy){
        return "Ausgewählte Therapie hat keine Therapie.";

      }
      if(!templateData.therapyRx[i].frequency){
        return "Bitte überprüfen Sie, ob die ausgewählte Therapie eine gültige Häufigkeit hat!"
      }
    }

    return "";
  };

  const handlecreateRx = async () => {
    const errorMessage = validateAppointmentTemplateOnSubmit(newData, notPerpetualRxs,selectedTherapies);
    if (errorMessage) {
      return notificationStore.showMessage(errorMessage, "error");
    }

    // return console.log("Create Appointment Success");

    await createForTreatments(newData);
    setAmount(0);
    setFrequency("");
    setDataRefreshFlag(!dataRefreshFlag);
    setCheckedMap({});
  };

  const handlecreateRxContinuous = async () => {
    const errorMessage = validateAppointmentTemplateOnSubmit(perpetualData, perpetaulRx,selectedPerpetualTherapies);
    if (errorMessage) {
      return notificationStore.showMessage(errorMessage, "error");
    }
    // return console.log("Create Appointment Success");
    await createForTreatments(perpetualData, true);
    setperpetualFrequency("");
    setDataRefreshFlag(true);
  };

  const handleContinousRx = async () => {
    // setperpetualAmount(0);
    setperpetualFrequency(-1);
    setDataRefreshFlag(true);
  };

  //   // This useEffect will run when the dialog box is opened.
  //   if (isSuggestionDialogOpen) {
  //     const fetchData = async () => {
  //       try {
  //         const response = await getSolutionsForContinuous(perpetualData, 4);
  //         console.log("response778", response);
  //         setSuggestionData(response);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [isSuggestionDialogOpen]);

  const [checkedMap, setCheckedMap] = useState<{ [key: string]: string | null }>({});
  const [checkedMapPerpetual, setCheckedMapPerpetual] = useState<{ [key: string]: string | null }>(
    {}
  );

  const updateCheckedMapVisitFeeObj = (
    therapyRxId: string,
    visitFee: string,
    isContinuous: boolean
  ) => {
    if (isContinuous) {
      setCheckedMapPerpetual((prevState) => ({
        ...prevState,
        [therapyRxId]: visitFee,
      }));
    } else {
      setCheckedMap((prevState) => ({
        ...prevState,
        [therapyRxId]: visitFee,
      }));
    }
  };

  const handleDropDownMenuChange = (
    therapyId: any,
    newVisitFee: string,
    tarifGroup: any,
    isContinuous: boolean
  ) => {
    const defaultVisitFee = getDefaultVisitFeeByRxOrt(
      therapyId,
      isContinuous ? perpetaulRx : notPerpetualRxs,
      isContinuous?selectedPerpetualTherapies:selectedTherapies
    );

    if (!defaultVisitFee) {
      updateCheckedMapVisitFeeObj(therapyId, "", isContinuous);
      return;
    }

    const appointmentTemplate: AppointmentTemplateDto = isContinuous ? perpetualData : newData;
    const setVisitFeeFunc = isContinuous ? setPerpetualSelectedVisitFeeObj : setSelectedVisitFeeObj;
    const therapyExistsInSelectedGroup = appointmentTemplate.therapyRx.findIndex(
      (therapyRxObj) => therapyRxObj.id == therapyId
    );
    if (therapyExistsInSelectedGroup > -1) {
      //Triggers the assignment of visitFees to the group of therapyRx

      if (visitFeeOptions.findIndex((option) => option.value == newVisitFee) < 0) {
        setVisitFeeFunc(null);
        return;
      }

      setVisitFeeFunc((prevData) => ({
        ...prevData,
        therapyRxId: therapyId,
        visitFee: newVisitFee,
      }));
    } else {
      // updates the visitFee to single therapyRx
      updateCheckedMapVisitFeeObj(therapyId, newVisitFee, isContinuous);
    }
  };

  const getVisitFeeDropdownValue = (
    templateData: AppointmentTemplateDto,
    therapyRxId: string,
    isContinuous: boolean
  ): string | undefined | null => {
    let selectedVisitFee = isContinuous ? selectedPerpetualVisitFeeObj : selectedVisitFeeObj;
    // let emptyValue = Math.random() * 1000 + "";
    let emptyValue = " ";
    //.repeat(Math.random() * 100);
    if (selectedVisitFee === null) {
      return emptyValue;
    }
    // let templateData = isContinuous ? perpetualData : newData;
    const thObjIndex = templateData.therapyRx.findIndex((thObj) => thObj.id == therapyRxId);
    if (thObjIndex > -1) {
      let visitFeeValue = templateData.therapyRx[thObjIndex].visitFee;

      if (visitFeeValue) {
        if (visitFeeValueToFeeCodeMap?.[visitFeeValue] == null) return emptyValue;
        return visitFeeValueToFeeCodeMap?.[visitFeeValue]!;

        // let visitFee = getVisitFeeFromVisitFeeValue(templateData, therapyRxId, visitFeeValue);
        // return visitFee;
      }
      return emptyValue;
    } else {
      //AddressId change is praxis address

      if (!checkedMap[therapyRxId]) return emptyValue;
      return checkedMap[therapyRxId] || emptyValue;
    }
  };
  // console.log("NewRx", newData, perpetualData, toJS(masterData), rxs, blockedData, patientId);
  const notPerpetualRx = rxs.filter((rx) => !rx.isPerpetual);
  const perpetaulRx = rxs.filter((rx) => rx.isPerpetual);
  // console.log("Not perpetual", notPerpetualRx);

  const visitFeeOptions = [
    { label: "HB2K", value: "HB2K" },
    { label: "HB7", value: "HB7" },
    { label: "HB", value: "HB" },
    { label: "HB2", value: "HB2" },
  ];

  // console.log("NewData", newData, perpetualData);
  console.log("NewData", newData);

  return (
    <>
      <FlatCard title="Standardtermine">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {notPerpetualRx.length === 0 && (
              <StyledTableRow>
                <TableCell colSpan={6} sx={{ padding: "2px 200px" }}>
                  Keine ungeplante Rezepte
                </TableCell>
              </StyledTableRow>
            )}

            <StyledTableContainer>
              <StyledTable>
                {notPerpetualRx.length > 0 && (
                  <TableHead>
                    <StyledTableRow >
                      <StyledTableCell>Rezept</StyledTableCell>
                      <StyledTableCell>Heilm.</StyledTableCell>
                      <StyledTableCell>Freq.</StyledTableCell>
                      <StyledTableCell>Menge</StyledTableCell>
                      <StyledTableCell>Fehlt</StyledTableCell>
                      <StyledTableCell>Ort</StyledTableCell>
                      <StyledTableCell>HB Pauschale</StyledTableCell>
                      <StyledTableCell>Auswahl</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                )}
                <TableBody>
                  {notPerpetualRx.map((rx) => (
                    <React.Fragment key={rx.id}>
                      {rx?.therapyRxs
                        ?.filter(
                          (therapy) =>
                            therapy?.amount !== undefined &&
                            therapy?.scheduledCount !== undefined &&
                            therapy?.amount - therapy?.scheduledCount !== 0
                        ) // Filter based on scheduledCount
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((therapy: any, index: any) => (
                          <StyledTableRow key={therapy.id}>
                            {index === 0 && (
                              <StyledTableCell
                                rowSpan={
                                  rx.therapyRxs?.filter(
                                    (therapy) =>
                                      therapy?.amount !== undefined &&
                                      therapy?.scheduledCount !== undefined &&
                                      therapy?.amount - therapy?.scheduledCount !== 0
                                  ).length
                                }
                              >
                                {rx.rxNumber}
                              </StyledTableCell>
                            )}
                            <StyledTableCell>{therapy?.therapy?.abbreviation}</StyledTableCell>
                            <StyledTableCell>{therapy?.frequency?.text}</StyledTableCell>
                            <StyledTableCell>{therapy?.amount}</StyledTableCell>
                            <StyledTableCell>
                              {therapy?.amount - therapy?.scheduledCount}
                            </StyledTableCell>
                            <StyledTableCell>{rx.isHomeVisit === true
                        ? "Haus/Heim"
                        : rx.isHomeVisit === false
                        ? "Praxis"
                        : "-"}</StyledTableCell>

                            <StyledTableCell>
                              <Box sx={{ minWidth: 60 }}>
                                <Controls.Select
                                  id="notPerpetualVisitFee"
                                  name="visitFEe"
                                  value={() => {
                                    let val = getVisitFeeDropdownValue(newData, therapy.id, false);
                                    return val;
                                  }}
                                  onChange={(e: any) => {
                                    handleDropDownMenuChange(
                                      therapy.id,
                                      e.target.value,
                                      therapy.therapy.tarifGroup?.id,
                                      false
                                    );
                                  }}
                                  options={
                                    visitFeeOptions
                                    // [
                                    //   { label: "HB2K", value: "HB2K" },
                                    //   { label: "HB7", value: "HB7" },
                                    //   { label: "HB", value: "HB" },
                                    // ]
                                  }
                                  disabled={!getDefaultVisitFeeByRxOrt(therapy.id, notPerpetualRxs,selectedTherapies)}
                                  style={{
                                    width: "100%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    fontSize: "10px",
                                  }}
                                />
                              </Box>
                            </StyledTableCell>

                            <StyledTableCell>
                              {selectedTherapies.some((selected) => selected.id === therapy.id) ? (
                                <Button
                                  onClick={() => handleRemove(therapy.id)}
                                  sx={{
                                    backgroundColor: "success.main",
                                    color: "white",
                                    height: "20px",
                                    width: "15px",
                                    fontSize: "12px", // Adjust the font size as needed
                                  }}
                                >
                                  ✓
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => handleRowSelect(therapy.id)}
                                  sx={{
                                    backgroundColor: "error.main",
                                    color: "white",
                                    height: "20px",
                                    width: "15px",
                                    fontSize: "12px", // Adjust the font size as needed
                                  }}
                                >
                                  ✗
                                </Button>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
        </Grid>

        {notPerpetualRx.length !== 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                type="number"
                label="Menge"
                variant="standard"
                sx={{ paddingLeft: "5px", height: "10px" }} // Adjust the width as needed
                // fullWidth
                value={amount}
                onChange={(e: any) => {
                  setAmount(Math.abs(parseInt(e.target.value)));
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Frequenz"
                variant="standard"
                sx={{ paddingLeft: "5px" }} // Adjust the width as needed
                // fullWidth
                value={frequency}
                onChange={(e: any) => setFrequency(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              {/* Address */}

              <FormControl
                variant="standard"
                sx={{ paddingLeft: "5px", minWidth: 60, width: "100%" }}
              >
                <InputLabel shrink>Address</InputLabel>
                <Select
                  value={(function () {
                    return addresses?.filter((add) => add.id == newData.addressId)[0]?.id || "";
                  })()}
                  onChange={(e: any) => {
                    setAddressId(e.target.value);
                    //onAddressChange(e.target.value, newData, notPerpetualRxs, false);
                  }}
                >
                  {addresses?.map((addressObj) => (
                    <MenuItem value={addressObj.id}>
                     {addressObj.type?visitTypeToIcon[addressObj.type?.toLowerCase()]:undefined} {addressObj.contactInfo?.addressLine1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlecreateRx}
                sx={{ marginTop: "10px" }}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        )}
      </FlatCard>
      <FlatCard title="Dauertermine">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {perpetaulRx.length === 0 && (
              <StyledTableRow>
                <TableCell colSpan={6} sx={{ padding: "2px 200px" }}>
                  Keine ungeplante Rezepte
                </TableCell>
              </StyledTableRow>
            )}

            <StyledTableContainer>
              <StyledTable>
                {perpetaulRx.length > 0 && (
                  <TableHead>
                    <StyledTableRow sx={{ backgroundColor: mobiliTheme.palette.primary.light }}>
                      <StyledTableCell>Rezept</StyledTableCell>
                      <StyledTableCell>Heilm.</StyledTableCell>
                      <StyledTableCell>Freq.</StyledTableCell>
                      <StyledTableCell>Menge</StyledTableCell>
                      <StyledTableCell>Fehlt</StyledTableCell>
                      <StyledTableCell>Ort</StyledTableCell>
                      <StyledTableCell>HB Pauschale</StyledTableCell>
                      <StyledTableCell>Auswahl</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                )}
                <TableBody>
                  {perpetaulRx.map((rx) => (
                    <React.Fragment key={rx.id}>
                      {rx?.therapyRxs
                        ?.filter(
                          (therapy) =>
                            therapy?.amount !== undefined &&
                            therapy?.scheduledCount !== undefined &&
                            therapy?.amount - therapy?.scheduledCount !== 0
                        ) // Filter based on scheduledCount
                        .map((therapy: any, index: any) => (
                          <StyledTableRow key={therapy.id}>
                            {index === 0 && (
                              <StyledTableCell
                                rowSpan={
                                  rx.therapyRxs?.filter(
                                    (therapy) =>
                                      therapy?.amount !== undefined &&
                                      therapy?.scheduledCount !== undefined &&
                                      therapy?.amount - therapy?.scheduledCount !== 0
                                  ).length
                                }
                              >
                                {rx.rxNumber}
                              </StyledTableCell>
                            )}
                            <StyledTableCell>{therapy?.therapy?.abbreviation}</StyledTableCell>
                            <StyledTableCell>{therapy?.frequency?.text}</StyledTableCell>
                            <StyledTableCell>{therapy?.amount}</StyledTableCell>
                            <StyledTableCell>
                              {therapy?.amount - therapy?.scheduledCount}
                            </StyledTableCell>
                            <StyledTableCell>{rx.isHomeVisit === true
                        ? "Haus/Heim"
                        : rx.isHomeVisit === false
                        ? "Praxis"
                        : "-"}</StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              {/* The pull down in Dauertermine for the visit fee */}
                              <Box sx={{ minWidth: 60 }}>
                                <Controls.Select
                                  id="perpetualVisitFee"
                                  name="visitFEePerpetual"
                                  value={() => {
                                    let val = getVisitFeeDropdownValue(
                                      perpetualData,
                                      therapy.id,
                                      true
                                    );
                                    return val;
                                  }}
                                  onChange={(e: any) =>
                                    handleDropDownMenuChange(
                                      therapy.id,
                                      e.target.value,
                                      therapy.therapy.tarifGroup?.id,
                                      true
                                    )
                                  }
                                  options={
                                    visitFeeOptions
                                    // [
                                    //   { label: "HB2K", value: "HB2K" },
                                    //   { label: "HB7", value: "HB7" },
                                    //   { label: "HB", value: "HB" },
                                    // ]
                                  }
                                  disabled={!getDefaultVisitFeeByRxOrt(therapy.id, perpetaulRx,selectedPerpetualTherapies)}
                                  // disabled={readOnlyView}
                                  style={{
                                    width: "100%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    fontSize: "10px",
                                  }}
                                />
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell>
                              {selectedPerpetualTherapies.some(
                                (selected) => selected.id === therapy.id
                              ) ? (
                                <Button
                                  onClick={() => handleperpetualRemove(therapy.id)}
                                  sx={{
                                    backgroundColor: "success.main",
                                    color: "white",
                                    height: "20px",
                                    width: "15px",
                                    fontSize: "12px", // Adjust the font size as needed
                                  }}
                                >
                                  ✓
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  onClick={() => handleperpetualRowSelect(therapy.id)}
                                  sx={{
                                    backgroundColor: "error.main",
                                    color: "white",
                                    height: "20px",
                                    width: "15px",
                                    fontSize: "12px", // Adjust the font size as needed
                                  }}
                                >
                                  ✗
                                </Button>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
        </Grid>
        {perpetaulRx.length !== 0 && (
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={4}>
  <TextField
      label="Amount"
      variant="standard"
      sx={{ paddingLeft: '5px',height:"10px" }} // Adjust the width as needed
      // fullWidth
      value={perpetualAmount}
      onChange={(e: any) => setperpetualAmount(e.target.value)}
      InputLabelProps={{ shrink: true }}
    />
  </Grid> */}
            <Grid item xs={12} md={3}>
              <TextField
                label="Frequency"
                variant="standard"
                sx={{ paddingLeft: "5px" }} // Adjust the width as needed
                // fullWidth
                value={perpetualfrequency}
                onChange={(e: any) => setperpetualFrequency(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              {/* Address */}

              <FormControl
                variant="standard"
                sx={{ paddingLeft: "5px", minWidth: 60, width: "100%" }}
              >
                <InputLabel shrink>Address</InputLabel>
                <Select
                  value={(function () {
                    return (
                      addresses?.filter((add) => add.id == perpetualData.addressId)[0]?.id || ""
                    );
                  })()}
                  onChange={(e: any) => {
                    setPerpetualAddressId(e.target.value);
                  }}
                >
                  {addresses?.map((addressObj) => (
                    <MenuItem value={addressObj.id}>
                      {addressObj.type?visitTypeToIcon[addressObj.type?.toLowerCase()]:undefined}{addressObj.contactInfo?.addressLine1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              {/* <Button
      variant="contained"
      color="primary"
      onClick={() => handleContinousRx()}
      sx={{marginTop:"10px"}}
    >
      OK
    </Button> */}
              <Button
                variant="contained"
                color="primary"
                // onClick={openSuggestionDialog}
                onClick={handlecreateRxContinuous}
                sx={{ marginTop: "10px" }}
              >
                OK
              </Button>
            </Grid>
            {/* <AppointmentSuggestion
            open={isSuggestionDialogOpen}
            onClose={() => setsuggestionDialogOpen(false)}
            continousData={perpetualData}
            setDataRefreshFlag={setDataRefreshFlag}
            dataRefreshFlag={dataRefreshFlag}
            patientId={patientId}
            blockedData={blockedData}
            setblockedData={setblockedData}
            continous={true}
            handleContinousRx={handleContinousRx}
            patientAvailabilityData={patientAvailablityData}
            setPatientAvailabilityData={setPatientAvailablityData}
          /> */}
          </Grid>
        )}
      </FlatCard>
    </>
  );
};

export default NewRx;
