import { AppointmentDto, TimeSlotDto, UserDto } from "../api-client";
import { TherapyExt } from "../molecules/Calendar/Calendar.type";

export const updateReceivedAppointment = (oldAppointment: AppointmentDto, user: UserDto, start: Date, end: Date) => {
  try {

    let current = new Date(start.getTime()!);

    // calculate the shift for heat treatments
    let shift = 0;
    const heatTreatments = ["PA", "HL"];
    let firstNonHeatTreatmentIndex = Infinity;

    // sort timeSlots by therapyRx order
    oldAppointment.timeSlots?.slice().sort(
        (a: TimeSlotDto, b: TimeSlotDto) => a.therapyRx?.order! - b.therapyRx?.order!
      ) as TimeSlotDto[];

    // Find the first non-heat treatment index
    oldAppointment.timeSlots?.forEach((timeSlot: TimeSlotDto, index: number) => {
      if (!heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation)) {
        firstNonHeatTreatmentIndex = Math.min(firstNonHeatTreatmentIndex, index);
      }
    });

    // Calculate the shift for heat treatments that are before the first non-heat treatment index
    oldAppointment.timeSlots?.forEach((timeSlot: TimeSlotDto, index: number) => {
      if (
        heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation) &&
        index < firstNonHeatTreatmentIndex
      ) {
        shift -= (timeSlot.therapyRx?.therapy as TherapyExt).duration;
      }
    });

    const newAppointment: AppointmentDto = {
      id: oldAppointment.id,
      start: start.toString()!,
      end: end.toString()!,
      frequency: oldAppointment.frequency,
      timeSlots: oldAppointment.timeSlots?.map((slot: any) => {
        const duration = parseInt((slot.therapyRx.therapy as TherapyExt).duration.toString());
        console.log("duration:", duration);
        const start = new Date(current.getTime());
        start.setMinutes(start.getMinutes());
        current.setMinutes(current.getMinutes() + duration);
        const end = new Date(current.getTime());
        start.setMinutes(start.getMinutes() + shift);
        end.setMinutes(end.getMinutes() + shift);
        console.log("start", start, "end", end);
        const timeSlot: TimeSlotDto = {
          id: slot.id,
          user,
          start: start.toISOString(),
          end: end.toISOString(),
          type: slot.type,
          therapyRx: {
            id: slot.therapyRx.id,
            therapy: {
              abbreviation: (slot.therapyRx.therapy as TherapyExt).abbreviation,
              duration: (slot.therapyRx.therapy as TherapyExt).duration,
            },
            amount: slot.therapyRx.amount,
            rx: {
              rxNumber: slot.therapyRx.rx.rxNumber,
            },
          },
        };
        return timeSlot;
      }),
    };
    console.log("Appointment to save", newAppointment);
    return newAppointment;
  } catch (error) {
    console.error("Error in createAppointment:", error);
    return null;
  };
}