import React from "react";
import { IconButton } from "@mui/material";
import { Save as SaveIcon, Edit as EditIcon, Cancel } from "@mui/icons-material";

interface EditOrDiscardToggleProps {
  editMode: boolean;
  disableSaveBtn: boolean;
  onSave: () => void;
  onDiscard: () => void;
  setEditMode: (value: boolean) => void;
}

const EditOrDiscardToggle = ({
  editMode,
  onSave,
  onDiscard,
  disableSaveBtn,
  setEditMode,
}: EditOrDiscardToggleProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {editMode && (
        <Cancel
          onClick={() => {
            onDiscard();
            setEditMode(false); // Exit edit mode on discard
          }}
          color="secondary"
          sx={{ cursor: "pointer" }}
        />
      )}
      <IconButton
        onClick={() => {
          if (editMode) {
            onSave();
            setEditMode(false); // Exit edit mode on save
          } else {
            setEditMode(true); // Enter edit mode
          }
        }}
        disabled={editMode && disableSaveBtn} // Only disable when saving
        style={{ marginLeft: editMode ? "10px" : "0" }}
        aria-label={editMode ? "Save" : "Edit"}
      >
        {editMode ? <SaveIcon sx={{ cursor: "pointer" }} /> : <EditIcon sx={{ cursor: "pointer" }} />}
      </IconButton>
    </div>
  );
};

export default EditOrDiscardToggle;
