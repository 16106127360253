import { action, makeObservable, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { PatientsApi, PatientDto, SearchDto, AddressDto } from "../../api-client";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";
import moment from "moment";
import NavigationStore from "../NavigationStore";
import UserStore from "../User/UserStore";

interface RoutingParams {
  pId: any;
  rxId?: any;
  selectedSubTab?: any;
  doctorReportId?:string | null;
  newdoctorReportId?:string | null;
}

export default class PatientsStore {
  public apiClient: PatientsApi;
  public authStore: AuthStore;
  public userStore: UserStore; // Include UserStore as a property
  //Array of all patients filtered
  @observable
  public selectedPatients: any = [];
  // Patient Data changes flag
  @observable
  public patientDataRefreshFlag: boolean = false;
  //Maintain the state of the tab
  @observable
  public state = {
    value: 0,
    tab: "new-person",
  };

  // @observable
  // public patientData: any=[];
  //currently selected patient id
  @observable
  public filteredPatient: any;
  //currently filtered patient
  @observable
  public selectedValue: string | null = null;
  //Subtabs open
  @observable
  public showSubTabs: boolean = false;
  @observable
  public patientSubTabs: Array<Array<any>> = [];

  @observable
  public isOpen: boolean = false;
  @observable
  public selectedSubTab: any = "";

  public selectedPatientsRoute: RoutingParams[] = [];

  private navigationStore: typeof NavigationStore; // Specify the type

  public constructor(apiClient: PatientsApi,userStore: UserStore, authStore: AuthStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.authStore = authStore;
    this.navigationStore = NavigationStore;
    this.userStore=userStore;
  }

  @action
  public setSelectedSubTab = (tab: number) => {
    this.selectedSubTab = tab;
  };

  @action
  public setPatientDataRefreshFlag = (value: boolean) => {
    this.patientDataRefreshFlag = value;
  };

  // Method to add or update selected patient route
  @action
  public updateSelectedPatientRoute = (routingParams: RoutingParams) => {
    const { pId, rxId, selectedSubTab,doctorReportId,newdoctorReportId } = routingParams;
    console.log("RoutingParams", routingParams);

    const existingPatientIndex = this.selectedPatientsRoute.findIndex(
      (patient: any) => patient.pId.toString() === pId.toString()
    );
    console.log("existing", existingPatientIndex, this.selectedPatientsRoute);

    if (existingPatientIndex !== -1) {
      // Update existing patient entry
      const existingPatient = this.selectedPatientsRoute[existingPatientIndex];
      const updatedPatient = {
        pId: pId,
        // rxId:  rxId || existingPatient.rxId  || null, // Preserve existing rxId if it exists
        rxId: rxId !== undefined ? rxId : existingPatient.rxId,
        doctorReportId:(doctorReportId!==undefined ) ? doctorReportId :existingPatient.doctorReportId,
        newdoctorReportId:newdoctorReportId || null
        // selectedSubTab: this.selectedSubTab || selectedSubTab || 0
      };
      this.selectedPatientsRoute[existingPatientIndex] = updatedPatient;
    } else {
      // Add new patient entry
      this.selectedPatientsRoute.push({
        pId: pId,
        rxId: rxId || null,
        doctorReportId:doctorReportId || null,
        newdoctorReportId:newdoctorReportId || null
        // selectedSubTab: selectedSubTab || 0
      });
    }

    console.log("Updated Selected Patients Route", this.selectedPatientsRoute);
    return this.selectedPatientsRoute;
  };

  // public updateSelectedSubTab = (pId: string, selectedSubTab: number) => {
  //   const existingPatientIndex = this.selectedPatientsRoute.findIndex((patient: any) => patient.pId.toString() === pId.toString());
  //   if (existingPatientIndex !== -1) {
  //     this.selectedPatientsRoute[existingPatientIndex].selectedSubTab = selectedSubTab;
  //   } else {
  //     console.error(`Patient with pId ${pId} not found in selectedPatientsRoute`);
  //   }
  // }

  @action
  public constructSearchPath = (routingParams: RoutingParams) => {
    const { pId, rxId, selectedSubTab,doctorReportId,newdoctorReportId } = routingParams;
    console.log("RoutingParams", routingParams, this.selectedSubTab);
    let urlPath = "/patient?";

    if (pId) {
      urlPath += `pId=${pId}`;
    }

    let finalSelectedSubTab = this.selectedSubTab; // Use selectedSubTab from the store by default

    // If selectedSubTab is provided from API response, use that instead
    if (selectedSubTab) {
      finalSelectedSubTab = selectedSubTab;
    }

    if (finalSelectedSubTab === 2) {
      urlPath += "&termin";
    } else if (finalSelectedSubTab === 3) {
      urlPath += "&bill";
    } else if (finalSelectedSubTab === 4) {
      urlPath += "&documents";
    }
    else if (finalSelectedSubTab === 5) {
      urlPath += "&medicalDocumentation";
    } 
    else if (finalSelectedSubTab ===6 ) {
      urlPath += "&drReports";
    } 
     else {
      urlPath += "&rxinfo"; // Default to rxinfo if selectedSubTab is not provided or not 1 or 2
    }

    if ( rxId) {
      urlPath += `&rxId=${rxId}`;    //"&" not "?" a URL cannot have two ? characters
    }
    if (newdoctorReportId !== undefined && newdoctorReportId !== null) {
      urlPath += `&newdoctorReportId=${newdoctorReportId}`;
    }
    // Add doctorReportId only if it's defined (not null or undefined)
 else if (doctorReportId !== undefined && doctorReportId !== null) {
    urlPath += `&doctorReportId=${doctorReportId}`;
  }


    console.log("Constructed URL Path", urlPath, finalSelectedSubTab);
    return urlPath;
  };

  @action
  public getSelectedPatientparams = (patientId: any) => {
    if (patientId) {
      const patient: any = this.selectedPatientsRoute.find(
        (patient: any) => patient.pId.toString() === patientId.toString()
      );
      console.log("SelectedRouttre", this.selectedPatientsRoute, patient, patientId);
      return patient;
    }
  };

  @action
  public setSelectedPatients = (patient: any, selectedPatient: any) => {
    if (patient) {
      this.selectedPatients = patient;
    } else {
      this.selectedPatients = [];
    }
    console.log("SELECTED PATIENTS", this.selectedPatients, selectedPatient);
    if (selectedPatient) {
      this.setFilteredPatient(selectedPatient.id);
      this.setSelectedValue(
        `${selectedPatient.lastName}, ${selectedPatient.firstName}, ${moment(
          selectedPatient.dob
        ).format("DD.MM.yyyy")} (${selectedPatient.id})`
      );
    } else {
      this.setFilteredPatient(null);
      this.setSelectedValue(null);
    }

    return this.selectedPatients;
  };

  @action
  public setShowSubTabs = (value: boolean) => {
    this.showSubTabs = value;
  };

  @action
  public setPatientSubTabs = (value: any) => {
    this.patientSubTabs = value;
  };

  @action
  public setFilteredPatient = (patient: any) => {
    this.filteredPatient = patient;
    const patientId = patient ? patient : null;

    // navigationStore.navigateToPatient(patientId)

    //     this.updateSelectedPatientRoute(patientId);
  };

  @action
  public setSelectedValue = (value: string | null) => {
    console.log("SELECTED VALUE", value);
    this.selectedValue = value;
  };

  //   @action setPatientData = ( data:any,selectedValue:any) => {
  //     this.patientData = data;
  //     console.log('PATIENT DATA',data,selectedValue)

  //  if(selectedValue){
  //       this.setSelectedValue(`${selectedValue.lastName},${selectedValue.firstName} (${moment(selectedValue.dob).format('DD.MM.yyyy')})-${selectedValue.id}`)}
  //       else{
  //         this.setSelectedValue(null)
  //       }

  //   };

  @action
  public setState = (value: any) => {
    this.state = value;
  };

  // @observable
  // public masterPatientData: PatientDto | undefined;

  @observable
  public setOpen = (value: boolean) => {
    this.isOpen = value;
  };

  // ... Other existing code ...

  // @action
  // public setMasterPatientData = (options: PatientDto) => {
  //   this.masterPatientData = options;
  // };

  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  /*
  @action
  public getPatients = async () => {
    const { data } = await this.apiClient.getPatients() as any;
    return data || [];
  };

  */
  @action
  public getAllPatients = async () => {
    const { data } = (await this.apiClient.getAllPatients()) as any;
    return data || [];
  };

  @action
  public getPatient = async (id: number): Promise<PatientDto> => {
    const { data } = (await this.apiClient.getPatient(id)) as any;
    if (!data) {
      throw new Error("Patient not found");
    }

    return data;
  };

  @action
  public async updatePatientData(patientId: number) {
    try {
      // Call the searchPatient method to retrieve updated patient data
      const response = await this.searchPatient({ id: patientId } as SearchDto);

      // Check if the patient already exists in selectedPatients
      const index = this.selectedPatients.findIndex((patient: any) => patient.id === response.id);
      if (index !== -1) {
        // Update the existing patient data in both selectedPatients and patientData
        this.selectedPatients[index] = response;
        // this.patientData[index] = response;
        // Update selectedPatients and patientData
        this.setSelectedPatients([...this.selectedPatients], response);
        // this.setPatientData([...this.patientData], response);
      }
    } catch (error) {
      // Handle errors if necessary
      console.error("Error updating patient data:", error);
    }
  }

  @action
  public postPatients = async (body: PatientDto) => {
    try {
      if (!this.userStore.user?.location?.id) {
        console.warn("Location Id not yet ready");
        return [];
      }
      const { data } = (await this.apiClient.postPatients({...body,location:this.userStore.user?.location})) as any;
      console.log("AKKSKKSSOOSOOOOOOOOOOOOOOOOO", data);
      if (data.patient) {
        if (data.messages && data.messages.length > 0) {
          const messages = data?.messages.map((msg: any) => msg.message);
          // Display the first message from data.message
          notificationStore.showMessage(messages.join("\n"), "success");
        } else {
          notificationStore.showMessage("Der Patient wurde erfolgreich erstellt!!", "success");
        }

        const response = await this.searchPatient({ id: data.patient.id });
        // Check if the patient already exists in selectedPatients
        const index = this.selectedPatients.findIndex((patient: any) => patient.id === response.id);
        if (index !== -1) {
          // Update the existing patient data in both selectedPatients and patientData
          this.selectedPatients[index] = response;
          //  this.patientData[index] = response;
          // Update selectedPatients and call setSelectedPatients
          this.setSelectedPatients([...this.selectedPatients], response);
          //  this.setPatientData([...this.patientData], response);
        }
        this.setPatientDataRefreshFlag(!this.patientDataRefreshFlag);
        //  else {
        //    // Add the new patient data to both selectedPatients and patientData
        //    this.selectedPatients.push(response);
        //    this.patientData.push(response);
        //  }

        // this.setMasterPatientData(response)
        // console.log('INSTRORE',this.masterPatientData)

        // this.setMasterPatientData(response)
        // console.log('INSTRORE',this.masterPatientData)

        return response || [];
      }
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      console.log("ERROR", error);
      // Check if the error response contains a message
      const errorMessage =
        error.response?.data?.message || "Fehler bei der Patientenaktualisierung";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
  @action
  public searchPatient = async (body: SearchDto) => {
    try {
      const { data } = (await this.apiClient.searchPatient(body)) as any;

      // this.setPatientData(data)
      // this.setMasterPatientData(data)

      // notifySuccess(`Der Patient wurde erfolgreich aktualisiert.`);
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage(
        "Patientendaten konnten nicht abgerufen werden!",
        "error",
        error
      );
      // notifyError('Failed')
    }
  };

  @action
  public deletePatientAddres = async (addressId: string) => {
    try {
      const response = await this.apiClient.deleteAddress(addressId);

      const data = response.data as AddressDto;

      return data;
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage("Fehler beim Löschen der Patientenadresse", "error", error);
      // notifyError('Failed')
    }
  };

  @action
  public getLiveValidation = async (rx: any) => {
    try{
      console.log('VALUES LETS SEEEEEEEEEEEEEEEE',rx)
      const {data} = (await this.apiClient.getLiveValidation(rx)) as any;
      console.log('LIVE VALIDATION',data)
      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage("Fehler bei der Patientenaktualisierung", "error", error);
      // notifyError('Failed')
    }
  }

  // Required in future
  // @action
  // public searchPatients = async (text: string) => {
  //   const { data } = await this.apiClient.searchPatients(text) as any;
  //   return data || [];
  // };
}
