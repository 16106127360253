import React, { useEffect, useState } from "react";
import { Button, IconButton, Grid, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Add, List, Close } from "@mui/icons-material";
import { CreateInitialEvaluationDto, GetInitialEvaluationDto } from "../../../api-client";
import moment from "moment";
import InitialEvaluationList from "./InitialEvaluationList";
import InitialEvaluationCard from "./InitialEvaluationCard";
import useStore from "../../../helpers/useStore";
import { DocumentationContext } from "../../../stores/Documentation/Documentation.provider";
import { observer } from "mobx-react-lite";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
interface InitialEvaluationsProps {
  patientId: string;
}

const InitialEvaluations: React.FC<InitialEvaluationsProps> = observer(({ patientId }) => {

  const pId = parseInt(patientId!);
  const DocumentationStore = useStore(DocumentationContext);
  const {
    fetchInitialEvaluations,
    createInitialEvaluation,
    updateInitialEvaluation,
    resetInitialEvaulations,
    initialEvaluations,
  } = DocumentationStore;

  const [showDummyInitialEvaluation, setShowDummyInitialEvaluation] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const latestInitialEvaluation = initialEvaluations?.[0];

  useEffect(() => {
    if (pId) {
      resetInitialEvaulations();
      fetchInitialEvaluations(pId, 1, 1);
    }
  }, [pId]);

  const handleAddDummyInitialEvaluation = () => {
    if (!pId) return null;
    setShowDummyInitialEvaluation(true);
  };

  const handleCreateInitialEvaluation = (initialEvaluation: CreateInitialEvaluationDto) => {
    createInitialEvaluation(initialEvaluation)
      .then(() => {
        setShowDummyInitialEvaluation(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {(showDummyInitialEvaluation || !latestInitialEvaluation) && (
        <Box position="relative" marginBottom={2}>
          <InitialEvaluationCard
            patientId={pId}
            onCreateInitialEvaluation={handleCreateInitialEvaluation}
            onUpdateInitialEvaluation={updateInitialEvaluation}
            onDeleteInitialEvaluation={() => setShowDummyInitialEvaluation(false)}
          />
        </Box>
      )}

      {latestInitialEvaluation && (
        <Box position="relative">
          {/* Floating Icons */}
          <Grid
            container
            spacing={1}
            sx={{
              position: "absolute",
              top: 0,
              right: -550, 
              zIndex: 10,
              paddingX: 1,
              pointerEvents: "none",
            }}
          >
            <Grid item>
              <IconButton
                color="default"
                onClick={() => setShowDialog(!showDialog)}
                sx={{ pointerEvents: "auto" }}
              >
                <List />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="default"
                onClick={handleAddDummyInitialEvaluation}
                disabled={showDummyInitialEvaluation}
                sx={{ pointerEvents: "auto" }}
              >
                <Add />
              </IconButton>
            </Grid>
          </Grid>

          {/* Initial Evaluation Card (Now Clickable) */}
          <InitialEvaluationCard
            patientId={pId}
            initialEvaluation={latestInitialEvaluation}
            onCreateInitialEvaluation={createInitialEvaluation}
            onUpdateInitialEvaluation={updateInitialEvaluation}
          />
        </Box>
      )}

      <Dialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>
          Initial Evaluations
          <IconButton
            aria-label="close"
            onClick={() => setShowDialog(false)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InitialEvaluationList patientId={pId} limit={10} />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default InitialEvaluations;
