import { action, makeObservable, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";
import { BiApi } from "../../api-client";
import { AxiosResponse } from "axios";

export default class BIStore {
  public apiClient: BiApi;
  public authStore: AuthStore;

  public constructor(apiClient: BiApi, authStore: AuthStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.authStore = authStore;
  }

  // add properties here that will behave like state variables

  // Also we wrap our APIs here:

  // @action - do we really need action here?

  public getPatientStats = async () => {
    try {
      const { data } = await this.apiClient.getPatientStats();
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response.data.message || "Problem fetching patient stats";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public getRxStats = async () => {
    try {
      const { data } = await this.apiClient.getRxStats();
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response.data.message || "Problem fetching Rx stats";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public getScheduleBasedStats = async (startDate: Date, endDate: Date) => {
    try {
      const { data } = await this.apiClient.getScheduleBasedStats(startDate.toISOString(), endDate.toISOString());
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response.data.message || "Problem fetching schedule-based stats";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public getTherapistStats = async (startDate: Date, endDate: Date) => {
    try {
      const { data } = await this.apiClient.getTherapistStats(startDate.toISOString(), endDate.toISOString());
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response.data.message || "Problem fetching therapist stats";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
}
