import { useEffect, useState } from "react";
import useStore from "../helpers/useStore";
import { TransactionsContext } from "../stores/Transactions/Transactions.provider";


export function useDownload() {
     const transactionStore = useStore(TransactionsContext);
    const { downloadPdf } = transactionStore;
    const [accessUrl, setAccessUrl] = useState<string>();
  
    const handleDownloadPdf = async (id: string) => {
      try {
        const response = await downloadPdf(id);
        setAccessUrl(response.accessUrl);
      } catch (err) {
        console.error("Error downloading pdf", err);
      }
    };
  
    useEffect(() => {
      if (accessUrl) {
        window.open(accessUrl, "_blank");
      }
    }, [accessUrl]);

    return { handleDownloadPdf };
}