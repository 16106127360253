import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextareaAutosize,
  Avatar,
  Box,
  Tooltip,
  TextField,
} from "@mui/material";
import { CreateTreatmentNoteDto, TreatmentNoteDTO, TimeSlotDto, RxDtoProcessingStatusEnum } from "../../../api-client";
import moment from "moment";
import { observer } from "mobx-react";
import EditOrDiscardToggle from "../../../helpers/EditorDiscardToggle";
import { formatDateUI, formatTime } from "../../../helpers/dateFormat";
import Controls from "../../../atoms/Controls";
import { TherapyChip } from "../../../atoms/Chip";
import { getStatusColor } from "../../PatientOverview/RxInfo/RxForms/statusColor";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";

moment.locale("de");

interface TreatmentNoteProps {
  timeSlot: TimeSlotDto;
  patientId: number | null;
  onCreateTreatmentNote: (treatmentNote: CreateTreatmentNoteDto) => Promise<TreatmentNoteDTO>;
  onUpdateTreatmentNote: (
    id: number,
    treatmentNote: CreateTreatmentNoteDto
  ) => Promise<TreatmentNoteDTO>;
}

const TreatmentNoteCard = observer(
  ({ timeSlot, patientId, onCreateTreatmentNote, onUpdateTreatmentNote }: TreatmentNoteProps) => {
    const [canEdit, setCanEdit] = useState(false);
    const [treatmentNote, setTreatmentNote] = useState(timeSlot?.treatmentNotes?.[0]);
    const [note, setNote] = useState(treatmentNote?.note || "");
    const noteRef = useRef<HTMLTextAreaElement>(null);

    const timeSlotStartDate = timeSlot.start;
    const isNoteChanged = treatmentNote?.note?.trim() !== note?.trim();
    const initials = treatmentNote?.user?.nickName || `${treatmentNote?.user?.firstName?.[0] || ""}${
      treatmentNote?.user?.lastName?.[0] || ""
    }`;

    useEffect(() => {
      if (canEdit && noteRef.current) {
        noteRef.current.removeAttribute("readonly");
        noteRef.current.focus();
      }
    }, [canEdit]);

    const handleSaveNote = () => {
      if (isNoteChanged) {
        const treatmentNoteObj: CreateTreatmentNoteDto = {
          note,
          treatmentId: timeSlot.id!,
          patientId: patientId!,
        };

        if (!treatmentNote?.id) {
          onCreateTreatmentNote(treatmentNoteObj).then((newNote) => {
            setCanEdit(false);
            setTreatmentNote(newNote);
          });
        } else {
          onUpdateTreatmentNote(treatmentNote.id, treatmentNoteObj).then((updatedNote) => {
            setCanEdit(false);
            setTreatmentNote(updatedNote);
          });
        }
      } else {
        setCanEdit(!canEdit);
      }
    };

    return (
      <Card sx={{ width: "100%", boxShadow: 3 }}>
        <CardContent sx={{ padding: "0px !important" }}>
          <Grid container spacing={1} alignItems="center">
            {/* Left Section */}
            <Grid item xs={12} md={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginBottom: 1,
                  }}
                >
                  {timeSlotStartDate ? formatDateUI(timeSlotStartDate) : "Kein Datum"}
                </Typography>

                {/* Therapy Information */}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                  sx={{ marginY: 1 }}
                >
                  <Controls.Chip
                    label={timeSlot?.therapyRx?.rx?.rxNumber || "RX"}
                    sx={{
                      fontSize: "0.8em",
                      padding: "2px 6px",
                      borderRadius: "0.5em",
                    }}
                  />
                  <TherapyChip
                    therapyName={(timeSlot.therapyRx?.therapy as any)?.abbreviation || ""}
                  />
                </Box>

                <Grid item xs={12} md={9}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" gap={2}>
                      {/* Time Range */}
                      {/* <Box display="flex" alignItems="center" gap={1}> */}
                      {/* RX Processing Status */}
                      <Tooltip title={`${timeSlot?.therapyRx?.rx?.processingStatus}`}>
                        <Avatar
                          sx={{
                            width: 25,
                            height: 25,
                            "& > *": {
                              display: "none !important", // Hide default content inside the Avatar
                            },
                            backgroundColor: getStatusColor(
                              timeSlot.therapyRx?.rx?.processingStatus as RxDtoProcessingStatusEnum
                            ),
                          }}
                        />
                      </Tooltip>
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "0.75em",
                          fontWeight: "bold",
                          marginTop: "4px",
                        }}
                      >
                        {!timeSlotStartDate && "nicht geplant"}
                        {timeSlotStartDate ? formatTime(timeSlotStartDate) : " "}
                        {" - "}
                        {timeSlot.end ? formatTime(timeSlot.end as any) : ""}
                      </Typography>
                      {/* </Box> */}
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            {/* Right Section */}
            <Grid item xs={12} md={9}>
              <Box display="flex" alignItems="center" gap={1}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Notiz"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  disabled={!canEdit}
                  // ref={noteRef}
                />
                {/* Textarea */}
                {/* <TextareaAutosize
                  minRows={3}
                  placeholder="Notiz"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  disabled={!canEdit}
                  ref={noteRef}
                  style={{
                    width: "100%",
                    fontSize: "1rem",
                    border: canEdit ? "1px solid #ccc" : "none",
                    borderRadius: "4px",
                    padding: "8px",
                    outline: "none",
                    resize: "none",
                  }}
                /> */}
                {/* Edit or Discard Toggle */}
                <EditOrDiscardToggle
                  editMode={canEdit}
                  onDiscard={() => {
                    setNote(treatmentNote?.note as any);
                    setCanEdit(!canEdit);
                  }}
                  onSave={handleSaveNote}
                  disableSaveBtn={!note?.length}
                  setEditMode={setCanEdit}
                />
                {/* User Information */}
                {treatmentNote?.user && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <MobiliAvatar
                      user={{ ...treatmentNote.user, nickName: initials }}
                      sx={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  </Box>
                )}
              </Box>

              {/* <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="flex-start" gap={2}>
                  <TextareaAutosize
                    minRows={5}
                    placeholder="Notiz"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    readOnly={!canEdit}
                    ref={noteRef}
                    style={{
                      width: "100%",
                      fontSize: "1rem",
                      border: canEdit ? "1px solid #ccc" : "none",
                      borderRadius: "4px",
                      padding: "8px",
                      outline: "none",
                      resize: "none",
                    }}
                  />
                  <EditOrDiscardToggle
                    editMode={canEdit}
                    onDiscard={() => {
                      setNote(treatmentNote?.note || "");
                      setCanEdit(false);
                    }}
                    onSave={handleSaveNote}
                    disableSaveBtn={!note?.length}
                  />
                </Box>
              </Box> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

export default TreatmentNoteCard;
