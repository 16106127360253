import { useLocalStore } from 'mobx-react';
import React, { createContext, ReactNode } from 'react';
import { BiApi, Configuration, NoteApi } from '../../api-client';
import { AuthContext } from '../Auth/auth.provider';
import useStore from '../../helpers/useStore';
import { PatientsContext } from '../Patients/patients.provider';
import BIStore from './biDashboardStore';



interface BIProviderProps {
  children: ReactNode;
}

export const BIContext = createContext<BIStore | null>(null);
BIContext.displayName = 'BIContext';

export const BIProvider = ({ children }: BIProviderProps) => {
const basePath=process.env.REACT_APP_API_URL
const AuthenticationStore = useStore(AuthContext);
const { authToken } = AuthenticationStore;


const config = new Configuration({
  basePath: basePath,
  accessToken: authToken || "",
});

// console.log('authiii',authToken,config)
  const apiClient = new BiApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(
    () => new BIStore(apiClient,AuthenticationStore)
  );

//   store.apiClient = apiClient;

  return (
    <BIContext.Provider value={store}>
      {children}
    </BIContext.Provider>
  );
};