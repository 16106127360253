import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Grid } from "@mui/material";
import FormDialog from "./TransactionForm";
import { useState } from "react";
import { transactionMethods } from "./types/kasse.types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

interface AlertDialogSlideProps {
  open: boolean;
  handleClose: () => void;
  patientData: any;
  selectedRow: any;
}

const AlertDialogSlide: React.FC<AlertDialogSlideProps> = ({
  open,
  handleClose,
  patientData,
  selectedRow,
}) => {
  const [openDialog, setOpenDialog] = useState({
    cash: false,
    ec: false,
    lastschrift: false,
  });

  const handleOpenDialog = (type: string) => {
    setOpenDialog({ ...openDialog, [type]: true });
    handleClose(); // Close the PaymentDialog
  };

  const handleCloseFormDialog = (type: string) => {
    setOpenDialog({ ...openDialog, [type]: false });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Please Choose Your Payment Service"}</DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          <Grid
            container
            spacing={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              {<Button onClick={() => handleOpenDialog("cash")}>Cash</Button>}
              {openDialog.cash && (
                <FormDialog
                  open={openDialog.cash}
                  handleTransactionClose={() => {
                    handleCloseFormDialog("cash");
                    handleClose();
                  }}
                  patientData={patientData}
                  transactionMethod={transactionMethods.CASH}
                  selectedRow={selectedRow}
                />
              )}
            </Grid>
            <Grid item>
              {<Button onClick={() => handleOpenDialog("ec")}>EC Cash</Button>}
              {openDialog.ec && (
                <FormDialog
                  open={openDialog.ec}
                  handleTransactionClose={() => {
                    handleCloseFormDialog("ec");
                    handleClose();
                  }}
                  patientData={patientData}
                  transactionMethod={transactionMethods.EC}
                  selectedRow={selectedRow}
                />
              )}
            </Grid>

            <Grid item>
              {<Button onClick={() => handleOpenDialog("lastschrift")}>LastSchrift</Button>}
              {openDialog.lastschrift && (
                <FormDialog
                  open={openDialog.lastschrift}
                  handleTransactionClose={() => {
                    handleCloseFormDialog("lastschrift");
                    handleClose();
                  }}
                  patientData={patientData}
                  transactionMethod={transactionMethods.LASTSCHRIFT}
                  selectedRow={selectedRow}
                />
              )}
            </Grid>

            {/* <Grid item>
              {<Button onClick={() => handleOpenDialog("rechnung")}>Rechnung</Button>}
              {openDialog.rechnung && (
                <FormDialog
                  open={openDialog.rechnung}
                  handleTransactionClose={() => {
                    handleCloseFormDialog("rechnung");
                    handleClose();
                  }}
                  patientData={patientData}
                  transactionType={transactionMethods.RECHNUNG}
                  selectedRow={selectedRow}
                />
              )}
            </Grid> */}
            {/* <Grid item>
              {<Button onClick={() => handleOpenDialog("versicherung")}>versicherung</Button>}
              {openDialog.versicherung && (
                <FormDialog
                  open={openDialog.versicherung}
                  handleTransactionClose={() => {
                    handleCloseFormDialog("versicherung");
                    handleClose();
                  }}
                  patientData={patientData}
                  transactionType={transactionMethods.VERSICHERUNG}
                  selectedRow={selectedRow}
                />
              )}
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialogSlide;
