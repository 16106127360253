import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import { CreateInitialEvaluationDto, GetInitialEvaluationDto } from "../../../api-client";
import EditOrDiscardToggle from "../../../helpers/EditorDiscardToggle";

interface InitialEvaluationProps {
  patientId: number;
  initialEvaluation?: GetInitialEvaluationDto;
  onCreateInitialEvaluation: (initialEvaluation: CreateInitialEvaluationDto) => void;
  onUpdateInitialEvaluation: (id: number, initialEvaluation: CreateInitialEvaluationDto) => void;
  onDeleteInitialEvaluation?: () => void;
}

export default function InitialEvaluationCard({
  patientId,
  initialEvaluation,
  onCreateInitialEvaluation,
  onUpdateInitialEvaluation,
  onDeleteInitialEvaluation,
}: InitialEvaluationProps) {
  const createIeObj = { goals: "", evaluation: "", patientId: patientId };
  const [ieObj, setIeObj] = useState<CreateInitialEvaluationDto>(createIeObj);
  const [canEdit, setCanEdit] = useState(initialEvaluation?.id ? false : true);
  // 
  // const befundRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (initialEvaluation) {
      setIeObj(initialEvaluation);
    }
  }, [initialEvaluation]);

  // useEffect(() => {
  //   if (canEdit && befundRef.current) {
  //     befundRef.current.removeAttribute("readonly");
  //     befundRef.current.focus(); // Directly focus on the textarea
  //   }
  // }, [canEdit]);

  console.log("ausiisiisidd",ieObj,canEdit)

  const handleChange = (key: string, value: string) => {
    let filledInitalEvaluation = Object.assign({ ...ieObj }, { [key]: value });

    setIeObj(filledInitalEvaluation);
  };

  const handleSaveInitialEvaluation = () => {
    if (!canEdit) {
      setCanEdit(true);
      return;
    }

    if (!initialEvaluation?.id) {
      onCreateInitialEvaluation(ieObj);
    } else {
      onUpdateInitialEvaluation(initialEvaluation.id, ieObj);
    }
    setCanEdit(false);
  };

  const handleDiscardChanges = () => {
    if (initialEvaluation) {
      setIeObj(initialEvaluation);
    } else {
      onDeleteInitialEvaluation && onDeleteInitialEvaluation();
      setIeObj(createIeObj);
    }
    setCanEdit(false);
  };

  return (
    <Card sx={{marginBottom:"4px"}}>
   <CardHeader
  title="Erstbefund"
  action={
    // canEdit ? (
    //   <>
    //     <IconButton
    //       onClick={handleSaveInitialEvaluation}
    //       color="primary"
    //       disabled={!ieObj?.evaluation?.trim() && !ieObj?.goals?.trim()} // Ensure both fields are checked
    //     >
    //       <SaveIcon />
    //     </IconButton>
    //     <IconButton onClick={handleDiscardChanges} color="secondary">
    //       <CancelIcon />
    //     </IconButton>
    //   </>
    // ) : (
    //   <IconButton onClick={() => setCanEdit(true)} color="default">
    //     <EditIcon />
    //   </IconButton>
    // )
    <EditOrDiscardToggle
    editMode={canEdit}
    onDiscard={handleDiscardChanges
    }
    onSave={handleSaveInitialEvaluation}
    disableSaveBtn={!ieObj?.evaluation?.trim() && !ieObj?.goals?.trim()}
    setEditMode={setCanEdit}
  />
  }
  subheader={moment(initialEvaluation?.createdAt).format("DD.MM.YYYY")}
/>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Befund
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={3}
              placeholder="Übungen und sonstige Foltermethoden"
              value={ieObj.evaluation}
              onChange={(e) => handleChange("evaluation", e.target.value)}
              disabled={!canEdit}
              variant="outlined"
              // ref={befundRef}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Ziele
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={3}
              placeholder="Besser werden"
              value={ieObj.goals}
              onChange={(e) => handleChange("goals", e.target.value)}
              disabled={!canEdit}
              variant="outlined"
            />
          </Grid>
        </Grid>
        {/* {!canEdit && (
          <Button onClick={() => setCanEdit(true)} variant="contained" color="primary" style={{ marginTop: "16px" }}>
            Bearbeiten
          </Button>
        )} */}
      </CardContent>
    </Card>
  );
}
