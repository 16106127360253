import { FC } from "react";
import { BaseTransactionDetailsDto } from "../../../../api-client";
import {
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDateUI } from "../../../../helpers/dateFormat";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useDownload } from "../../../../hooks/useDownload";

interface PatientAllTransactionTableProps {
  allTransactions: BaseTransactionDetailsDto[];
}

const PatientAllTransactionTable: FC<PatientAllTransactionTableProps> = ({ allTransactions }) => {
  const { handleDownloadPdf } = useDownload();
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Transaktion Nummer</TableCell>
            <TableCell>Rezeptnummer</TableCell>
            <TableCell>RezeptDatum</TableCell>
            <TableCell align="left">Typ</TableCell>
            <TableCell align="left">Gesamtpreis (€)</TableCell>
            <TableCell align="left">Erstellungsdatum</TableCell>
            <TableCell align="left">Erstellt von</TableCell>
            <TableCell align="left">Belege</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allTransactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                scope="row"
              >
                {
                  <Chip
                    key={index}
                    label={transaction.transactionNumber}
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <Chip
                    key={index}
                    label={transaction?.rx?.rxNumber}
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <Chip
                    key={index}
                    label={formatDateUI(transaction?.rx?.rxDate) ?? ""}
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <Chip
                    key={index}
                    label={transaction.type}
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <Chip
                    key={index}
                    // label={Number(transaction.amount).toFixed(2).replace(".", ",") + " €"}
                    label={
                      transaction.amount < 0
                        ? `(${Math.abs(Number(transaction.amount)).toFixed(2).replace(".", ",")} €)`
                        : `${Number(transaction.amount).toFixed(2).replace(".", ",")} €`
                    }
                    color="primary"
                    style={{ margin: "2px", color: transaction.amount < 0 ? "red" : undefined }}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <Chip
                    key={index}
                    label={formatDateUI(transaction.createdAt)}
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <Chip
                    key={index}
                    label={transaction.user.firstName + " " + transaction.user.lastName}
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                }
              </TableCell>
              <TableCell>
                {transaction.docDto?.id && (
                  <IconButton
                    className="fancy-button"
                    onClick={() => handleDownloadPdf(transaction.docDto!.id)}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientAllTransactionTable;
