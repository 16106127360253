import React from "react";
import { Fab, IconButton } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import styles from "./FloatingButton.module.css"; // Import the modular CSS

interface CaptureButtonProps {
  onCapture: () => void;
  type: "camera" | "file";
}

const CaptureButton=({ onCapture, type }: CaptureButtonProps) =>{
  // Determine the button style based on the type
  const buttonClass = type === "camera" ? styles.camera : styles.file;

  return (
    <Fab
      className={`${styles.fab} ${buttonClass}`}
      color="primary"
      onClick={onCapture}
    >
      {type === "camera" ? (
        <CameraAltIcon />
      ) : (
        <ImageIcon />
      )}
    </Fab>
  );
}

export default CaptureButton;
