import React, { FC, useEffect, useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { useEditBillInfo } from "../hooks/useEditBillInfo";
import { EditBillInfoProps } from "../types/EditBillInfo.types";
import "../../common/helpers/css/direct-bill-info.css";

// Sub-components
import {
  A5PrintComponent,
  ExportConfirmationDialog,
  TaxierenConfirmationDialog,
  InputTestTypeDialog,
  BillCardList,
} from "../exports";
import { PrintingBackdrop } from "./sub-components/BackDrop";
import { AddSingleRxDialog, DeleteConfirmationDialog } from "../../common/exports";
import { HeaderButtons } from "./sub-components/HeaderButtons";
import { TestType } from "../types/TestTypes.interface";
import { useBillSummary } from "../../NewBill/hooks/useBillSummary";
import BillSummary from "../../NewBill/components/sub-components/BillSummary";
import SpotlightSearch from "../../common/components/SpotlightSearch";

const EditBillInfo: FC<EditBillInfoProps> = (props) => {
  const {
    // states
    anchorEl,
    openDeleteDialog,
    bills,
    notes,
    openExportDialog,
    selectedRxId,
    openTaxierenDialog,
    printData,
    isPrinting,
    isDrucken,
    printRef,
    todayString,
    isSoftDeleteDialogOpen,
    testToggleChecked,
    openTestTypeDialog,
    testTypeData,

    // handlers
    setOpenCancelTaxierenDialog,
    setOpenExportDialog,
    setSelectedRxId,
    setNotes,
    setTestTypeData,
    setTestToggleChecked,
    handleMenuOpen,
    handleMenuClose,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleTaxierenClick,
    handleCancelTaxierenConfirm,
    handleCancelTaxierenClick,
    handleAddSingleRx,
    handleValidationFilter,
    handleSave,
    handleDrucken,
    handleExport,
    handleExportConfirm,
    handleSoftDeleteCancel,
    handleSoftDeleteConfirm,
    handleTestToggleChange,
    handleTestTypeDialogClose,

    // from props
    onRowClick,
  } = useEditBillInfo(props);

  const { totalRxs, passedValidationRxs, problematicRxs, taxiertRxs, numberOfPayers } =
    useBillSummary({ bills });

  const [isSpotlightOpen, setIsSpotlightOpen] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Backdrop for printing */}
      <PrintingBackdrop isPrinting={isPrinting || isDrucken} />

      {/* Header Buttons */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#5c758a",
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <HeaderButtons
          onAddSingleRx={() => setIsSpotlightOpen(true)}
          handleValidationFilter={handleValidationFilter}
          handleSave={handleSave}
          handleDrucken={() => handleDrucken(testTypeData.type)}
          handleExport={handleExport}
          testToggleChecked={testToggleChecked}
          handleTestToggleChange={handleTestToggleChange}
          testTypeData={testTypeData}
        />

        <Box sx={{ px: 1 }}>
          <BillSummary
            totalRxs={totalRxs}
            passedValidationRxs={passedValidationRxs}
            problematicRxs={problematicRxs}
            taxiertRxs={taxiertRxs}
            numberOfPayers={numberOfPayers}
          />
        </Box>
      </Box>

      {/* BILLS */}
      <BillCardList
        bills={bills}
        todayString={todayString}
        selectedRxId={selectedRxId}
        notes={notes}
        setNotes={setNotes}
        onRowClick={onRowClick}
        setSelectedRxId={setSelectedRxId}
        handleMenuOpen={handleMenuOpen}
        handleTaxierenClick={handleTaxierenClick}
      />

      <SpotlightSearch
        open={isSpotlightOpen}
        onOpen={() => setIsSpotlightOpen(true)}
        onClose={() => setIsSpotlightOpen(false)}
        onRxSelect={(rx) => {
          handleAddSingleRx(rx.rxNumber);
          setIsSpotlightOpen(false);
        }}
      />

      {/* Export Confirmation Dialog */}
      <ExportConfirmationDialog
        open={openExportDialog}
        onClose={() => {
          setOpenExportDialog(false);
        }}
        onConfirm={() => {
          if (testToggleChecked) {
            handleExportConfirm(testTypeData); // If user is in test mode, pass the testType, If not in test mode, pass nothing => defaults to GLOBAL
          } else {
            handleExportConfirm({ type: TestType.GLOBAL, email: undefined });
          }
        }}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />

      {/* Soft Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={isSoftDeleteDialogOpen}
        onCancel={handleSoftDeleteCancel}
        onConfirm={handleSoftDeleteConfirm}
        title="Transaktionsbestätigung löschen"
        content="Dies ist das letzte Element. Möchten Sie die Transaktion wirklich löschen?"
      />

      {/* Taxieren Confirmation Dialog */}
      <TaxierenConfirmationDialog
        open={openTaxierenDialog}
        onClose={() => {
          setOpenCancelTaxierenDialog(false);
        }}
        onConfirm={handleCancelTaxierenConfirm}
      />

      {/* Print Component For Taxiren*/}
      {printData && (
        <div style={{ display: "none" }}>
          <A5PrintComponent
            ref={printRef}
            billNumber={printData.billNumber}
            rxNumber={printData.rxNumber}
            careProviderIk={printData.careProviderIk}
          />
        </div>
      )}

      {/* Test Type Dialog - For Test Mode Only */}
      <InputTestTypeDialog
        isOpen={openTestTypeDialog}
        onClose={() => {
          setTestToggleChecked(false);
          handleTestTypeDialogClose();
        }}
        onConfirm={(chosenType, chosenEmail) => {
          setTestTypeData({ type: chosenType, email: chosenEmail });
          handleTestTypeDialogClose();
        }}
      />

      {/* Kebab Menu (Actions) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Entfernen</MenuItem>
        <MenuItem onClick={handleCancelTaxierenClick}>Untaxieren</MenuItem>
      </Menu>
    </Box>
  );
};

export default EditBillInfo;
