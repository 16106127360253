import Plot from "react-plotly.js";
import { Layout } from "plotly.js";


interface LineChartProps {
    cumulativeData: { locationId: string, locationName: string, date: Date, cumulativeSum: number }[];
}

const CumulativePatientsPlot: React.FC<LineChartProps> = ({cumulativeData}) => {
    const groupedData = Object.groupBy(cumulativeData, ({ locationName }) => locationName);

    const traces: Plotly.Data[] = [];
    Object.keys(groupedData).forEach(locationName => {
        // generate a trace for current location
        const trace: Plotly.Data = {
            x: groupedData[locationName]?.map(entry => entry.date),  
            y: groupedData[locationName]?.map(entry => entry.cumulativeSum), 
            type: 'scatter',
            mode: 'lines+markers',
            name: locationName, 
        };
        
        // Push the trace to the traces array
        traces.push(trace);
    });
    console.log(traces);

    const layout: Partial<Layout> = {
        title: {
            text: 'Cumulative number of patients',
        },
        xaxis: {
            type: 'date',
            tickformat: '%d.%m.%Y'
        },
        yaxis: {
            title: 'count'
        },
        showlegend: true,
    };

    return <Plot data={traces} layout={layout} />;
}

export default CumulativePatientsPlot;