import { useEffect, useRef, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import {
  DoctorReportDto,
  DoctorReportStausDto,
  DoctorReportStausDtoStatusEnum,
  PatientDto,
} from "../../../../api-client";
import { observer } from "mobx-react";
import DoctorReportSelector from "./DoctorReportSelector";
import notificationStore from "../../../../stores/Notification/notificationStore";
import { DocumentationContext } from "../../../../stores/Documentation/Documentation.provider";
import useStore from "../../../../helpers/useStore";
import DoctorReportForm, { DoctorReportFormRef } from "./DoctorReportForm";
import { Add, Edit, Visibility } from "@mui/icons-material";
import { PatientsContext } from "../../../../stores/Patients/patients.provider";
import { useLocation, useNavigate } from "react-router-dom";
import PatientCancellationWidget from "../../../PatientOverview/PatientHistory/PatientCancellationWidget";

interface DoctorReportOverViewProps {
  patientData: PatientDto;
  routingParams?: any;
}

const DoctorReportOverView: React.FC<DoctorReportOverViewProps> = observer(({ patientData,routingParams }) => {
  const DocumentationStore = useStore(DocumentationContext);
   const navigate = useNavigate();
  const PatientStore= useStore(PatientsContext);
  const {getSelectedPatientparams,constructSearchPath,updateSelectedPatientRoute}=PatientStore;
  const { getDoctorReportsForPatient, requestDoctorReport, sendDoctorReportByEmail, updateDoctorReport,getDoctorReportStatus } =
    DocumentationStore;
  const [doctorReports, setDoctorReports] = useState<DoctorReportDto[]>([]);
  const [doctorViewLoading, setDoctorViewLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [doctorReportStatus, setDoctorReportStatus] = useState<DoctorReportStausDto>();
  const [accessUrl, setAccessUrl] = useState<string>();
  const [dataRefreshFlag, setDataRefreshFlag] = useState(false);
  const doctorReportFormRef = useRef<DoctorReportFormRef>(null);
  const isDoctorReportCompleteOrSent = () => {
    let status = doctorReportStatus?.status;
    if (
      status === DoctorReportStausDtoStatusEnum.Complete ||
      status === DoctorReportStausDtoStatusEnum.Sent
    )
      return true;
    return false;
  };
  const location = useLocation();
  const fetchDoctorReports = async () => {
    try {
      setDoctorViewLoading(true);
      const response = await getDoctorReportsForPatient(patientData.id as number);
      console.log("auusisisi", response);
      setDoctorReports(response);
 
      
        // const queryParams = new URLSearchParams(location.search);
        // queryParams.set('doctorReportId', doctorReport.id); // Update or add doctorReportId
        // navigate({ search: queryParams.toString() }, { replace: true }); // Update URL without reloading
      

      return response || [];
    } catch (err) {
      notificationStore.showMessage("Error while fetching reports", "error");
    } finally {
      setDoctorViewLoading(false);
    }
  };

  useEffect(() => {
 

    if (patientData.id) {
       fetchDoctorReports();
    }
  }, [patientData.id,dataRefreshFlag]);


  const [openRxView, setOpenRxView] = useState<boolean>(false);
  const [openSeriesView, setSeriesOpenView] = useState<boolean>(false);
  const [changeInSeriesData, setChangeInSeriesData] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editReportMode, setEditReportMode] = useState<boolean>(false);
  const [doctorReport, setDoctorReport] = useState<DoctorReportDto>({
    id: selectedRow.id ? selectedRow.id : "",
    rxId: selectedRow.rx?.id ? selectedRow.rx?.id : "",
  });
  useEffect(() => {
    const fetchAndFilterReports = async () => {
      try {
        if (routingParams ) {

          if(routingParams.newdoctorReportId)
          {
            setDoctorReport({...doctorReport,rxId:routingParams.newdoctorReportId})//Wen creating new doctor report for rxId
              setEditReportMode(true)
          }
          else if (routingParams.doctorReportId){
            let filteredReport;
          // setDoctorViewLoading(true);
           filteredReport = doctorReports?.find(
            (report: DoctorReportDto) => report.id === routingParams.doctorReportId
          );
          if(!filteredReport){
         
            const response=await fetchDoctorReports();
             filteredReport = response?.find(
              (report: DoctorReportDto) => report.id === routingParams.doctorReportId
            );
          }
          handleRowClick(filteredReport);
         
          
       
        }}
      } catch (error) {
        console.error("Error fetching or filtering doctor reports:", error);
      }
    };
  
    fetchAndFilterReports();
  }, [routingParams]);
  

  // useEffect(() => {
  //   const fetchAndFilterReports = async () => {
  //     try {
  //       console.log("azusususiisis", routingParams);
  
  //       // Check if the report already exists in doctorReports
  //       if (routingParams && routingParams.doctorReportId) {
  //         const existingReport = doctorReports.find(
  //           (report: DoctorReportDto) => report.id === routingParams.doctorReportId
  //         );
  
  //         // If report exists, set it without making an API call
  //         if (!existingReport) {
  //           setDoctorReport({...doctorReport,rxId:routingParams.doctorReportId})//Wen creating new doctor report for rxId
  //           setEditReportMode(true)
  //           // handleRowClick(existingReport); // Optionally trigger row click if you want to handle that here
  //         } else {
  //           // If report does not exist, fetch the reports from API
  //           setDoctorViewLoading(true);
  //           const reports = await fetchDoctorReports();
  
       
  //             const filteredReport = reports?.find(
  //               (report: DoctorReportDto) => report.id === routingParams.doctorReportId
  //             );
  //             if (filteredReport) {
  //               setDoctorReport(filteredReport);
  //               handleRowClick(filteredReport); // Handle the row click or whatever action you want
  //             } 
            
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching or filtering doctor reports:", error);
  //     } finally {
  //       setDoctorViewLoading(false);
  //     }
  //   };
  
  //   fetchAndFilterReports();
  // }, [routingParams]);
  
  

  useEffect(() => {
    if (accessUrl) {
      window.open(accessUrl, "_blank");
    }
  }, [accessUrl]);

  const handleRowClick = (row: any) => {
    try {
    
      // if (!noNavigate) {
      updateSelectedPatientRoute({pId:patientData.id,doctorReportId:row.id,newdoctorReportId:null})
        const selectedPatientParams = getSelectedPatientparams(patientData.id);
        if (selectedPatientParams) {
          const urlPath = constructSearchPath({ pId: selectedPatientParams.pId, doctorReportId: row.id,  selectedSubTab: 6 });
          navigate(urlPath);
        } else {
          const urlPath = constructSearchPath({ pId: patientData.id, doctorReportId: row.id,  selectedSubTab: 6 });
          navigate(urlPath);
        }
      // }
      setSelectedRow(row);
      setOpenRxView(row);
    } catch (error) {
      console.log("tatazsusuisisi",error)
      notificationStore.showMessage("Doctor-ID wurde nicht gefunden", "error");
    }
  };
  console.log("AJKSISISO", doctorReports, selectedRow);

  const handleDocumentClick = (reportId: string) => {
    const fetchAccess = async () => {
      try {
        setActionLoading(true)
        const response = await requestDoctorReport(reportId);
        setAccessUrl(response.accessUrl);
      } catch (err) {
        notificationStore.showMessage("Zugriff auf Dokument ist fehlgeschlagen", "error", err);
      }
      finally{
        setActionLoading(false)
      }
    };

    fetchAccess();
  };

  const handleSendClick = (report: DoctorReportDto) => {
    const fetch = async () => {
      try {
        setActionLoading(true)
        const response = await sendDoctorReportByEmail(report.id);
      const doctorReportStatus= await getDoctorReportStatus(report.rxId)

          setDoctorReportStatus(doctorReportStatus);
        // Update the doctor reports status
        setDoctorReports((prevReports:any) => {
          const updatedReports = [...prevReports]; // Create a shallow copy of the previous reports array
          const reportIndex = updatedReports.findIndex(doc => doc.id === report.id); // Find the index of the report to be updated
        
          if (reportIndex !== -1) {
            updatedReports[reportIndex] = {
              ...updatedReports[reportIndex], // Keep the other properties unchanged
              status: doctorReportStatus.status // Update only the status
            };
          }
        
          return updatedReports; // Return the updated reports array
        });
        

        // setDataRefreshFlag((prevFlag) => !prevFlag); // Toggle flag to re-fetch data
      } catch (err) {
        notificationStore.showMessage("Fehler beim Senden des Berichts", "error", err);
      }
      finally{
        setActionLoading(false)
      }
    };

    fetch();
  };

  const onFormSave = async () => {
    if (doctorReportFormRef.current) {
      try {
        await doctorReportFormRef.current.handleSubmitDoctorReport(); // Wait for submission to complete
        setEditReportMode(false); // Close the edit mode after successful save
        setDataRefreshFlag((prevFlag) => !prevFlag); // Toggle flag to re-fetch data
      } catch (error:any) {
        console.error("Error while saving doctor report:", error);
        notificationStore.showMessage(error?.message, "error",error); // Error feedback
      }
    }
  };
  

  const handleCloseDialog = (statusChange?:boolean) => {
    const originalReport = doctorReports.find((report) => report.id === doctorReport.id);
    // If no status change, revert to the original doctor report stat

    setEditReportMode(false);
    if(statusChange===true){
      setDataRefreshFlag((prev)=>!prev)
    }
    else{
      if (doctorReports?.length > 0 && doctorReport.id) {
       
        if (originalReport) {
          setDoctorReport(originalReport); // Revert doctorReport to its original state
        }
      }
    }
      // Check if `routingParams.rxId` matches `selectedRx.id` and set it to null if it does
     // Check if `routingParams.rxId` matches `selectedRx.id` and set it to null if it does
     const updatedRoutingParams = { ...routingParams };

     if (updatedRoutingParams.newdoctorReportId && updatedRoutingParams.newdoctorReportId !== doctorReport.id) {
       updatedRoutingParams.newdoctorReportId = null;
      //  updateSelectedPatientRoute(updatedRoutingParams)
       navigate(constructSearchPath(updatedRoutingParams));
     }



  };

  useEffect(() => {
    if (selectedRow && selectedRow.rx) {
      getDoctorReportStatus(selectedRow.rx.id)
        .then((response: DoctorReportStausDto) => {
          setDoctorReportStatus(response);
        })
        .catch((error:any) => {
          console.log("error fetching doctor report status :", error);
        });
    }
  }, [selectedRow]);

  const handleEditClick=(report:DoctorReportDto)=>{
    // setSelectedRow(report)
    handleRowClick(report)
    setEditReportMode(!editReportMode);
  }

  const getReportIcon = () => {
    // if (!doctorReportStatus?.doctorReportExists) return <Add />;
    if (isDoctorReportCompleteOrSent()) return <Visibility />;
    return <Edit onClick={() => setEditReportMode(!editReportMode)} />;
  };

  return (
    <Box
      sx={{
        border: "0px dotted black",
        padding: "8px",
        boxSizing: "border-box",
        width: "auto",
      }}
    >
   
        <Grid container sx={{ border: "0px dotted red" }} justifyContent="flex-start" spacing={2}>
          {/* Left side components */}
          <Grid item xs={12} md={4} sx={{ border: "0px solid red" }}>
            <Grid container>
              <Grid item xs={12}>
                <Card sx={{ padding: "0px" }}>
                  <CardHeader title="Arzt Reports" />

                  <CardContent sx={{ padding: "0px" }}>
                    {doctorViewLoading && <CircularProgress/>}
                    {!doctorViewLoading &&doctorReports && doctorReports.length === 0 && (
                      <Typography>Kein Arztbericht gefunden</Typography>
                    )}
                    {!doctorViewLoading && doctorReports && doctorReports.length > 0 && (
                      <DoctorReportSelector
                        doctorReports={doctorReports}
                        selectedRow={selectedRow}
                        handleRowClick={handleRowClick}
                        handleEditClick={handleEditClick}
                        handleOpenClick={handleDocumentClick}
                        handleSendClick={handleSendClick}
                        actionLoading={actionLoading}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>{" "}
            {/* End of grid container*/}
          </Grid>{" "}
          <Grid item xs={12} md={8} sx={{ border: "0px solid red" }}>
            {/* {openRxView && (
              <Card>
                <CardHeader
                  title={`Doctor Report`}
                  sx={{ height: "40px" }}
                  action={
                    <>
                   <IconButton onClick={() => setEditReportMode(!editReportMode)}>
                        {getReportIcon()}
                      </IconButton>
                    </>
                  }
                />
                <CardContent>

                <DoctorReportForm
                  ref={doctorReportFormRef}
                  editMode={editReportMode?isDoctorReportCompleteOrSent():true}
                  onUpdateReportStatus={(doctorReportStatus: DoctorReportStausDto) => {
                    setDoctorReportStatus(doctorReportStatus);
                  }}
                  doctorReport={doctorReport}
                  setDoctorReport={setDoctorReport}
                  rxId={selectedRow.rx?.id}
                />
                {editReportMode &&   <><Button onClick={handleCloseDialog} color="secondary">
                    Cancel
                  </Button><Button onClick={onFormSave} variant="contained" color="primary" disabled={isDoctorReportCompleteOrSent()}>
                      Confirm
                    </Button></>}
                </CardContent>
              </Card>
            )} */}
             {openRxView && (
              <Card>
                <CardHeader
                  title={`Arzt Report- ${selectedRow.rx?.rxNumber}`}
                  sx={{ height: "40px" }}
                  action={
                    <>
                   <IconButton >
                        {getReportIcon()}
                      </IconButton>
                    </>
                  }
                />
                <CardContent>
                <DoctorReportForm
                  ref={doctorReportFormRef}
                  editMode={false}
                  doctorReport={doctorReport}
                  setDoctorReport={setDoctorReport} 
                  onUpdateReportStatus={(doctorReportStatus: DoctorReportStausDto) => {
                    setDoctorReportStatus(doctorReportStatus);
                  }}
                  rxId={selectedRow.rx?.id}
                  handleCloseDialog={handleCloseDialog}
                />

                </CardContent></Card>)}

               {editReportMode && (
            // <Dialog open={editReportMode} onClose={handleCloseDialog} fullWidth maxWidth="md">
            //   <DialogTitle>
            //     {editReportMode ? "Arzt Report" : "View Arzt Report"}
            //   </DialogTitle>
            //   <DialogContent>
                <DoctorReportForm
                  ref={doctorReportFormRef}
                  editMode={editReportMode}
                  doctorReport={doctorReport}
                  setDoctorReport={setDoctorReport}
                   onUpdateReportStatus={(doctorReportStatus: DoctorReportStausDto) => {
                    setDoctorReportStatus(doctorReportStatus);
                  }}
                  rxId={selectedRow.rx?.id}
                  handleRowClick={handleRowClick}
                  handleCloseDialog={handleCloseDialog}
                  onSave={onFormSave}
                  routingParams={routingParams}
                />
            //   </DialogContent>
            //   <DialogActions>
            //     <Button onClick={handleCloseDialog} variant="text">
            //     Abbrechen
            //     </Button>
            //     <Button onClick={onFormSave} variant="contained" disabled={isDoctorReportCompleteOrSent()}>
            //     Speichern
            //     </Button>
            //   </DialogActions>
            // </Dialog>
          )}
          </Grid>
          {/* {editReportMode && (
            <Dialog open={editReportMode} onClose={handleCloseDialog} fullWidth maxWidth="md">
              <DialogTitle>
                {editReportMode ? "Edit Doctor Report" : "View Doctor Report"}
              </DialogTitle>
              <DialogContent>
                <DoctorReportForm
                  ref={doctorReportFormRef}
                  editMode={isDoctorReportCompleteOrSent()}
                  rxId={selectedRow.rx?.id}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                  Cancel
                </Button>
                <Button onClick={onFormSave} variant="contained" color="primary" disabled={isDoctorReportCompleteOrSent()}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          )} */}
        </Grid>
      
    </Box>
  );
});

export default DoctorReportOverView;
