import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { TextField, Grid } from "@mui/material";
import { DoctorReportDto } from "../../../../api-client";

interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: any;
  editMode?: boolean;
}

const TherapyChangeSuggestionsCard=({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps) =>{
  const [therapyChangeSuggestions, setTherapyChangeSuggestions] = useState("");

  useEffect(() => {
    if (doctorReport) {
      setTherapyChangeSuggestions(doctorReport.therapyChangeSuggestions!);
    }
  }, [doctorReport]);

  const handleChangeDoctorReport = (key: string, value: any) => {
    let updatedDoctorReport = { ...doctorReport, [key]: value };
    setDoctorReport(updatedDoctorReport);
  };

  return (
    <DoctorReportCard title="Vorschläge zur Änderung des Therapie" editMode={editMode}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Vorschläge zur Änderung"
            placeholder="Text eingeben..."
            multiline
            rows={4}
            value={therapyChangeSuggestions}
            onChange={(e) => {
              setTherapyChangeSuggestions(e.target.value);
              handleChangeDoctorReport("therapyChangeSuggestions", e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </DoctorReportCard>
  );
}
export default TherapyChangeSuggestionsCard;
