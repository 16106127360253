import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { CardContent, Grid, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import { DoctorReportDto } from "../../../../api-client";

interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: any;
  editMode?: boolean;
}

export default function ChangeOfTreatmentCard({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps) {
  const [isRegulatedTreatment, setIsRegulatedTreatment] = useState<boolean | null>(null);
  const [therapyFrequency, setTherapyFrequency] = useState<boolean | null>(null);
  const [singleTherapy, setSingleTherapy] = useState<boolean | null>(null);
  const [groupTherapy, setGroupTherapy] = useState<boolean | null>(null);
  const [treatmentModification, setTreatmentModification] = useState<string>("");

  useEffect(() => {
    if (doctorReport) {
      setIsRegulatedTreatment(doctorReport.isRegulatedTreatment!);
      setTherapyFrequency(doctorReport.therapyFrequency!);
      setSingleTherapy(doctorReport.singleTherapy!);
      setGroupTherapy(doctorReport.groupTherapy!);
      setTreatmentModification(doctorReport.treatmentModification!);
    }
  }, [doctorReport]);

  const handleChangeDoctorReport = (key: string, value: any) => {
    let updatedDoctorReport = { ...doctorReport, [key]: value };
    setDoctorReport(updatedDoctorReport);
  };

  return (
    <DoctorReportCard title="Durchführung/Änderung der Behandlung" editMode={editMode}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sx={{ textWrap: "nowrap" }}>
            <Typography variant="body2">Behandlung gemäß Verordung</Typography>
            <Typography variant="body2">Änderung</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch
              checked={isRegulatedTreatment!}
              onChange={() => {
                setIsRegulatedTreatment(!isRegulatedTreatment);
                handleChangeDoctorReport("isRegulatedTreatment", !isRegulatedTreatment);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2">Therapiefrequenz</Typography>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch
              checked={therapyFrequency!}
              onChange={() => {
                setTherapyFrequency(!therapyFrequency);
                handleChangeDoctorReport("therapyFrequency", !therapyFrequency);
              }}
              disabled={isRegulatedTreatment!}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2">Einzeltherapie</Typography>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch
              checked={singleTherapy!}
              onChange={() => {
                setSingleTherapy(!singleTherapy);
                handleChangeDoctorReport("singleTherapy", !singleTherapy);
              }}
              disabled={isRegulatedTreatment!}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2">Gruppentherapie</Typography>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch
              checked={groupTherapy!}
              onChange={() => {
                setGroupTherapy(!groupTherapy);
                handleChangeDoctorReport("groupTherapy", !groupTherapy);
              }}
              disabled={isRegulatedTreatment!}
            />
          </Grid>
        </Grid>

        <Typography variant="body2" style={{ fontSize: "13px" }}>
          Nach Rücksprache mit dem verordnenden Arzt
        </Typography>

        <TextField
          label="Änderung"
          multiline
          variant="outlined"
          fullWidth
          value={treatmentModification}
          onChange={(e) => {
            setTreatmentModification(e.target.value);
            handleChangeDoctorReport("treatmentModification", e.target.value);
          }}
          disabled={isRegulatedTreatment!}
          rows={3}
          style={{ marginTop: "8px" }}
        />
      </CardContent>
    </DoctorReportCard>
  );
}
