import React, { FC } from "react";
import "../helpers/css/rubberStamp.css"; // Import the CSS file

interface RubberStampProps {
  text: string;
  color?: string;
  size?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isdisabled?: boolean;
}

const RubberStamp: FC<RubberStampProps> = ({
  text,
  color = "#504f4f",
  size = "50px",
  onClick,
  isdisabled = false,
}) => {
  return (
    <button
      type="button"
      className="rubber-stamp"
      style={{
        color,
        width: `calc(${size} / 1.5)`,
        height: `calc(${size} / 1.5)`,
        lineHeight: `calc(${size} / 1.5)`,
        fontSize: `calc(${size} / 7)`,
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={onClick}
      disabled={isdisabled}
    >
      {text}
    </button>
  );
};

export default RubberStamp;
