// helper functions for rx stats
import { CalculateRxDTO, RxStatsDto } from "../../../api-client";
import { startOfWeek, addWeeks, startOfMonth, addMonths, isEqual, isBefore } from 'date-fns';

// structure of grouped data (group by location)
interface GroupedData {
    [locationId: string]: RxStatsDto[]
}

interface TotalCountData {
    locationId: string, 
    date: Date, 
    totalCount: number
}

// function to calculate total number of Rxs by date
export const calculateTotalRx = (rxData: RxStatsDto[]): TotalCountData[] => {
    // Array to store the results
    const totalCountData: TotalCountData[] = [];

    // Group by location
    const groupedData: GroupedData = Object.groupBy(rxData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.rxCreatedAt).getTime() - new Date(b.rxCreatedAt).getTime());
        
        // get unique dates
        const uniqueDates = [
            ...new Set(groupedData[locationId].map((entry) => new Date(entry.rxCreatedAt).toISOString().split('T')[0]))
        ];

        // For each unique date, get the number of Rxs for that date
        uniqueDates.forEach((date) => {
            const total = groupedData[locationId].filter((entry) => new Date(entry.rxCreatedAt).toISOString().split('T')[0] === date
            ).length;
        
            totalCountData.push({ 
                locationId: locationId, 
                date: new Date(date), 
                totalCount: total 
            });
        });
    });
    return totalCountData;
}


interface CountData {
    locationId: string, 
    date: Date, 
    newOngoingRxs: number, 
    newCompletedRxs: number
}

// function to calculate number of new Rxs by bin
export const calculateNewRxs = (rxData: RxStatsDto[], binType: 'week' | 'month'): CountData[] => {
    // Array to store the results
    const countData: CountData[] = [];

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(rxData, ({ locationId }) => locationId) as GroupedData;

    // Loop through each location
    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.rxCreatedAt).getTime() - new Date(b.rxCreatedAt).getTime());

        // Generate bins
        let currentBinStart: Date;
        let bins: Date[] = [];

        const firstEntryDate = new Date(groupedData[locationId][0].rxCreatedAt);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].rxCreatedAt);

        if (binType === 'week') {
            currentBinStart = startOfWeek(firstEntryDate);
            bins.push(currentBinStart);

            // Generate bins for the following weeks
            for (let i = 1; isBefore(currentBinStart, lastEntryDate); i++) {
                currentBinStart = addWeeks(currentBinStart, 1); // to get next week
                bins.push(currentBinStart);
            }

        } else { 
            currentBinStart = startOfMonth(firstEntryDate);
            bins.push(currentBinStart);

            // Generate bins for the following months
            for (let i = 1; isBefore(currentBinStart, lastEntryDate); i++) {
                currentBinStart = addMonths(currentBinStart, 1); // to get next month
                bins.push(currentBinStart);
            }

        }

        // Loop through the bins 
        let nextBinStart: Date;

        bins.forEach((binStart) => {
            if (binType === 'week') {
                nextBinStart = addWeeks(binStart, 1);
            } else {
                nextBinStart = addMonths(binStart, 1);
            }
            
            // count Rxs (ongoing) in current bin
            const ongoingRxsInBin = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "in Behandlung"
            ).length;

            // count Rxs (completed) in current bin
            const completedRxsInBin = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "abgeschlossen"
            ).length;

            countData.push({
                locationId: locationId,
                date: binStart,
                newOngoingRxs: ongoingRxsInBin,
                newCompletedRxs: completedRxsInBin,
            });
        })    
    });
    return countData;
}


interface TotalPriceData {
    locationId: string, 
    date: Date, 
    totalOngoingRxPrice: number, 
    totalCompletedRxPrice: number
}

// function to calculate total Rx value by bin
export const calculateTotalRxValue = (rxData: RxStatsDto[], binType: 'week' | 'month'): TotalPriceData[] => {
    // Array to store the results
    const totalRxPriceData: TotalPriceData[] = [];

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(rxData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.rxCreatedAt).getTime() - new Date(b.rxCreatedAt).getTime());

        // Generate bins
        let currentBinStart: Date;
        let bins: Date[] = [];

        const firstEntryDate = new Date(groupedData[locationId][0].rxCreatedAt);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].rxCreatedAt);

        if (binType === 'week') {
            currentBinStart = startOfWeek(firstEntryDate);
            bins.push(currentBinStart);

            // Generate bins for the following weeks
            for (let i = 1; isBefore(currentBinStart, lastEntryDate); i++) {
                currentBinStart = addWeeks(currentBinStart, 1); // to get next week
                bins.push(currentBinStart);
            }

        } else { 
            currentBinStart = startOfMonth(firstEntryDate);
            bins.push(currentBinStart);

            // Generate bins for the following months
            for (let i = 1; isBefore(currentBinStart, lastEntryDate); i++) {
                currentBinStart = addMonths(currentBinStart, 1); // to get next month
                bins.push(currentBinStart);
            }

        }

        // Loop through the bins 
        let nextBinStart: Date;

        bins.forEach((binStart) => {
            if (binType === 'week') {
                nextBinStart = addWeeks(binStart, 1);
            } else {
                nextBinStart = addMonths(binStart, 1);
            }
            
            // calculate total price of Rxs (ongoing) in current bin
            const totalOngoingRxPrice = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "in Behandlung"
            ).reduce((total, entry) => total + entry.realPrice, 0);

            // calculate total price of Rxs (completed) in current bin
            const totalCompletedRxPrice = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "abgeschlossen"
            ).reduce((total, entry) => total + entry.realPrice, 0);

            totalRxPriceData.push({
                locationId: locationId,
                date: binStart,
                totalOngoingRxPrice: totalOngoingRxPrice,
                totalCompletedRxPrice: totalCompletedRxPrice,
            });
        });    
    });
    return totalRxPriceData;
}
