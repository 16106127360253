import Plot from "react-plotly.js";
import { Layout } from "plotly.js";
import * as d3 from "d3";

interface LineChartProps {
    scheduleValueData: { date: Date, locationId: string, totalValue: number }[];
}

const ScheduleApptValuesPlot: React.FC<LineChartProps> = ({scheduleValueData}) => {
    const groupedData = Object.groupBy(scheduleValueData, ({ locationId }) => locationId);

    const numLocations = Object.keys(groupedData).length
    const colorPalette = d3.scaleOrdinal(d3['schemeTableau10']).range().slice(0, numLocations);

    const traces: Plotly.Data[] = [];
    Object.keys(groupedData).forEach((locationId, index) => {
        const color = colorPalette[index % colorPalette.length];

        const trace: Plotly.Data = {
            x: groupedData[locationId]?.map(entry => entry.date),  
            y: groupedData[locationId]?.map(entry => entry.totalValue), 
            type: 'scatter',
            mode: 'lines+markers',
            name: locationId,
            line: { color: color },
        };

        traces.push(trace);
    });

    const layout: Partial<Layout> = {
        title: {
            text: 'Total value of appointments',
        },
        xaxis: {
            type: 'date',
            tickformat: '%d.%m.%Y',
            // tickmode: 'linear',
            // dtick: 'M1',
        },
        yaxis: {
            title: 'total',
            rangemode: 'tozero',
        },
        showlegend: true,
    };

    return <Plot data={traces} layout={layout} />;
}

export default ScheduleApptValuesPlot;