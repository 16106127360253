import moment from "moment";
import {
  AppointmentDto,
  BackPlanResult,
  CAResponse,
  ContinuousAppointmentDto,
  ContinuousEventTimeSlotDto,
  EventTimeSlotDto,
  LeaveOfAbsenceDto,
  PatientAvailabilityDto,
  PatientDto,
  TimeSlotDto,
  UserDto,
} from "../../api-client";
import useStore from "../../helpers/useStore";
import { UserContext } from "../../stores/User/User.provider";
import Calendar from "../../molecules/Calendar/Calendar";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import { Event, TherapyExt, TimeSlotExt } from "../../molecules/Calendar/Calendar.type";
import { useSearchParams } from "react-router-dom";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import { EventReceiveArg } from "@fullcalendar/interaction";
import { observer } from "mobx-react";
import notificationStore from "../../stores/Notification/notificationStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import EventShelf from "../../molecules/Calendar/EventShelf";
import CalendarDrawer from "../../molecules/Calendar/CalendarDrawer";
import { AppointmentCard } from "../ScheduleOverview/Appointments/AppointmentSuggestion";
import FlatCard from "../../atoms/FlatCard";
import { WebSocketContext } from "../../stores/WebSocket/WebSocket.provider";
import HoverSidebar from "../../molecules/HoverSidebar";
import CancellationDrawer from "../../molecules/Calendar/CancellationDrawer";
import { updateReceivedAppointment } from "../../helpers/calendar";

const Scheduler = observer(() => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const location = user?.location?.id;
  if (!location) {
    console.warn("No location found for user");
  } else {
    console.log("No worries, location is right here:", location);
  }
  const [users, setUsers] = useState<UserDto[]>([]);
  // const [appointments, setAppointments] = useState<AppointmentDto[]>([]);
  const [goneFishings, setGoneFishings] = useState<TimeSlotDto[]>([]);
  const [leaveOfAbsences, setLeaveOfAbsences] = useState<LeaveOfAbsenceDto[]>([]);
  const [EventTimeslots, setEventTimeslots] = useState<EventTimeSlotDto[]>([]);
  const [lunchBreaks, setLunchBreaks] = useState<TimeSlotDto[]>([]);
  const [travelTimes, setTravelTimes] = useState<TimeSlotDto[]>([]);
  const [selectedShelfAppointment, setSelectedShelfAppointment] = useState<AppointmentDto| undefined>();
  const [continuousEventTimeslots, setContinuousEventTimeslots] = useState<
    ContinuousEventTimeSlotDto[]
  >([]);
  const [unscheduleds, setUnscheduleds] = useState<AppointmentDto[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const PatientsStore = useStore(PatientsContext);
  const { getPatient, getAllPatients } = PatientsStore;
  const [isHoverSidebarOpen, setIsHoverSidebarOpen] = useState(false);
  const CalendarStore = useStore(CalendarContext);
  const {
    getAppointment,
    getContinuousAppointment,
    getUsersByLocation,
    getPatientsByAppointmentDate,
    getAppointmentsByDateAndLocation,
    createTimeslot,
    createEventTimeSlot,
    createContinuousEventTimeSlot,
    createLeaveOfAbsence,
    saveAppointment,
    getUnscheduledPatientAppointments,
    deleteTimeslot,
    deleteContinuousTimeslot,
    getContinuousAppointmentsByLocation,
    getContinuousAppointmentsForPatient,
    getUnscheduledContinuousAppointments,
    updateTimeSlot,
    updateContinuousTimeSlot,
    getPatientUnavailabilityForCalendar,
    getPatientUnavailabeTypeByDateForCalendar,
    getLatestUpdatedAppointments,
  } = CalendarStore;
  const AppointmentStore = useStore(AppointmentContext);
  const {
    updateContinuousAppointment,
    getAppointmentsForPatient,
    cancelledAppointments,
    getCancelledAppointments,
    setCancelledAppointments,
    getCancelledAppointmentsForPatient,
  } = AppointmentStore;
  const WebSocketStore = useStore(WebSocketContext);
  const { socket } = WebSocketStore;
  const [queryParameters] = useSearchParams();
  const dateParam = queryParameters.get("date");
  const [selectedDate, setSelectedDate] = useState<Date>(() => {
    return new Date(
      dateParam ? dateParam :
      localStorage.getItem("lastViewedDate") || moment(new Date()).format("YYYY-MM-DD")
    );
  });
  const weeklyWorkDay = true;
  const [patient, setPatient] = useState<PatientDto>();
  const [continuousEvents, setContinuousEvents] = useState<ContinuousAppointmentDto[]>([]);
  const isContinuousParam = queryParameters.get("continuousView");
  const [continuousView, setContinuousView] = useState<boolean>(isContinuousParam === "true");
  const [cancelledView, setCancelledView] = useState<boolean>(false);
  const [allAppointments, setAllAppointments] = useState<AppointmentDto[]>([]); //Includes all appoitnemnts
  const [filteredAppointmentss, setFilteredAppointmentss] = useState<AppointmentDto[]>([]); //Excludes cancelled appointments
  const [shelfAppointments, setShelfAppointments] = useState<AppointmentDto[]>([]);
  const [shelfContinuousAppointments, setShelfContinuousAppointments] = useState<
    ContinuousAppointmentDto[]
  >([]);
  const [reFetch, setRefetch] = useState<Date>(new Date());
  const [patientAppointments, setPatientAppointments] = useState<AppointmentDto[]>();
  const [drawerOpen, setDrawerOpen] = useState(!!queryParameters.get("pid"));
  const [selectedEventRxId, setSelectedEventRxId] = useState<string | undefined>();
  const [targetPatient, setTargetPatient] = useState<PatientDto>();
  const [showOpenDrawerButton, setShowOpenDrawerButton] = useState<boolean>(
    !!queryParameters.get("pid")
  );
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [responseDialogData, setResponseDialogData] = useState<CAResponse | undefined>();
  const [patientAvailability, setPatientAvailability] = useState<PatientAvailabilityDto[]>();
  const [patientUnavailabeTypes, setPatientUnavailabeTypes] = useState<TimeSlotDto[] | undefined>();
  const [isLoadingCancelled, setIsLoadingCancelled] = useState(false);
  
  const fetchCancelledData = async () => {
    try {
      setIsLoadingCancelled(true);
      const response = await getCancelledAppointments();
      setCancelledAppointments(response);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoadingCancelled(false);
    }
  };

  useEffect(() => {
    fetchCancelledData();
  }, [location, reFetch]);
  const [fetchDelay, setFetchDelay] = useState(0);

  const handleContinuousViewChange = async (continuous: boolean) => {
    const patientId = patient?.id?.toString();
    if (patientId) {
      await fetchUnscheduledAppointments(patientId, continuous);
    }
    setContinuousView(continuous);
    setShelfAppointments([]);
    setShelfContinuousAppointments([]);
  };

  const handleCancelledViewChange = (checked: boolean) => {
    setCancelledView(checked);
  };

  useEffect(() => {
    const isContinuousParam = queryParameters.get("continuousView");
    if (isContinuousParam !== null) {
      handleContinuousViewChange(isContinuousParam === "true");
    }
    if (dateParam !== null) {
      setSelectedDate(new Date(dateParam!));
    }
  }, [queryParameters]);

  // Ref to keep the most up-to-date cancelledAppointments
  const cancelledAppointmentsRef = useRef(cancelledAppointments);

  // Update the ref whenever cancelledAppointments changes
  useEffect(() => {
    cancelledAppointmentsRef.current = cancelledAppointments;
  }, [cancelledAppointments]);

  const updateCancelledAppointments = (appointment:any, isDelete:boolean) => {
    if (isDelete) {
      const index = cancelledAppointmentsRef.current.cancelledAppointments.findIndex(
        (existingAppointment:any) => existingAppointment.appointment.id === appointment.id
      );
    // If the appointment already exists, return the current state (do nothing)
    if (index !== -1) {
      return cancelledAppointmentsRef.current;
    }

    return {
      ...cancelledAppointmentsRef.current,
      cancelledAppointments: [
        ...cancelledAppointmentsRef.current.cancelledAppointments || [],
        {
          ...appointment.cancelledAppData,
          appointment: {
            ...appointment.cancelledAppData.appointment, // Ensure spreading the appointment correctly
            end: null, // Setting `end` to null
            originalDate: appointment?.cancelledAppData?.originalDate, // Fixed typo to `originalDate`
            resolved: appointment?.cancelledAppData?.resolved,
            reason: appointment?.cancelledAppData?.reason,
          },
        },
      ],
    };
    
  }
   else {
    return {
      ...cancelledAppointmentsRef.current,
      cancelledAppointments: (cancelledAppointmentsRef.current.cancelledAppointments || []).filter(
        (entry:any) => entry.appointment.id !== appointment.id
      ),
    };
  }
};

  useEffect(() => {
    // Handle incoming appointment update
    socket.on("appointmentUpdate", (e) => {
      console.log("Received update from Web Socket:", e.webSocket);
      
      const { appointment, type, source, action } = e.webSocket;

      switch (type) {
        case "TimeSlot":
          const appointmentDto = appointment as AppointmentDto;

          setAllAppointments((prev: any) => {
            if (action === "Delete") {
              setPatientUnavailabeTypes((prevUnavailable) => {
                const patientUnavailable: TimeSlotDto = {
                  id: appointmentDto.id,
                  start: appointmentDto.start!,
                  end: appointmentDto.end!,
                  type: "PatientUnvailable",
                };
                return [
                  ...(prevUnavailable ? prevUnavailable : []), // If prevUnavailable is undefined, use an empty array
                  patientUnavailable,
                ];
              });
              
              // If the appointment is by the therapist, update it in allAppointments
              if (appointment.cancelledAppData && appointment.cancelledAppData.byTherapist) {
                setUnscheduleds((prevUnscheduleds: any) => [
                  ...prevUnscheduleds,
                  {
                    ...appointment,
                    end: null,
                    originalDate: appointment?.cancelledAppData?.originalDate,
                    resolved: appointment?.cancelledAppData?.resolved,
                    reason: appointment?.cancelledAppData?.reason,
                    byTherapist:appointment?.cancelledAppData?.byTherapist
                  },
                ]);

                const updatedCancelledAppointments = updateCancelledAppointments(appointment, true);
                setCancelledAppointments(updatedCancelledAppointments);
                return prev.map((appointmentData: any) =>
                  appointmentData.id === appointmentDto.id
                    ? {
                        ...appointmentData,
                        end: null,
                        originalDate: appointment?.cancelledAppData?.originalDate,
                        resolved: appointment?.cancelledAppData?.resolved,
                        reason: appointment?.cancelledAppData?.reason,
                        byTherapist:appointment?.cancelledAppData?.byTherapist
                      } // Set end to null for the deleted appointment
                    : appointmentData
                );
              } else {
                setUnscheduleds((prevUnscheduledAppointments) => [
                  ...prevUnscheduledAppointments,
                  appointmentDto,
                ]);
                // If not by the therapist, filter it out of allAppointments
                return prev.filter((appointment: any) => appointment.id !== appointmentDto.id);
              }
            } else if (action === "Update") {

              if (appointment && appointment.isCancelledAppointment) {
                const updatedCancelledAppointments = updateCancelledAppointments(appointment, false);
                // Pass the updated state to the action
                setCancelledAppointments(updatedCancelledAppointments);
                // Before updating, remove the appointment from setUnscheduleds if it exists
                setUnscheduleds((prevUnscheduleds: any) =>
                  prevUnscheduleds.filter(
                    (unscheduled: any) => unscheduled.id !== appointmentDto.id
                  )
                );
              }

              // Update the appointment in allAppointments
              return prev.map((appointmentData: any) =>
                appointmentData.id === appointmentDto.id ? appointmentDto : appointmentData
              );
              
            } 
            else if (action === "Create") {
              if (appointment && appointment.isCancelledAppointment) {
                const updatedCancelledAppointments = updateCancelledAppointments(
                  appointment,
                  false
                );

                // Pass the updated state to the action
                setCancelledAppointments(updatedCancelledAppointments);
                // Before creating, remove the appointment from setUnscheduleds if it exists
                setUnscheduleds((prevUnscheduleds: any) =>
                  prevUnscheduleds.filter(
                    (unscheduled: any) => unscheduled.id !== appointmentDto.id
                  )
                );
              }

              // Create the new appointment
              return [...prev, appointmentDto];
              
            }
            return prev;
          });
          console.log("Termin erfolgreich geändert", appointmentDto);
          break;

        case "ContinuousTimeSlot":
          const continuousAppointmentDto = appointment as ContinuousAppointmentDto;
          setContinuousEvents((prev) => {
            if (action === "Delete") {
              return prev.filter((appointment) => appointment.id !== continuousAppointmentDto.id);
            }
            if (action === "Update") {
              return prev.map((appointment) =>
                appointment.id === continuousAppointmentDto.id
                  ? continuousAppointmentDto
                  : appointment
              );
            }
            if (action === "Create") {
              return [...prev, continuousAppointmentDto];
            }
            return prev;
          });
          console.log("Dauertermin erfolgreich geändert", continuousAppointmentDto);
          break;

        case "EventTimeSlot":
          const updatedEventTimeSlot = appointment as EventTimeSlotDto;

          setEventTimeslots((prev) => {
            if (action === "Delete") {
              return prev.filter((eventTimeSlot) => eventTimeSlot.id !== updatedEventTimeSlot.id);
            }
            if (action === "Update") {
              return prev.map((eventTimeSlot) =>
                eventTimeSlot.id === updatedEventTimeSlot.id ? updatedEventTimeSlot : eventTimeSlot
              );
            } 
            if (action === "Create") {
              return [...prev, updatedEventTimeSlot];
            }
            return prev;
          });
          console.log("EventTimeSlot erfolgreich geändert", updatedEventTimeSlot);
          break;

        case "ContinuousEventTimeSlot":
          const updatedContinuousEventTimeSlot = appointment as ContinuousEventTimeSlotDto;
          setContinuousEventTimeslots((prev) => {
            if (action === "Delete") {
              return prev.filter(
                (continuousEventTimeSlot) =>
                  continuousEventTimeSlot.id !== updatedContinuousEventTimeSlot.id
              );
            } 
            if (action === "Update") {
              return prev.map((continuousEventTimeSlot) =>
                continuousEventTimeSlot.id === updatedContinuousEventTimeSlot.id
                  ? updatedContinuousEventTimeSlot
                  : continuousEventTimeSlot
              );
            }
            if (action === "Create") {
              return [...prev, updatedContinuousEventTimeSlot];
            }
            return prev;
          });
          console.log(
            "ContinuousEventTimeSlot erfolgreich geändert",
            updatedContinuousEventTimeSlot
          );
          break;

        case "LeaveOfAbsence":
          const updatedLeaveOfAbsence = appointment as LeaveOfAbsenceDto;
          setLeaveOfAbsences((prev) => {
            if (action === "Delete") {
              return prev.filter(
                (leaveOfAbsence) => leaveOfAbsence.id !== updatedLeaveOfAbsence.id
              );
            }
            if (action === "Update") {
              return prev.map((leaveOfAbsence) =>
                leaveOfAbsence.id === updatedLeaveOfAbsence.id
                  ? updatedLeaveOfAbsence
                  : leaveOfAbsence
              );
            } 
            if (action === "Create") {
              return [...prev, updatedLeaveOfAbsence];
            }
            return prev;
          });
          console.log("LeaveOfAbsence erfolgreich geändert", updatedLeaveOfAbsence);
          break;

        case "GoneFishing":
          const updatedGoneFishing = appointment as TimeSlotDto;
          setGoneFishings((prev) => {
            if (action === "Delete") {
              return prev.filter((goneFishing) => goneFishing.id !== updatedGoneFishing.id);
            }
            if (action === "Create") {
              return [...prev, updatedGoneFishing];
            }
            return prev;
          });
          console.log("GoneFishing erfolgreich geändert", updatedGoneFishing);
          break;
        
        case "TravelTime":
          const updatedTravelTime = appointment as TimeSlotDto;
          setTravelTimes((prev) => {
            if (action === "Delete") {
              return prev.filter((travelTime) => travelTime.id !== updatedTravelTime.id);
            }
            if (action === "Update") {
              return prev.map((travelTime) =>
                travelTime.id === updatedTravelTime.id ? updatedTravelTime : travelTime
              );
            }
            if (action === "Create") {
              return [...prev, updatedTravelTime];
            }
            return prev;
          });
          console.log("TravelTime erfolgreich geändert", updatedTravelTime);
          break;

        case "LunchBreak":
          const updatedLunchBreak = appointment as TimeSlotDto;
          setLunchBreaks((prev) => {
            if (action === "Update") {
              return prev.map((lunchBreak) =>
                lunchBreak.id === updatedLunchBreak.id ? updatedLunchBreak : lunchBreak
              );
            }
            return prev;
          });
          break;

        default:
          renderCalendar();
          break;
      }

      // Cleanup on unmount
      return () => {
        socket.off("appointmentUpdate");
      };
    });
  }, [socket]);

  const fetchFullCalendarData = async () => {
    if (!location) {
      // cant do anythign yet
      return;
    }
    try {
      Loading(true);
      console.log("Loading started");
      const patientId = queryParameters.get("pid");
      let date = moment(selectedDate).format(DATE_FORMAT);

      const fetchPromises = [
        fetchUsers(),
        continuousView
          ? fetchContiniuousAppointmentsByLocation(location)
          : fetchAppointments(date, location),
      ];

      if (patientId) {
        fetchPromises.push(fetchPatient(patientId));
        fetchPromises.push(fetchTargetPatient(patientId));
        fetchPromises.push(fetchUnscheduledAppointments(patientId, continuousView));
        fetchPromises.push(fetchPatientAppointments(patientId));
        fetchPromises.push(fetchPatientAvailability(patientId));
        fetchPromises.push(fetchPatientUnavailabeTypes(patientId));
      }

      await Promise.all(fetchPromises);
    } catch (error) {
      Loading(false);
      notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
      console.log("Loading error", error);
    } finally {
      Loading(false);
      console.log("Loading finished");
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchFullCalendarData();      
    }, fetchDelay);

    // Cleanup the timeout if the user navigates again before the delay is finished
    return () => {
      clearTimeout(handler);
    };
  }, [selectedDate, location, continuousView, reFetch]);

  const Loading = (isLoading: boolean) => {
    setShowLoading(isLoading);
  };

  const handleDateChange = (date: Date) => {
    setFetchDelay(500); // Set fetch delay to 500ms on date change
    setSelectedDate(date);

    // Reset fetch delay back to 0 after date change to avoid affecting other cases
    setTimeout(() => setFetchDelay(0), 500);
  };

  const fetchPatient = async (patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patient = await getPatient(pid);
      setPatient(patient);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTargetPatient = async (patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patient = await getPatient(pid);
      setTargetPatient(patient);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPatientAvailability = useCallback(async (patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patientAvailablilties = await getPatientUnavailabilityForCalendar(pid);
      console.log("patientAvailablilties:", patientAvailablilties);
      setPatientAvailability(patientAvailablilties);
    } catch (error) {
      console.log(error);
    }
  }, [getPatientUnavailabilityForCalendar]);

  const fetchPatientUnavailabeTypes = useCallback(async (patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patientUnavailabeTypes = await getPatientUnavailabeTypeByDateForCalendar(
        pid,
        moment(selectedDate).format(DATE_FORMAT)
      );
      console.log("patientUnavailabeTypes:", patientUnavailabeTypes);
      setPatientUnavailabeTypes(patientUnavailabeTypes);
    } catch (error) {
      console.log(error);
    }
  }, [getPatientUnavailabeTypeByDateForCalendar, selectedDate]);

  const checkLatestAppointmentChanges = async () => {
    try {
      const latestUpdatedAppointments: AppointmentDto[] = await getLatestUpdatedAppointments(5);
      if (latestUpdatedAppointments.length > 0) {
        console.log("latestUpdatedAppointments:", latestUpdatedAppointments);
        renderCalendar();
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
    } finally {
      console.log("checkLatestAppointmentChanges Loading finished");
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await getUsersByLocation(location!);
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("asllsodoododspssoskxmxjxksk",allAppointments,unscheduleds)
    setFilteredAppointmentss(
      cancelledView
        ? allAppointments
        : allAppointments.filter((appointment: any) => appointment.end !== null)
    );
  }, [allAppointments, cancelledView]);

  useEffect(()=>{
    const patientId = patient?.id?.toString() || queryParameters.get("pid");
    console.log("ausiisididood",patientId,patient)
    if (patientId) {
      fetchPatientAppointments(patientId);
      fetchPatientAvailability(patientId);
    }
  },[allAppointments])

  const fetchAppointments = async (date: string, locationId: string) => {
    try {
      const data = await getAppointmentsByDateAndLocation(date, locationId, weeklyWorkDay);
      if (data) {
        setAllAppointments(data.appointments);

        // setAppointments(data.appointments);
        setGoneFishings(data.goneFishings);
        setLeaveOfAbsences(data.leaveOfAbsences);
        setEventTimeslots(data.eventTimeSlots);
        setLunchBreaks(data.lunchBreaks);
        setTravelTimes(data.travelTimes);
        console.log("appointments:", data.appointments);
        console.log("goneFishings:", data.goneFishings);
        console.log("leaveOfAbsences:", data.leaveOfAbsences);
        console.log("eventTimeSlots:", data.eventTimeSlots);
        console.log("lunchBreaks:", data.lunchBreaks);
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
    } finally {
      console.log("fetchAppointments Loading finished");
    }
  };

  const fetchContiniuousAppointmentsByLocation = async (locationId: string) => {
    try {
      const continuousApps = await getContinuousAppointmentsByLocation(locationId);
      setContinuousEvents(continuousApps.appointments);
      setContinuousEventTimeslots(continuousApps.eventTimeSlots);
      console.log("Continiuous Appointments:", continuousApps.appointments);
      console.log("Continiuous EventTimeSlots:", continuousApps.eventTimeSlots);
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Dauertermine", "error", error);
    } finally {
      console.log("fetch Continiuous Appointments Loading finished");
    }
  };

  const fetchUnscheduledAppointments = async (patientId: string, continuousView: boolean) => {
    try {
      const unscheduleds = continuousView
        ? await getUnscheduledContinuousAppointments(patientId)
        : await getUnscheduledPatientAppointments(patientId);
      console.log("unscheduleds:", unscheduleds);
      const cancelledAppointments = await getCancelledAppointmentsForPatient(
        patientId as unknown as number
      );
      // Step 2: Filter cancelled appointments where `byTherapist` is true
      const filteredCancelledAppointments = cancelledAppointments.filter(
        (cancelled: any) => cancelled.byTherapist === true && !cancelled.resolved
      );

      // Step 3: Add cancelled appointments to unscheduled appointments
      const updatedUnscheduleds = [
        ...unscheduleds, // Existing unscheduled appointments
        ...filteredCancelledAppointments?.map((cancelled: any) => {
          // Add the cancelled appointment to unscheduled if necessary
          return {
            ...cancelled.appointment, // Add appointment data from the cancelled one
            resolved: cancelled.resolved,
            byTherapist: cancelled.byTherapist,
            originalDate: cancelled.originalDate,
          };
        }),
      ];
      setUnscheduleds(updatedUnscheduleds);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("Unscheduled Loading finished");
    }
  };

  const fetchPatientAppointments = async (patientId: string) => {
    try {
      const appointments = await getAppointmentsForPatient(patientId);
      console.log("appointments:", appointments);
      setPatientAppointments(appointments);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("fetchPatientAppointments Loading finished");
    }
  };

  const onCreateEvent = async (event: Event) => {
    try {
      console.log("Event", event);
      const userId: string = event.resourceId || "";
      const user = users.find((u) => u.id === userId);
      let result: any;

      Loading(true);

      switch (event.type) {
        case "GoneFishing":
          const timeslot: TimeSlotDto = {
            user,
            type: "GoneFishing",
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("Timeslot", timeslot);
          result = await createTimeslot(timeslot);
          break;
        case "LeaveOfAbsence":
          const leaveOfAbsence: LeaveOfAbsenceDto = {
            user,
            type: "LeaveOfAbsence",
            loAType: event.loaType,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("LeaveOfAbsence", leaveOfAbsence);
          result = await createLeaveOfAbsence(leaveOfAbsence);
          break;
        case "EventTimeSlot":
          const eventTimeSlot: EventTimeSlotDto = {
            user,
            type: "EventTimeSlot",
            title: event.title,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("EventTimeSlot", eventTimeSlot);
          result = await createEventTimeSlot(eventTimeSlot);
          break;
        case "ContinuousEventTimeSlot":
          const continuousEventTimeSlot: ContinuousEventTimeSlotDto = {
            user,
            type: "ContinuousEventTimeSlot",
            title: event.title,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("ContinuousEventTimeSlot", continuousEventTimeSlot);
          result = await createContinuousEventTimeSlot(continuousEventTimeSlot);
          break;
        default:
          break;
      }
      notificationStore.showMessage("Event erfolgreich hinzugefügt", "success");
      console.log(result);
    } catch (error) {
      console.log(error);
      notificationStore.showMessage("Fehler beim Hinzufügen des Events", "error", error);
    } finally {
      Loading(false);
    }
  };

  const renderCalendar = async () => {
    let date = moment(selectedDate).format(DATE_FORMAT);
    console.log("renderCalendar date:", date);
    setRefetch(new Date());
    // continuousView ?
    // fetchContiniuousAppointmentsByLocation(location!) :
    // fetchAppointments(date, location!);
  };

  const updateContinuousWithResponse = async (
    id: string,
    appointment: ContinuousAppointmentDto
  ) => {
    const result = await updateContinuousAppointment(id, appointment);
    setResponseDialogData(result);
    if (result && result.backPlanResults.length > 0) setShowResponseDialog(true);
    return result;
  };

  const onChangeEvent = async (event: Event, relatedEvents?: Event[]) => {
    try {
      Loading(true);

      const userId = event.resourceId || "";
      const user = users.find((u) => u.id === userId);

      const relatedEventUserId = relatedEvents?.map((event) => event.resourceId)[0];
      const relatedEventUser = users.find((u) => u.id === relatedEventUserId);

      console.log("onChangeEvent Event", event);

      if (!event.appointmentId) {
        console.log("Event has no appointmentId, there is a timeslot of a different type");
        const timeslot: TimeSlotDto = {
          id: event.id!,
          user,
          start: event.start!,
          end: event.end!,
          type: event.type!,
        };
        const result = continuousView
          ? await updateContinuousTimeSlot(event.id!, timeslot)
          : await updateTimeSlot(event.id!, timeslot);
        console.log(result);
      } else {
        const appointment = continuousView
          ? await getContinuousAppointment(event.appointmentId!)
          : await getAppointment(event.appointmentId!);
        if (appointment) {
          const appDto = appointment as AppointmentDto;
          console.log("Appointment in onChangeEvent in Scheduler", appDto);

          // for main event
          setTimeSlotChanges(appDto, event, user!);

          // for related events
          relatedEvents?.forEach((relatedEvent) => {
            setTimeSlotChanges(appDto, relatedEvent, relatedEventUser!);
          });

          const result = continuousView
            ? updateContinuousWithResponse(event.appointmentId!, appDto)
            : await saveAppointment(event.appointmentId!, appDto);

          // if (result) {
          //   notificationStore.showMessage("Termin erfolgreich geändert", "success");
          // }
        }
      }
    }
    catch (error) {
      console.log(error);
      notificationStore.showMessage("Fehler beim Ändern des Termins", "error", error);
    } finally {
      Loading(false);
    }
  };

  const setTimeSlotChanges = (appDto: AppointmentDto, event: Event, user: UserDto) => {
    const updatedTimeSlot = appDto.timeSlots?.find((slot) => slot.id?.toString() === event.id);
    if (updatedTimeSlot) {
      updatedTimeSlot.user = user;
      updatedTimeSlot.start = event.start!;
      updatedTimeSlot.end = event.end!;
    }
    const indexOfUpdatedTimeSlot = appDto.timeSlots?.findIndex(
      (slot) => slot.id === event.timeSlot?.id
    );
    if (indexOfUpdatedTimeSlot !== undefined && indexOfUpdatedTimeSlot !== -1) {
      if (appDto.timeSlots) {
        appDto.timeSlots[indexOfUpdatedTimeSlot] = updatedTimeSlot!;
      }
    }
  };

  const onEventReceive = async (eventReceive: EventReceiveArg) => {
    try {
      // const draggedEl = eventReceive.draggedEl;
      // draggedEl.style.display = "none"; // Detach the dragged element from the DOM
      // eventReceive.draggedEl.parentNode?.removeChild(eventReceive.draggedEl);
      const event = eventReceive.event;
      console.log("Event", event.toPlainObject());
      const userId = eventReceive.event.getResources()[0]?.id;
      const user = users.find((u) => u.id === userId);
      // let current = new Date(event.start?.getTime()!);

      // // calculate the shift for heat treatments
      // let shift = 0;
      // const heatTreatments = ["PA", "HL"];
      // let firstNonHeatTreatmentIndex = Infinity;

      // Loading(true);

      // // sort timeSlots by therapyRx order
      // const timeSlots = event.extendedProps.timeSlots
      //   ?.slice()
      //   .sort(
      //     (a: TimeSlotDto, b: TimeSlotDto) => a.therapyRx?.order! - b.therapyRx?.order!
      //   ) as TimeSlotDto[];

      // // Find the first non-heat treatment index
      // timeSlots.forEach((timeSlot: TimeSlotDto, index: number) => {
      //   if (!heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation)) {
      //     firstNonHeatTreatmentIndex = Math.min(firstNonHeatTreatmentIndex, index);
      //   }
      // });

      // // Calculate the shift for heat treatments that are before the first non-heat treatment index
      // timeSlots.forEach((timeSlot: TimeSlotDto, index: number) => {
      //   if (
      //     heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation) &&
      //     index < firstNonHeatTreatmentIndex
      //   ) {
      //     shift -= (timeSlot.therapyRx?.therapy as TherapyExt).duration;
      //   }
      // });

      // const appointment: AppointmentDto = {
      //   start: event.start?.toString()!,
      //   end: event.end?.toString()!,
      //   frequency: {
      //     id: event.extendedProps.frequency.id,
      //     text: event.extendedProps.frequency.text,
      //     prefferedValue: event.extendedProps.frequency.prefferedValue,
      //   },
      //   timeSlots: timeSlots
      //     // ?.sort(
      //     //   (a: TimeSlotDto, b: TimeSlotDto) =>
      //     //     new Date(a.start).getTime() - new Date(b.start).getTime()
      //     // )
      //     .map((slot: any) => {
      //       const duration = parseInt((slot.therapyRx.therapy as TherapyExt).duration.toString());
      //       console.log("duration:", duration);
      //       const start = new Date(current.getTime());
      //       start.setMinutes(start.getMinutes());
      //       current.setMinutes(current.getMinutes() + duration);
      //       const end = new Date(current.getTime());
      //       start.setMinutes(start.getMinutes() + shift);
      //       end.setMinutes(end.getMinutes() + shift);
      //       console.log("start", start, "end", end);
      //       const timeSlot: TimeSlotDto = {
      //         id: slot.id,
      //         user,
      //         start: start.toISOString(),
      //         end: end.toISOString(),
      //         type: slot.type,
      //         therapyRx: {
      //           id: slot.therapyRx.id,
      //           therapy: {
      //             abbreviation: (slot.therapyRx.therapy as TherapyExt).abbreviation,
      //             duration: (slot.therapyRx.therapy as TherapyExt).duration,
      //           },
      //           amount: slot.therapyRx.amount,
      //           rx: {
      //             rxNumber: slot.therapyRx.rx.rxNumber,
      //           },
      //         },
      //       };
      //       return timeSlot;
      //     }),
      // };
      // console.log("Appointment to save", appointment);
      Loading(true);

      const oldAppointment: AppointmentDto = {
        id: event.id!,
        timeSlots: event.extendedProps.timeSlots,
        frequency: event.extendedProps.frequency,
      };

      const newAppointment = updateReceivedAppointment(oldAppointment, user!, event.start!, event.end!);
      console.log("Appointment to save", newAppointment);

      if (newAppointment) {
        const result = continuousView
          ? // await saveContinuousAppointment(event.id!, appointment) :
            await updateContinuousWithResponse(event.id!, newAppointment)
          : await saveAppointment(event.id!, newAppointment);
        console.log("save Appointment result", result);
        
        if (result) {
          setShelfAppointments(shelfAppointments.filter((app) => app.id !== event.id));
          setShelfContinuousAppointments(
            shelfContinuousAppointments.filter((app) => app.id !== event.id)
          );
          setUnscheduleds(unscheduleds.filter((app) => app.id !== event.id));
          notificationStore.showMessage("Termin erfolgreich umgezogen", "success");
        }
      }
    } catch (error) {
      console.error("Error in onEventReceive:", error);
    } finally {
      Loading(false); 
    }
  };

  const onSelectedShelfEventReceive = async (appointment: AppointmentDto) => {
    try {
      Loading(true);
      
      const result = continuousView
        ? await updateContinuousWithResponse(appointment.id!, appointment)
        : await saveAppointment(appointment.id!, appointment);
      console.log(result);
      if (result) {
        setShelfAppointments(shelfAppointments.filter((app) => app.id !== appointment.id));
        setShelfContinuousAppointments(
          shelfContinuousAppointments.filter((app) => app.id !== appointment.id)
        );
        setUnscheduleds(unscheduleds.filter((app) => app.id !== appointment.id));
        notificationStore.showMessage("Termin erfolgreich hinzugefügt", "success");
      }
    } catch (error) {
      console.error("Error in onSelectedShelfEventReceive:", error);
    } finally {
      Loading(false);
    }
  };

  const onRemoveEvent = async (timeslotId: string) => {
    try {
      Loading(true);
      const result = await deleteTimeslot(timeslotId);
      console.log(result);
    } catch (error) {
      console.error("Error in onRemoveEvent:", error);
    } finally {
      Loading(false);
    }
  };

  const onRemoveContinuousEvent = async (timeslotId: string) => {
    const result = await deleteContinuousTimeslot(timeslotId);
    console.log(result);
  };

  const onMoveEventDate = async (event: Event) => {
    if (continuousView) {
      const continuousAppDto: ContinuousAppointmentDto = await getContinuousAppointment(
        event.appointmentId!
      );
      console.log("Event moved to shelf box:", continuousAppDto);
      continuousAppDto.timeSlots?.sort(
        (a: TimeSlotDto, b: TimeSlotDto) =>
          new Date(a.start).getTime() - new Date(b.start).getTime()
      );
      notificationStore.showMessage("Dauertermin ins Regal verschoben", "info");
      setShelfContinuousAppointments([...shelfContinuousAppointments, continuousAppDto]);
    } else {
      const appDto: AppointmentDto = await getAppointment(event.appointmentId!);
      appDto.timeSlots?.sort(
        (a: TimeSlotDto, b: TimeSlotDto) =>
          new Date(a.start).getTime() - new Date(b.start).getTime()
      );
      console.log("Event moved to shelf box:", appDto);
      notificationStore.showMessage("Termin ins Regal verschoben", "info");
      setShelfAppointments([...shelfAppointments, appDto]);
    }
  };

  const handleAddShelfEvent = async (event: Event) => {
    console.log("Event moved from shelf box:", event);
    const lastShelfEvent = continuousView
      ? shelfContinuousAppointments[shelfContinuousAppointments.length - 1]
      : shelfAppointments[shelfAppointments.length - 1];
    const userId = event.resourceId || "";
    const user = users.find((u) => u.id === userId);
    let current = new Date(event.start);

    if (lastShelfEvent) {
      try {
        //get the last shelf event duration in minutes
        const lastShelfEventDuration = lastShelfEvent.timeSlots!.reduce(
          (acc, slot) => acc + (slot.therapyRx?.therapy as TherapyExt).duration,
          0
        );

        lastShelfEvent.start = event.start;
        lastShelfEvent.end = moment(event.start)
          .add(lastShelfEventDuration, "minutes")
          .toISOString();

        lastShelfEvent.timeSlots = lastShelfEvent
          .timeSlots!.sort(
            (a: TimeSlotDto, b: TimeSlotDto) =>
              new Date(a.start).getTime() - new Date(b.start).getTime()
          )
          .map((slot: any) => {
            const duration = parseInt((slot.therapyRx.therapy as TherapyExt).duration.toString());
            const start = new Date(current.getTime());
            start.setMinutes(start.getMinutes());
            current.setMinutes(current.getMinutes() + duration);
            const end = new Date(current.getTime());
            console.log("start", start, "end", end);
            const timeSlot: TimeSlotDto = {
              id: slot.id,
              user,
              start: start.toISOString(),
              end: end.toISOString(),
              type: slot.type,
              therapyRx: {
                id: slot.therapyRx.id,
                therapy: {
                  abbreviation: (slot.therapyRx.therapy as TherapyExt).abbreviation,
                  duration: (slot.therapyRx.therapy as TherapyExt).duration,
                },
                amount: slot.therapyRx.amount,
                rx: {
                  rxNumber: slot.therapyRx.rx?.rxNumber,
                },
              },
            };
            return timeSlot;
          });

        const result = continuousView
          ? await updateContinuousWithResponse(
              lastShelfEvent.id!,
              lastShelfEvent as ContinuousAppointmentDto
            )
          : await saveAppointment(lastShelfEvent.id!, lastShelfEvent as AppointmentDto);
        console.log(result);
        setShelfAppointments(shelfAppointments.filter((app) => app.id !== lastShelfEvent.id));
        setShelfContinuousAppointments(
          shelfContinuousAppointments.filter((app) => app.id !== lastShelfEvent.id)
        );
        notificationStore.showMessage("Termin erfolgreich hinzugefügt", "success");
      } catch (error) {
        console.log(error);
        notificationStore.showMessage("Fehler beim Hinzufügen des Events", "error", error);
      } finally {
        renderCalendar();
      }
    }
  };

  const onEventContentClick = async (event: TimeSlotExt) => {
    console.log("Event content clicked", event);
    const patientId = event?.patient?.id?.toString();
    const rxId = event?.therapyRx?.rx?.id;
    if (patientId) {
      setDrawerOpen(true);
      setSelectedEventRxId(rxId);
      setShowOpenDrawerButton(true);
      await Promise.all([
        fetchPatient(patientId),
        fetchUnscheduledAppointments(patientId, continuousView),
        fetchPatientAppointments(patientId),
        fetchPatientAvailability(patientId),
        fetchPatientUnavailabeTypes(patientId),
      ]);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
    setShowOpenDrawerButton(true);
  };

  const handlePatientTabChange = async (newPatientId: string) => {
    fetchPatient(newPatientId);
    fetchPatientAppointments(newPatientId);
  };

  const handleTargetPatientChange = async (newTarget: PatientDto) => {
    // replace target with temporary patient
    setTargetPatient(newTarget);
    const patientId = newTarget.id?.toString()!;
    fetchPatient(patientId);
  };

  const onCloseNotesDialog = () => {
    renderCalendar();
  };

  const handleSelectShelfAppointment = (appId: string) => {
    const selectedAppointment = unscheduleds.find((app) => app.id === appId);
    if (selectedAppointment) {
      setSelectedShelfAppointment(selectedAppointment);
    }
  }

  const handleDataRefreshFlag = async () => {
    try {
      if (!patient?.id) {
        console.error("Patient not found");
        return;
      }
      Loading(true);
      await fetchUnscheduledAppointments(patient.id.toString(), continuousView);
    } catch (error) {
      console.error("Error fetching unscheduled appointments:", error);
    } finally {
      Loading(false);
    }
  }

  const drawerWidth = 320;
  const leftSidebarWidth = 320;
  return (
    <Grid container height={"100%"}>
      <Grid item width={drawerOpen ? drawerWidth : 0}>
        <CalendarDrawer
          open={drawerOpen}
          patient={patient!}
          targetPatient={targetPatient!}
          appointments={patientAppointments || []}
          drawerWidth={drawerWidth}
          selectedTab={selectedEventRxId}
          showOpenButton={showOpenDrawerButton}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
          onPatientTabChange={handlePatientTabChange}
          onTargetPatientChange={handleTargetPatientChange}
          setDataRefreshFlag={handleDataRefreshFlag}
        />{" "}
      </Grid>
      {/* Removed width={drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%"} */}
      <Grid
        item
        // width={drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%"}
        width={`calc(${isHoverSidebarOpen ? "100%" : "98%"} - ${drawerOpen ? drawerWidth : 0}px - ${
          isHoverSidebarOpen ? leftSidebarWidth : 0
        }px)`}
      >
        <Grid
          container
          direction="column"
          sx={{
            border: "0px dotted blue",
            height: "100%",
            flexWrap: "nowrap",
            alignItems: "stretch",
          }}
        >
          <Grid item sx={{ border: "0px solid green" }}>
            {/* unscheduled events */}
            {patient &&
              (continuousView
                ? shelfContinuousAppointments.length === 0
                : shelfAppointments.length === 0) && (
                <EventShelf
                  appointments={unscheduleds}
                  title={`Offene ${continuousView ? "Dauertermin" : "Termine"}`}
                  patient={patient}
                  onSelectEvent={handleSelectShelfAppointment}
                />
              )}
            {/* shelf events */}
            {(shelfAppointments.length > 0 || shelfContinuousAppointments.length > 0) && (
              <EventShelf
                appointments={continuousView ? shelfContinuousAppointments : shelfAppointments}
                title={"Ablage"}
                onSelectEvent={handleSelectShelfAppointment}
              />
            )}
          </Grid>
          <Grid item sx={{ border: "0px solid red", flexGrow: 1 }}>
            <Calendar
              isContinuous={continuousView}
              isCancelledView={cancelledView}
              users={users}
              appointments={filteredAppointmentss?.filter(
                (app) => shelfAppointments.findIndex((shelfApp) => shelfApp.id === app.id) === -1
              )}
              goneFishings={goneFishings}
              leaveOfAbsences={leaveOfAbsences}
              eventTimeslots={EventTimeslots}
              lunchBreaks={lunchBreaks}
              travelTimes={travelTimes}
              selectedShelfAppointment={selectedShelfAppointment}
              patientAvailabilities={patientAvailability}
              patientUnavailableTypes={patientUnavailabeTypes}
              continuousEventTimeSlots={continuousEventTimeslots}
              continuousAppointments={continuousEvents.filter(
                (app) =>
                  shelfContinuousAppointments.findIndex((shelfApp) => shelfApp.id === app.id) === -1
              )}
              onDatesSet={handleDateChange}
              onCreateEvent={onCreateEvent}
              onChangeEvent={onChangeEvent}
              onEventReceive={onEventReceive}
              onRemoveEvent={onRemoveEvent}
              onRemoveContinuousEvent={onRemoveContinuousEvent}
              onOutdated={renderCalendar}
              onContinuousViewChange={handleContinuousViewChange}
              onCancelledViewChange={handleCancelledViewChange}
              isLoading={showLoading}
              onMoveEventDate={onMoveEventDate}
              isShelfEvents={shelfAppointments.length > 0 || shelfContinuousAppointments.length > 0}
              onAddShelfEvent={handleAddShelfEvent}
              onEventContentClick={onEventContentClick}
              onCloseNotesDialog={onCloseNotesDialog}
              onSelectedShelfEventReceive={onSelectedShelfEventReceive}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ position: "relative" }}>
        <HoverSidebar onToggle={(open) => setIsHoverSidebarOpen(open)} width= {""+leftSidebarWidth+"px"}>
          <Typography variant="h6">Stornierte Termine</Typography>
          {!isLoadingCancelled &&
            cancelledAppointments.cancelledAppointments &&
            cancelledAppointments.cancelledAppointments.length === 0 && <>Kein Termin</>}

          {!isLoadingCancelled &&
            cancelledAppointments.cancelledAppointments &&
            cancelledAppointments.cancelledAppointments.length > 0 && (
              <CancellationDrawer
                cancelledAppointments={cancelledAppointments}
                onEventContentClick={onEventContentClick}
              />
            )}
          {isLoadingCancelled && <CircularProgress size={60} />}
        </HoverSidebar>
      </Grid>

      <CAResponseDialog
        open={showResponseDialog}
        handleClose={() => {
          setShowResponseDialog(false);
        }}
        responseDialogData={responseDialogData}
      />
    </Grid>
  );
});

const CAResponseDialog = (props: any) => {
  const response = props.responseDialogData as CAResponse;
  let caAppDto: ContinuousAppointmentDto | undefined;
  let backPlanResults: BackPlanResult[] | undefined;

  if (props.responseDialogData) {
    caAppDto = response.savedCA;
    backPlanResults = response.backPlanResults;
  }
  return (
    <Dialog open={props.open} onClose={props.handleClose} style={{ zIndex: 2000 }} fullWidth>
      <DialogTitle id="alert-dialog-title">{"Zurückplanen"}</DialogTitle>
      <DialogContent>
        {caAppDto && (
          <>
            <FlatCard title="Dauertermin">
              <Grid container spacing={3}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <AppointmentCard appointment={caAppDto} continous={true} />
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </FlatCard>
            <FlatCard title="Ergebnisse der Zurückplanung">
              <List>
                {backPlanResults?.map((result: BackPlanResult, index) => (
                  <ListItem>
                    <Grid container spacing={3} alignItems={"center"}>
                      <Grid item xs={3}>
                        <Typography>{new Date(result.date).toLocaleDateString()}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {result.app && (
                          <AppointmentCard appointment={result.app} continous={false} />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{result.message}</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </FlatCard>
          </>
        )}
        <></>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Scheduler;
