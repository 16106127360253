import { useLocalObservable } from "mobx-react-lite";
import React, { createContext, ReactNode } from "react";
import { Configuration, DocumentationApi, PatientsApi, StorageApi } from "../../api-client";
import { AuthContext } from "../Auth/auth.provider";
import useStore from "../../helpers/useStore";
import StorageStore from "./StorageStore";

interface StorageProviderProps {
  children: ReactNode;
}

export const StorageContext = createContext<StorageStore | null>(null);

 const StorageProvider = ({ children }: StorageProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL;
   const AuthenticationStore = useStore(AuthContext);
   const { authToken } = AuthenticationStore;

  const config = new Configuration({
    basePath: basePath,
    accessToken: authToken || "",
  });

  const apiClient = new StorageApi(config);

  const store = useLocalObservable(() => new StorageStore(apiClient, AuthenticationStore));

  store.apiClient = apiClient;

  return <StorageContext.Provider value={store}>{children}</StorageContext.Provider>;
};

export default StorageProvider;


