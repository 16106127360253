import { Dayjs } from "dayjs";

export enum transactionTypes {
  EINNAHME = "Einnahme",
  AUSGABE = "Ausgabe",
}

export enum paymentStatus {
  PAID = "Bezahlt",
  PARTIAL = "Teilweise bezahlt",
  NOT_PAID = "Nicht bezahlt",
}

export enum transactionMethods {
  CASH = "CASH",
  LASTSCHRIFT = "LASTSCHRIFT",
  EC = "EC",
  VERSICHERUNG = "VERSICHERUNG",
}

export enum recnungTransactionMethods {
  RECHNUNG = "RECHNUNG",
  BANK_TRANSFER = "BANKTRANSFER",
}

export type AllTransactionMethods = transactionMethods | recnungTransactionMethods;


export interface DateRange {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export type DateRangesByMethod = {
  [methods in AllTransactionMethods]?: DateRange;
};