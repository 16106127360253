import React, { useState } from "react";
import { Fab, Zoom } from "@mui/material";
import { CameraAlt, AttachFile, Cancel } from "@mui/icons-material"; // Example icons
import { mobiliTheme } from "../themes/mobiliTheme";

// Define the IconType
type IconType = React.ReactNode;

interface FloatingActionButtonProps {
  mainIcon: IconType; // Main FAB icon
  icons: { icon: IconType; onClick: () => void }[]; // Additional action buttons
}

const FloatingActionButton: React.FC<FloatingActionButtonProps> = ({ mainIcon, icons }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Extra Floating Buttons (Appear LEFT of main button) */}
      {icons.map((item, index) => (
        <Zoom key={index} in={open}>
          <Fab
            size="small"
            onClick={() => {
              item.onClick();
              setOpen(false); // Auto-close after click
            }}
            // style={{
            //   position: "fixed",
            //   bottom: 16,
            //   right: 16 + (index + 1) * 60, // Adjusting right position to move left
            // }}
          >
            {item.icon}
          </Fab>
        </Zoom>
      ))}

      {/* Main Floating Action Button */}
      <Fab
        onClick={() => setOpen(!open)}
        // sx={{background:mobiliTheme.palette.secondary.main}}
        // style={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {open ? <Cancel /> : mainIcon}
      </Fab>
    </>
  );
};

export default FloatingActionButton;
