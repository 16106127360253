import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { observer } from "mobx-react";
import { PatientDto, TherapyRxDto } from "../../../../api-client";
import Controls from "../../../../atoms/Controls";
import RxStore from "../../../../stores/Rx/rxStore";
import useStore from "../../../../helpers/useStore";
import { RxStoreContext } from "../../../../stores/Rx/rx.provider";
import notificationStore from "../../../../stores/Notification/notificationStore";
import { MoreVert } from "@mui/icons-material";

interface TherapyRxSeriesProps {
  rowData: TherapyRxDto[];
  patientData:PatientDto;
  setDataRefresh:any;
  dataRefreshFlag:boolean | null;
  setChangeInSeriesData:any;
  changeInSeriesData:boolean;
}
const style={
  width:"30%"
}
const TherapyRxSeries: React.FC<TherapyRxSeriesProps> = observer(
  ({ rowData,patientData, setDataRefresh, dataRefreshFlag,setChangeInSeriesData,changeInSeriesData }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [menu, setMenu] = useState<null | HTMLElement>(null);
    const [newSeriesLabel, setNewSeriesLabel] = useState("")
    const [adddialogOpen, setAddDialogOpen] = useState(false);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const RxStore = useStore(RxStoreContext);
    const { savedSeriesForPatient,updateSeriesForTherapyRx,addNewSeriesForPatient } = RxStore;

    const handleDialogOpen = (notes: any) => {
      setSelectedNotes(notes);
      setDialogOpen(true);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
      setSelectedNotes([]);
    };
    const handleAddClick = () => {
      setAddDialogOpen(true);
    };

    const handleAddDialogClose = () => {
      setAddDialogOpen(false);
    };

    const handleMenuClose = () => {
      setMenu(null);
    };

    const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
      setMenu(event.currentTarget);
    };

    const truncateText = (text: string, maxLength: number) => {
      if (text && text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
      }
      return text;
    };
    const [loading, setLoading] = useState(false);

    const handleChange = async (therapyRxId:string,event:any) => {
      setLoading(true);
      try {
        console.log("KAKSKKSS",event)
        // Validate if a series is selected
        if (!event || !event.target.id) {
          notificationStore.showMessage('Bitte wählen Sie eine Serie zum Aktualisieren aus', 'warning');
          return;
        }
  
        // Make API call to update therapyRx.rxSeries
        const response = await updateSeriesForTherapyRx(therapyRxId, event.target.id);
        // handleData(patientData)
        if (response) {
          notificationStore.showMessage("Serie erfolgreich aktualisiert!","success")
        }
  setDataRefresh(!dataRefreshFlag);
  setChangeInSeriesData(!changeInSeriesData);
  // setSeriesSelectedRow(selectedSeriesRow)
  // setOpenRxView(true);

      
      } catch (error:any) {
        notificationStore.showMessage(error, 'error');
      } finally {
        setLoading(false);
      }
    };
    const handleAddSeriesSubmit = async () => {
      try {
      const response=  await addNewSeriesForPatient(patientData.id as number, { label: newSeriesLabel });
        // notificationStore.showMessage("New series added successfully!", "success");
        setNewSeriesLabel("");
        setAddDialogOpen(false);
        setDataRefresh(!dataRefreshFlag);
        handleMenuClose();
        // handleData(patientData)
      } catch (error: any) {
        notificationStore.showMessage(error.message || "Error adding new series", "error");
      }
    };
    console.log("INSIDE SERIE", rowData);
    return (
      <Card sx={{marginTop:"20px"}}>
        <CardHeader title="Verordnete Heilmittel"   action={
          <><IconButton
            onClick={(event: any) => handleMoreButtonClick(event, rowData)}
          >
            <MoreVert />
          </IconButton><Menu
            anchorEl={menu}
            open={Boolean(menu)}
            onClose={handleMenuClose}
          >
              <MenuItem onClick={handleAddClick}>Neue Serie hinzufügen</MenuItem>
            </Menu>
            <Dialog open={adddialogOpen} onClose={handleAddDialogClose}>
        <DialogTitle>Neue Serie hinzufügen</DialogTitle>
        <DialogContent dividers>
        <Controls.Input
                    name="newSeriesLabel"
                    label="Serienetikett"
                    value={newSeriesLabel}
                    onChange={(e:any) => setNewSeriesLabel(e.target.value)}
                  />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} variant="text" >
          Abbrechen
          </Button>
          <Button onClick={handleAddSeriesSubmit} variant="contained" >
          Speichern
          </Button>
        </DialogActions>
      </Dialog></>
        }/>
        <CardContent>
          <Table aria-label="series-rx-table">
            <TableHead>
              <TableRow>
                <TableCell sx={style}>Rx</TableCell>
                <TableCell sx={style}>Heilmittel</TableCell>
                <TableCell sx={style}>ICD Codes</TableCell>
                <TableCell sx={style}>Diagnosegruppe</TableCell>
                <TableCell sx={style} >Weitere med. Befunde</TableCell>
                <TableCell sx={style}>Notes</TableCell>
                <TableCell sx={style}>Series</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.map((therapyRx: any, index) => (
                <TableRow key={therapyRx.id}>
                  <TableCell sx={style}>{therapyRx.rx.rxNumber}</TableCell>
                  <TableCell sx={style}>
                  {/* <Typography variant="subtitle1"> */}
                   
                   {therapyRx.therapy.abbreviation}
                   {therapyRx.isDoubledBy || therapyRx.isSupplementedBy ? 
                     `${therapyRx.isDoubledBy ? `, ${therapyRx.isDoubledBy.therapy.abbreviation}` : ""}${therapyRx.isSupplementedBy ? `, ${therapyRx.isSupplementedBy.therapy.abbreviation}` : ""}` 
                     : ""}
                 
                 
                                     {/* </Typography> */}
                  </TableCell>

                  <TableCell sx={style}>
                    {therapyRx?.rx?.icdCodeEntries?.map(
                      (icdCode: any, idx: any) => (
                        <Typography key={idx}>{icdCode.text}</Typography>
                      )
                    )}
                  </TableCell>
                  <TableCell sx={style}>
                    {therapyRx.rx?.diagnosisGroup?.code || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      ...style,
                      cursor: therapyRx.rx?.additionalNotes
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() => {
                      if (therapyRx.rx.additionalNotes) {
                        handleDialogOpen([
                          { text: therapyRx.rx.additionalNotes },
                        ]);
                      }
                    }}
                  >
                    {truncateText(therapyRx.rx.additionalNotes, 20) || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      cursor:
                        therapyRx.rx?.notes?.length > 0 ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (therapyRx.rx.notes.length > 0) {
                        handleDialogOpen(therapyRx.rx.notes);
                      }
                    }}
                  >
                    {therapyRx.rx?.notes?.length > 0
                      ? truncateText(therapyRx.rx.notes[0].text, 20)
                      : "-"}
                  </TableCell>

                  <TableCell sx={style}>
                    {" "}
                    <Controls.Select
                      name="rxSeries"
                      // label="Rezeptverbleib"
                      value={therapyRx.rxSeries.label}
                      style={{ width: "100px" }}
                      id={1}
                      onChange={(event:any)=>{handleChange(therapyRx.id,event)}}
                      options={savedSeriesForPatient.map((option: any) => ({
                        label: option.label,
                        value: option.label,
                        id: option.id,
                      }))}
                      onBlur={true}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Dialog for displaying notes */}
          <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Notes Details</DialogTitle>
            <DialogContent dividers>
              {selectedNotes.map((note: any, index) => (
                <div key={index}>
                  <Typography variant="body1" gutterBottom>
                    <span style={{ fontWeight: "bold" }}>
                      Note {index + 1}:
                    </span>{" "}
                    {note.text}
                  </Typography>
                  <ul style={{ paddingLeft: "20px" }}>
                    {note.bulletPoints &&
                      note.bulletPoints.map((bullet: any, bulletIndex: any) => (
                        <li key={bulletIndex}>{bullet}</li>
                      ))}
                  </ul>
                </div>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    );
  }
);

export default TherapyRxSeries;
