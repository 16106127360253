import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
  Typography,
  Box,
  List,
  ListItem,
  Chip,
  Grid,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { TransactionsContext } from "../../../../../../stores/Transactions/Transactions.provider";
import useStore from "../../../../../../helpers/useStore";
import debounce from "lodash.debounce";
import { TherapyChip } from "../../../../../../atoms/Chip";
import Controls from "../../../../../../atoms/Controls";
import { mobiliTheme } from "../../../../../../themes/mobiliTheme";

interface SpotlightSearchProps {
  open: boolean; // from parent
  onOpen: () => void; // we’ll call this when user presses Ctrl+K
  onClose: () => void; // close callback from parent
  onRxSelect: (rx: any) => void;
}

export default function SpotlightSearch({
  open,
  onOpen,
  onClose,
  onRxSelect,
}: SpotlightSearchProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState<any[]>([]);

  // Store function
  const transactionStore = useStore(TransactionsContext);
  const { getPatientAndRxDetails } = transactionStore;

  // Listen for Ctrl+K
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "k") {
        e.preventDefault();
        onOpen();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onOpen]);

  useEffect(() => {
    if (!open) {
      setSearchTerm("");
      setPatients([]);
      setLoading(false);
    }
  }, [open]);

  const fetchPatients = useCallback(
    async (term: string) => {
      if (!term.trim()) {
        setPatients([]);
        return;
      }
      setLoading(true);
      try {
        const response = await getPatientAndRxDetails(term);
        if (!response) throw new Error("Failed to fetch");
        setPatients(response);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    },
    [getPatientAndRxDetails]
  );

  const debouncedFetchPatients = useMemo(() => {
    return debounce((term: string) => {
      fetchPatients(term);
    }, 300);
  }, [fetchPatients]);

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchPatients(searchTerm);
    } else {
      setPatients([]);
      setLoading(false);
    }

    // Cleanup to cancel any pending calls when unmounting or changing searchTerm
    return () => {
      debouncedFetchPatients.cancel();
    };
  }, [searchTerm, debouncedFetchPatients]);

  const handleRxClick = (rx: any) => {
    onRxSelect(rx);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Patienten und Rx suchen</DialogTitle>
      <DialogContent>
        {/* Search field */}
        <TextField
          autoFocus
          margin="dense"
          label="Bitte geben Sie den Namen des Patienten ein ..."
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Loading indicator */}
        {loading && (
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress size={24} />
          </Box>
        )}

        {/* Patient + Rx results */}
        {!loading && patients.length > 0 && (
          <List>
            {patients.map((patient) => (
              <React.Fragment key={patient.id}>
                {/* Patient Header */}
                <ListItem>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    {patient.firstName} {patient.lastName}
                  </Typography>
                  {patient.dob && (
                    <Typography
                      variant="body2"
                      sx={{ ml: 2, color: "text.secondary" }}
                    >
                      DOB: {patient.dob}
                    </Typography>
                  )}
                </ListItem>

                {/* Rx cards */}
                {(patient.rxs || []).map((rx: any) => (
                  <Box
                    key={rx.id}
                    mt={1}
                    mb={1}
                    ml={4}
                  >
                    <Card
                      variant="outlined"
                      sx={{
                        backgroundColor: "rgba(255,255,255,0.9)",
                        border: "2px solid #f8f3f4",
                        borderRadius: 4,
                        boxShadow: 4,
                        transition: "transform 0.2s ease, box-shadow 0.2s ease",
                        "&:hover": {
                          boxShadow: 8,
                          transform: "translateY(-3px)",
                        },
                      }}
                    >
                      <CardActionArea onClick={() => handleRxClick(rx)}>
                        <CardContent
                          sx={{
                            backgroundColor: mobiliTheme.lowLevelContainerBackground,
                            "&:hover": {
                              backgroundColor: "secondary.light",
                            },
                          }}
                        >
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid
                              item
                              xs={2}
                            >
                              <Typography variant="subtitle2">{rx.rxNumber ?? "N/B"}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                            >
                              <Typography variant="subtitle2">
                                {rx.tarifGroup?.id ?? "N/B"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                            >
                              <Typography
                                variant="subtitle2"
                                color={rx.date ? "#003059" : "#9C1A35"}
                                fontWeight={rx.date ? "bold" : "normal"}
                              >
                                {rx.date ?? "Kein Datum"}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                            >
                              <Typography
                                variant="subtitle2"
                                color={rx.account ? "black" : "#9C1A35"}
                              >
                                {rx.account?.id ?? "Kein Mandant"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                            >
                              <Typography
                                variant="subtitle2"
                                color={rx.icdCodeEntries.length > 0 ? "black" : "#9C1A35"}
                              >
                                {(rx.icdCodeEntries || [])
                                  .map((code: any) => code.text)
                                  .join(", ") || "Keine ICD-Codes"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                            >
                              <Controls.Chip
                                sx={{
                                  fontSize: "1em",
                                  padding: "0px 0px",
                                }}
                                label={
                                  rx.isHomeVisit === true
                                    ? "Haus/Heim"
                                    : rx.isHomeVisit === false
                                    ? "Praxis"
                                    : "-"
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={5}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                                flexWrap="wrap"
                              >
                                {(rx.therapyRxs || []).length > 0 ? (
                                  rx.therapyRxs.map((tRx: any, idx: number) => (
                                    <TherapyChip
                                      key={idx}
                                      therapyName={tRx.therapy?.abbreviation ?? "N/B"}
                                      amount={tRx.amount}
                                      sx={{ fontSize: "0.9em" }}
                                    />
                                  ))
                                ) : (
                                  <Chip label="No Therapy" />
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </List>
        )}

        {/* No results found */}
        {!loading && patients.length === 0 && searchTerm && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mt: 2 }}
          >
            No matching patients found.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
