import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { DoctorReportDto } from "../../../../api-client";

interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: (updatedDoctorReport: DoctorReportDto) => void;
  editMode?: boolean;
}

const ContinueTreatmentCard = ({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps) => {
  const [continueTreatment, setContinueTreatment] = useState<null | boolean>(null);

  useEffect(() => {
    if (doctorReport) {
      setContinueTreatment(doctorReport.continueTreatment ?? null);
    }
  }, [doctorReport]);

  const handleChangeDoctorReport = (key: string, value: any) => {
    const updatedDoctorReport = { ...doctorReport, [key]: value };
    setDoctorReport(updatedDoctorReport);
  };

  const continueTreatmentOptions = [
    { value: "null", label: "keine Angabe", actualValue: null },
    { value: "true", label: "ja", actualValue: true },
    { value: "false", label: "nein", actualValue: false },
  ];

  const handleSelectionChange = (selectedValue: string) => {

    const actualValue:any= continueTreatmentOptions.find((option) => option.value === selectedValue)
      ?.actualValue;
    setContinueTreatment(actualValue);
    handleChangeDoctorReport("continueTreatment", actualValue);
  };

  return (
    <DoctorReportCard title="Fortsetzung der Therapie" editMode={editMode}>
      <CardContent>
        <FormControl fullWidth variant="outlined" disabled={!editMode}>
          <InputLabel>Vorschlag</InputLabel>
          <Select
            value={
              continueTreatmentOptions.find((option) => option.actualValue === continueTreatment)
                ?.value || "null"
            }
            onChange={(e) => handleSelectionChange(e.target.value)}
            label=""
          >
            {continueTreatmentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </DoctorReportCard>
  );
};

export default ContinueTreatmentCard;
