import React, { FC, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Box,
} from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import "../../common/helpers/css/direct-bill-info.css";
import { TestType } from "../types/TestTypes.interface";

interface InputTestTypeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (type: TestType, email?: string) => void;
}

const InputTestTypeDialog: FC<InputTestTypeDialogProps> = ({ isOpen, onClose, onConfirm }) => {
  const [testTypeValue, setTestTypeValue] = useState<TestType>(TestType.INTERNAL);
  const [emailValue, setEmailValue] = useState("");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as TestType;
    setTestTypeValue(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(testTypeValue, emailValue.trim() || undefined);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="input-email-dialog-title"
    >
      <DialogTitle
        id="input-email-dialog-title"
        className="dialog-title"
      >
        Testtyp
      </DialogTitle>
      <DialogContent className="dialog-content">
        <DialogContentText id="input-email-dialog-description">
          Bitte wählen Sie den Testtyp und folgen Sie den weiteren Anweisungen
        </DialogContentText>

        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", m: 1 }}>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={testTypeValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={TestType.INTERNAL}
              control={<Radio />}
              label="Internal"
            />
            <FormControlLabel
              value={TestType.EXTERNAL}
              control={<Radio />}
              label="External"
            />
          </RadioGroup>
        </Box>

        {testTypeValue === TestType.INTERNAL && (
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={emailValue}
            onChange={handleEmailChange}
          />
        )}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={handleConfirm}>Einreichen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputTestTypeDialog;
