import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
} from "@mui/material";
import TerminView from "./TerminView";
import { TerminViewConstants } from "../TerminView";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import useStore from "../../../helpers/useStore";
import { AppointmentDto, RxDto, RxDtoProcessingStatusEnum } from "../../../api-client";
import notificationStore from "../../../stores/Notification/notificationStore";
import { RxStoreContext } from "../../../stores/Rx/rx.provider";
import { PatientsContext } from "../../../stores/Patients/patients.provider";
import { TransactionsContext } from "../../../stores/Transactions/Transactions.provider";

interface RxStatusNotificationProps {
  open: boolean;
  onClose: () => void;
  rxData: RxDto;
  patientId: number;
}

const RxStatusNotification: React.FC<RxStatusNotificationProps> = ({
  open,
  onClose,
  rxData,
  patientId,
}) => {
  const [appointments, setAppointments] = useState<AppointmentDto[]>([]);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [dataRefreshFlag, setDataRefreshFlag] = useState(false);
  const [openResponseDialog, setResponseDialog] = useState(false);
  const AppointmentStore = useStore(AppointmentContext);
  const { getUnattendedAppointmentsForRx } = AppointmentStore;
  const TransactionStore = useStore(TransactionsContext);
  const { getDuePaymentByRxNumber } = TransactionStore;
  const RxStore = useStore(RxStoreContext);
  const { getRxPrice, updateprocessingStatus } = RxStore;
  const PatientStore = useStore(PatientsContext);
  const { patientDataRefreshFlag, setPatientDataRefreshFlag } = PatientStore;
  const [copaymentMessage, setCopaymentMessage] = useState<string>("");
  const [saveLoading,setSaveLoading]=useState(false);
  const [copaymentLoading,setCopaymentLoading]=useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAppointmentsLoading(true);
        const appointments = await getUnattendedAppointmentsForRx(rxData.id as string);
        setAppointments(appointments);
      } catch (error) {
        console.log("Error", error);
      } finally {
        setAppointmentsLoading(false);
      }
    };
    fetchData();
  }, [rxData.id]);

  const roundToNearestHundreth = (num: number): number => {
    return Math.ceil(num * 100) / 100;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCopaymentLoading(true)
        const dueOrOverpayed = await getDuePaymentByRxNumber(rxData.rxNumber as string);

        if (dueOrOverpayed) {
          const { dueAmount } = dueOrOverpayed;

          if (dueAmount > 0) {
            // Patient owes money
            setCopaymentMessage(
              `Der Patient muss noch einen Betrag von ${dueAmount.toFixed(2)} € bezahlen.`
            );
          } else if (dueAmount < 0) {
            // Patient has overpaid
            setCopaymentMessage(
              `Ein Betrag von ${Math.abs(dueAmount).toFixed(
                2
              )} € wird an den Patienten zurückerstattet.`
            );
          } else {
            // No payment needed
            setCopaymentMessage(
              "Es wurden keine Zuzahlungen geleistet, daher sind keine weiteren Abrechnungsschritte erforderlich."
            );
          }
        } else {
          // Handle unexpected case where dueOrOverpayed is null/undefined
          setCopaymentMessage(
            "Der Zuzahlungsstatus konnte nicht ermittelt werden. Bitte versuchen Sie es später erneut."
          );
        }
      } catch (error) {
        console.error("Fehler beim Abrufen des Zuzahlungsstatus:", error);
        setCopaymentMessage(
          "Der Zuzahlungsstatus konnte nicht ermittelt werden. Bitte versuchen Sie es später erneut."
        );
      }
      finally{
        setCopaymentLoading(false)
      }
    };

    if (openResponseDialog) {
      fetchData();
    }
  }, [openResponseDialog]);

  const handleSave = async () => {
    try {
      setSaveLoading(true)
      const response = await updateprocessingStatus(
        rxData.id as string,
        RxDtoProcessingStatusEnum.Abgebrochen
      );
      setPatientDataRefreshFlag(!patientDataRefreshFlag);
      if (response) {
        setResponseDialog(!openResponseDialog);
      }
      onClose();
    } catch (error) {
      notificationStore.showMessage("Error while deleteing appointments", "error");
    }
    finally{
      setSaveLoading(false);
    }
  };

  const handleCloseResponseDialog = () => {
    setResponseDialog(false);
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Bestätigung erforderlich</DialogTitle>
        <DialogContent>
          <Box sx={{ margin: "10px", fontSize: "14px" }}>
            <Typography sx={{fontWeight: "bold",
                fontSize: "1.1rem"
               }}>
              Der Rezeptstatus wird auf "abgebrochen" aktualisiert. Sind Sie sicher, dass Sie aktualisieren? 
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ marginTop: "10px" ,fontWeight: "bold",
                fontSize: "1.1rem",
               }}>
              Durch diese Aktion werden die unbeaufsichtigten Termine entfernt:

            </Typography>
          </Box>

          {/* List unattended appointments */}
          <Box sx={{ height: "500px", overflowY: "auto" }}>
            <TerminView
              appointments={appointments}
              patientId={patientId}
              dataRefreshFlag={dataRefreshFlag}
              setDataRefreshFlag={setDataRefreshFlag}
              appointmentsLoading={appointmentsLoading}
              isView={TerminViewConstants.ALL_APPOINTMENTS}
            />
          </Box>

          <Typography variant="body1" mt={2}>
            {appointments &&
              appointments.length > 0 &&
              "Die Zuzahlung muss möglicherweise jetzt angepasst werden."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose} disabled={saveLoading}>
            Abbrechen
          </Button>
          <Button onClick={handleSave} variant="contained" disabled={saveLoading}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openResponseDialog} onClose={handleCloseResponseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Zuzahlungsstatus</DialogTitle>
        <DialogContent>
          {copaymentLoading ? (
            <CircularProgress />
          ) : (
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.25rem",
                textAlign: "center",
                p: 2,
              }}
            >
              {copaymentMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResponseDialog} variant="text">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RxStatusNotification;
