import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import styles from "./DoctorReportCard.module.css";

interface DoctorReportCardProps {
  title: string;
  children: React.ReactNode;
  editMode?: boolean;
}

export default function DoctorReportCard({ title, editMode, children }: DoctorReportCardProps) {
  return (
    <Card
      style={{
        flex: 1,
        opacity: editMode ? 1 : 0.8,
        pointerEvents: editMode ? "auto" : "none", // Disables interaction when in edit mode
      }}
    >
      <CardHeader
        title={<Typography variant="subtitle1" className={styles.cardSubtitle}>{title}</Typography>}
        style={{ padding: 0 }}
      />
      <CardContent style={{ padding: "8px" }}>
        {children}
      </CardContent>
    </Card>
  );
}
