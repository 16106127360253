import React, { FC } from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { RxBill } from "../types/PatientBills.types";
import { GetRechnungDetailsDto } from "../../../../api-client";

interface PatientBillTableProps {
  rxBills: RxBill[];
  rechnungMap: Record<string, GetRechnungDetailsDto | undefined>;
  onClickZahlen: (rxNumber: string | undefined) => void;
  onClickBill: (bill: RxBill) => void;
  onClickReturnPatientBill: (bill: RxBill) => void;
  loadingRows: Record<string, boolean>;
}

const PatientBillTable: FC<PatientBillTableProps> = ({
  rxBills,
  onClickZahlen,
  onClickBill,
  onClickReturnPatientBill,
  rechnungMap,
  loadingRows,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>RX Nummer</TableCell>
            <TableCell align="left">Rx Datum</TableCell>
            <TableCell align="left">Heilmittel und Menge</TableCell>
            <TableCell align="left">Gesamtpreis (€)</TableCell>
            <TableCell align="left">Bezahlt (€)</TableCell>
            <TableCell align="left">Fällig (€)</TableCell>
            <TableCell align="center">Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rxBills.map((bill, index) => {
            const shouldWarn = bill.isEstimated;
            const rxId = bill.rxId;
            const rechnung = rxId ? rechnungMap[rxId] : undefined;
            const rxPrice =
              bill.isEstimated && bill.isTimeSlotZero ? bill.estimatedPrice : bill.realPrice;

            const tooltipTitle = shouldWarn
              ? "Dies ist ein geschätzter Preis. Bitte überprüfen Sie die Zeitfenster"
              : rechnung && Number(rechnung.amount.toFixed(2)) === Number(rxPrice.toFixed(2))
              ? ""
              : "Klicken zum Bezahlen";

            let rechnungElem: React.ReactNode;
            if (!rechnung) {
              rechnungElem = (
                <>
                  <Grid
                    container
                    gap={1}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Button
                      size="medium"
                      variant="contained"
                      color="secondary"
                      onClick={() => onClickZahlen(bill.rxNumber)}
                      sx={{
                        fontSize: "0.7rem",
                        padding: "2px 5px",
                        minWidth: "auto",
                      }}
                    >
                      Zahlen
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      sx={{
                        fontSize: "0.7rem",
                        padding: "2px 5px",
                        minWidth: "auto",
                      }}
                      disabled={loadingRows[bill.rxNumber ?? ""]}
                      onClick={() => onClickBill(bill)}
                    >
                      {loadingRows[bill.rxNumber ?? ""] ? (
                        <>
                          <CircularProgress
                            size={16}
                            color="inherit"
                          />
                          <span style={{ marginLeft: 8 }}>Bitte warten...</span>
                        </>
                      ) : (
                        "Rechnung erstellen"
                      )}
                    </Button>
                  </Grid>
                </>
              );
            } else {
              const rechnungAmountFixed = Number(rechnung.amount.toFixed(2));
              const rxPriceFixed = Number(rxPrice.toFixed(2));
              if (rechnungAmountFixed === rxPriceFixed) {
                rechnungElem = (
                  <Chip
                    label="Rechnung erstellt"
                    color="success"
                    variant="outlined"
                  />
                );
              } else {
                rechnungElem = (
                  <>
                    <Grid
                      container
                      gap={1}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        size="medium"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClickZahlen(bill.rxNumber)}
                        sx={{
                          fontSize: "0.7rem",
                          padding: "2px 5px",
                          minWidth: "auto",
                        }}
                      >
                        Zahlen
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        sx={{
                          fontSize: "0.7rem",
                          padding: "2px 5px",
                          minWidth: "auto",
                        }}
                        onClick={() => onClickBill(bill)}
                      >
                        Rechnung erstellen
                      </Button>
                    </Grid>
                  </>
                );
              }
            }
            return (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                >
                  {
                    <Chip
                      key={index}
                      label={bill.rxNumber}
                      color="primary"
                      style={{ margin: "2px" }} // Add some margin for spacing
                    />
                  }
                </TableCell>
                <TableCell align="left">
                  {
                    <Chip
                      key={index}
                      label={bill.date}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  }
                </TableCell>
                <TableCell align="left">
                  {bill.therapies.map((therapyInfo, index) => (
                    <Chip
                      key={index}
                      label={`${therapyInfo.therapy} - ${therapyInfo.amount}`}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  ))}
                </TableCell>
                <TableCell align="left">
                  <Chip
                    key={index}
                    label={
                      <span>
                        {Number(
                          shouldWarn && bill.isTimeSlotZero ? bill.estimatedPrice : bill.realPrice
                        )
                          .toFixed(2)
                          .replace(".", ",")}
                        {" € "}
                        {shouldWarn && bill.isTimeSlotZero ? (
                          <span style={{ color: "#9C1A35" }}> geschätzter Preis</span>
                        ) : (
                          ""
                        )}
                      </span>
                    }
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                </TableCell>
                <TableCell align="left">
                  {
                    <Chip
                      key={index}
                      label={Number(bill.amountPaid).toFixed(2).replace(".", ",") + " €"}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  }
                </TableCell>
                <TableCell align="left">
                  {
                    <Chip
                      key={index}
                      label={Number(bill.dueAmount).toFixed(2).replace(".", ",") + " €"}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  }
                </TableCell>
                <TableCell align="center">
                  {bill.dueAmount === 0 ? (
                    <Chip
                      key={index}
                      label={"Bezahlt"}
                      style={{ marginRight: "2px", color: "#6FBF99" }}
                      color="success"
                      variant="outlined"
                    />
                  ) : bill.dueAmount < 0 ? (
                    // <Chip
                    //   key={index}
                    //   label={"Überbezahlt"}
                    //   style={{ marginRight: "2px", color: "#9C1A35" }}
                    //   color="error"
                    //   variant="outlined"
                    //   />
                    <Button
                      size="medium"
                      variant="contained"
                      color="secondary"
                      onClick={() => onClickReturnPatientBill(bill)}
                      sx={{
                        fontSize: "0.7rem",
                        padding: "2px 5px",
                        minWidth: "auto",
                      }}
                    >
                      Überbezahlt
                    </Button>
                  ) : (
                    <Tooltip
                      title={tooltipTitle}
                      arrow
                    >
                      <span>{rechnungElem}</span>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientBillTable;
