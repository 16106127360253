import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import useStore from "../helpers/useStore";
import { AppointmentContext } from "../stores/Appointment/appointment.provider";
import React, { useEffect, useState } from "react";
import { DateTimeRange, DateTimeRangePicker } from "../atoms/DateTimeRange";
import {
  AppointmentDto,
  TimeSlotDto,
  UpdateCancelledAppointmentDto,
  UpdateCancelledAppointmentDtoTimelinessEnum,
} from "../api-client";
import Controls from "../atoms/Controls";
import { TransactionsContext } from "../stores/Transactions/Transactions.provider";
import {
  recnungTransactionMethods,
  transactionMethods,
  transactionTypes,
} from "../components/Transactions/Kasse/types/kasse.types";
import { UserContext } from "../stores/User/User.provider";
import notificationStore from "../stores/Notification/notificationStore";
import moment from "moment";
import PatientCancellationWidget from "../components/PatientOverview/PatientHistory/PatientCancellationWidget";

interface Props {
  patientId: number;
  appointment: AppointmentDto;
  isOpen: boolean;
  onClose: (actionPerformed: boolean) => void;
}

export const CancelAppointmentDialog: React.FC<Props> = ({
  patientId,
  appointment,
  isOpen,
  onClose,
}) => {
  const AppointmentStore = useStore(AppointmentContext);
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  // const [reason, setReason] = React.useState<string | undefined>(undefined);
  const [cancelAppDto, setCancelAppDto] = useState<UpdateCancelledAppointmentDto>({
    callStatus: "", // provide default values if necessary
    reason: "",
    noShowInvoice: false,
  });
  const [patientReason, setPatientReason] = React.useState<string | undefined>(undefined);
  const [therapistReason, setTherapistReason] = React.useState<string | undefined>(undefined);

  const [savingChanges, setSavingChanges] = React.useState(false);
  const [unavailabiltyRange, setUnavailabiltyRange] = React.useState<DateTimeRange>({
    startDate: new Date(appointment.start as string),
    endDate: new Date(appointment.end as string),
  });
  const { cancelAppointment, saveBlockedSlotForPatient } = AppointmentStore;
  const TransactionStore = useStore(TransactionsContext);
  const { getValueofAppointment, noShowInvoiceTransaction } = TransactionStore;

  const [whoCancelled, setWhoCancelled] = React.useState<"patient" | "therapist" | "no_trace">(
    "patient"
  );

  const handleChangeWhoCancelled = (
    event: React.MouseEvent<HTMLElement>,
    value: "patient" | "therapist"
  ) => {
    setWhoCancelled(value);
  };

  const handleAppointmentCancellation = async () => {
    let shouldSkipFinally = false; // Flag to control finalization steps
    try {
      // Check if cancellation reason is provided
      if (whoCancelled === "patient" || whoCancelled === "therapist") {
        if (!cancelAppDto.reason) {
          notificationStore.showMessage(
            "Der Grund für die Stornierung ist zwingend erforderlich!!!",
            "warning"
          );
          shouldSkipFinally = true; // Set the flag to skip finalization
          return;
        }
      }
      const cancelledAppt = await cancelAppointment(
        appointment.id as string,
        whoCancelled == "therapist",
        cancelAppDto
      );
      if (whoCancelled == "patient") {
        // post a patient unavailabilty to the api which has the same start and end time as the appointment
        console.log("Unavailability range:", unavailabiltyRange);
        // convert range to TimeSlotDto
        const timeSlot: TimeSlotDto = {
          start: new Date(unavailabiltyRange.startDate).toISOString(),
          end: new Date(unavailabiltyRange.endDate).toISOString(),
          type: "PatientUnvailable",
        };
        await saveBlockedSlotForPatient(patientId, [timeSlot]);
      }
      if (cancelledAppt && cancelledAppt.noShowInvoice) {
        const response = await getValueofAppointment(cancelledAppt.id); //change api getValueofAppointment()
        // Get all account ids and tarifGroup ids from therapyRxs
        const accountIds = cancelledAppt.therapyRxs.map(
          (therapyRx: any) => therapyRx.rx?.account?.id
        );
        const tarifGroupIds = cancelledAppt.therapyRxs.map(
          (therapyRx: any) => therapyRx.rx?.tarifGroup?.id
        );
        // Check if all account ids are the same
        const allSameAccount = accountIds.every((id: any) => id === accountIds[0]);

        // Check if all tarifGroup ids are the same
        const allSameTarifGroup = tarifGroupIds.every((id: any) => id === tarifGroupIds[0]);
        if (!allSameAccount || !allSameTarifGroup) {
          throw new Error(
            "Die Konto-IDs und Tarifgruppen-IDs sind identisch. Die Transaktion konnte nicht erstellt werden."
          );
        }

        // Assign the accountName from the therapyRxs
        let accountName = accountIds[0] || "";
        if (response) {
          //throw error if 2 different accountId or tAccountId for appointment rxs
          const transactionData = {
            text: `Ausgefallener Termin am ${moment(cancelledAppt.createdAt).format("DD.MM.YYYY")}`,
            type: transactionTypes.EINNAHME,
            amount: parseFloat(response.totalDueAmount),
            method: recnungTransactionMethods.RECHNUNG,
            accountId: accountName as string,
            userId: user?.id || "",
            wasExported: false,
            patientId: patientId as any,
            // paymentStatus: formJson.paymentStatus as paymentStatus,
            // cloudLink: formJson.cloudLink as string,
            textDescription: `Ausfallrechnung`,
            tAccountId: tarifGroupIds[0] === "Privat" ? 4105 : (4101 as any),
          };

          const data = await noShowInvoiceTransaction(
            cancelledAppt.appointment.id,
            transactionData
          );
          if (data && data.docDto && data.docDto.accessUrl) {
            // load the pDF from the url in a new window
            console.log("Opening PDF in new window:", data.docDto.accessUrl);
            window.open(data.docDto.accessUrl, "_blank");
          } else {
            console.error("No access URL found in transaction data:", data);
          }
        }
      }

      // await new Promise((resolve) => setTimeout(resolve, 5000));
      onClose(true);
    } catch (error: any) {
      console.error("Error marking as attended/unattended:", error);
      notificationStore.showMessage(error.message, "error");
    } finally {
      setSavingChanges(false);
      if (shouldSkipFinally) return;
      onClose(true);
    }
  };

  // Automatically set timeliness based on current time and appointment start time
  useEffect(() => {
    if (whoCancelled === "patient") {
      // Only execute for patient cancellations

      const currentTime = new Date().getTime();
      const appointmentStartTime = new Date(appointment.start as string).getTime();
      const timeDifferenceInHours = (appointmentStartTime - currentTime) / (1000 * 60 * 60);

      if (timeDifferenceInHours > 24) {
        setCancelAppDto((prev) => ({
          ...prev,
          timeliness: UpdateCancelledAppointmentDtoTimelinessEnum.Fristgerecht24StVorher,
        }));
      } else if (timeDifferenceInHours <= 24 && timeDifferenceInHours > 0) {
        setCancelAppDto((prev) => ({
          ...prev,
          timeliness: UpdateCancelledAppointmentDtoTimelinessEnum.Nichtfristgerecht,
        }));
      } else {
        setCancelAppDto((prev) => ({
          ...prev,
          timeliness: UpdateCancelledAppointmentDtoTimelinessEnum.NichtErschienen,
        }));
      }
    }
  }, [appointment.start, whoCancelled]); // Also depend on `whoCancelled`

  useEffect(() => {
    if (whoCancelled === "therapist") {
      setCancelAppDto((prevState) => ({
        ...prevState,
        reason: therapistReason || "",
        noShowInvoice: false,
        timeliness: undefined,
      }));
    } else if (whoCancelled === "patient") {
      setCancelAppDto((prevState) => ({
        ...prevState,
        reason: patientReason || "",
      }));
    } else if (whoCancelled === "no_trace") {
      setCancelAppDto((prevState) => ({
        ...prevState,
        reason: "", // No reason for no_trace
        noShowInvoice: false,
        timeliness: undefined,
      }));
    }
  }, [whoCancelled, therapistReason, patientReason]);

  const handleOk = () => {
    setSavingChanges(true);
    handleAppointmentCancellation();
  };

  const optionsForPatient = ["Krankeit", "Ferien/Urlaub", "Nach Rücksprache mit dem Arzt"];
  const optionsForTherapist = ["Therapeut krank", "Therapeut kindkrank", "Therapeut im Urlaub"];
  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose(false)}
      maxWidth="md"
      sx={{ "& .MuiDialog-paper": { height: "650px", width: "900px" } }}
    >
      <DialogTitle>Termin stornieren</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid
            item
            xs={7}
          >
            <Typography>Stornierung eingeleitet durch ...</Typography>
            <ToggleButtonGroup
              value={whoCancelled}
              onChange={handleChangeWhoCancelled}
              exclusive
            >
              <ToggleButton
                value="patient"
                sx={{ fontSize: "13px" }}
              >
                Patient
              </ToggleButton>
              <ToggleButton
                value="therapist"
                sx={{ fontSize: "13px" }}
              >
                Therapeut
              </ToggleButton>
              <ToggleButton
                value="no_trace"
                sx={{ fontSize: "13px" }}
              >
                Stornieren ohne Nachverfolgung
              </ToggleButton>
            </ToggleButtonGroup>

            {whoCancelled == "patient" && (
              <>
                <Controls.Select
                  name="timeliness"
                  label="Aktualität"
                  onChange={(event: any) =>
                    setCancelAppDto((prevState) => ({
                      ...prevState,
                      timeliness: event.target.value as UpdateCancelledAppointmentDtoTimelinessEnum,
                    }))
                  }
                  value={cancelAppDto.timeliness}
                  options={Object.values(UpdateCancelledAppointmentDtoTimelinessEnum).map(
                    (value) => ({
                      value: value,
                      label: value,
                    })
                  )}
                />
                <Controls.Checkbox
                  name="noShowInvoice"
                  label="Ausfallrechnung"
                  value={cancelAppDto.noShowInvoice}
                  onChange={(event: any) =>
                    setCancelAppDto((prevState) => ({
                      ...prevState,
                      noShowInvoice: event.target.value,
                    }))
                  }
                />
                <Autocomplete
                  freeSolo
                  options={optionsForPatient}
                  value={patientReason}
                  onInputChange={(event, newValue) => {
                    setPatientReason(newValue || undefined);
                    // setCancelAppDto((prevState) => ({
                    //   ...prevState,
                    //   reason: newValue || undefined,
                    // }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vermerk"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
                <Typography>Der Patient ist für die folgende Zeitspanne nicht verfügbar</Typography>
                <DateTimeRangePicker
                  range={unavailabiltyRange}
                  onChange={setUnavailabiltyRange}
                />
              </>
            )}

            {whoCancelled == "therapist" && (
              <Autocomplete
                freeSolo
                options={optionsForTherapist}
                value={therapistReason}
                onInputChange={(event, newValue) => {
                  setTherapistReason(newValue || undefined);
                  //  setCancelAppDto((prevState) => ({
                  //    ...prevState,
                  //    reason: newValue || undefined,
                  //  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Begründung"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                )}
              />
            )}
            {whoCancelled === "no_trace" && (
              <>
                <Typography>Stornieren ohne Nachverfolgung</Typography>
              </>
            )}
          </Grid>

          <Grid
            sx={{
              border: "2px solid #ccc",
              maxHeight: "500px",
              height: "600px",
              overflowY: "auto",
            }}
            item
            xs={5}
          >
            <Typography sx={{ fontSize: "16px" }}>Vorherige Stornierung</Typography>
            <PatientCancellationWidget patientId={patientId} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {savingChanges ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Button
              onClick={() => onClose(false)}
              variant="text"
            >
              Abbrechen
            </Button>
            <Button
              disabled={!whoCancelled}
              onClick={handleOk}
            >
              Bestätigen
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
