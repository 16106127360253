export enum TerminViewConstants {
    SERIES_VIEW,
    RX_VIEW,
    TERMIN_VIEW,        // Scheduled appointments
    CONTINOUS_TERMIN,   // Scheduled continuous appointments
    UNSCHEDULED_TERMIN,
    UNSCHEDULED_CONT_TERMIN,
    CANCELLED_TERMIN,
    READ_ONLY_VIEW,
    ALL_APPOINTMENTS
}
