import React, { useState, useRef, useCallback } from "react";
import { Button, Dialog, DialogActions, IconButton } from "@mui/material";
import { CameraAlt, CheckCircle, Cancel, Photo } from "@mui/icons-material";
import Webcam from "react-webcam";
import { mobiliTheme } from "../../../../themes/mobiliTheme";
import CaptureButton from "../captureButton/CaptureButton";

const CameraCapture = ({ onCapture }: { onCapture: (file: File) => void }) => {
  const [isOptionDialogOpen, setOptionDialogOpen] = useState(false);
  const [isCameraDialogOpen, setCameraDialogOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const webcamRef = useRef<Webcam | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Open dialogs
  const openOptionDialog = () => {
    setOptionDialogOpen(true);
    setPreviewImage(null);
    setSelectedFile(null);
  };

  const openCameraDialog = () => {
    setOptionDialogOpen(false);
    setCameraDialogOpen(true);
    setPreviewImage(null);
  };

  // Capture from Webcam
  const handleCapture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setPreviewImage(imageSrc || null);
    }
  }, []);

  // Open file input (no label needed)
  const handleOpenFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file selection
  const handleSelectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));
      setOptionDialogOpen(false);
      onCapture(file);
    }
  };

  // Save the image
  const handleSave = () => {
    if (previewImage) {
      fetch(previewImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "captured-image.jpg", { type: "image/jpeg" });
          onCapture(file);
          setCameraDialogOpen(false);
        });
    } else if (selectedFile) {
      onCapture(selectedFile);
      setSelectedFile(null);
    }
  };

  return (
    <>
      <CaptureButton type="camera" onCapture={openOptionDialog} />

      {/* Option Dialog */}
      <Dialog open={isOptionDialogOpen} onClose={() => setOptionDialogOpen(false)} maxWidth="xl">
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={openCameraDialog}
            startIcon={<CameraAlt />}
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row", // Align icon and text horizontally
              alignItems: "center", // Align text and icon properly
              width: "100%",
            }}
          >
            Kamera verwenden
          </Button>

          {/* Hidden File Input */}
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleSelectImage}
          />

          {/* Button to trigger file selection */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenFilePicker}
            startIcon={<Photo />}
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row", // Same as above for horizontal alignment
              alignItems: "center", // Align text and icon properly
              width: "100%",
            }}
          >
            Bild auswählen
          </Button>


        </div>
        <DialogActions>
        <Button onClick={() => setOptionDialogOpen(false)} variant="text">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Camera Dialog (Fixed Fullscreen) */}
      <Dialog
        open={isCameraDialogOpen}
        onClose={() => setCameraDialogOpen(false)}
        fullScreen
        // PaperProps={{ style: { backgroundColor: "black", overflow: "hidden" } }} // Prevent scrolling
      >
        {/* Camera Preview / Captured Image */}
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!previewImage ? (
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode: "user" }}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <img
              src={previewImage}
              alt="Captured"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          )}
        </div>

        {/* Capture / Retake & Save Buttons */}
        <div
          style={{
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!previewImage ? (
            <Button
              variant="contained"
              onClick={handleCapture}
              style={{
                width: 60,
                height: 70,
                borderRadius: "50%",
                backgroundColor: "white",
                border: "5px solid rgba(255, 255, 255, 0.8)",
              }}
            >
              <CameraAlt fontSize="large" style={{ color: "black" }} />
            </Button>
          ) : (
            <div style={{ display: "flex", gap: 20 }}>
              {/* Retake (X Button) */}
              <IconButton
                onClick={() => setPreviewImage(null)}
                sx={{
                  background: mobiliTheme.palette.error.main,
                  color: "white",
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                }}
              >
                <Cancel fontSize="large" />
              </IconButton>

              {/* Save (Green Tick) */}
              <IconButton
                onClick={handleSave}
                sx={{
                  background: mobiliTheme.palette.success.main,
                  color: "white",
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                }}
              >
                <CheckCircle fontSize="large" />
              </IconButton>
            </div>
          )}
        </div>
        <DialogActions>
          {/* Close (Cancel) Button in Top Left */}
          <Button onClick={() => setCameraDialogOpen(false)} variant="text">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CameraCapture;
