// usePatientRechnungen.ts
import { useCallback, useEffect, useState } from "react";
import { BaseTransactionDetailsDto, GetRechnungDetailsDto } from "../../../../api-client";
import { RxBill } from "../types/PatientBills.types";

interface UsePatientRechnungenProps {
    patientId: number;
    getTransactionByPatientId: (patientId: number) => Promise<BaseTransactionDetailsDto[]>;
    getPaidBillsByPatientId: (patientId: number) => Promise<{ rxInfo: RxBill[] }>;
    getRechnungByPatientId: (patientId: number) => Promise<GetRechnungDetailsDto[]>;
    getRechnungForRx: (rxId: string) => Promise<GetRechnungDetailsDto | null>;
    lastUpdate: any;
}

export const usePatientRechnungen = ({
  patientId,
  getTransactionByPatientId,
    getPaidBillsByPatientId,
    getRechnungByPatientId,
    getRechnungForRx,
  lastUpdate,
}: UsePatientRechnungenProps) => {
  const [paidBills, setPaidBills] = useState<RxBill[]>([]);
    const [allTransactions, setAllTransactions] = useState<BaseTransactionDetailsDto[]>([]);
    const [rechnungBills, setRechnungBills] = useState<GetRechnungDetailsDto[]>([]);
  const [rechnungMap, setRechnungMap] = useState<Record<string, GetRechnungDetailsDto>>({});
  
  const [loadingCount, setLoadingCount] = useState(0);
  const isLoading = loadingCount > 0;

  const fetchPaidBills = useCallback(async () => {
    try {
      setLoadingCount((count) => count + 1); 
      const response = await getPaidBillsByPatientId(patientId);
    const bills = response.rxInfo;
    // Sort bills based on the numeric suffix of the rxNumber (e.g., "5016-1", "5016-2", etc.)
    const sortedBills = bills.sort((a: any, b: any) => {
      const [prefixA, suffixA] = a.rxNumber.split("-");
      const [prefixB, suffixB] = b.rxNumber.split("-");
      if (prefixA !== prefixB) {
        return prefixA.localeCompare(prefixB);
      }
      return parseInt(suffixA, 10) - parseInt(suffixB, 10);
    });
    setPaidBills(sortedBills);
    } finally {
      setLoadingCount((count) => count - 1);
    }
    
  }, [getPaidBillsByPatientId, patientId]);
    
    

  const fetchRechnungBills = useCallback(async () => {
    try {
      setLoadingCount((count) => count + 1); 
      const rechnungBills = await getRechnungByPatientId(patientId);
        const sortedRechnungBills = rechnungBills.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )

        setRechnungBills(sortedRechnungBills);
    } finally {
      setLoadingCount((count) => count - 1);
    }
        
    }, [getRechnungByPatientId, patientId])

  const fetchTransactions = useCallback(async () => {
    try {
      setLoadingCount((count) => count + 1); 
      const transactions = await getTransactionByPatientId(patientId);
    const sortedTransactions = transactions.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setAllTransactions(sortedTransactions);
    } finally {
      setLoadingCount((count) => count - 1);
    }
    
  }, [getTransactionByPatientId, patientId]);
    
    
  const fetchAllRxRechnungen = useCallback(async () => {
      
    try {
      const tempMap: Record<string, GetRechnungDetailsDto> = {};
    for (const bill of paidBills) {
      if (bill.rxId) {
        const rxRechnung = await getRechnungForRx(bill.rxId);
        if (rxRechnung) {
          tempMap[bill.rxId] = rxRechnung;
        }
      }
    }
    setRechnungMap(tempMap);
    } finally { 
      setLoadingCount((count) => count - 1); 
    }
    
  }, [paidBills, getRechnungForRx]);

  useEffect(() => {
    fetchPaidBills();
  }, [fetchPaidBills, lastUpdate]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, lastUpdate]);
    
    useEffect(() => {
        fetchRechnungBills();
    }, [fetchRechnungBills, lastUpdate])

    useEffect(() => {
    if (paidBills.length > 0) {
      fetchAllRxRechnungen();
    }
  }, [paidBills, fetchAllRxRechnungen]);

  return { paidBills, allTransactions, rechnungBills, fetchPaidBills, fetchTransactions, fetchRechnungBills, rechnungMap, fetchAllRxRechnungen, isLoading};
};
