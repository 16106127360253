import { action, makeObservable, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { PatientsApi,PatientDto, SearchDto, DoctorApi, CreateDoctorDto, SearchDoctorDto } from "../../api-client";
import AuthStore from "../Auth/authStore";

export default class DoctorStore {
  public apiClient: DoctorApi;
  public authStore: AuthStore;

  public constructor(apiClient: DoctorApi,authStore: AuthStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.authStore=authStore;
    this.getAllDoctors(); // Call the initialization method

  }
  @observable
  public doctorOptions: CreateDoctorDto[] = [];

  

  @action
  public setDoctorOptions = (options: CreateDoctorDto[]) => {
    this.doctorOptions = options;
  };
  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  // @observable
  // public updatedDoctor: CreateDoctorDto | null = null;
  
  // @action
  // public setUpdatedDoctor = (doctor: CreateDoctorDto | null) => {
  //   this.updatedDoctor = doctor;
  //   console.log('UPDATAEDDDOCTOR',this.updatedDoctor?.number)
  // };



  @action
  public addUpdateDoctor = async (body:CreateDoctorDto) => {
    try{
    const { data } = (await this.apiClient.addUpdateDoctor(body)) as any;
    this.setDoctorOptions(data.data || []);
    console.log('doctorll',this.doctorOptions,data)
    // this.setUpdatedDoctor(data.saved); // Set the updated doctor
    return data.saved || [];}
    catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      
    }
  };

  @action
  public getAllDoctors = async () => {
     // Ensure authentication token is available before making the API call
     const authToken = this.authStore.authToken;
    const { data } = (await this.apiClient.getAllDOctorList()) as any;
    this.setDoctorOptions(data || []);
    console.log('doctorl45',this.doctorOptions)
    console.log('Dataui',data)
    return data || [];
  };

  @action
  public searchDoctor = async (body:SearchDoctorDto) => {
    try{
    const { data } = (await this.apiClient.searchDoctor(body)) as any;
    return data || [];}
    catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      
    }

  }

}
  

