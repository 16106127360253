import React, { createRef, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {
  DateClickArg,
  EventDragStartArg,
  EventDragStopArg,
  EventReceiveArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import {
  AppointmentDto,
  ContinuousAppointmentDto,
  ContinuousEventTimeSlotDto,
  EventTimeSlotDto,
  LeaveOfAbsenceDto,
  PatientAvailabilityDto,
  PatientDto,
  TimeSlotDto,
  UserDto,
} from "../../api-client";
import {
  DateSelectArg,
  DateSpanApi,
  EventAddArg,
  EventApi,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventHoveringArg,
  EventMountArg,
  EventRemoveArg,
} from "@fullcalendar/core";
import moment from "moment";
import {mapTherapyToColor} from "../../helpers/generateColor";
import { Event, Resource, PatientExt, TherapyExt, TimeSlotExt, TherapyFrequencyExt } from "./Calendar.type";
import "./Calendar.css";
import GroupCheckBox from "./GroupCheckBox";
import { Backdrop, CircularProgress, Snackbar, Tooltip, Grid, Chip, Card, CardContent, CardActions, Button } from "@mui/material";
import CalendarDatePicker from "./CalendarDatePicker";
import { TimeSlotDialog } from "../../components/TerminPlan/TimeSlotDialog";
import UserPopover from "./UserPopover";
import { ResourceLabelContentArg, ResourceLabelMountArg } from "@fullcalendar/resource";
import AddEventModal from "./AddEventModal";
import { mobiliTheme, therapyColors } from "../../themes/mobiliTheme";
import ContextMenu, { MousePosition } from "./ContextMenu";
import useStore from "../../helpers/useStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import { CancelAppointmentDialog } from "../CancelAppointmentDialog";
import { HeaderToolbar } from "./HeaderToolbar";
import notificationStore from "../../stores/Notification/notificationStore";
import WarningDialog from "../../atoms/WarningDialog";
import { TopLevelPaper } from "../../themes/StyledComponents";
import { EventImpl } from "@fullcalendar/core/internal";
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AppointmentNotes from "./AppointmentNotes";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { observer } from "mobx-react";
import useCalendarValidations from "./Calendar.validations";
import ScheduleIcon from '@mui/icons-material/Schedule';
import AppointmentEditCard from "../../components/ScheduleOverview/Appointments/AppointmentEditCard";
import GoogleMapEmbed from "./GoogleMapEmbed";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import { Home, Business, Store, Start } from "@mui/icons-material";
import { updateReceivedAppointment } from "../../helpers/calendar";
import AppointmentOverviewModal from "../../components/PatientOverview/RxInfo/AppointmentOverviewModal";
import FaceIcon from '@mui/icons-material/Face';
import Face4Icon from '@mui/icons-material/Face4';
import { useSearchParams } from "react-router-dom";
moment.locale("de");

interface CalendarProps {
  users?: UserDto[];
  appointments?: AppointmentDto[];
  goneFishings?: TimeSlotDto[];
  leaveOfAbsences?: LeaveOfAbsenceDto[];
  eventTimeslots?: EventTimeSlotDto[];
  continuousEventTimeSlots?: ContinuousEventTimeSlotDto[];
  continuousAppointments?: ContinuousAppointmentDto[];
  lunchBreaks?: TimeSlotDto[];
  travelTimes?: TimeSlotDto[];
  selectedShelfAppointment?: AppointmentDto;
  patientAvailabilities?: PatientAvailabilityDto[];
  patientUnavailableTypes?: TimeSlotDto[];
  onDatesSet: (date: Date) => void;
  onCreateEvent?: (event: Event) => void;
  onChangeEvent?: (event: Event, relatedEvents?: Event[]) => void;
  onEventReceive?: (event: EventReceiveArg) => void;
  onRemoveEvent?: (id: string) => void;
  onRemoveContinuousEvent?: (id: string) => void;
  onOutdated: () => void;
  onContinuousViewChange: (continuous: boolean) => void;
  isContinuous?: boolean;
  isCancelledView?:boolean;
  onCancelledViewChange:(checked:boolean)=>void;
  isLoading?: boolean;
  onMoveEventDate?: (event: Event) => void;
  isShelfEvents: boolean;
  onAddShelfEvent?: (event: Event) => void;
  onEventContentClick?: (event: TimeSlotExt) => void;
  onCloseNotesDialog?: () => void;
  onSelectedShelfEventReceive?: (appointment: AppointmentDto) => void;
}

const Calendar = observer(({
  users,
  appointments,
  goneFishings,
  leaveOfAbsences,
  eventTimeslots,
  continuousEventTimeSlots,
  lunchBreaks,
  travelTimes,
  patientAvailabilities,
  patientUnavailableTypes,
  continuousAppointments,
  isContinuous = false,
  isCancelledView=false,
  isLoading = false,
  isShelfEvents = false,
  selectedShelfAppointment,
  onDatesSet,
  onCreateEvent,
  onChangeEvent,
  onEventReceive,
  onRemoveEvent,
  onRemoveContinuousEvent,
  onOutdated,
  onContinuousViewChange,
  onCancelledViewChange,
  onMoveEventDate,
  onAddShelfEvent,
  onEventContentClick,
  onCloseNotesDialog,
  onSelectedShelfEventReceive
}: CalendarProps) => {
  const AppointmentStore = useStore(AppointmentContext);
  const { 
    setAttended, 
    cancelContinuousAppointment, 
    adjustOutsideAppointments, 
    warningDialogMessage, 
    setWarningDialogMessage, 
    saveAppointments
  } = AppointmentStore;
  const CalendarStore = useStore(CalendarContext);
  const { 
    getAppointment, 
    getRouteDurationsForAppointments, 
    getPatientUnavailabeTypeByDateForCalendar,
    getPatientUnavailabilityForCalendar,
    createTimeslot,
    updateTimeSlot,
    deleteTimeslot
  } = CalendarStore;
  const [isCancellationDialogOpen, setCancellationDialogOpen] = useState(false);
  const [isContinuousCancelationWarningOpen, setContinuousCancelationWarningOpen] = useState(false);
  const selectedAppointmentRef = useRef<AppointmentDto | null>(null);
  const [selectedContinuousAppointment, setSelectedContinuousAppointment] =
    useState<ContinuousAppointmentDto | null>(null); // Track the selected continuous appointment for the warning
  const [selectedPatientId, setselectedPatientId] = useState<number | null>(null); // Track the selected appointment for the dialog
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const calendarRef = createRef<FullCalendar>();
  const [openEventModal, setOpenEventModal] = useState(false);
  const [event, setEvent] = useState<Event>();
  const [groupCheck, setGroupCheck] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [queryParameters] = useSearchParams();
  const dateParam = queryParameters.get("date");
  const [date, setDate] = useState<string>(() => {
    return dateParam ? dateParam : 
    localStorage.getItem("lastViewedDate") || moment(new Date()).format("YYYY-MM-DD");
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [clickedTS, setModalTS] = useState<any>();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuEvent, setContextMenuEvent] = useState<Event>();
  const [mousePosition, setMousePosition] = useState<MousePosition>({ mouseX: null, mouseY: null });
  const [selectedUser, setSelectedUser] = useState<UserDto>();
  const [handleEventChangeForResizing, setHandleEventChangeForResizing] = useState(true);
  const [showNotesDialog, setShowNotesDialog] = useState<boolean>(false);
  const { frequencyValidation, hasTherapistCredentials, isPatientEventOverlapping, isPatientAppointmentOverlapping } = useCalendarValidations();
  const [patientAvailability, setPatientAvailability ] = useState<PatientAvailabilityDto>();
  const [isAppointmentEditOpen, setIsAppointmentEditOpen] = useState<boolean>(false);
  const [dataRefreshFlag, setDataRefreshFlag] = useState<boolean>(false);
  const [routeplanUser, setRouteplanUser] = useState<UserDto>();
  const [isRouteplanView, setIsRouteplanView] = useState<boolean>(false);
  const [googleMapAppointments, setGoogleMapAppointments] = useState<AppointmentDto[]>();
  const [travelTimeSlots, setTravelTimeSlots] = useState<TimeSlotDto[]>();
  const [routeDurations, setRouteDurations] = useState<(number | null)[]>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [focusableElements, setFocusableElements] = useState<HTMLDivElement[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const allFocusableRef = useRef<HTMLElement[]>([]);
  const [overviewAppointmentPatientId, setOverviewAppointmentPatientId] = useState<number|null>(null);

  const slotMinTime = "08:00:00";
  const slotMaxTime = "21:00:00";
  const heatTreaments = ["PA", "HL"];

  let eventGuid = 0;

  const createEventId = () => {
    return String(eventGuid++);
  };

  const handleSelect = (selectInfo: DateSelectArg) => {
    const { startStr, endStr, allDay, resource, view } = selectInfo;
    let calendarApi = view.calendar;

    const event: Event = {
      id: createEventId(),
      title: "",
      start: startStr,
      end: endStr,
      resourceId: resource?.id,
      allDay: allDay,
    };
    calendarApi.addEvent(event);
    console.log("event added to calendar", event);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    // Remove 'selected' class from all events
    document.querySelectorAll('.fc-event.selected').forEach((eventEl) => {
      eventEl.classList.remove('selected');
    });

    const appointmentDto = appointments?.find((a) => a.id === clickInfo.event.extendedProps.appointmentId);
    console.log("selected appointment", appointmentDto);
    selectedAppointmentRef.current = appointmentDto!;
    
    // Add 'selected' class to the clicked event
    const clickedEventEl = clickInfo.el;
    if (clickedEventEl) {
      clickedEventEl.classList.add('selected');
    }
  };

  const handleEvents = (events: EventApi[]) => {
    // console.log('handleEvents', events);
    setCurrentEvents(events);
  };

  const getSelectedDate = (): Date => {
    let calendarApi = calendarRef.current?.getApi()!;
    return calendarApi?.getDate();
  };

  const callbackDatesSet = () => {
    let date = getSelectedDate();
    if (date) setDate(moment(date).format("YYYY-MM-DD"));
    return onDatesSet(date);
  };

  const handleEventAdd = (eventAddInfo: EventAddArg) => {
    console.log("eventAddInfo", eventAddInfo.event.toPlainObject());

    if (eventAddInfo.event.extendedProps.type === "TimeSlot") {
      // Don't allow timeslots to be added independently
      return;
    }
    
    const event: Event = {
      id: undefined, // this is a new event
      title: eventAddInfo.event.title,
      start: eventAddInfo.event.start?.toString()!,
      end: eventAddInfo.event.end?.toString()!,
      resourceId: eventAddInfo.event._def.resourceIds?.toString()!,
      allDay: eventAddInfo.event.allDay,
      timeSlot: {
        start: eventAddInfo.event.start?.toString()!,
        end: eventAddInfo.event.end?.toString()!,
      },
    };
    console.log("add event modal for:", event);
    setEvent(event);
    setOpenEventModal(true);

    // return onCreateEvent && onCreateEvent(event!);
  };

  const handleEventChange = async (eventChangeInfo: EventChangeArg) => {
    // handle event change only for single timeslots here, multiple timeslots are handled in handleEventDrop
    if (eventChangeInfo.event.extendedProps.multipleTimeSlots === true) {
      return;
    }
    
    // dont allow resizing of timeSlots
    if (!handleEventChangeForResizing) {
      return;
    }

    // Don't allow scheduler's database changes in routeplan view
    if (isRouteplanView) {      

      const appId = eventChangeInfo.event.extendedProps.appointmentId || eventChangeInfo.event.id;      
      const app = googleMapAppointments?.find((a) => a.id === appId);

      if (app?.timeSlots && app.timeSlots[0]) {
        // update the time slot in the google map appointments
        app.timeSlots[0].start = eventChangeInfo.event.start?.toISOString()!;
        app.timeSlots[0].end = eventChangeInfo.event.end?.toISOString()!;
      }
      
      if (app) {
        // update the appointment in the google map appointments
        const updatedAppointments = googleMapAppointments!.map((a) =>
          a.id === app?.id! ? app : a
        );

        // sort the appointments by start time
        const sortedApps = updatedAppointments.sort((a, b) => {
          return new Date(a?.timeSlots?.[0].start!).getTime() - new Date(b?.timeSlots?.[0].start!).getTime();
        });
        
        setGoogleMapAppointments(sortedApps);
      }

      // check if the patient is available for the route plan view
      const patientId = parseInt(eventChangeInfo.event.extendedProps.patient?.id);
      if (eventChangeInfo.event.extendedProps.type === "TimeSlot") {
        const isPatientAvailable = await checkPatientAvailabilityForRoutePlanView(eventChangeInfo.event, patientId!);
        if (!isPatientAvailable) {
          return eventChangeInfo.revert();
        }
      }
      // setRefetch(new Date());
      return;
    }

    console.log("eventChangeInfo", eventChangeInfo.event.toPlainObject());

    const event: Event = {
      id: eventChangeInfo.event.id,
      title: eventChangeInfo.event.title,
      start: eventChangeInfo.event.start?.toISOString()!,
      end: eventChangeInfo.event.end?.toISOString()!,
      resourceId: eventChangeInfo.event._def.resourceIds?.toString()!,
      allDay: eventChangeInfo.event.allDay,
      appointmentId: eventChangeInfo.event.extendedProps.appointmentId,
      type: eventChangeInfo.event.extendedProps.type,
    };

    if (eventChangeInfo.event.extendedProps.type !== "TimeSlot") {
      switch (eventChangeInfo.event.extendedProps.type) {
        case "LeaveOfAbsence":
          updateAppoitmentForLeaveOfAbsence(eventChangeInfo);
          break;
        case "EventTimeSlot":
          updateAppoitmentForEventTimeSlot(eventChangeInfo);
          break;
        case "TravelTime":
          updateAppointmentForTravelTime(eventChangeInfo);
          break;
        default:
          break;
      }
      console.log("event is going to be updated in the database:", event);
      return onChangeEvent && onChangeEvent(event);
    }

    const abbreviation = eventChangeInfo.event.extendedProps.timeSlot?.therapy?.abbreviation;
    const userId = eventChangeInfo.event.getResources()[0]?.id;

    // Check if the event is within working hours
    if (isEventOutsideOfWorkingHours(eventChangeInfo.event)) {
      if (window.confirm("Der Termin liegt außerhalb der Arbeitszeit, möchten Sie Überstunden ändern oder reparieren?")) {
        // api call to repair overtimes
        repairOvertimes(eventChangeInfo.event, userId);
      }
    }
    
    if (userId && abbreviation) {
      const hasCredentials = await hasTherapistCredentials(userId!, abbreviation);
      if (!hasCredentials) {
        notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviation, "warning");
        return eventChangeInfo.revert();
      }
      else {
        updateAppoitmentForSingleTimeSlot(eventChangeInfo);
        console.log("event is going to be updated in the database:", event);
        return onChangeEvent && onChangeEvent(event);
      }
    }
  };


  const checkPatientAvailabilityForRoutePlanView = async (event: EventApi, patientId: number): Promise<boolean> => {
    const patientUnavailabilities: PatientAvailabilityDto[] = await getPatientUnavailabilityForCalendar(patientId);
    console.log("patientUnavailabilities", patientUnavailabilities);
    const eventStart = moment(event.start).format("HH:mm");
    const eventEnd = moment(event.end).format("HH:mm");
    patientUnavailabilities?.forEach((pa) => {
      const paStart = moment(pa.startTime).format("HH:mm");
      const paEnd = moment(pa.endTime).format("HH:mm");
      if (pa.dayOfWeek === moment(event.start).day() &&
      moment(eventStart, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm")) ||
      moment(eventEnd, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm"))) {
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart + " und " + paEnd, "warning");
        return false;
      }
    });

    const patientUnavailabeTypes: TimeSlotDto[] = await getPatientUnavailabeTypeByDateForCalendar(patientId, moment(event.start).format("YYYY-MM-DD"));
    console.log("patientUnavailabeTypes", patientUnavailabeTypes);
    for (const patientAvailability of patientUnavailabeTypes || []) {
      const paStart = moment(patientAvailability.start);
      const paEnd = moment(patientAvailability.end);
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
      if (paStart.isBefore(eventEnd) && paEnd.isAfter(eventStart) && patientId === patientAvailability.patient?.id) {
        console.log("eventAllow", "Patient is not available between " + paStart + " and " + paEnd);
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart.format("HH:mm") + " und " + paEnd.format("HH:mm"), "warning");
        return false;
      }
    };

    return true;
  }

  const handleEventRemove = (eventRemoveInfo: EventRemoveArg) => {
    console.log("eventRemoveInfo", eventRemoveInfo);
    // return onRemoveEvent && onRemoveEvent(parseInt(eventRemoveInfo.event.id));
  };

  const handleEventReceive = async (eventReceiveInfo: EventReceiveArg) => {
    // Recieve external events
    console.log("eventReceiveInfo", eventReceiveInfo.event.toPlainObject());
    const originalEvent = eventReceiveInfo.event;
    const appointmentId = originalEvent.id.toString();
    const date = moment(originalEvent.start).format("YYYY-MM-DD");
    const userId = originalEvent.getResources()[0]?.id;
    const abbreviations = originalEvent.extendedProps.timeSlots?.map((ts: any) => ts.therapyRx?.therapy?.abbreviation);    

    if (originalEvent.extendedProps.type === "TimeSlot") {
      // Don't allow timeslots as external events
      return;
    }

    if (isRouteplanView) {

      // Don't allow scheduler's database changes in routeplan view and add the event to the google map appointments
      const appointment: AppointmentDto = await getAppointment(appointmentId);
      if (appointment && appointment.timeSlots && appointment.timeSlots[0]) {
        appointment.timeSlots[0].start = originalEvent.start?.toISOString()!;
        appointment.timeSlots[0].end = originalEvent.end?.toISOString()!;

        // TODO: what if the appointment has multiple time slots?

        setGoogleMapAppointments((prev) => {
          return [...prev!, appointment].sort((a, b) => {
            return new Date(a?.timeSlots?.[0].start!).getTime() - new Date(b?.timeSlots?.[0].start!).getTime();
          });
        })
      }
      return;
    }

    try {
      const frequency = !isContinuous && await frequencyValidation(appointmentId, date);
      const credentials = await hasTherapistCredentials(userId, abbreviations);
      if (frequency && frequency.validity === "Invalid") {
        notificationStore.showMessage(frequency.message, "warning");
        originalEvent.remove(); // Remove the event if frequency validation fails
      } else if (!credentials) {
        notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviations, "warning");
        originalEvent.remove(); // Remove the event if therapist has no credentials
      }
      else {
        onEventReceive && onEventReceive(eventReceiveInfo);
      }
    } catch (error) {
      console.error("Error during frequency validation:", error);
      originalEvent.remove(); // Optionally remove the event on error
    }
  };  

  const eventDragStart = async (eventDragStartArg: EventDragStartArg) => {
    console.log("eventDragStart", eventDragStartArg.event.toPlainObject());
    if (eventDragStartArg.event.extendedProps.multipleTimeSlots) {
      console.log("multipleTimeSlots");
      setMessage("Umzug Termin mit mehreren TimeSlots!");
      setOpenMessage(true);

      // // make grouped events draggable together
      // if (eventDragStartArg.event.groupId !== undefined)
      //   setGroupCheck(true);
    }
  };

  const eventDragStop = (eventDragStopArg: EventDragStopArg) => {
    console.log("eventDragStop", eventDragStopArg.event.toPlainObject());
  };

  const handleEventDrop = async (eventDropInfo: EventDropArg) => {
    // handle event drop only for multiple timeslots!
    if (!eventDropInfo.event.extendedProps.multipleTimeSlots) {
      return;
    }

    if (isRouteplanView) return; // don't allow scheduler's database changes in routeplan view

    console.log("event:", eventDropInfo.event.toPlainObject());

    // main event that is dragged
    const event: Event = {
      id: eventDropInfo.event.id,
      title: eventDropInfo.event.title,
      start: eventDropInfo.event.start?.toISOString()!,
      end: eventDropInfo.event.end?.toISOString()!,
      resourceId: eventDropInfo.event._def.resourceIds?.toString()!,
      allDay: eventDropInfo.event.allDay,
      appointmentId: eventDropInfo.event.extendedProps.appointmentId,
      type: eventDropInfo.event.extendedProps.type,
    };

    let isOutsideWorkingHours = false;

     // Check if the event is within working hours
     if (isEventOutsideOfWorkingHours(eventDropInfo.event)) {
      isOutsideWorkingHours = true;
    }

    const abbreviations: string[] = [];
    if (eventDropInfo.event.extendedProps.type === "TimeSlot") {
      // add current event therapy abbreviation to the list
      abbreviations.push(eventDropInfo.event.extendedProps.timeSlot?.therapy?.abbreviation);
    }
    const userId = eventDropInfo.event.getResources()[0]?.id;

    for (const related of eventDropInfo.relatedEvents) {
      // Check if the related event is within working hours
      if (isEventOutsideOfWorkingHours(related)) {
        isOutsideWorkingHours = true;
        // Break the loop if the event is outside working hours
        break;
      }
      // add therapy abbreviation to the list
      abbreviations.push(related.extendedProps.timeSlot?.therapy?.abbreviation);
    };

    if (userId && abbreviations.length > 0) {
      const hasCredentials = await hasTherapistCredentials(userId, abbreviations);
      if (!hasCredentials) {
        notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviations, "warning");
        return eventDropInfo.revert();
      }
    }

    if (isOutsideWorkingHours) {
      if (window.confirm("Der Termin liegt außerhalb der Arbeitszeit, möchten Sie Überstunden ändern oder reparieren?")) {
        // api call to repair overtimes
        repairOvertimes(eventDropInfo.event, userId);
      }
    }

    const relatedEvents = eventDropInfo.relatedEvents.map((related) => {
      console.log("related event:", related.toPlainObject());
      const relevent: Event = {
        id: related.id,
        title: related.title,
        start: related.start?.toISOString()!,
        end: related.end?.toISOString()!,
        resourceId: related._def.resourceIds?.toString()!,
        allDay: related.allDay,
        appointmentId: related.extendedProps.appointmentId,
        type: related.extendedProps.type,
      };
      return relevent;
    });

    updateAppoitmentForMultipleTimeSlot(eventDropInfo);

    console.log("event is going to be updated in the database:", event);
    return onChangeEvent && onChangeEvent(event, relatedEvents);
  };
  
  // this function removes the events jump casued by drga and drop for a single timeslot
  const updateAppoitmentForSingleTimeSlot = async (eventChangeInfo: EventChangeArg) => {
    const start = eventChangeInfo.event.start?.toISOString()!;
    const end = eventChangeInfo.event.end?.toISOString()!;

    appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === eventChangeInfo.event.id))?.timeSlots?.forEach((ts) => {
      ts.start = start;
      ts.end = end;
      ts.user = users?.find((u) => u.id === eventChangeInfo.event._def.resourceIds?.toString());
    });
  }

  // this function removes the events jump casued by drga and drop for a event timeslot
  const updateAppoitmentForEventTimeSlot = async (eventChangeInfo: EventChangeArg) => {
    eventTimeslots?.forEach((ts) => {
      if (ts.id === eventChangeInfo.event.id) {          
        ts.start = eventChangeInfo.event.start?.toISOString()!;
        ts.end = eventChangeInfo.event.end?.toISOString()!;
        ts.user = users?.find((u) => u.id === eventChangeInfo.event._def.resourceIds?.toString());
      }
    });
  }

  // this function removes the events jump casued by drga and drop for a leave of absence
  const updateAppoitmentForLeaveOfAbsence = async (eventChangeInfo: EventChangeArg) => {
    leaveOfAbsences?.forEach((ts) => {
      if (ts.id === eventChangeInfo.event.id) {          
        ts.start = eventChangeInfo.event.start?.toISOString()!;
        ts.end = eventChangeInfo.event.end?.toISOString()!;
        ts.user = users?.find((u) => u.id === eventChangeInfo.event._def.resourceIds?.toString());
      }
    });
  }
  
  // this function removes the events jump casued by drga and drop for a travel time
  const updateAppointmentForTravelTime = async (eventChangeInfo: EventChangeArg) => {
    travelTimes?.forEach((tt) => {
      if (tt.id === eventChangeInfo.event.id) {
        tt.start = eventChangeInfo.event.start?.toISOString()!;
        tt.end = eventChangeInfo.event.end?.toISOString()!;
        tt.user = users?.find((u) => u.id === eventChangeInfo.event._def.resourceIds?.toString());
      }
    });
  }
  
  // this function removes the events jump casued by drga and drop for multiple timeslots
  const updateAppoitmentForMultipleTimeSlot = async (eventDropInfo: EventDropArg) => {
    const start = eventDropInfo.event.start?.toISOString()!;
    const end = eventDropInfo.event.end?.toISOString()!;

    appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === eventDropInfo.event.id))?.timeSlots?.forEach((ts) => {
      ts.start = start;
      ts.end = end;
      ts.user = users?.find((u) => u.id === eventDropInfo.event._def.resourceIds?.toString());
    });
    
    eventDropInfo.relatedEvents.forEach((related) => {
      const start = eventDropInfo.event.start + related.start?.toISOString()!;
      const end = eventDropInfo.event.end + related.end?.toISOString()!;

      appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === related.id))?.timeSlots?.forEach((ts) => {
        ts.start = start;
        ts.end = end;
        ts.user = users?.find((u) => u.id === related._def.resourceIds?.toString());
      });
    });
  }

  const isEventOutsideOfWorkingHours = (event: EventImpl): boolean => {
    const user = users?.find((u) => u.id === event._def.resourceIds?.toString());
    const workingHours = user?.weeklyWorkDays?.find((w) => w.dayOfWeek === event.start?.getDay() && w.isBreak === false);
  
    if (!workingHours) {
      return false;
    }
  
    const eventStartTime = moment(event.start).format("HH:mm");
    const eventEndTime = moment(event.end).format("HH:mm");
  
    const workingStartTime = moment(workingHours.startTime).format("HH:mm");
    const workingEndTime = moment(workingHours.endTime).format("HH:mm");
  
    const isOutside = moment(eventStartTime, "HH:mm").isBefore(moment(workingStartTime, "HH:mm")) ||
                      moment(eventEndTime, "HH:mm").isAfter(moment(workingEndTime, "HH:mm"));

    console.log("event times:", eventStartTime, eventEndTime, ", working hours:", workingStartTime, workingEndTime);
    console.log("isOutsideWorkingHours:", isOutside);
    
    return isOutside;
  };

  const repairOvertimes = async (event: EventImpl, userId: string) : Promise<any> => {
    const user = users?.find((u) => u.id === userId);
    const workingHours = user?.weeklyWorkDays?.find((w) => w.dayOfWeek === event.start?.getDay());
    const start = event.start?.toISOString()!;
    const end = event.end?.toISOString()!;
    console.log("repairing overtimes for event", start, end);
    
    const result = await adjustOutsideAppointments(userId, start, end);
    console.log("repairing overtimes for event", result);
  }
  
  const handleCloseMessage = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  // const handleEventModalClose = (selectedTimeSlot?: TimeSlotDto) => {

  //   if (event) {
  //     const ts: TimeSlotExt = {
  //       id: selectedTimeSlot?.id,
  //       start: event.start,
  //       end: event.end,
  //     }
  //     event.id = selectedTimeSlot?.appointmentId;
  //     event.timeSlot = ts;
  //     console.log('event is going to be added to the database:', event);
  //     setOpenEventModal(false);
  //     return onCreateEvent && onCreateEvent(event!);
  //   }

  // };

  const handleCreateEvent = (event: Event) => {
    console.log("event is going to be added to the database:", event);
    setOpenEventModal(false);
    return onCreateEvent && onCreateEvent(event);
  };

  const handleEventModalClose = () => {
    // remove the event from the calendar
    let calendarApi = calendarRef.current?.getApi()!;
    calendarApi.getEventById("0")?.remove();

    setOpenEventModal(false);
  };

  const onDateChange = (date: string) => {
    calendarRef.current?.getApi()?.gotoDate(date);
  };

  const handleCancelAppointment = (timeSlot: TimeSlotExt) => {
    console.log("Canceling appointment", timeSlot);
    // grab the appointment for this timeslot:
    const appointment = appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === timeSlot.id));
    if (!appointment) {
      notificationStore.showMessage("Appointment not found for timeslot", "error");
      return;
    }
    selectedAppointmentRef.current = appointment;
    setselectedPatientId(timeSlot.patient?.id!);
    setIsContextMenuOpen(false);
    setCancellationDialogOpen(true);
  };

  const handleCancelContinuousAppointment = (timeSlot: TimeSlotExt) => {
    console.log("Canceling continuous appointment", timeSlot);
    const appointment = continuousAppointments?.find((a) =>
      a.timeSlots?.some((ts) => ts.id === timeSlot.id)
    );
    if (!appointment) {
      notificationStore.showMessage("Appointment not found for timeslot", "error");
      return;
    }
    setSelectedContinuousAppointment(appointment);
    setIsContextMenuOpen(false);
    setContinuousCancelationWarningOpen(true);
  };

  const onCancelContinuousAppointmentConfirm = async () => {
    await cancelContinuousAppointment(selectedContinuousAppointment?.id!);
    setContinuousCancelationWarningOpen(false);
  };

  const handleCloseCancellation = (actionPerformed: boolean) => {
    setCancellationDialogOpen(false);
  };

  const handleAttended = (timeSlot: TimeSlotExt) => {
    const appointment = appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === timeSlot.id));
    toggleAppointmentAttended(appointment!);
    // context menu is closed immediately, outside the promise, as it doesn't need to wait for the promise to resolve.
    setIsContextMenuOpen(false);
  };
  const handleCloseWarning=()=>{
    setWarningDialogMessage("")
  }

  const toggleAppointmentAttended = async (appointment: AppointmentDto) => {
    console.log("Marking as attended", appointment);
    if (!appointment) return;
    setShowLoading(true);
    setAttended(appointment?.id!, !appointment?.attended!).then(() => {
      appointments?.forEach((a) => {
        if (a.id === appointment?.id) {
          a.attended = !a.attended;
        }
      });
    }).catch((error) => {
      notificationStore.showMessage("Error marking appointment as attended", "error", error);
    }).finally(() => {
      setShowLoading(false);
    });
  }

  const handleEditAppointment = (timeSlot: TimeSlotExt) => {
    const appointment = appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === timeSlot.id));
    if (!appointment) {
      notificationStore.showMessage("Appointment not found for timeslot", "error");
      return;
    }
    console.log("Editing appointment", appointment);    
    selectedAppointmentRef.current = appointment;
    setselectedPatientId(timeSlot.patient?.id!);
    setIsContextMenuOpen(false);
    setIsAppointmentEditOpen(true); 
  }

  const handleEditContinuousAppointment = (timeSlot: TimeSlotExt) => {
    const appointment = continuousAppointments?.find((a) =>
      a.timeSlots?.some((ts) => ts.id === timeSlot.id)
    );
    if (!appointment) {
      notificationStore.showMessage("Appointment not found for timeslot", "error");
      return;
    }
    console.log("Editing continuous appointment", appointment);
    setSelectedContinuousAppointment(appointment);
    setselectedPatientId(timeSlot.patient?.id!);
    setIsContextMenuOpen(false);
    setIsAppointmentEditOpen(true);
  }

  useEffect(() => {
    setShowLoading(isLoading);
    // RESOURCES //////////////////////////////////////////////
    const resourceUsers = routeplanUser ? [routeplanUser] : selectedUser ? [selectedUser] : users;
    const resources = resourceUsers?.map((user, index) => {
      const selectedDate = getSelectedDate();
      const selectedDayIndex: number = getSelectedDate().getDay();

      const validWeeklyWorkDays = user.weeklyWorkDays?.filter((f) => {
        const validityStart = f.workPlan?.validityStart ? new Date(f.workPlan.validityStart) : null;
        const validityEnd = f.workPlan?.validityEnd ? new Date(f.workPlan.validityEnd) : null;

        return (
          f.dayOfWeek === selectedDayIndex &&
          (!validityStart || validityStart <= selectedDate) &&
          (!validityEnd || validityEnd >= selectedDate)
        );
      });

      const businessHours =
        validWeeklyWorkDays &&
        validWeeklyWorkDays.map((day) => {
          return {
            daysOfWeek: [day.dayOfWeek],
            startTime: moment(day.startTime).format("HH:mm"),
            endTime: moment(day.endTime).format("HH:mm"),
          };
        });

      let resource: Resource = {
        id: user.id!,
        title: isRouteplanView ? user.lastName! + ' Route' : user.nickName!,
        // eventColor: generateColor(user.nickName!),
        businessHours: businessHours,
        extendedProps: {
          link: user.id,
        },
      };
      return resource;
    });

    toggleSlotLaneVisibility(false);

    // add new resource for Patient Availability
    const patientAvailability = patientAvailabilities?.find((pa) => pa.dayOfWeek === getSelectedDate().getDay());    
    if (patientAvailability || (patientUnavailableTypes && patientUnavailableTypes.length > 0)) {
      console.log("adding patient availability to resources", patientAvailability);
      setPatientAvailability(patientAvailability);
      const newResource: Resource = {
        id: "0",  // id 0 is reserved for patient availability
        title: "PA",
        businessHours: [
          {
            daysOfWeek: [patientAvailability?.dayOfWeek],
            startTime: patientAvailability?.startTime ? moment(patientAvailability?.startTime).format("HH:mm") : slotMinTime,
            endTime: patientAvailability?.endTime ? moment(patientAvailability?.endTime).format("HH:mm"): slotMaxTime,
          },
        ],
      };
      resources?.push(newResource);
      console.log("newResource for patient availability", newResource);

      toggleSlotLaneVisibility(true);
    }

    // add a copy of routeplanUser's resource for showing the old routeplan
    if (isRouteplanView && routeplanUser) {
      const routeplanResource: Resource = {
        id: "1",  // id 1 is reserved for old routeplan
        title: routeplanUser.lastName! + ' Route (old)',
        businessHours: resources?.find((r) => r.id === routeplanUser.id)?.businessHours,
      };
      resources?.push(routeplanResource);
    }

    setResources(resources!);

    // EVENTS //////////////////////////////////////////////
    const events: Event[] = [];

      // continuous appointments as events
    if (isContinuous) {
      const continuous = continuousAppointments
        ?.map((appointment: ContinuousAppointmentDto) => {
          let patient = appointment.timeSlots![0].therapyRx?.rx?.patient as PatientExt;
          let multipleTimeSlots = appointment.timeSlots!.length > 1;
          let evts = appointment.timeSlots?.map((timeSlot) => {
            const therapy = timeSlot.therapyRx?.therapy as TherapyExt;
            let event: Event = {
              id: timeSlot.id?.toString(), // should be a unique id
              title: patient.firstName + ' ' + patient.lastName,
              start: timeSlot.start!,
              end: timeSlot.end!,
              patient:patient,
              // startTime, endTime and daysOfWeek are used for recurring events
              startTime: moment(timeSlot.start!).format("HH:mm:ss"),
              endTime: moment(timeSlot.end!).format("HH:mm:ss"),
              daysOfWeek: [moment(timeSlot.start!).day().toString()],
              resourceId: timeSlot.user?.id,
              groupId: appointment.id?.toString(),
              appointmentId: appointment.id?.toString(),
              type: "TimeSlot",
              multipleTimeSlots,
              timeSlot: {
                id: timeSlot.id,
                patient: patient,
                therapy: therapy,
                user: timeSlot.user?.lastName!,
                visitType: appointment.address?.type,
                therapyRx: timeSlot.therapyRx,
                appointmentId: appointment.id?.toString(),
              },
              frequency: appointment.frequency as TherapyFrequencyExt,
              color: mapTherapyToColor(therapy.abbreviation),
              display: heatTreatmentBackground(therapy),
              containsHeatTreatment: heatTreaments.includes(therapy.abbreviation!),
              address: appointment.address,
            };
            return event;
          });

          // group contains heat treatments, note all other events
          if (evts?.some((event) => event.display === "background")) {
            console.log("grouping heat treatments");
            evts?.forEach((event) => {
              if (event.display !== "background")
                event.containsHeatTreatment = true;
            });
          }
          
          return evts!;
        })
        .flat();

      if (continuous) events.push(...continuous);
        
      const continuousEventTimeSlot = continuousEventTimeSlots?.map((eventTimeslot, index) => {
        let ets: Event = {
          id: eventTimeslot.id?.toString(),
          title: eventTimeslot.title!,
          start: eventTimeslot.start!,
          end: eventTimeslot.end!,
          startTime: moment(eventTimeslot.start!).format("HH:mm:ss"),
          endTime: moment(eventTimeslot.end!).format("HH:mm:ss"),
          daysOfWeek: [moment(eventTimeslot.start!).day().toString()],
          resourceId: eventTimeslot.user?.id,
          type: "ContinuousEventTimeSlot",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.secondary.main,
        };
        return ets;
      });
      events.push(...continuousEventTimeSlot!);

      if (patientAvailability && patientAvailability.startTime && patientAvailability.endTime) {
        const paEvent: Event = {
          id: "0",  // id 0 is reserved for patient availability
          title: patientAvailability.patient?.lastName!,
          start: patientAvailability.startTime,
          end: patientAvailability.endTime,
          startTime: moment(patientAvailability.startTime!).format("HH:mm:ss"),
          endTime: moment(patientAvailability.endTime!).format("HH:mm:ss"),
          daysOfWeek: [patientAvailability.dayOfWeek?.toString()!],
          resourceId: "0",
          type: "PatientAvailability",
          color: mobiliTheme.palette.info.main,
          resourceEditable: false,
          editable: false,
          classNames: ["striped-event"],
        };
        events.push(paEvent);
      }
      
    } else {
      console.log("processing appointments into events");
      // timeslots as events
      const timeslots = appointments?.map((appointment) => {
          let patient = appointment.timeSlots![0].therapyRx?.rx?.patient as PatientExt;
          let multipleTimeSlots = appointment.timeSlots!.length > 1;
          let evts = appointment.timeSlots?.map((timeSlot) => {
            const therapy = timeSlot.therapyRx?.therapy as TherapyExt;
            let event: Event = {
              id: timeSlot.id?.toString(), // should be a unique id
              title: patient.firstName + ' ' + patient.lastName,
              start: timeSlot.start!,
              end: timeSlot.end!,
              resourceId: timeSlot.user?.id,
              groupId: groupCheck ? appointment.id?.toString() + timeSlot.user?.id! : "",
              appointmentId: appointment.id?.toString(),
              multipleTimeSlots,
              patient:patient,
              address: appointment.address,
              timeSlot: {
                id: timeSlot.id,
                patient: patient,
                therapy: therapy,
                user: timeSlot.user?.lastName!,
                visitType: appointment.address?.type,
                type:timeSlot.type,
                therapyRx: timeSlot.therapyRx,
                appointmentId: appointment.id?.toString(),
                treatmentIndex: timeSlot.treatmentIndex,
                isCancelled:appointment.originalDate && !appointment.resolved ?true:false
              },
              type: "TimeSlot",
              frequency: appointment.frequency as TherapyFrequencyExt,
              color: appointment.attended
              ? mobiliTheme.palette.success.main // If attended, use the success color
              : mapTherapyToColor(therapy.abbreviation), // Otherwise, use the therapy-specific color
              // classNames: appointment.attended?["striped-event"]:[],
              classNames: [
                appointment.attended ? "striped-event" : "", // Apply "striped-event" if attended
                appointment.originalDate && !appointment.resolved && !appointment.end
                  ? "striped-cancelled-event" // Apply "striped-cancelled-event" if cancelled
                  : ""
              ].filter(Boolean),
               

              attended: appointment.attended,
              editable: (isRouteplanView && appointment.address?.type === 'Praxis') ? false : !appointment.attended,
              resizable: false,
              resourceEditable: !appointment.attended,
              display: heatTreatmentBackground(therapy),
              hasNotes: appointment.notes?.length! > 0,
              
              containsHeatTreatment: heatTreaments.includes(therapy.abbreviation!),
            };
            return event;
          });

          // group contains heat treatments, note all other events
          if (evts?.some((event) => event.display === "background")) {
            console.log("grouping heat treatments");
            evts?.forEach((event) => {
              if (event.display !== "background")
                event.containsHeatTreatment = true;
            });
          }

          return evts!;
        })
        .flat();
      
      // add new event for Patient Availability from patient_availability table
      if (patientAvailability) {
        const paEvent: Event = {
          id: "0",  // id 0 is reserved for patient availability
          title:patientAvailability.patient?.firstName + ' ' + patientAvailability.patient?.lastName!,
          start: patientAvailability.startTime ? patientAvailability.startTime : slotMinTime,
          end: patientAvailability.endTime ? patientAvailability.endTime : slotMaxTime,
          startTime: patientAvailability.startTime ? moment(patientAvailability.startTime).format("HH:mm:ss") : slotMinTime,
          endTime: patientAvailability.endTime ? moment(patientAvailability.endTime).format("HH:mm:ss") : slotMaxTime,
          daysOfWeek: [patientAvailability.dayOfWeek?.toString()!],
          resourceId: "0",
          type: "PatientAvailability",
          color: mobiliTheme.palette.info.main,
          resourceEditable: false,
          editable: false,
          classNames: ["striped-event"],
        };
        timeslots?.push(paEvent);
      }

      // add PatientUnavailable types from time_slots table
      if (patientUnavailableTypes) {
        const patientUnavailableEvents = patientUnavailableTypes.map((patientUnavailable, index) => {
          let pu: Event = {
            id: patientUnavailable.id?.toString(),
            title: patientUnavailable.patient?.firstName + ' ' + patientUnavailable.patient?.lastName,
            start: patientUnavailable.start!,
            end: patientUnavailable.end!,
            resourceId: "0",
            type: "PatientUnavailable",
            editable: true,
            resourceEditable: false,
            resizable: true,
            color: mobiliTheme.palette.error.main,
            classNames: ["striped-event"],
          };
          return pu;
        });
        timeslots?.push(...patientUnavailableEvents);
      }

      events.push(...timeslots!);

      // goneFishings as events
      const goneFishingsEvents = goneFishings?.map((goneFishing, index) => {
        let gfs: Event = {
          id: goneFishing.id?.toString(),
          title: goneFishing.user?.lastName!,
          start: goneFishing.start!,
          end: goneFishing.end!,
          resourceId: goneFishing?.user?.id,
          color: "black",
          type: "GoneFishing",
          editable: false,
          resizable: false,
          resourceEditable: false,
        };
        return gfs;
      });
      events.push(...goneFishingsEvents!);

      const leaveOfAbsencesEvents = leaveOfAbsences?.map((leaveOfAbsence, index) => {
        let loa: Event = {
          id: leaveOfAbsence.id?.toString(),
          title: leaveOfAbsence.loAType!,
          start: moment(leaveOfAbsence.start!).toISOString(),
          end: moment(leaveOfAbsence.end!).toISOString(),
          resourceId: leaveOfAbsence.user?.id,
          type: "LeaveOfAbsence",
          editable: true,
          resourceEditable: false,
          resizable: true,
          classNames: ["striped-event"],
          color: mobiliTheme.palette.info.main,
        };
        return loa;
      });
      events.push(...leaveOfAbsencesEvents!);

      const eventTimeslotsEvents = eventTimeslots?.map((eventTimeslot, index) => {
        let ets: Event = {
          id: eventTimeslot.id?.toString(),
          title: eventTimeslot.title!,
          start: eventTimeslot.start!,
          end: eventTimeslot.end!,
          resourceId: eventTimeslot.user?.id,
          type: "EventTimeSlot",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.secondary.main,
        };
        return ets;
      });
      events.push(...eventTimeslotsEvents!);
      const lunchBreakEvents = lunchBreaks?.map((lunchBreak, index) => {
        let ets: Event = {
          id: lunchBreak.id?.toString(),
          title: "Pause",
          start: lunchBreak.start!,
          end: lunchBreak.end!,
          resourceId: lunchBreak.user?.id,
          type: "LunchBreak",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.info.light,
        };
        return ets;
      });      
      events.push(...lunchBreakEvents!);

      const travelTimeEvents = travelTimes?.map((travelTime, index) => {
        let ets: Event = {
          id: travelTime.id?.toString(),
          title: "Fahrzeit",
          start: travelTime.start!,
          end: travelTime.end!,
          resourceId: travelTime.user?.id,
          type: "TravelTime",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.info.dark,
        };
        return ets;
      }
      );
      events.push(...travelTimeEvents!);
    }

    if (isRouteplanView) {
      try {
        // add old routeplan events to the events
        const routeplanUserId = routeplanUser?.id;
        const routeplanUserEvents = events?.filter((e) => e.resourceId === routeplanUserId);
        const oldRouteplanEvents = routeplanUserEvents?.map((event) => {
          let oldEvent: Event = {
            ...event,
            title: event.title + " (old)",
            id: event.id + "_old",
            resourceId: "1",
            groupId: event.groupId + "_old",
            editable: false,
            resizable: false,
            resourceEditable: false,
          };
          return oldEvent;
        });
        events.push(...oldRouteplanEvents!);
    
        // filter out user appointments without addresses
        if (googleMapAppointments) {
          console.log("appointmentsWithValidAddresses", googleMapAppointments);
          setShowLoading(true);
    
          // get route durations for appointments
          getRouteDurationsForAppointments(googleMapAppointments)
            .then((routeDurations: (number | null)[]) => {
              setRouteDurations(routeDurations);
              console.log("routeDuration", routeDurations);
    
              // create travel time events for route durations
              const travelTimeslots: TimeSlotDto[] = [];
              routeDurations
                .filter((duration) => duration !== null)
                .forEach((duration, index: number) => {
                  let appointmentLastTimeSlot = googleMapAppointments[index].timeSlots![googleMapAppointments[index].timeSlots!.length - 1];
                  let timeSlot: TimeSlotDto = {
                    start: appointmentLastTimeSlot.end!,
                    end: moment(appointmentLastTimeSlot.end!).add(duration, "minutes").toISOString(),
                    type: "TravelTime",
                    user: routeplanUser,
                  };
                  travelTimeslots.push(timeSlot);
    
                  // adjust the next appointment's start time to follow the travel time duration
                  if (googleMapAppointments[index + 1]) {
                    let nextAppointment = googleMapAppointments[index + 1];
                    let nextAppointmentFirstTimeSlot = nextAppointment.timeSlots![0];
    
                    // calculate the duration of the next appointment
                    const nextAppointmentStart = moment(nextAppointmentFirstTimeSlot.start);
                    const nextAppointmentEnd = moment(nextAppointmentFirstTimeSlot.end);
                    const nextAppointmentDuration = nextAppointmentEnd.diff(nextAppointmentStart, 'minutes');
    
                    // set the next appointment's start time to the end of the travel time
                    nextAppointmentFirstTimeSlot.start = timeSlot.end;
    
                    // adjust the next appointment's end time based on its duration
                    nextAppointmentFirstTimeSlot.end = moment(timeSlot.end).add(nextAppointmentDuration, 'minutes').toISOString();
                  }
                });
              setTravelTimeSlots(travelTimeslots);
    
              const travelTimeEvents: Event[] = travelTimeslots.map((ts, index) => {
                let event: Event = {
                  id: "travelTime_" + index,
                  title: "Fahrzeit",
                  start: ts.start!,
                  end: ts.end!,
                  resourceId: routeplanUserId,
                  type: "NewTravelTime",
                  resourceEditable: false,
                  resizable: false,
                  color: mobiliTheme.palette.secondary.main,
                };
                return event;
              });
              events.push(...travelTimeEvents);
              setEvents([...events, ...travelTimeEvents]);

              // timeslots as events
              const timeslots = googleMapAppointments?.map((appointment) => {
                let patient = appointment.timeSlots![0].therapyRx?.rx?.patient as PatientExt;
                let multipleTimeSlots = appointment.timeSlots!.length > 1;
                let evts = appointment.timeSlots?.map((timeSlot) => {
                  const therapy = timeSlot.therapyRx?.therapy as TherapyExt;
                  let event: Event = {
                    id: timeSlot.id?.toString(), // should be a unique id
                    title: patient.lastName,
                    start: timeSlot.start!,
                    end: timeSlot.end!,
                    resourceId: timeSlot.user?.id,
                    groupId: groupCheck ? appointment.id?.toString() + timeSlot.user?.id! : "",
                    appointmentId: appointment.id?.toString(),
                    type: "TimeSlot",
                    multipleTimeSlots,
                    patient:patient,
                    address: appointment.address,
                    timeSlot: {
                      id: timeSlot.id,
                      patient: patient,
                      therapy: therapy,
                      user: timeSlot.user?.lastName!,
                      visitType: appointment.address?.type,
                      therapyRx: timeSlot.therapyRx,
                      appointmentId: appointment.id?.toString(),
                      treatmentIndex: timeSlot.treatmentIndex,
                      isCancelled:appointment.originalDate && !appointment.resolved ?true:false
                    },
                    frequency: appointment.frequency as TherapyFrequencyExt,
                    color: appointment.attended
                    ? mobiliTheme.palette.success.main // If attended, use the success color
                    : mapTherapyToColor(therapy.abbreviation), // Otherwise, use the therapy-specific color
                    // classNames: appointment.attended?["striped-event"]:[],
                    classNames: [
                      appointment.attended ? "striped-event" : "", // Apply "striped-event" if attended
                      appointment.originalDate && !appointment.resolved && !appointment.end
                        ? "striped-cancelled-event" // Apply "striped-cancelled-event" if cancelled
                        : ""
                    ].filter(Boolean),
                    attended: appointment.attended,
                    editable: (isRouteplanView && appointment.address?.type === 'Praxis') ? false : !appointment.attended,
                    resizable: false,
                    resourceEditable: !appointment.attended,
                    display: heatTreatmentBackground(therapy),
                    hasNotes: appointment.notes?.length! > 0,
                    containsHeatTreatment: heatTreaments.includes(therapy.abbreviation!),
                  };
                  return event;
                });
                return evts!;
              }
              ).flat();
              events.push(...timeslots);
              // const newTravelTimesEvents = events.filter(event => !(event.type === "TravelTime" && event.resourceId !== "1"));
              setEvents(events!);

            })
            .catch((error) => {
              console.error("Error getting route durations", error);
            })
            .finally(() => {
              setShowLoading(false);
            });
        }
      } catch (error) {
        console.error("Error during routeplan view:", error);
      }
    }
    
    setEvents(events!);
    console.log("events", events!);
  }, [
    appointments,
    continuousAppointments,
    goneFishings,
    leaveOfAbsences,
    eventTimeslots,
    continuousEventTimeSlots,
    users,
    groupCheck,
    routeplanUser,
    googleMapAppointments
  ]);

  useEffect(() => {
    localStorage.setItem("lastViewedDate", date);
    return onDatesSet(new Date(date));
  }, [date]);

  useEffect(() => {
    // initialize the calendar for routeplan view
    if (isRouteplanView) {
      setGoogleMapAppointments(appointments?.filter((a) => 
        a.address && 
        a.address.type !== "Praxis" &&
        a.attended === false &&
        a.timeSlots?.some((ts) => ts.user?.id === routeplanUser?.id)
      ));
    }
  }, [routeplanUser]);

  useEffect(() => {
    if (resources && resources.length > 0) {
      // Generate focusable elements
      const rows = document.querySelectorAll("tr");
      let tabIndex = 0;
  
      const slots = Array.from(document.querySelectorAll(".fc-timegrid-slot")) as HTMLDivElement[];
      const events = Array.from(document.querySelectorAll(".fc-timegrid-event-harness")) as HTMLDivElement[];

      rows.forEach((row) => {
        const existingTds = row.querySelectorAll("td");
  
        // Skip header rows or rows with existing extra columns
        if (!existingTds.length || existingTds.length > 2) return;
  
        const targetTd = row.querySelector(".fc-timegrid-slot-lane") as HTMLElement;
        const time = targetTd?.getAttribute("data-time");
  
        // Add new <td> elements for each resource
        resources
          .filter((resource) => resource.id !== "0") // Exclude patient availability resource
          .sort((a, b) => a.id.localeCompare(b.id))
          .forEach((resource) => {
            const newTd = document.createElement("td");
            newTd.setAttribute("data-resource-id", resource.id);
            newTd.className = "fc-timegrid-slot fc-timegrid-slot-resource";
            newTd.setAttribute("tabindex", tabIndex.toString());
            newTd.setAttribute("data-time", time ?? "");
  
            // Add click listener for each new <td>
            newTd.addEventListener("click", (e) => {
              const clickedTd = e.target as HTMLDivElement;
              //NOTE: focused index is different from tabIndex because of the extra columns
              const focusedIndex = allFocusableRef.current.indexOf(clickedTd);
              setCurrentIndex(focusedIndex);
              clickedTd.focus();
            });
  
            row.appendChild(newTd);
            tabIndex++;
          });
      });
  
      slots.forEach((slot) => {
        slot.addEventListener("focus", () => {

          // remove all selected classes
          allFocusableRef.current.forEach((el) => {
            el.classList.remove("focused");
            let anchor = el.querySelector("a");
            if (anchor) anchor.classList.remove("selected");
          });

          const slotTime = slot.getAttribute("data-time");
          const resourceId = slot.closest("td")?.getAttribute("data-resource-id");
      
          let selectedAppointment = null;
      
          const overlappingEvent = events.find((event) => {
            const eventParentCell = event.closest("td");
            const eventResourceId = eventParentCell?.getAttribute("data-resource-id");
      
            const timeRangeText = event.querySelector("b")?.textContent?.trim();
            if (!timeRangeText) return false;
      
            const [startTime, endTime] = timeRangeText.split(" - ").map((time) => {
              const [hours, minutes] = time.split(":");
              return new Date(`1970-01-01T${hours}:${minutes}`);
            });
            const slotDateTime = slotTime ? new Date(`1970-01-01T${slotTime}`) : null;
      
            if (
              eventResourceId === resourceId &&
              slotDateTime &&
              startTime <= slotDateTime &&
              slotDateTime < endTime
            ) {
              // Find the appointment using time and resource ID
              const appointment = appointments?.find((a) =>
                a.timeSlots?.some((ts) => {
                  const tsStartTime = moment(ts.start).format("HH:mm:ss");
                  const tsEndTime = moment(ts.end).format("HH:mm:ss");
                  const formattedStartTime = moment(startTime).format("HH:mm:ss");
                  const formattedEndTime = moment(endTime).format("HH:mm:ss");
      
                  return (
                    tsStartTime === formattedStartTime &&
                    tsEndTime === formattedEndTime &&
                    ts.user?.id === resourceId
                  );
                })
              );
      
              if (appointment) {
                selectedAppointment = appointment;
                return true;
              }
            }
      
            return (
              eventResourceId === resourceId &&
              slotDateTime &&
              startTime <= slotDateTime &&
              slotDateTime < endTime
            );
          });
      
          if (selectedAppointment) {
            selectedAppointmentRef.current = selectedAppointment;
          }
      
          if (overlappingEvent) {
            overlappingEvent.setAttribute("tabindex", "-1");
            overlappingEvent.classList.add("focused");
            overlappingEvent.focus();
      
            overlappingEvent.addEventListener(
              "blur",
              () => {
                overlappingEvent.classList.remove("focused");
                selectedAppointmentRef.current = null;
              },
              { once: true }
            );
          }
        });
      });
      
      events.forEach((event) => {
        event.addEventListener("click", () => {
          // remove all selected classes
          allFocusableRef.current.forEach((el) => {
            el.classList.remove("focused");
            let anchor = el.querySelector("a");
            if (anchor) anchor.classList.remove("selected");
          });

          const eventParentCell = event.closest("td");
          const resourceId = eventParentCell?.getAttribute("data-resource-id");
          const timeRangeText = event.querySelector("b")?.textContent?.trim();
          if (!timeRangeText) return;
  
          const [startTime, endTime] = timeRangeText.split(" - ").map((time) => {
            const [hours, minutes] = time.split(":");
            return new Date(`1970-01-01T${hours}:${minutes}`);
          });
  
          const overlappingSlots = slots.filter((slot) => {
            const slotTime = slot.getAttribute("data-time");
            const slotDateTime = slotTime ? new Date(`1970-01-01T${slotTime}`) : null;
            return (
              resourceId === slot.closest("td")?.getAttribute("data-resource-id") &&
              slotDateTime &&
              startTime <= slotDateTime &&
              slotDateTime < endTime
            );
          });
  
          if (overlappingSlots.length) {
            // focus the first slot behind the event
            const slot = overlappingSlots[0]; 
            const slotIndex = allFocusableRef.current.indexOf(slot);
            setCurrentIndex(slotIndex);
            // slot.focus();

            // blur all other slots
            slots.forEach((s) => {
              if (s !== slot) s.blur();
            });
          }
        })
      });
  
      const allFocusable = [...slots, ...events];
      allFocusableRef.current = allFocusable;
      setFocusableElements(allFocusable);
  
      // Add keyboard navigation
      const handleKeyDown = (e: KeyboardEvent) => {
        if (!allFocusable.length) return;

        // Skip keyboard hotkeys if the user is typing in an input field
        if (document.activeElement?.tagName !== "TD" && document.activeElement?.tagName !== "DIV") return;

        let patientAvailabilityView = patientAvailability || (patientUnavailableTypes && patientUnavailableTypes.length > 0);
        let resourceLength = patientAvailabilityView ? resources.length - 1 : resources.length;
        let nextIndex = currentIndex;
        let offset = resourceLength + 2; // 2 is the number of default columns of FullCalendar
  
        if (e.key === "ArrowRight") {
          e.preventDefault();
          nextIndex = (currentIndex + 1) % allFocusable.length;
        } else if (e.key === "ArrowLeft") {
          e.preventDefault();
          nextIndex = (currentIndex - 1 + allFocusable.length) % allFocusable.length;
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          do {
            nextIndex = (nextIndex + offset) % allFocusable.length;
          } while (isWithinEventRange(allFocusable[nextIndex]));
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          do {
            nextIndex = (nextIndex - offset + allFocusable.length) % allFocusable.length;
          } while (isWithinEventRange(allFocusable[nextIndex]));
        } else if (e.key === "Enter") {
          e.preventDefault();
          const element = allFocusable[currentIndex];
          console.log("selected slot:", element.dataset.time);
          console.log("selected appointment", selectedAppointmentRef.current);

          // remove all selected classes
          allFocusable.forEach((el) => {
            let anchor = el.querySelector("a");
            if (anchor) anchor.classList.remove("selected");
          });

          if (selectedAppointmentRef.current) {
            const tsx: TimeSlotExt = {
              id: undefined,
              start: selectedAppointmentRef.current?.timeSlots![0].start,
              end: selectedAppointmentRef.current?.timeSlots![0].end,
              user: element.closest("td")?.getAttribute("data-resource-id")!,
              patient: selectedAppointmentRef.current?.timeSlots![0].therapyRx?.rx?.patient as PatientExt,
              therapy: selectedAppointmentRef.current?.timeSlots![0].therapyRx?.therapy as TherapyExt,
              therapyRx: selectedAppointmentRef.current?.timeSlots![0].therapyRx,
              appointmentId: selectedAppointmentRef.current?.id,
            }
            setModalTS(tsx);
            return onEventContentClick && onEventContentClick(tsx);
          }

        } else if (e.key === "f") {
          // create an EventTimeSlot event
          e.preventDefault();
          const element = allFocusable[currentIndex];
          const selectedDateTime = moment(date + 'T' + element.dataset.time); 
          const event: Event = {
            id: undefined, // this is a new event
            title: "",
            start: selectedDateTime.toISOString(),
            resourceId: element.closest("td")?.getAttribute("data-resource-id")!,
            end: selectedDateTime.add(5, 'minutes').toISOString(),
            timeSlot: {
              start: selectedDateTime.toISOString(),
              end: selectedDateTime.add(5, 'minutes').toISOString(),
            },
          };
          console.log("add event modal for:", event);
          setEvent(event);
          setOpenEventModal(true);
        } else if (e.key === "w") {
          // mark the selected appointment as attended/unattended
          e.preventDefault();
          if (!selectedAppointmentRef.current) {
            console.log("no selected appointment");
            return;
          }
          toggleAppointmentAttended(selectedAppointmentRef.current!);
          return;
        } else if (e.key === "b") {
          // plan a selected appointment from shelf
          e.preventDefault();
          if (!selectedShelfAppointment) {
            console.log("no selected shelf appointment");
            return;
          }
          console.log("planning unscheduled appointment from shelf", selectedShelfAppointment);
          const element = allFocusable[currentIndex];
          console.log("selected slot:", element.dataset.time);
          planShelfAppointment(element, selectedShelfAppointment!);
          return;
        } else if (e.key === "ü") {
          // open the patient appointments overview for the selected appointment
          e.preventDefault();
          if (!selectedAppointmentRef.current) {
            console.log("no selected appointment");
            return;
          }
          let patieintId = (selectedAppointmentRef.current?.timeSlots?.[0].therapyRx?.rx?.patient as PatientDto).id;          
          setOverviewAppointmentPatientId(patieintId!);
        }
  
        setCurrentIndex(nextIndex);
        allFocusable[nextIndex].focus();
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }
  }, [resources, currentIndex, patientAvailability, patientUnavailableTypes]);

  // Check if the td is within an appointment range to skip it when navigating
  const isWithinEventRange = (element: HTMLElement) => {
    const slotTime = element.getAttribute("data-time");
    const resourceId = element.closest("td")?.getAttribute("data-resource-id");

    return allFocusableRef.current.some((el) => {
      const timeRangeText = el.querySelector("b")?.textContent?.trim();
      if (!timeRangeText) return false;

      const [startTime, endTime] = timeRangeText.split(" - ").map((time) => {
        const [hours, minutes] = time.split(":");
        return new Date(`1970-01-01T${hours}:${minutes}`);
      });
      const elementTime = slotTime ? new Date(`1970-01-01T${slotTime}`) : null;
      const eventParentCell = el.closest("td");
      const eventResourceId = eventParentCell?.getAttribute("data-resource-id");

      return (
        eventResourceId === resourceId &&
        elementTime &&
        startTime < elementTime &&
        elementTime < endTime
      );
    });
  };
  
  const toggleSlotLaneVisibility = (shouldShow: boolean) => {
    const rows = document.querySelectorAll("tr");
    rows.forEach((row) => {
      const targetTd = row.querySelector(".fc-timegrid-slot-lane") as HTMLElement;
      if (targetTd) {
        targetTd.style.display = shouldShow ? "block" : "none";
      }
    });
  };    

  const planShelfAppointment = async (element: HTMLDivElement, appointment: AppointmentDto) => {
    // Recieve external events
    console.log("plan unscheduled shelf appointment", element);
    console.log("selected shelf appointment", appointment);
    
    const userId = element.closest("td")?.getAttribute("data-resource-id")!;
    const user = users?.find((user) => user.id === userId);
    const abbreviations = appointment.timeSlots?.map((ts: any) => ts.therapyRx?.therapy?.abbreviation);   
    const duration = appointment.timeSlots?.reduce((acc, ts: any) => acc + ts.therapyRx?.therapy?.duration, 0);
    const patientId = patientAvailability?.patient?.id;
    const start = moment(date + 'T' + element.dataset.time).toDate();
    const end = moment(date + 'T' + element.dataset.time).add(duration, 'minutes').toDate();

    // set appointment object with the new start, end times and user
    const updatedAppointment = updateReceivedAppointment(appointment, user!, start, end);

    if (isRouteplanView) {
      // Don't allow scheduler's database changes in routeplan view and add the event to the google map appointments
      const app: AppointmentDto = await getAppointment(appointment.id!);
      if (app && app.timeSlots && app.timeSlots[0]) {
        app.timeSlots[0].start = start.toISOString()!;
        app.timeSlots[0].end = end.toISOString()!;

        // TODO: what if the appointment has multiple time slots?

        setGoogleMapAppointments((prev) => {
          return [...prev!, appointment].sort((a, b) => {
            return new Date(a?.timeSlots?.[0].start!).getTime() - new Date(b?.timeSlots?.[0].start!).getTime();
          });
        })
      }
      return;
    }

    // patient events overlapping validation
    const isOverlapping = isPatientAppointmentOverlapping(start, end, appointments!, appointment);
    if (isOverlapping) {
      if (isRouteplanView) return true; // allow overlapping in routeplan view
      console.log("eventAllow", "Patient event is overlapping");
      notificationStore.showMessage("Patienten-Termin überschneidet sich mit einem anderen Termin", "warning");
      return false;
    }

    // if (dropInfo.resource?.id === "0" || dropInfo.resource?.id === "1") {
    //   console.log("eventAllow", "add events to PA column is not allowed");
    //   return false;
    // }
    
    if (patientAvailability && patientId === patientAvailability.patient?.id) {
      const paStart = moment(patientAvailability.startTime).format("HH:mm");
      const paEnd = moment(patientAvailability.endTime).format("HH:mm");
      const eventStart = moment(start).format("HH:mm");
      const eventEnd = moment(end).format("HH:mm");

      if (moment(eventStart, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm")) ||
          moment(eventEnd, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm"))) {
        console.log("eventAllow", "Patient is not available between " + paStart + " and " + paEnd);
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart + " und " + paEnd, "warning");
        return false;
      }
    }

    for (const patientAvailability of patientUnavailableTypes || []) {
      const paStart = moment(patientAvailability.start);
      const paEnd = moment(patientAvailability.end);
      const eventStart = moment(start);
      const eventEnd = moment(end);

      if (paStart.isBefore(eventEnd) && paEnd.isAfter(eventStart) && patientId === patientAvailability.patient?.id) {
        console.log("eventAllow", "Patient is not available between " + paStart + " and " + paEnd);
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart.format("HH:mm") + " und " + paEnd.format("HH:mm"), "warning");
        return false;
      }
    };


    try {
      const frequency = !isContinuous && await frequencyValidation(appointment.id!, date);
      const credentials = await hasTherapistCredentials(userId, abbreviations!);
      if (frequency && frequency.validity === "Invalid") {
        notificationStore.showMessage(frequency.message, "warning");
        return;
      } else if (!credentials) {
        notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviations, "warning");
        return;
      }
      else {
        onSelectedShelfEventReceive && onSelectedShelfEventReceive(updatedAppointment!);
      }
    } catch (error) {
      console.error("Error during frequency validation:", error);
      return;
    }
  }

  const heatTreatmentBackground = (therapy: TherapyExt): string => {
    enum display {
      block = "block",
      background = "background",
      none = "none",
    }
    if (heatTreaments.includes(therapy.abbreviation!)) {
      return display.background;
    } else {
      return display.block;
    }
  };

  const renderEventContent = (eventContent: EventContentArg) => {    
    const timeSlot = eventContent.event.extendedProps.timeSlot as TimeSlotExt;
    const containsHeatTreatment = eventContent.event.extendedProps.containsHeatTreatment;
    const hasNotes = eventContent.event.extendedProps.hasNotes;
    const isBackgroundTreatment = heatTreaments.includes(timeSlot?.therapy?.abbreviation!);
    const type = eventContent.event.extendedProps.type;

    if (type === "TimeSlot") {
      if (isBackgroundTreatment) {
        return (
          <>
            <b style={{ color: timeSlot &&  timeSlot.isCancelled ? "grey" : "default" }}>{moment(eventContent.event.startStr).format("HH:mm")} - {moment(eventContent.event.endStr).format("HH:mm")}</b> <br />
            <i style={{ color:timeSlot && timeSlot.isCancelled ? "grey" : "default" }}>{eventContent.event.title}</i> <br />
            <span style={{ color:timeSlot && timeSlot.isCancelled ? "grey" : "default" }}>{timeSlot?.therapy?.abbreviation}</span> <br />
          </>
        );
      } else {
        return (
          <>
            <b style={{ color:timeSlot && timeSlot.isCancelled ? "grey" : "default"}}>{eventContent.timeText}</b>
            <span style={{ color: timeSlot && timeSlot.isCancelled ? "grey" : "default" }}> {timeSlot?.therapy?.abbreviation}</span>
            <span style={{display:"flex", flexDirection:"row", float:"right"}}>
              {containsHeatTreatment && 
                <Tooltip title="Warmpackung inklusive">
                  <LocalFireDepartmentIcon fontSize={"small"} style={{ color: mobiliTheme.palette.secondary.main }}/>
                </Tooltip>
              }
              {hasNotes && 
                <Tooltip title="Notizen">
                  <span onClick={(e) => onNoteIconClick(e, timeSlot)}>
                    <NoteAltOutlinedIcon fontSize={"small"} sx={{cursor: "pointer"}}/>
                  </span>
                </Tooltip>
              }
              {timeSlot?.therapyRx?.rx?.isPerpetual &&
                <Tooltip title="Dauertermin">
                  <AllInclusiveIcon fontSize={"small"}/>
                </Tooltip>
              }
              {timeSlot.visitType &&
                <Tooltip title="Besuchstyp">
                  <span style={{ color: mobiliTheme.palette.primary.main }}>
                  {
                    timeSlot.visitType === "Haus" ? <Home fontSize={"small"} /> :
                    timeSlot.visitType === "Praxis" ? <Store fontSize={"small"} /> :
                    timeSlot.visitType === "Heim" ? <Business fontSize={"small"} /> : null
                  }
                  </span>
                </Tooltip>
              }
              {timeSlot?.treatmentIndex && 
                <span style={{position: "absolute", bottom: "2px", right: "2px"}}>
                  <Chip label={timeSlot?.treatmentIndex} size="small" sx={{fontSize: "1em", height:"15px" }} />
                </span>
              }
            </span> 
            <br />
            <span style={{ color: timeSlot && timeSlot.isCancelled ? "grey" : "default" }}>
              <Chip 
                // icon={eventContent.event.extendedProps.patient.salutation === "Herr" ? <FaceIcon /> : <Face4Icon />}
                label={eventContent.event.title} 
                variant="filled" 
                size="small"
                sx={{fontSize: "1em", height:"15px" }}/>
            </span>
            {/* <span style={{ color: timeSlot && timeSlot.isCancelled ? "grey" : "default" }}>{timeSlot?.therapy?.abbreviation}</span> */}
          </>
        );
      }
    }
    else {
      return (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <b>{eventContent.timeText}</b>
          {eventContent.event.title}
        </div>
      );
    }
  };


  const resourceLabelContent = (resource: ResourceLabelContentArg) => {
    if (resource.resource.id === "0")
      return <ScheduleIcon/>; 

    return <UserPopover 
      resource={resource} 
      onConfirm={onOutdated} 
      onRouteplanUserChange={onRouteplanUserChange}
    />;
  };

  const onRouteplanUserChange = (user: UserDto) => {
    if (appointments?.length! < 2) { // check if the user has at least 2 appointments for routeplan
      notificationStore.showMessage("Routeplan kann nicht angezeigt werden, da keine Termine vorhanden sind", "warning");
      return;
    }
    setRouteplanUser(user);
    setIsRouteplanView(true);
  }

  const handleContextMenuClose = () => {
    setIsContextMenuOpen(false);
    setMousePosition({ mouseX: null, mouseY: null });
  };

  const handleEventDidMount = (arg: EventMountArg) => {
    arg.el.addEventListener("click", (e) => {
      if (arg.event.extendedProps.type === "TimeSlot")
        {
          // e.preventDefault();
          // setModalTS(arg.event.extendedProps.timeSlot as TimeSlotExt);
          // onShowPatient(arg.event.extendedProps.timeSlot as TimeSlotExt);
          setModalTS(arg.event.extendedProps.timeSlot as TimeSlotExt);
          return onEventContentClick && onEventContentClick(arg.event.extendedProps.timeSlot as TimeSlotExt);
        }
    });

    arg.el.addEventListener("contextmenu", (e) => {
      e.preventDefault(); // Prevent default behavior (e.g., opening a context menu)

      // Check if the click has clientX and clientY properties (to ensure it's a valid click event)
      if (e.clientX && e.clientY) {
        setMousePosition({
          mouseX: e.clientX - 2,
          mouseY: e.clientY - 4,
        });

        // Create an event object to pass to the menu
        const event: Event = {
          id: arg.event.id,
          title: arg.event.title,
          start: arg.event.start?.toISOString()!,
          end: arg.event.end?.toISOString()!,
          resourceId: arg.event._def.resourceIds?.toString()!,
          allDay: arg.event.allDay,
          appointmentId: arg.event.extendedProps.appointmentId,
          type: arg.event.extendedProps.type,
          timeSlot: arg.event.extendedProps.timeSlot as TimeSlotExt,
          multipleTimeSlots: arg.event.extendedProps.multipleTimeSlots,
          groupId: arg.event.groupId,
          attended: arg.event.extendedProps.attended,
          frequency: arg.event.extendedProps.frequency,
          address: arg.event.extendedProps.address,
        };

        // Set states to control the opening of the menu
        setIsContextMenuOpen(true);
        setModalTS(arg.event.extendedProps.timeSlot);
        setContextMenuEvent(event);
      }
    });
  };

  const handleRemoveGoneFishing = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveEvent && onRemoveEvent(timeslotId);
    }
  };

  const handleRemoveEventTimeSlot = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveEvent && onRemoveEvent(timeslotId);
    }
  };

  const handleRemoveLeaveOfAbsence = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveEvent && onRemoveEvent(timeslotId);
    }
  };

  const handleRemoveContinuousEventTimeSlot = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveContinuousEvent && onRemoveContinuousEvent(timeslotId);
    }
  };

  const handleMoveEvent = (event: Event) => {
    console.log("Moving event from calendar", event);
    setIsContextMenuOpen(false);
    calendarRef.current
      ?.getApi()
      ?.getEvents()
      .forEach((e) => {
        if (e.groupId === event.groupId) {
          e.remove();
        }
      });
    console.log("Termin aus dem kalender entfernt");
    return onMoveEventDate && onMoveEventDate(event);
  };

  const handleDateClick = (clickInfo: DateClickArg) => {
    console.log("date click", clickInfo);
    if (isShelfEvents) {
      if (clickInfo.jsEvent.clientX && clickInfo.jsEvent.clientY) {
        setMousePosition({
          mouseX: clickInfo.jsEvent.clientX - 2,
          mouseY: clickInfo.jsEvent.clientY - 4,
        });
        const event: Event = {
          type: "ShelfEvent",
          title: undefined!,
          start: clickInfo.dateStr,
          resourceId: clickInfo.resource?.id,
        };
        setIsContextMenuOpen(true);
        setContextMenuEvent(event);
        // return onAddShelfEvent && onAddShelfEvent(event);
      }
    }
  };

  const handleEventMouseEnter = ({ event, el, jsEvent }: EventHoveringArg) => {
    const ts = event.extendedProps.timeSlot as TimeSlotDto;
    const patient = event.extendedProps.patient as PatientDto;

    let displayStr = "";
    if (patient) {
      displayStr =
        displayStr +
        `${patient.salutation ? patient.salutation : ""} ${patient.lastName}, ${patient.firstName}`;
    }
    if (ts) {
      displayStr = displayStr + `\nRezept: ${ts.therapyRx?.rx?.rxNumber}`;
    }
    console.log("event mouse enter", event.extendedProps, ts, patient);
    console.log("tooltip", displayStr);
    el.setAttribute("title", displayStr);
  };
  const handleAddShefEvent = (event: Event) => {
    console.log("Adding shelf event", event);
    setIsContextMenuOpen(false);
    return onAddShelfEvent && onAddShelfEvent(event);
  };

  const onGroupChecked = (checked: boolean) => {
    setGroupCheck(checked);
    onOutdated();
  };

  const onUserChange = (userId: string) => {
    if (userId) {
      // user weekly view
      const user = users?.find((u) => u.id === userId);
      if (!user) return;
      setSelectedUser(user);
      changeCalendarView("resourceTimeGridWeek");
    } else {
      // general daily view
      setSelectedUser(undefined);
      changeCalendarView("resourceTimeGridDay");
    }
  };

  const changeCalendarView = (view: string) => {
    let calendarApi = calendarRef.current?.getApi();
    calendarApi?.changeView(view);
    if (view === "resourceTimeGridWeek") {
      calendarApi?.view?.calendar.setOption("dayHeaderFormat", { weekday: 'long' });
    }
  }

  const handleContinuousViewChange = (continuous: boolean) => {
    changeCalendarView("resourceTimeGridDay");
    setSelectedUser(undefined);
    return onContinuousViewChange(continuous);
  };

  const handleEventResize = (info: EventResizeDoneArg) => {
    const event = events.find((e) => e.id === info.event.id);
    if (event && !event.resizable) {
      info.revert();
      setHandleEventChangeForResizing(false);
    } else {
      // Handle the resize action if the event is resizable
      console.log("Event resized:", info.event);
    }
  };

  const onNoteIconClick = (event: any, timeSlot: TimeSlotExt) => {
    event.stopPropagation();
    setModalTS(timeSlot);
    setShowNotesDialog(true);
  }

  const onCloseNotesDialogEvent = () => {
    setShowNotesDialog(false);
    return onCloseNotesDialog && onCloseNotesDialog();
  }

  const eventAllow = (dropInfo: DateSpanApi, draggedEvent: EventApi | null): boolean => {
    
    const start = moment(dropInfo.start).toDate();
    const end = moment(dropInfo.end).toDate();
    
    // patient events overlapping validation
    const isOverlapping = isPatientEventOverlapping(start, end, currentEvents, draggedEvent!);
    if (isOverlapping) {
      if (isRouteplanView) return true; // allow overlapping in routeplan view
      console.log("eventAllow", "Patient event is overlapping");
      // notificationStore.showMessage("Patienten-Termin überschneidet sich mit einem anderen Termin", "warning");
      return false;
    }

    if (dropInfo.resource?.id === "0" || dropInfo.resource?.id === "1") {
      console.log("eventAllow", "add events to PA column is not allowed");
      return false;
    }
    
    if (patientAvailability && parseInt(draggedEvent?.extendedProps.patient?.id) === patientAvailability.patient?.id) {
      const paStart = moment(patientAvailability.startTime).format("HH:mm");
      const paEnd = moment(patientAvailability.endTime).format("HH:mm");
      const eventStart = moment(start).format("HH:mm");
      const eventEnd = moment(end).format("HH:mm");

      if (moment(eventStart, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm")) ||
          moment(eventEnd, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm"))) {
        console.log("eventAllow", "Patient is not available between " + paStart + " and " + paEnd);
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart + " und " + paEnd, "warning");
        return false;
      }
    }

    for (const patientAvailability of patientUnavailableTypes || []) {
      const paStart = moment(patientAvailability.start);
      const paEnd = moment(patientAvailability.end);
      const eventStart = moment(start);
      const eventEnd = moment(end);

      if (paStart.isBefore(eventEnd) && paEnd.isAfter(eventStart) && parseInt(draggedEvent?.extendedProps.patient?.id) === patientAvailability.patient?.id) {
        console.log("eventAllow", "Patient is not available between " + paStart + " and " + paEnd);
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart.format("HH:mm") + " und " + paEnd.format("HH:mm"), "warning");
        return false;
      }
    };

    return true;
  }

  const handleEventOverlap = (stillEvent: EventApi, movingEvent: EventApi | null): boolean => {
    console.log("handleEventOverlap", 
      stillEvent.toPlainObject(), 
      movingEvent?.toPlainObject());

    if (stillEvent.display === "background" || movingEvent?.display === "background") {
      return true;
    }

 
    if (movingEvent && movingEvent.extendedProps) {
      const timeSlots = movingEvent.extendedProps.timeSlots || [movingEvent.extendedProps.timeSlot];
    
      if (timeSlots?.some((slot: any) => slot.type === "GroupTimeSlotPatient")) {
        return true;
      }
    }
    
  

    console.log("handleEventOverlap", "Appointment cannot overlap");
    // notificationStore.showMessage("Termin kann nicht überlappen", "warning");
    return false;
  };

  const handleSelectable = (selectInfo: DateSpanApi) => {
    if (isShelfEvents) {
      return false;
    }
    if (selectInfo.resource?.id === "0") {
      return false;
    }
    return true;
  }

  const handleResourceLabelDidMount = (arg: ResourceLabelMountArg) => {
    if (arg.resource.id === "0") {
      arg.el.style.backgroundColor = mobiliTheme.palette.secondary.light;
    }
  }

  const handleCloseEditAppointment = (actionPerformed: boolean) => {
    setIsAppointmentEditOpen(false);
    if (actionPerformed) {
      onOutdated();
    }
  }

  const handleCloseNotesDialog = () => {
    onOutdated();
  }

  const handleAppointmentOverview = (patientId: number) => {
    setOverviewAppointmentPatientId(patientId);
  }

  const onCloseRouteplan = () => {
    setIsRouteplanView(false);
    setRouteplanUser(undefined);
    onOutdated();
  }

  const onSaveRouteplan = async () => {
    try {
      console.log("Saving routeplans...", googleMapAppointments);
  
      // save appointments
      const result = await saveAppointments(googleMapAppointments!);
      console.log("saveAppointments result", result);
  
      // create new travel times 
      console.log("travelTimeSlots", travelTimeSlots);
      // const newTravelTimeSlots = travelTimeSlots?.filter((t) => !(t.type === "TravelTime" && t.user?.id !== "1"));
      // console.log("newTravelTimeSlots", newTravelTimeSlots);
      travelTimeSlots?.forEach((travelTime) => {
        try {
          createTimeslot(travelTime);
        } catch (error) {
          console.error("Error creating timeslot for travelTime:", travelTime, error);
          notificationStore.showMessage("Fehler beim Erstellen der Fahrzeiten", "error", error);
        }
      });
  
      // filter old travel times
      const oldTravelTimes = events.filter(
        (event) => event.type === "TravelTime" && event.resourceId === routeplanUser?.id
      );
      console.log("oldTravelTimes", oldTravelTimes);
      oldTravelTimes?.forEach((travelTime) => {
        try {
          deleteTimeslot(travelTime.id!);
        } catch (error) {
          console.error("Error deleting timeslot for travelTime:", travelTime, error);
          notificationStore.showMessage("Fehler beim Löschen der Fahrzeiten", "error", error);
        }
      });
  
      setIsRouteplanView(false);
      setRouteplanUser(undefined);
      onOutdated();
    } catch (error) {
      console.error("Error saving routeplan:", error);
      notificationStore.showMessage("Fehler beim Speichern des Routeplans", "error", error);
    }
  };  

  const handleCloseOverviewAppointment = () => {
    setOverviewAppointmentPatientId(null);
  };

  const handleViewDidMount = (view: any) => {
    setTimeout(() => {
      console.log("View did mount", view);
      adjustTableTimeCells();
    }, 0);
  }

  const adjustTableTimeCells = () => {
    // Select all rows in the timegrid
    const rows = document.querySelectorAll('.fc-timegrid-body tr');
  
    // Iterate through every 3 rows
    for (let i = 0; i < rows.length; i += 3) {
      // Get the first `<td>` of the current row
      const currentCell = rows[i].querySelector('td:first-child') as HTMLElement;
      if (currentCell) {
        // Set `rowspan` to 3
        currentCell.setAttribute('rowspan', '3');
      }
  
      // Hide the first `<td>` in the next two rows
      for (let j = 1; j < 3; j++) {
        if (rows[i + j]) {
          const nextCell = rows[i + j].querySelector('td:first-child') as HTMLElement;
          if (nextCell) {
            nextCell.style.display = 'none';
          }
        }
      }
    }
  };

  return (
    <>
       {warningDialogMessage!=="" && (
       <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseWarning} // Function to close the dialog
            title="Bearbeitungsstatus aktualisiert"
            content={warningDialogMessage}
            typeToProceed={["Ok"]}
            
          />
         )} 
      <Grid container direction={"column"} sx={{ border: "0px dotted black", height: "100%" }}>
        <Grid item sx={{ border: "0px solid blue", display: "flex" }}>
          <HeaderToolbar
            date={date}
            calendarRef={calendarRef}
            isContinuous={isContinuous}
            isCancelledView={isCancelledView}
            groupCheck={groupCheck}
            onDatesSet={onDatesSet}
            onDateChange={onDateChange}
            onOutdated={onOutdated}
            onContinuousViewChange={handleContinuousViewChange}
            onCancelledViewChange={onCancelledViewChange}
            onGroupChecked={onGroupChecked}
            users={users!}
            onUserChange={(userId) => onUserChange(userId)}
          />
        </Grid>
        <Grid item sx={{ display: "flex", flexGrow: 1 }}>
          <TopLevelPaper sx={{ flexGrow: 1, ...(isRouteplanView && {width: "40%"}) }}>
            <FullCalendar
              height="100%"
              ref={calendarRef}
              locale={"de"}
              initialDate={new Date(date)}
              schedulerLicenseKey="0067980715-fcs-1697454050"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceDayGridPlugin,
                resourceTimeGridPlugin,
                resourceTimelinePlugin,
              ]}
              headerToolbar={false}
              titleFormat={
                isContinuous
                  ? { weekday: "long", separator: " bis " }
                  : { year: "numeric", month: "long", day: "numeric", weekday: "long" }
              }
              buttonText={{
                today: "Heute",
                month: "Monat",
                year: "Jahr",
                week: "Woche",
                day: "Tag",
                list: "Liste",
              }}
              initialView="resourceTimeGridDay"
              views={{
                timeGridWeek: {
                  dayHeaderFormat: { weekday: "long" },
                },
              }}
              slotDuration={"00:05"}
              slotLabelFormat={{
                hour: "2-digit", //2-digit, numeric
                minute: "2-digit", //2-digit, numeric
                meridiem: false, //lowercase, short, narrow, false (display of AM/PM)
                hour12: false, //true, false
              }}
              eventTimeFormat={{
                hour: "2-digit", //2-digit, numeric
                minute: "2-digit", //2-digit, numeric
                meridiem: false, //lowercase, short, narrow, false (display of AM/PM)
                hour12: false, //true, false
              }}
              slotMinTime={slotMinTime}
              slotMaxTime={slotMaxTime}
              firstDay={1}
              defaultTimedEventDuration={"00:30:00"}
              editable={true}
              eventResizableFromStart={false}
              selectable={true}
              selectAllow={handleSelectable} // Use selectAllow to control the selectability
              selectMirror={true}
              dayMaxEvents={true}
              droppable={true}
              nowIndicator={true}
              allDaySlot={false}
              resources={resources}
              resourceOrder={"id"}  // default is "id"
              events={events}
              eventInteractive={true} // will cause all events to be focusable/tabbable.
              datesSet={callbackDatesSet}
              // loading={onLoading}
              select={handleSelect}
              selectMinDistance={10}  // Minimum distance for a drag to be considered a selection
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              // you can update a remote database when these fire:
              eventAdd={handleEventAdd}
              eventChange={handleEventChange} // handle the changes for the event that was directly dragged
              eventRemove={handleEventRemove}
              eventDrop={handleEventDrop} // handle the changes for related events (grouped events) that are dragged along with the main event
              eventReceive={handleEventReceive}
              eventDragStart={eventDragStart}
              eventDragStop={eventDragStop}
              resourceLabelContent={resourceLabelContent}
              eventDidMount={handleEventDidMount}
              dateClick={handleDateClick}
              eventMouseEnter={handleEventMouseEnter}
              eventResize={handleEventResize}
              eventAllow={eventAllow}
              eventOverlap={handleEventOverlap}
              resourceLabelDidMount={handleResourceLabelDidMount}
              viewDidMount={handleViewDidMount}
            />
            
          </TopLevelPaper>
          {isRouteplanView && 
          <TopLevelPaper sx={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "60%" }}>
            <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <CardContent sx={{ flexGrow: 1, display: "flex",  overflow: "auto", flexDirection: "column", padding: 0 }}>
                {googleMapAppointments?.map((appointment) => {
                  return (
                    <div>
                      {appointment.address?.contactInfo?.addressLine1}, {moment(appointment.timeSlots?.[0].start).format("HH:mm")} - {moment(appointment.timeSlots?.[0].end).format("HH:mm")}
                    </div>
                  )})
                }
                <GoogleMapEmbed appointments={googleMapAppointments}/>
              </CardContent>
              <CardActions>
                <Button variant="outlined" onClick={onCloseRouteplan}>Abbrechen</Button>
                <Button onClick={onSaveRouteplan}>Speichern</Button>
              </CardActions>
            </Card>
          </TopLevelPaper>}
        </Grid>
      </Grid>
   
      {/* dialogues, models, menues */}
      <AddEventModal
        event={event}
        open={openEventModal}
        onConfirm={handleCreateEvent}
        onClose={handleEventModalClose}
        continuous={isContinuous}
      />
      <Backdrop open={showLoading} style={{ zIndex: 1300, color: mobiliTheme.palette.primary.light }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        message={message}
        onClose={handleCloseMessage}
      />
      <ContextMenu
        open={isContextMenuOpen}
        event={contextMenuEvent!}
        timeSlot={clickedTS}
        continuous={isContinuous}
        mouseX={mousePosition.mouseX}
        mouseY={mousePosition.mouseY}
        onClose={handleContextMenuClose}
        onRemoveGoneFishing={handleRemoveGoneFishing}
        onRemoveEventTimeSlot={handleRemoveEventTimeSlot}
        onRemoveContinuousEventTimeSlot={handleRemoveContinuousEventTimeSlot}
        onRemoveLeaveOfAbsence={handleRemoveLeaveOfAbsence}
        onMoveEvent={handleMoveEvent}
        onAddShelfEvent={handleAddShefEvent}
        onCancelAppointment={handleCancelAppointment}
        onCancelContinuousAppointment={handleCancelContinuousAppointment}
        onToggleAttended={handleAttended}
        onEditAppointment={isContinuous ? handleEditContinuousAppointment : handleEditAppointment}
        onCloseNotesDialog={handleCloseNotesDialog}
        onAppointmentOverview={handleAppointmentOverview}
      />
      {showNotesDialog && 
        <AppointmentNotes appointmentId={clickedTS?.appointmentId} showDialog={showNotesDialog} onClose={onCloseNotesDialogEvent}/>
      }
      <TimeSlotDialog open={isModalOpen} setOpen={setModalOpen} timeSlot={clickedTS} />
      {isCancellationDialogOpen && (
        <CancelAppointmentDialog
          patientId={selectedPatientId as number}
          isOpen={isCancellationDialogOpen}
          onClose={handleCloseCancellation}
          appointment={selectedAppointmentRef.current as AppointmentDto}
        />
      )}
      {isContinuousCancelationWarningOpen && (
        <WarningDialog
          title="Stornieren"
          content="Sind Sie sicher, dass Sie den Dauert Termin stornieren möchten?"
          open={isContinuousCancelationWarningOpen}
          onClose={() => setContinuousCancelationWarningOpen(false)}
          onContinue={onCancelContinuousAppointmentConfirm}
        />
      )}
      {isAppointmentEditOpen &&
      <AppointmentEditCard
        open={isAppointmentEditOpen}
        appointment={(isContinuous ? selectedContinuousAppointment : selectedAppointmentRef.current) as AppointmentDto}
        onClose={handleCloseEditAppointment}
        patientId={selectedPatientId!}
        dataRefreshFlag={dataRefreshFlag}
        setDataRefreshFlag={setDataRefreshFlag}
        isContinous={isContinuous}
      />}
      {overviewAppointmentPatientId && 
      <AppointmentOverviewModal open={overviewAppointmentPatientId !== null} onClose={handleCloseOverviewAppointment} patientId={overviewAppointmentPatientId}/>}
    </>
  );
});

export default Calendar;
