import Plot from "react-plotly.js";
import { Layout } from "plotly.js";


interface LineChartProps {
    countData: { locationId: string, date: Date, totalCount: number }[];
}

const RxCountsPlot: React.FC<LineChartProps> = ({countData}) => {
    const groupedData = Object.groupBy(countData, ({ locationId }) => locationId);

    const traces: Plotly.Data[] = [];
    Object.keys(groupedData).forEach(locationId => {
        // generate a trace for current location
        const trace: Plotly.Data = {
            x: groupedData[locationId]?.map(entry => entry.date),  
            y: groupedData[locationId]?.map(entry => entry.totalCount), 
            type: 'scatter',
            mode: 'lines+markers',
            name: locationId, 
        };
        
        // Push the trace to the traces array
        traces.push(trace);
    });

    const layout: Partial<Layout> = {
        title: {
            text: 'Number of Rxs',
        },
        xaxis: {
            type: 'date',
            tickformat: '%d.%m.%Y'
        },
        yaxis: {
            title: 'count',
            rangemode: 'tozero'
        },
    };

    return <Plot data={traces} layout={layout} />;
}

export default RxCountsPlot;