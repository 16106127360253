import { useEffect, useState } from "react";
import DataTable from "../../../molecules/DataTable";

import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";

import {
  AppointmentDto,
  DoubleTreatmentRxDto,
  PatientDto,
  RxDto,
  RxSeriesDto,
  SearchDto,
  TherapyRxDto,
} from "../../../api-client";
import { Add, Delete, MoreVert } from "@mui/icons-material";
import { RxStoreContext } from "../../../stores/Rx/rx.provider";
import useStore from "../../../helpers/useStore";
import { C } from "@fullcalendar/core/internal-common";
import { PatientsContext } from "../../../stores/Patients/patients.provider";
import { observer } from "mobx-react";
import ReceiptDataEntryDialog from "../../PatientDataEntryForm/Receipt";
import { handleParseInput } from "../../../helpers/parseInput";
import { visitTypeMapping } from "../../../helpers/visitTypeMapping";
import { v4 as uuidv4 } from "uuid";
import TicketForm from "../../Ticketing/TicketEditCreate/ticketForm";
import DialogBoxAppointment from "../../PrintAppointment/printRxDialogBox";
import RxPage from "../../RxPage";
import { useNavigate } from "react-router-dom";
import Controls from "../../../atoms/Controls";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import EditTable, { validateFrequency, validateTherapy } from "../../../molecules/Editable";
import notificationStore from "../../../stores/Notification/notificationStore";
import RxSelector from "./RxSelector";
import RxRowOverview from "./RxRowOverview";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import TerminView from "./TerminView";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import FormDialog from "../../Transactions/Kasse/TransactionForm";
import AlertDialogSlide from "../../Transactions/Kasse/TransactionMethod";

import WarningDialog from "../../../atoms/WarningDialog";
import RxSeriesSelector from "./RxSeries/RxSeriesSelector";
import RxSeriesRowView from "./RxSeries/RxSeriesRowView";
import TherapyRxSeries from "./RxSeries/TherapyRxSeries";
import { TerminViewConstants } from "../TerminView";
import FlatCard from "../../../atoms/FlatCard";
import RxFormsData from "./RxForms/RxFormsData";
import { formatDateInternals } from "../../../helpers/dateFormat";

interface RxOverViewProps {
  patientData: PatientDto;
  openPatientTab: (row: any) => void;
  setPatientData: (row: any) => void;
  handleData: (row: any) => void;
  masterData?: any;
  routingParams?: any;
  noNavigate?: boolean;
  handleDoctorReportView?: (rxId: string) => void;
}
interface RowData {
  id: string;
  [key: string]: any;
}

const RxOverView: React.FC<RxOverViewProps> = observer(
  ({
    patientData,
    openPatientTab,
    setPatientData,
    handleData,
    masterData,
    routingParams,
    noNavigate,
    handleDoctorReportView,
  }) => {
    const [searched, setSearched] = useState<string>("");
    const [data, setData] = useState<any>([]);
    const [rxSeriesData, setRxSeriesData] = useState<RxSeriesDto[]>([]);
    const [therapyRxSeriesData, setTherapyRxSeriesData] = useState<TherapyRxDto[]>([]);
    const [appointmentSeriesData, setAppointmentSeriesData] = useState<AppointmentDto[]>([]);
    const [isSeriesLoading, setIsSeriesLoading] = useState(false);
    const [openTicket, setOpenTicket] = useState<any>(false);
    const [menu, setMenu] = useState<null | HTMLElement>(null);
    const [onNewRx, setOnNewRx] = useState<boolean>(false);
    const [openRxView, setOpenRxView] = useState<boolean>(false);
    const [openSeriesView, setSeriesOpenView] = useState<boolean>(false);
    const [changeInSeriesData, setChangeInSeriesData] = useState<boolean>(false);
    const [editRxMode, setEditRxMode] = useState<boolean>(false);
    const RxStore = useStore(RxStoreContext);
    const {
      copyToContinous,
      deleteRx,
      saveRxs,
      getRxSeriesForPatient,
      getTherapyRxsSeriesForPatient,
      savedSeriesForPatient,
      setSavedSeriesForPatient,
    } = RxStore;
    const PatientStore = useStore(PatientsContext);
    const AppointmentStore = useStore(AppointmentContext);
    const { getAppointmentsRx, getAppointmentsForSeries } = AppointmentStore;
    const {
      selectedPatients,
      getSelectedPatientparams,
      constructSearchPath,
      setSelectedSubTab,
      searchPatient,
      patientDataRefreshFlag,
      setPatientDataRefreshFlag,
      setState,
      state,
    } = PatientStore;
    const MasterTableDataStore = useStore(MasterTablesContext);
    const { getTherapyId, masterTablesData } = MasterTableDataStore;

    const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);
    // const [dataRefreshFlag, setDataRefreshFlag] = useState(null);Removed since added in store to track patientDatachanges
    const [seriesdataRefreshFlag, setSeriesDataRefreshFlag] = useState(null);
    const [rxtableData, setrxTableData] = useState<any>([]);
    const [ticketEditable, setTicketEditable] = useState<boolean>(false);
    const [tableData, setTableData] = useState<RowData[]>([{ id: uuidv4() }]); // Initialize with an empty array
    const navigate = useNavigate();

    const [isQuickAdd, setIsQuickAdd] = useState(false); // State variable to control the visibility of the Box
    const [inputValue, setInputValue] = useState("");
    const [editable, setEdittable] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [savingRx, setSavingRx] = useState(false);

    const [selectedRow, setSelectedRow] = useState<any>({});
    const [selectedSeriesRow, setSeriesSelectedRow] = useState<RxSeriesDto | undefined>({});
    const [previousRow, setPreviousRow] = useState({});
    const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);
    const [unsavedDeleteWarning, setShowDeleteWarning] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false); // Flag to track unsaved changes
    const [showDialogBoxAppointment, setShowDialogBoxAppointment] = useState<boolean>(false);
    const [rxTypeData, setRxTypeData] = useState("");

    const [rxViewLoading, setRxViewLoading] = useState(false);

    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevent the default Enter key behavior

        if (inputValue.toString().toLowerCase() === "blanko") {
          handleBlankDialog();
        } else {
          setIsQuickAdd((prev) => !prev); // Toggle isQuickAdd
        }
      }
    };

    const handleRxTypeChange = (event: any) => {
      setRxTypeData(event.target.value);
    };
    const rxType = masterTablesData
      .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
      .map((obj: any) => obj.rxType);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
      setUnsavedChanges(true);
    }, [tableData]);

    // for Termine ausdrucken button
    // const [showDialogBoxAppointment, setShowDialogBoxAppointment] = useState<boolean>(false);
    // Separate handler for the "More" button click
    const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
      setSelectedRow(row);

      setMenu(event.currentTarget);
      // Stop event propagation to prevent it from reaching the row click handler
      event.stopPropagation();
    };
    const [appointmentsLoading, setAppointmentsLoading] = useState(false);
    useEffect(() => {
      const fetchData = async () => {
        if (selectedRow) {
          try {
            if (selectedRow.rxNumber) {
              setAppointmentsLoading(true);

              const appointment = await getAppointmentsRx(selectedRow.rxNumber);
              const filteredAppointments = appointment.filter((app: any) => app.start !== null);

              setAppointments(filteredAppointments);
            }
          } catch (error) {
            console.error("Error fetching appointments:", error);
            // Handle error if needed
          } finally {
            setAppointmentsLoading(false);
          }
        }
      };

      fetchData();
    }, [selectedRow, patientDataRefreshFlag]); // Dependency array to watch for changes in selectedRow

    //Series Data
    useEffect(() => {
      const fetchData = async () => {
        try {
          // if(selectedSeriesRow){
          //   setIsSeriesLoading(true);
          //   const rxSeries = await getRxSeriesForPatient(patientData.id as number);
          //   console.log("selectedSerrtrstsadadst",rxSeries)

          //   // setSeriesSelectedRow(rxSeries.map((rx:any)=>rxSeries.id===selectedSeriesRow.id))
          //   setRxSeriesData(rxSeries)
          //   setSavedSeriesForPatient(rxSeries);
          // }
          if (selectedSeriesRow && selectedSeriesRow.id) {
            console.log("sasa", selectedSeriesRow);
            setOpenRxView(false);
            // setSeriesOpenView(selectedSeriesRow);
            // setIsSeriesLoading(true)
            const appointmentSeries = await getAppointmentsForSeries(selectedSeriesRow.id);
            setAppointmentSeriesData(appointmentSeries);
            const therapyRxsSeries = await getTherapyRxsSeriesForPatient(patientData.id as number);
            setTherapyRxSeriesData(therapyRxsSeries);
          }
        } catch (error) {
          console.error("Error fetching appointments:", error);
          // Handle error if needed
        } finally {
          setIsSeriesLoading(false);
        }
      };

      fetchData();
    }, [selectedSeriesRow, seriesdataRefreshFlag]); // Dependency array to watch for changes in selectedRow
    useEffect(() => {
      const fetchData = async () => {
        try {
          console.log("JAJAJJJS", selectedSeriesRow);
          if (selectedSeriesRow) {
            setIsSeriesLoading(true);
            const rxSeries = await getRxSeriesForPatient(patientData.id as number);
            console.log("JAJAjjjkJJJS", rxSeries);

            // Find the selected series row in rxSeries
            const selectedRx = rxSeries.find((rx: any) => rx.id === selectedSeriesRow.id);
            setSeriesSelectedRow(selectedRx);
            console.log("JAJAjjjkkkkJJJS", selectedRx);
            setRxSeriesData(rxSeries);
            setSavedSeriesForPatient(rxSeries);
          }
        } catch (error) {
          console.error("Error fetching appointments:", error);
          // Handle error if needed
        } finally {
          setIsSeriesLoading(false);
        }
      };

      fetchData();
    }, [seriesdataRefreshFlag]); // Dependency array to watch for changes in selectedRow

    const handleCloseDialog = () => {
      setSelectedRow(previousRow);
      // Filter out the selected row from the rxs array
      const updatedRxs = patientData?.rxs?.filter((rx) => rx.id !== selectedRow.id) ?? [];

      // Update the patientData state with the filtered rxs array
      const formattedValues = {
        ...patientData,
        rxs: updatedRxs,
      };

      setPatientData(formattedValues);
      setOnNewRx(false);

      console.log("Closed the add dialog, ", masterTablesData);
    };
    const handleMenuClose = () => {
      setMenu(null);
    };

    const updateTableData = (data: DoubleTreatmentRxDto[]) => {
      // Create a new array to hold the updated data
      const updatedData: any[] = [];

      data.forEach((item: any) => {
        // Divide the amount by 2 for both the original and duplicated rows
        const updatedAmount = item.amount;

        // Create a new row with the updated amount
        const newItem = { ...item, amount: updatedAmount };

        // Always add the original item to the updated data array
        updatedData.push(newItem);

        // If the item has duplicate: true, create a new row without duplicate: true and with the updated amount
        if (item.isDoubled) {
          const newId = uuidv4();
          const newDuplicateItem = { ...item, id: newId, amount: updatedAmount };
          delete newDuplicateItem.isDoubled; // Remove the duplicate: true property
          updatedData.push(newDuplicateItem);
        }
      });
      setTableData(updatedData);
    };
    const gridColumns: any[] = [
      // { field: "RezNum", headerName: "RezNum", width: 150 },
      // {
      //   field: "date",
      //   headerName: "Rezept Date",
      //   width: 150,
      //   // type: "date",
      // },
      // { field: "creationDate", headerName: "Creation", width: 100 },
      { field: "visitType", headerName: "Location", editable: true },
      { field: "therapy", headerName: "Heilmittel", editable: true },
      { field: "amount", headerName: "Menge", editable: true },
      { field: "frequency", headerName: "Frequency", editable: true },
    ];
    console.log("RXDATA", patientData);
    const [columnSets, setColumnSets] = useState<any[]>([]);
    const handleAddColumnSet = () => {
      const newColumnSet = generateColumnSet(columnSets.length + 1);
      setColumnSets((prevColumnSets) => [...prevColumnSets, newColumnSet]);
    };

    // Function to generate a new column set
    const generateColumnSet = (columnIndex: any) => [
      {
        field: `therapy${columnIndex}`,
        headerName: `Heilmittel ${columnIndex}`,
        editable: true,
      },
      {
        field: `amount${columnIndex}`,
        headerName: `Menge ${columnIndex}`,
        editable: true,
      },
      {
        field: `frequency${columnIndex}`,
        headerName: `Frequency ${columnIndex}`,
        editable: true,
      },
    ];
    const handlerxTableDataChange = (data: any) => {
      console.log("Data", data);

      const updatedRxs = data.map((rx: any) => {
        if (rx.visitType && visitTypeMapping.hasOwnProperty(rx.visitType)) {
          rx.visitType = visitTypeMapping[rx.visitType];
        }
        if (rx.visitType) {
          // Set isHomeVisit based on visitType
          if (rx.visitType === "Haus" || rx.visitType === "Heim") {
            rx.isHomeVisit = true;
          } else if (rx.visitType === "Praxis") {
            rx.isHomeVisit = false;
          } else {
            rx.isHomeVisit = null; // Set to null if it's neither Haus nor Praxis
          }
        }
        const existingRxIndex = patientData?.rxs?.findIndex(
          (existingRx: any) => existingRx.id === rx.id
        );

        // const newRowAdded = Math.abs(
        //   rx.therapies?.length - columnSets.length - 1
        // );
        // console.log('ZUi',newRowAdded,rx.therapies?.length,columnSets.length)

        // // Add new column sets if needed
        // const newColumnSets = Array.from({ length: newRowAdded }, (_, index) =>
        //   generateColumnSet(columnSets.length + index + 1)
        // );
        // setColumnSets((prevColumnSets) => [...prevColumnSets, ...newColumnSets]);

        const therapyRxs: any[] = [];

        if (rx.rxtype || rx.therapyRxs) {
          console.log("ZUUJJJSJJSJ", rx, rxtableData);
          // const rxs = {
          //   rxNumber: rx.rxNumber,
          //   id: rx.id,
          //   date: rx.date,
          //   number: rx.therapies[0]?.amount,
          //   therapy: rx.therapies[0]?.therapy?.abbreviation,
          //   therapyId: rx.therapies[0]?.id,
          //   frequency:rx.therapies[0]?.frequency

          // };
          // setrxTableData([rxs] as any)
          const rxs: any = {
            rxNumber: rx.rxNumber,
            id: rx.id,
            creationDate: rx.creationDate,
            isHomeVisit: rx.isHomeVisit,
            // date: rx.date,
            amount: rx.amount,
            therapy: rx.therapy,
            therapyId: rx.therapyId,
            frequency: rx.frequency,
          };
          //   number: rx.therapies[0]?.amount,
          //   therapy: rx.therapies[0]?.therapy?.abbreviation,
          //   therapyId: rx.therapies[0]?.id,
          //   frequency:rx.therapies[0]?.frequency

          rx.therapyRxs.forEach((therapy: any, index: any) => {
            if (index === 0) {
              // For the first therapy, use the existing fields
              rxs.therapyId = therapy.id;
              rxs.amount = therapy.amount;
              rxs.therapy = therapy.therapy?.abbreviation || therapy.therapy;
              rxs.frequency = therapy.frequency?.text || therapy.frequency;
            } else {
              // For subsequent therapies, add index to the field names
              rxs[`therapyId${index}`] = therapy.id;
              rxs[`amount${index}`] = therapy.amount;
              rxs[`therapy${index}`] = therapy.therapy?.abbreviation || therapy.therapy;
              rxs[`frequency${index}`] = therapy.frequency?.text || therapy.frequency;
            }
          });
          // rxs.location=rx.location
          console.log("Rx112", rxtableData);
          // Set the rxTableData with the newly constructed rxs array
          // setrxTableData((prevRxTableData:any) => {
          //   const updatedRxTableData = [...prevRxTableData, rxs];
          //   console.log('Updated Rx Table Data:', updatedRxTableData);
          //   return updatedRxTableData;
          // });
          setrxTableData((prevRxTableData: any) => {
            const updatedRxTableData = [...prevRxTableData];
            const existingIndex = updatedRxTableData.findIndex((item) => item.id === rxs.id);

            if (existingIndex !== -1) {
              updatedRxTableData[existingIndex] = rxs;
            } else {
              updatedRxTableData.push(rxs);
            }

            console.log("Updated Rx Table Data:", updatedRxTableData);
            return updatedRxTableData;
          });
          // setrxTableData([...rxtableData, rxs] as any);
          console.log("Rx", rxtableData, rxs);
          return rx;
        }
        console.log("Thzuuuu");
        for (const key in rx) {
          if (rx.hasOwnProperty(key) && key.startsWith("therapy") && !key.startsWith("therapyId")) {
            const index = key.slice(key.indexOf("y") + 1);
            const therapyNumber = rx[`amount${index}`] || "";
            const therapy = rx[`therapy${index}`] || "";
            const frequency = rx[`frequency${index}`] || "";
            const therapyId = rx[`therapyId${index}`] || "";

            // Check if therapy data exists before adding
            if (therapyNumber || therapy || frequency) {
              const therapyObj = {
                id: therapyId ? therapyId : uuidv4(),
                amount: therapyNumber,
                therapy: therapy,
                frequency: frequency,
              };
              console.log("Therapyiiii", therapyObj);
              therapyRxs.push(therapyObj);
            }
          }
        }
        console.log("JKKK", therapyRxs);
        // Update existing rx if found, otherwise create a new one
        if (existingRxIndex !== -1) {
          console.log("ABN", therapyRxs);
          return {
            ...(patientData?.rxs ? [existingRxIndex] : rx),
            isHomeVisit: rx.isHomeVisit,
            therapyRxs: therapyRxs,
          };
        } else {
          console.log("RXFGG", rx);
          return {
            rxNumber: rx.rxNumber,
            id: rx.id,
            isHomeVisit: rx.isHomeVisit,
            creationDate: rx.creationDate,
            // date: rx.date,
            therapyRxs: therapyRxs,
          };
        }
      });

      console.log("UPDFT", updatedRxs, rxtableData);
      const maxTherapies = Math.max(
        ...updatedRxs.map((rx: any) => (rx.therapyRxs ? rx.therapyRxs.length : 0))
      );

      // Calculate the number of new column sets to add
      const newColumnSetsCount = Math.max(0, maxTherapies - columnSets.length - 1);

      // Add new column sets if needed
      const newColumnSets = Array.from({ length: newColumnSetsCount }, (_, index) =>
        generateColumnSet(columnSets.length + index + 1)
      );
      setColumnSets((prevColumnSets) => [...prevColumnSets, ...newColumnSets]);
      // Check if any field other than 'id' has a value
      const haveRxs = (objects: any) => {
        for (const obj of objects) {
          let allValuesAreEmpty = true;

          for (const key in obj) {
            if (
              key !== "id" &&
              obj[key] !== undefined &&
              (!Array.isArray(obj[key]) || obj[key].length !== 0)
            ) {
              allValuesAreEmpty = false;
              break; // Exit the inner loop as soon as a non-empty value is found.
            }
          }

          if (allValuesAreEmpty) {
            return false; // Return false if all values are empty except 'id'.
          }
        }

        return true; // Return true if any object has at least one non-empty value.
      };

      handleData((prevValues: any) => ({
        ...prevValues,
        rxs: haveRxs(updatedRxs) ? updatedRxs : [],
      }));
    };

    // useEffect(() => {
    //   console.log("MASTER PATIENT DATA43 CHANGED", masterPatientData);
    //   // Check if masterPatientData is not empty before updating the component
    //   if (masterPatientData && masterPatientData !== undefined) {
    //     setPatientData(masterPatientData);
    //   }
    // }, [masterPatientData]);
    const handleDeleteRx = async (selectedRx: any) => {
      console.log("Selected Rx:", selectedRx);

      try {
        let response;
        // setOpenRxView(false);

        if (selectedRx.rxNumber) {
          // Make an API call to delete the prescription
          response = await deleteRx(selectedRx.id);
          setSelectedRow(previousRow);
          setOpenRxView(false);
          setMenu(null);

          if (response) {
            // Update the local state (data) by filtering out the deleted prescription
            const updatedData = data.filter((rx: any) => rx.id !== selectedRx.id);
            setData(updatedData); // Update the 'data' state here

            // Update the parent component's state (patientData) by filtering out the deleted prescription
            const updatedRxs = patientData?.rxs?.filter((rx: any) => rx.id !== selectedRx.id) || [];
            const updatedPatientData = {
              ...patientData,
              rxs: updatedRxs,
            };

            setPatientData(updatedPatientData);
            handleData(patientData);
            // Check if `routingParams.rxId` matches `selectedRx.id` and set it to null if it does
            const updatedRoutingParams = { ...routingParams };

            if (updatedRoutingParams.rxId === selectedRx.id) {
              updatedRoutingParams.rxId = null;
            }

            navigate(constructSearchPath(updatedRoutingParams));
          }
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const updatedData = (patientData.rxs || []).map((obj: any) => {
            const therapiesAbbreviation = (obj.therapyRxs || [])
              .map((therapy: any) => therapy.therapy?.abbreviation)
              .join(", ");
            const diagnosisConcat = (obj.icdCodeEntries || [])
              .map((icd: any) => icd.userText)
              .join(", ");

            return {
              ...obj,
              therapyAbbreviation: therapiesAbbreviation || "-",
              isVirtual: obj.isPerpetual ? "Y" : "N",
              date: obj.date ? formatDateInternals(obj.date) : null,
              diagnosis: diagnosisConcat || "-",
              status: obj.insuranceStatus || "-",
              // Add the "actions" property
              actions: (
                <IconButton onClick={(event) => handleMoreButtonClick(event, obj)}>
                  <MoreVert />
                </IconButton>
              ),
            };
          });
          const filteredData = includeDeleted
            ? updatedData
            : updatedData.filter((rx: any) => rx.deletedAt === null);
          console.log("FILTERED DATA345", filteredData);

          setData(filteredData);
          // setIsSeriesLoading(true)
        } catch (error) {
          console.log("AESRSRRSRSRTST", error);
          notificationStore.showMessage("Fehler beim Abrufen der Seriendaten", "error");
        }
      };
      fetchData();
    }, [patientData, includeDeleted]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await searchPatient({ id: patientData.id });
          if (!response) {
            throw new Error(`No data found for patient ID ${patientData.id}`);
          }

          setPatientData(response);
        } catch (error) {
          console.log("AESRSRRSRSRTST", error);
          notificationStore.showMessage("Fehler beim Abrufen der Seriendaten", "error");
        }
      };
      fetchData();
    }, [changeInSeriesData]);
    console.log("SELECTEDROW,DATA", selectedRow, patientData);
    const handleCreateVirtualPrescription = async () => {
      try {
        setRxViewLoading(true);
        // Make an API call to create a virtual prescription for the selected row
        const response = await copyToContinous(selectedRow.id);
        handleMenuClose();
        // Concatenate the new values to the 'rxs' array in patientData
        const updatedRxs = [
          ...(patientData?.rxs || []), // Provide a default empty array if patientData?.rxs is undefined
          response, // Replace 'response' with the actual new value
        ];

        // Create a new object with the updated 'rxs' array
        const updatedPatientData = {
          ...patientData, // Copy the existing patientData properties
          rxs: updatedRxs, // Update the 'rxs' property with the concatenated array
        };

        // setMasterPatientData(updatedPatientData);
        // Update the patientData state with the new object
        setPatientData(updatedPatientData);
        handleData(updatedPatientData);
        setData(updatedRxs);
        // After the API call is successful, fetch updated data and refresh the component
      } catch (error) {
        // Handle any errors that may occur during the API call
        console.error("Error creating virtual prescription:", error);
        // You can also display an error message to the user.
      } finally {
        setRxViewLoading(false);
      }
    };

    console.log("DATACHEK", data, patientData);
    const handleRowDoubleClick = () => {
      const row = {
        id: uuidv4(),
        isHomeVisit: null,
        therapy: "",
        amount: "",
        frequency: "",
      };
      console.log("Row:", row);
      setPreviousRow(selectedRow);
      setSelectedRow(row);
      const formattedValues = {
        ...patientData,
        rxs: [...(patientData?.rxs ?? []), row], // Providing an empty array as a fallback if patientData?.rxs is undefined
      };
      setPatientData(formattedValues);

      // Set 'onNewRx' to true
      setOnNewRx(true);
    };
    const handleBlankDialog = () => {
      const row = {
        id: uuidv4(),
        isHomeVisit: null,
        rxtype: "Blankoverordnung",
        therapy: "",
        amount: "",
        frequency: "",
      };
      console.log("Row:", row);
      setPreviousRow(selectedRow);
      setSelectedRow(row);
      const formattedValues = {
        ...patientData,
        rxs: [...(patientData?.rxs ?? []), row], // Providing an empty array as a fallback if patientData?.rxs is undefined
      };
      setPatientData(formattedValues);
      setOnNewRx(true);
    };
    const handleCloseTicketForm = () => {
      setOpenTicket(false);
    };

    // show a dialog box for printing the appointments
    // const rxNumber = selectedRow["rxNumber"];
    const tarifGroupRx = selectedRow.tarifGroup?.id || null;
    const isCopayment = selectedRow["isCopaymentExempted"];
    const rxDate = selectedRow.date;

    const handleShowAppointment = () => {
      setShowDialogBoxAppointment(true);
    };

    const handleHideAppointment = () => {
      setShowDialogBoxAppointment(false);
    };

    const handleRowClick = (row: any) => {
      setSeriesSelectedRow({});
      console.log("AGHSHSJKSKSK", data, selectedRow, row);
      try {
        if (!noNavigate) {
          const selectedPatientParams = getSelectedPatientparams(patientData.id);
          console.log("SELECTEDPATIENTPARAS", selectedPatientParams);
          if (selectedPatientParams) {
            const urlPath = constructSearchPath({ pId: selectedPatientParams.pId, rxId: row.id });
            navigate(urlPath);
          } else {
            const urlPath = constructSearchPath({ pId: patientData.id, rxId: row.id });
            navigate(urlPath);
          }
        }
        setSelectedRow(row);
        setOpenRxView(row);
        setSeriesOpenView(false);
      } catch (error) {
        notificationStore.showMessage("Rx-ID wurde nicht gefunden", "error");
      }
    };

    const handleSeriesRowClick = async (row: any) => {
      try {
        setIsSeriesLoading(true);
        console.log("Handling row click", row);
        // setSelectedRow({});
        setOpenRxView(false);
        setSeriesOpenView(row);

        setSeriesSelectedRow(row);
        setSelectedRow({});
      } catch (error) {
        notificationStore.showMessage("Rx-ID wurde nicht gefunden", "error");
      } finally {
        setIsSeriesLoading(false);
      }
    };

    useEffect(() => {
      console.log("RoutingParams0", routingParams);
      if (routingParams && routingParams.rxId) {
        const row = patientData.rxs?.find((rx: any) => rx.id === routingParams.rxId);
        handleRowClick(row); // Call handleRowClick with row and rxId
      }
    }, [routingParams]);

    // Define a function to handle closing the dialog
    const handleClose = () => {
      setEditRxMode(false);
    };
    const handleEditClose = () => {
      setInputValue("");
      setTableData([]);
      setIsQuickAdd(false);
      setInputValue("");
      setTableData([]);
    };

    const handleCloseEditDialog = () => {
      if (unsavedChanges) {
        // If there are unsaved changes, set a state to open the warning dialog
        setShowunsavedChangesWarning(true);
      } else {
        handleEditClose();
      }
    };

    const handleUnsavedChangesWarning = () => {
      handleSave();
    };

    const handleCloseUnsavedChangesWarning = () => {
      handleEditClose();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowunsavedChangesWarning(false);
    };
    const handleDeleteContinue = () => {
      handleDeleteRx(selectedRow);
    };

    const handleCloseDeleteWarning = () => {
      handleMenuClose();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowDeleteWarning(false);
    };

    const handleCardClick = (rx: RxDto) => {
      setSelectedRow(rx);
    };

    console.log("Here is the Master Data", masterData);

    const insurer = masterData
      .filter((obj: any) => "insurer" in obj)
      .map((obj: any) => obj.insurer);

    let tarifGroup: any;
    const filteredInsurer = insurer[0]?.filter(
      (obj: any) => obj.id === (patientData.payer as any)?.id || patientData.payer
    );
    if (filteredInsurer && filteredInsurer.length > 0) {
      tarifGroup = filteredInsurer[0].tarifGroup?.id;
    } else {
      tarifGroup = patientData.tarifGroupId;
    }

    console.log("TARIF", tarifGroup);

    console.log("EDIT", editRxMode);
    const handleSave = async () => {
      try {
        if (!rxTypeData) {
          notificationStore.showMessage("Bitte wählen Sie Rezept-typ, um fortzufahren.", "error");
          return;
        }
        setSavingRx(true);
        const therapyRxs: any[] = [];
        const filteredTherapy = (tableData || []) // Ensure formData.therapyRxs is an array or provide an empty array
          .filter((therapy: any) => therapy.therapy.id !== "");

        // Loop through tableData to collect therapy data
        for (const therapy of filteredTherapy) {
          const tarifTherapy = getTherapyId(tarifGroup, therapy.therapy);
          if (!tarifTherapy) {
            notificationStore.showMessage(`Heilmittel ${therapy.therapy} nicht gefunden`, "error");
            return;
          }

          const therapyObj = {
            id: therapy.id || uuidv4(),
            amount: therapy.amount || "",
            therapy: {
              id: tarifTherapy ?? "",
              abbreviation: therapy?.therapy ?? "",
            },
            frequency: {
              id: therapy?.frequency ?? "",
              text: therapy?.frequency ?? "",
            },
            isHomeVisit: therapy.isHomeVisit,
            doubledTreatmentIds: therapy.doubledTreatmentIds,
            isDoubled: therapy.isDoubled,
            rxSeries: therapy.rxSeries,
          };

          // Check if therapy data exists before adding

          if (
            !(therapyObj.frequency && therapyObj.frequency.id) ||
            !(therapyObj.therapy && therapyObj.therapy.id) ||
            !therapyObj.amount
          ) {
            throw new Error(
              "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
            );
          }
          const validTherapy = validateTherapy(therapyObj.therapy.abbreviation, masterTablesData);
          const validFrequency = validateFrequency(therapyObj.frequency.id, masterTablesData);
          if (!validTherapy || !validFrequency) {
            throw new Error("Bitte geben Sie eine gültige Therapie und Häufigkeit ein.");
          } else if (therapyObj.amount || therapyObj.therapy || therapyObj.frequency) {
            therapyRxs.push(therapyObj);
          }
        }
        const selectedPayer: any = insurer[0].find(
          (option: any) =>
            option.id === (patientData.payer as any)?.id ||
            option.ik === (patientData.payer as any)?.id
        );

        // Construct the Rx object with therapyRxs array
        const rxData = {
          Receiptpayer: (patientData.payer as any)?.id,
          insuranceStatus: patientData?.status,
          insureeNumber: patientData?.insuranceNo,
          rxtype: rxTypeData,
          tarifGroupId: patientData.tarifGroupId || selectedPayer?.tarifGroup?.id,
          isHomeVisit: therapyRxs[0].isHomeVisit,
          therapyRxs: therapyRxs,
        };

        console.log("Updatede data:", [rxData], therapyRxs, tableData);

        const data = await saveRxs(patientData.id as number, [rxData]);
        setUnsavedChanges(false);
        console.log("Updated data:", data);
        handleData({
          ...patientData,
          rxs: [...(patientData.rxs as any), ...data.map((item: any) => ({ ...item }))],
        });
        setIsQuickAdd(false);
        setInputValue("");
      } catch (error: any) {
        notificationStore.showMessage(
          error.message || "Fehler beim Speichern des Rezepts",
          "error"
        );
      } finally {
        setSavingRx(false);
      }
    };

    const isLoading = !(masterTablesData.length > 0);

    const [openTransactionType, setOpenTransactionType] = useState<boolean>(false);

    const handleOpenTransactionType = () => {
      setOpenTransactionType(true);
    };

    const handleCloseTransactionType = () => {
      setOpenTransactionType(false);
    };

    console.log("Selected ROW VUSSSSSSSSSSSSSSSSSSs", selectedRow);

    return (
      <Box
        sx={{
          border: "0px dotted black",
          padding: "8px",
          boxSizing: "border-box",
          width: "auto",
        }}
      >
        {rxViewLoading && <CircularProgress />}
        {!rxViewLoading && (
          <Grid
            container
            sx={{ border: "0px dotted red" }}
            justifyContent="flex-start"
            spacing={2}
          >
            {/* Left side components */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{ border: "0px solid red" }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                >
                  <Card sx={{ marginBottom: 2, padding: "0px" }}>
                    <CardHeader title="Neues Rezept" />
                    <CardContent
                      sx={{
                        padding: "0px",
                        border: "0px solid green",
                        paddingBottom: "4px !important",
                      }}
                    >
                      <Box
                        sx={{
                          padding: "4px",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="6 KG HB 2x or 6 KG HB 8 KGG 1-2 x or 6 KG 2x">
                          <TextField
                            disabled={isLoading}
                            type="text"
                            label={
                              isLoading ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                >
                                  <CircularProgress
                                    size={20}
                                    sx={{ marginRight: 1 }}
                                  />
                                  Loading...
                                </Box>
                              ) : (
                                "Schnelltext"
                              )
                            }
                            placeholder="6 KG HB 2x or 6 KG HB 8 KGG 1-2 x or 6 KG 2x"
                            sx={{
                              padding: 0,
                              borderRadius: "4px",
                              backgroundColor: "white",
                            }} // Adjust the width as needed
                            value={inputValue} // Assuming inputValue is the state variable holding the value of the TextField
                            InputLabelProps={{ style: { fontWeight: "bold" } }}
                            onChange={(e) => setInputValue(e.target.value)} // Assuming setInputValue is the function to update the inputValue state
                            // onKeyDown={(e) => {
                            //   if (e.key === "Enter") {
                            //     e.preventDefault(); // Prevent the default Enter key behavior
                            //     setIsQuickAdd((prev) => !prev); // Set isBoxOpen to true
                            //   }
                            // }}
                            onKeyDown={handleKeyDown}
                          />
                        </Tooltip>

                        {isQuickAdd && ( // Render the Box when isBoxOpen is true
                          <Dialog
                            open={isQuickAdd} // Assuming isOpen is the state to control the dialog visibility
                            onClose={handleCloseEditDialog} // Assuming handleClose is the function to close the dialog
                            sx={{
                              position: "absolute",
                              top: "20%",
                              height: "500px",
                              width: "100%",
                              // zIndex: 13888,
                            }}
                          >
                            <DialogTitle>Neues Rezept erstellen</DialogTitle>
                            <DialogContent sx={{ width: "500px" }}>
                              <Controls.Select
                                name="rxtype"
                                label="Rezept-typ"
                                // id={1}
                                value={rxTypeData}
                                onChange={handleRxTypeChange}
                                options={rxType[0]?.map((option: any) => ({
                                  label: option.text.toString(),
                                  value: option.text.toString(),
                                  id: option.id.toString(),
                                }))}
                                required
                              />
                              <FlatCard title="Heilmittel">
                                <EditTable
                                  initialColumns={gridColumns}
                                  initialRows={tableData}
                                  // columnSets={columnSets}
                                  onTableDataChange={setTableData}
                                  parseInput={handleParseInput}
                                  // handleAddColumnSet={handleAddColumnSet}
                                  masterTablesData={masterData}
                                  isQuickAdd={isQuickAdd}
                                  quickAddValue={inputValue}
                                  isTreatmentRows
                                />
                              </FlatCard>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="text"
                                onClick={handleCloseEditDialog}
                              >
                                Abbrechen
                              </Button>
                              <Button
                                onClick={handleSave}
                                variant="contained"
                                disabled={savingRx}
                              >
                                Speichern
                              </Button>
                            </DialogActions>
                          </Dialog>
                        )}
                        <Button
                          onClick={() => {
                            handleRowDoubleClick();
                          }}
                          disabled={isLoading}
                        >
                          <Add />
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Card sx={{ padding: "0px" }}>
                    <CardHeader
                      title="Rezepte"
                      action={
                        <FormControlLabel
                          sx={{ width: "80" }}
                          control={
                            <Switch
                              checked={includeDeleted || false}
                              onChange={(e) => {
                                setIncludeDeleted(e.target.checked);
                              }}
                            />
                          }
                          label="Gelöschte Rx"
                        />
                      }
                    />

                    <CardContent sx={{ padding: "0px" }}>
                      {data.length === 0 && <Typography>No Rezept found</Typography>}
                      {data.length > 0 && (
                        <RxSelector
                          data={data}
                          handleRowClick={handleRowClick}
                          selectedRow={selectedRow}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  sx={{ width: "100%", paddingTop: "20px" }}
                >
                  <Card sx={{ padding: "0px" }}>
                    <CardHeader title="Behandlungsserien" />

                    <CardContent sx={{ padding: "0px", overflowX: "auto" }}>
                      {isSeriesLoading && <CircularProgress />}
                      {!isSeriesLoading && rxSeriesData.length === 0 && (
                        <Typography>Keine Serien gefunden</Typography>
                      )}
                      {!isSeriesLoading && rxSeriesData.length > 0 && (
                        <RxSeriesSelector
                          data={rxSeriesData}
                          handleRowClick={handleSeriesRowClick}
                          selectedRow={selectedSeriesRow}

                          // handleMoreButtonClick={handleMoreButtonClick}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>{" "}
              {/* End of grid container*/}
            </Grid>{" "}
            {/* End of grid item for left column*/}
            {/* Right side */}
            {onNewRx && (
              <ReceiptDataEntryDialog
                openDialog={onNewRx}
                handleCloseDialog={handleCloseDialog}
                selectedRow={selectedRow}
                patientValues={patientData}
                masterData={masterData}
                handleData={handleData}
                handleAddColumnSet={handleAddColumnSet}
                onTableDataChange={handlerxTableDataChange}
                rxTableData={setrxTableData}
                parseInput={handleParseInput}
                additionalRx
              />
            )}
            {openRxView && (
              <Grid
                item
                xs={12}
                md={9}
                sx={{ border: "0px dashed blue" }}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={9}
                  >
                    {" "}
                    {/* Set RxRowOverview to take 12 columns on medium screens and above */}
                    <RxRowOverview
                      rowData={data.find((rx:any) => rx.id === selectedRow.id) || {}}
                      editRxMode={editRxMode}
                      setEditRxMode={setEditRxMode}
                      patientValues={patientData}
                      masterData={masterData}
                      handleData={handleData}
                      setPatientData={(row: any) => setPatientData(row)}
                      handleMoreButtonClick={handleMoreButtonClick}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                  >
                    {" "}
                    {/* Set the extra Box to take 12 columns on medium screens and above */}
                    <Card>
                      <CardHeader title="Termine" />
                      <CardContent>
                        <TerminView
                          appointments={appointments}
                          patientId={patientData.id}
                          dataRefreshFlag={patientDataRefreshFlag}
                          setDataRefreshFlag={setPatientDataRefreshFlag}
                          appointmentsLoading={appointmentsLoading}
                          isView={TerminViewConstants.RX_VIEW}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {openSeriesView && selectedSeriesRow && (
              <Grid
                item
                xs={12}
                md={9}
                sx={{ border: "0px dashed green" }}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={9}
                  >
                    <RxSeriesRowView
                      key={selectedSeriesRow.id}
                      rowData={selectedSeriesRow}
                      patientData={patientData}
                      setDataRefresh={setSeriesDataRefreshFlag}
                      dataRefreshFlag={seriesdataRefreshFlag}
                      setSeriesOpenView={setSeriesOpenView}
                      setChangeInSeriesData={setChangeInSeriesData}
                      changeInSeriesData={changeInSeriesData}
                    />

                    <TherapyRxSeries
                      rowData={therapyRxSeriesData}
                      patientData={patientData}
                      setDataRefresh={setSeriesDataRefreshFlag}
                      dataRefreshFlag={seriesdataRefreshFlag}
                      setChangeInSeriesData={setChangeInSeriesData}
                      changeInSeriesData={changeInSeriesData}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                  >
                    {" "}
                    <Card>
                      <CardHeader title="Termine" />
                      <CardContent>
                        {/* Set the extra Box to take 12 columns on medium screens and above */}
                        <TerminView
                          appointments={appointmentSeriesData}
                          patientId={patientData.id}
                          dataRefreshFlag={seriesdataRefreshFlag}
                          setDataRefreshFlag={setSeriesDataRefreshFlag}
                          appointmentsLoading={isSeriesLoading}
                          isView={TerminViewConstants.SERIES_VIEW}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* <Dialog
            open={editRxMode}
            onClose={handleClose}
            aria-labelledby="edit-rx-dialog"
            fullScreen
          >
            <DialogContent> */}
            {editRxMode && (
              <RxFormsData
                isEditing={true}
                selectedRow={selectedRow}
                patientValues={patientData}
                masterData={masterData}
                handleData={handleData}
                setPatientData={(row: any) => setPatientData(row)}
                openRx={editRxMode}
                onCloseRx={handleClose}
                useDialog={true}
                //  setUnsavedChanges={setUnsavedChanges}
              />
              //    <RxPage
              //    isEditing={true}
              //    selectedRow={selectedRow}
              //    patientValues={patientData}
              //    masterData={masterData}
              //    handleData={handleData}
              //    setPatientData={(row: any) => setPatientData(row)}
              //    openRx={editRxMode}
              //    onCloseRx={handleClose}
              //    useDialog={true}
              //  />
            )}
            {/* </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog> */}
            <Menu
              anchorEl={menu}
              open={Boolean(menu)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={handleCreateVirtualPrescription}
                disabled={selectedRow.isPerpetual}
              >
                Dauerrezept anlegen
              </MenuItem>
              {selectedRow?.deletedAt === null && (
                <MenuItem onClick={() => setShowDeleteWarning(true)}>Rezept löschen</MenuItem>
              )}
              {/* <MenuItem onClick={() => {
              handleRowClick(selectedRow);
              // openPatientTab(selectedRow);
            }}>
              Rx bearbeiten
          </MenuItem> */}
              <MenuItem
                onClick={() => {
                  setOpenTicket(!openTicket);
                  setMenu(null);
                }}
              >
                Ticket erstellen
              </MenuItem>
              {<MenuItem onClick={handleShowAppointment}>Termin Ausdrucken</MenuItem>}
              {showDialogBoxAppointment && (
                <DialogBoxAppointment
                  id={selectedRow["rxNumber"]}
                  openDialog={showDialogBoxAppointment}
                  handleOnClose={handleHideAppointment}
                  single={true}
                  tarifRx={tarifGroupRx}
                  rxDate={rxDate}
                />
              )}
              {/* {<MenuItem onClick={handleOpenTransactionType}>Zahlen</MenuItem>} */}
              {
                <MenuItem
                  onClick={() => {
                    if (handleDoctorReportView) {
                      handleDoctorReportView(selectedRow.id);
                    }
                  }}
                >
                  Arztbericht hinzufügen
                </MenuItem>
              }
              {
                <AlertDialogSlide
                  open={openTransactionType}
                  handleClose={handleCloseTransactionType}
                  patientData={patientData}
                  selectedRow={selectedRow}
                />
              }

              {/* Add more menu items as needed */}
            </Menu>
            {unsavedDeleteWarning && (
              <WarningDialog
                open={true} // Ensure the warning dialog is open
                onClose={handleCloseDeleteWarning} // Function to close the dialog
                onContinue={handleDeleteContinue} // Function to continue despite unsaved changes
                title="Sind Sie sicher, dass Sie das Rezept löschen möchten?"
                content="Klicken Sie bitte auf Bestätigen, um das Rezept zu löschen."
              />
            )}
            {openTicket && (
              <TicketForm
                openTicket={openTicket}
                onCloseTicketForm={handleCloseTicketForm}
                data={{
                  rxId: selectedRow.id,
                  rx: selectedRow,
                  patientId: patientData.id,
                  patient: patientData,
                }}
              />
            )}
          </Grid>
        )}
        {unsavedChangesWarning && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
            onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
            title="Änderungen speichern?"
            content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
            isConfirmSave
          />
        )}
      </Box>
    );
  }
);

export default RxOverView;
