import React, { useEffect, useState } from "react";
import { TextField, Grid, FormControl, InputLabel, Switch, FormControlLabel, Card, Typography } from "@mui/material";
import moment from "moment";
import { DoctorReportDto } from "../../../../api-client";
import { DatePicker } from "@mui/x-date-pickers";

interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: any;
  editMode?: boolean;
}

const PrintingAndShippingProtocolCard = ({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps) => {
  const [showModal, setShowModal] = useState(false);
  const [processingDate, setProcessingDate] = useState<string>("");
  const [printedAt, setPrintedAt] = useState<string>("");
  const [sentByEmailAt, setSentByEmailAt] = useState<string>("");
  const [sentByEmailAtTime, setSentByEmailAtTime] = useState<string>("");
  const [sentByFaxAt, setSentByFaxAt] = useState<string>("");
  const [sentByFaxAtTime, setSentByFaxAtTime] = useState<string>("");
  const [IsPrintedAtDisabled, setPrintedAtDisabled] = useState<boolean>(true);
  const [IsSentByFaxAtDisabled, setSentByFaxAtDisabled] = useState<boolean>(true);
  const [IsSentByEmailDisabled, setSentByEmailDisabled] = useState<boolean>(true);

  const [dateTimePresentationProp, setDateTimePresentationProp] = useState("date");
  const [currentInput, setCurrentInput] = useState<
    | "processingDate"
    | "printedAt"
    | "sentByEmailAt"
    | "sentByEmailAtTime"
    | "sentByFaxAt"
    | "sentByFaxAtTime"
    | null
  >(null);
  const dateTimeFormat = "dd.MM.yyyy";

  useEffect(() => {
    if (doctorReport) {
      if (doctorReport.processingDate)
        setProcessingDate(moment(doctorReport.processingDate).format(dateTimeFormat));
      if (doctorReport.printedAt) {
        setPrintedAt(moment(doctorReport.printedAt).format(dateTimeFormat));
        setPrintedAtDisabled(false);
      }

      if (doctorReport.sentByEmailAt) {
        setSentByEmailAt(moment(doctorReport.sentByEmailAt).format(dateTimeFormat));
        setSentByEmailAtTime(moment(doctorReport.sentByEmailAt).format("HH:mm"));
        setSentByEmailDisabled(false);
      }

      if (doctorReport.sentByFaxAt) {
        setSentByFaxAt(moment(doctorReport.sentByFaxAt).format(dateTimeFormat));
        setSentByFaxAtTime(moment(doctorReport.sentByFaxAt).format("HH:mm"));
        setSentByFaxAtDisabled(false);
      }
    }
  }, [doctorReport]);

  const handleChangeDoctorReport = (key: string, value: any) => {
    let updatedDoctorReport = Object.assign(doctorReport, {
      [key]: value,
    });
    setDoctorReport(updatedDoctorReport);
  };

  const handleDateChange = (dateTime: string) => {
    const formattedDate = moment(dateTime).format("YYYY-MM-DD");
    const formattedTime = moment(dateTime).format("HH:mm");

    switch (currentInput) {
      case "processingDate":
        setProcessingDate(formattedDate);
        handleChangeDoctorReport("processingDate", formattedDate);
        break;
      case "printedAt":
        setPrintedAt(formattedDate);
        handleChangeDoctorReport("printedAt", formattedDate);
        break;
      case "sentByEmailAt": {
        let combinedEmailAtDateTime = sentByEmailAtTime
          ? moment(`${formattedDate} ${sentByEmailAtTime}`, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : formattedDate;

        setSentByEmailAt(formattedDate);
        handleChangeDoctorReport("sentByEmailAt", combinedEmailAtDateTime);
        break;
      }
      case "sentByEmailAtTime": {
        setSentByEmailAtTime(formattedTime);
        const currentDate = moment().format("YYYY-MM-DD");

        const combinedEmailAtDateTime = moment(
          `${sentByEmailAt || currentDate} ${formattedTime}`,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");

        handleChangeDoctorReport("sentByEmailAt", combinedEmailAtDateTime);

        if (!sentByEmailAt) setSentByEmailAt(currentDate);
        break;
      }
      case "sentByFaxAt": {
        let combinedFaxAtDateTime = sentByFaxAtTime
          ? moment(`${formattedDate} ${sentByFaxAtTime}`, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : formattedDate;

        setSentByFaxAt(formattedDate);
        handleChangeDoctorReport("sentByFaxAt", combinedFaxAtDateTime);
        break;
      }
      case "sentByFaxAtTime":
        setSentByFaxAtTime(formattedTime);
        const currentDate = moment().format("YYYY-MM-DD");

        const combinedFaxAtDateTime = moment(
          `${sentByFaxAt || currentDate} ${formattedTime}`,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");

        handleChangeDoctorReport("sentByFaxAt", combinedFaxAtDateTime);

        if (!sentByFaxAt) setSentByFaxAt(currentDate);
        break;
    }

    if (dateTimePresentationProp !== "time") setShowModal(false);
  };
  const isCardDisable = true;

  return (
    <Card>
      <Typography  sx={{
              fontWeight: "bold",
              textDecoration: "underline",
            }}>Druck-und Versandprotokoll</Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
        <InputLabel>Bearbeitungsdatum</InputLabel>
          <DatePicker
            label=""
            value={processingDate ? moment(processingDate, dateTimeFormat).toDate() : null}
            onChange={(date: any) => {
              setCurrentInput("processingDate");
              setDateTimePresentationProp("date");
              handleDateChange(date);
            }}
            format={dateTimeFormat}
            disabled={isCardDisable}
          />
        </Grid>

        <Grid item xs={6}>
          {/* <FormControl fullWidth> */}
            <InputLabel>Gedruckt am</InputLabel>
            <DatePicker
              label=""
              value={printedAt ? moment(printedAt, dateTimeFormat).toDate() : null}
              onChange={(date: any) => {
                setCurrentInput("printedAt");
                setDateTimePresentationProp("date");
                handleDateChange(date);
              }}
              format={dateTimeFormat}
              disabled={isCardDisable || IsPrintedAtDisabled}
            />
          {/* </FormControl> */}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>per Fax</InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="am"
                value={sentByFaxAt ? moment(sentByFaxAt, dateTimeFormat).toDate() : null}
                onChange={(date: any) => {
                  setCurrentInput("sentByFaxAt");
                  setDateTimePresentationProp("date");
                  handleDateChange(date);
                }}
                format={dateTimeFormat}
                disabled={isCardDisable || IsSentByFaxAtDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="um"
                value={sentByFaxAtTime ? moment(sentByFaxAtTime, dateTimeFormat).toDate() : null}
                onChange={(date: any) => {
                  setCurrentInput("sentByFaxAtTime");
                  setDateTimePresentationProp("date");
                  handleDateChange(date);
                }}
                format={dateTimeFormat}
                disabled={isCardDisable || IsSentByFaxAtDisabled}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel>per Email</InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="am"
                value={sentByEmailAt ? moment(sentByEmailAt, dateTimeFormat).toDate() : null}
                onChange={(date: any) => {
                  setCurrentInput("sentByEmailAt");
                  setDateTimePresentationProp("date");
                  handleDateChange(date);
                }}
                format={dateTimeFormat}
                disabled={isCardDisable || IsSentByEmailDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {" "}
              {/* Each DatePicker takes half the width */}
              <DatePicker
                label="um"
                value={
                  sentByEmailAtTime ? moment(sentByEmailAtTime, dateTimeFormat).toDate() : null
                }
                onChange={(date: any) => {
                  setCurrentInput("sentByEmailAtTime");
                  setDateTimePresentationProp("date");
                  handleDateChange(date);
                }}
                format={dateTimeFormat}
                disabled={isCardDisable || IsSentByEmailDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default PrintingAndShippingProtocolCard;
