import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import InsurancePageLayout from "../../Insurance/Base/pages/InsurancePageLayout";
import EuroIcon from "@mui/icons-material/Euro";
import PaymentsIcon from "@mui/icons-material/Payments";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BusinessIcon from "@mui/icons-material/Business";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TransactionsDisplayContainer from "../../Kasse/components/TransactionsDisplayContainer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { recnungTransactionMethods, transactionMethods } from "../../Kasse/types/kasse.types";

export default function TransactionPage() {
  const [selectedTabs, setSelectedTabs] = useState(1);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTabs(newValue);
  };

  return (
    <div style={{ margin: "5px" }}>
      <Grid
        item
        xs={12}
        md
        sx={{ border: "0px solid" }}
      >
        <Tabs
          orientation="horizontal"
          value={selectedTabs}
          onChange={handleTabChange}
          aria-label="sub-tabs"
        >
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                CASH
              </span>
            }
            icon={
              <PaymentsIcon
                sx={{
                  fontWeight: selectedTabs === 1 ? "bold" : "normal",
                }}
              />
            }
            value={1}
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                EC CASH
              </span>
            }
            value={2}
            icon={
              <EuroIcon
                sx={{
                  fontWeight: selectedTabs === 2 ? "bold" : "normal",
                }}
              />
            }
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                LASTSCHRIFT
              </span>
            }
            value={3}
            icon={
              <AccountBalanceWalletIcon
                sx={{
                  fontWeight: selectedTabs === 3 ? "bold" : "normal",
                }}
              />
            }
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                BANK TRANSFER
              </span>
            }
            value={4}
            icon={
              <AssuredWorkloadIcon
                sx={{
                  fontWeight: selectedTabs === 3 ? "bold" : "normal",
                }}
              />
            }
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                OFFENE POSTEN
              </span>
            }
            value={5}
            icon={
              <ReceiptIcon
                sx={{
                  fontWeight: selectedTabs === 4 ? "bold" : "normal",
                }}
              />
            }
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                ABRECHNUNG
              </span>
            }
            value={6}
            icon={
              <BusinessIcon
                sx={{
                  fontWeight: selectedTabs === 5 ? "bold" : "normal",
                }}
              />
            }
          />
        </Tabs>
      </Grid>
      {(() => {
        switch (selectedTabs) {
          case 1:
            return <TransactionsDisplayContainer transactionMethod={transactionMethods.CASH} />;
          case 2:
            return <TransactionsDisplayContainer transactionMethod={transactionMethods.EC} />;
          case 3:
            return (
              <TransactionsDisplayContainer transactionMethod={transactionMethods.LASTSCHRIFT} />
            );
          case 4:
            return (
              <TransactionsDisplayContainer
                transactionMethod={recnungTransactionMethods.BANK_TRANSFER}
              />
            );
          case 5:
            return (
              <TransactionsDisplayContainer
                transactionMethod={recnungTransactionMethods.RECHNUNG}
              />
            );
          case 6:
            return <InsurancePageLayout />;
          default:
            return null;
        }
      })()}
    </div>
  );
}
