import React, { useEffect, useState } from 'react';
import { Drawer, Typography, IconButton, Divider, Grid, Tab, Box, SwipeableDrawer, Button, Menu, MenuItem } from "@mui/material";
import { AppointmentDto, PatientDto, TimeSlotDto } from '../../api-client';
import PatientOverview from '../../components/PatientOverview/PatientOverview';
import { DrawerHeader } from '../../components/Appbar';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { mobiliTheme } from '../../themes/mobiliTheme';
import TerminView from '../../components/PatientOverview/RxInfo/TerminView';
import { TerminViewConstants } from '../../components/PatientOverview/TerminView';
import { TherapyFrequencyExt } from './Calendar.type';
import { observer } from 'mobx-react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PushPin } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

interface CalendarDrawerProps {
  open: boolean;
  patient: PatientDto;
  targetPatient: PatientDto;
  appointments: AppointmentDto[];
  drawerWidth: number;
  selectedTab?: string;
  showOpenButton?: boolean;
  onClose: () => void;
  onOpen: () => void;
  onPatientTabChange?: (newValue: string) => void;
  onTargetPatientChange?: (patient: PatientDto) => void;
  setDataRefreshFlag: (flag: any) => void;
}


const CalendarDrawer: React.FC<CalendarDrawerProps> = observer(({ 
  open,
  patient,
  targetPatient,
  appointments,
  drawerWidth,
  selectedTab,
  showOpenButton,
  onOpen,
  onClose,
  onPatientTabChange,
  onTargetPatientChange,
  setDataRefreshFlag
 }) => {

  const [tabValue, setTabValue] = useState('');
  const [tabValuePatient, setTabValuePatient] = useState('');
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    patientId: number;
  } | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const pid = new URLSearchParams(location.search).get('pid');

  const updateQueryParams = (key: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    if (appointments.length > 0) {
      setTabValue(selectedTab ?? appointments[appointments.length - 1].timeSlots![0].therapyRx?.rx?.id!);
    }
    setTabValuePatient(patient?.id?.toString() ?? '');
    if (pid) {
      console.log(`Fetching data for pid: ${pid}`);
    }
  }
  , [appointments, pid, patient]);

  const groupTimeSlotsByRx = (appointments: AppointmentDto[]) => {
    const groupedTimeslots: Record<string, TimeSlotDto[]> = {};

    appointments.sort((a, b) => {
      const aRxNumber = a.timeSlots?.[0].therapyRx?.rx?.rxNumber ?? "";
      const bRxNumber = b.timeSlots?.[0].therapyRx?.rx?.rxNumber ?? "";
      const [aMain, aSub] = aRxNumber.split("-").map(Number);
      const [bMain, bSub] = bRxNumber.split("-").map(Number);
      return aMain - bMain || aSub - bSub;
    }).forEach(appointment => {
      appointment.timeSlots?.forEach(timeSlot => {
        const key = timeSlot.therapyRx?.rx?.id!;
        if (!groupedTimeslots[key]) {
          groupedTimeslots[key] = [];
        }
        groupedTimeslots[key].push(timeSlot);
      });
    });
    
    console.log('Grouped Timeslots:', groupedTimeslots);
    return groupedTimeslots;
  };

  const groupedTimeslots = groupTimeSlotsByRx(appointments);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  }

  const handleTargetPatientChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValuePatient(newValue);
    return onPatientTabChange && onPatientTabChange(newValue);
  };

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>, patientId: number) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      patientId: patientId,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handlePinToggle = (index: number) => {
    updateQueryParams('pid', index.toString());
    setContextMenu(null);
    return onTargetPatientChange && onTargetPatientChange(patient);
  };

  return (
    <>
      {showOpenButton && !open && 
        <IconButton 
          onClick={onOpen}
          sx={{position: 'fixed', left: 5, zIndex: 1200}}>
          <ArrowForwardIosIcon />
        </IconButton>
      }
      <Drawer 
        open={open}
        onClose={onClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: `${mobiliTheme.topLevelBackground}`,
            marginLeft: 5, // 40px is the width of the side menu
            marginTop: 8, // 64px is the height of the appbar
          },
        }}
        variant="persistent"
      >
        <Grid padding={1} >
          {patient && (
            <Typography>
              <DrawerHeader sx={{minHeight: '0 !important', justifyContent: 'space-between'}}>
                <TabContext value={tabValuePatient}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTargetPatientChange} variant='scrollable' scrollButtons='auto'>
                      {targetPatient && 
                      <Tab value={targetPatient?.id?.toString()} key={targetPatient?.id} 
                        label={<span>{targetPatient.lastName}
                          <PushPin  sx={{ 
                            fontSize: '0.75rem',
                            position: 'absolute',
                            top: 3,
                            right: 3,
                           }}
                          /></span>} 
                      />}
                      {patient.id !== targetPatient?.id && 
                      <Tab value={patient.id?.toString()} key={patient.id} label={patient.lastName} onContextMenu={(event) => handleRightClick(event, patient.id!)}/>}
                    </TabList>
                    {/* Context Menu */}
                    <Menu
                      open={contextMenu !== null}
                      onClose={handleClose}
                      anchorReference="anchorPosition"
                      anchorPosition={
                        contextMenu !== null
                          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                          : undefined
                      }
                    >
                      <MenuItem onClick={() => handlePinToggle(patient.id!)}>
                        Pin Patient
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        Close
                      </MenuItem>
                    </Menu>
                  </Box>
                </TabContext>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </DrawerHeader>
              <PatientOverview 
                patientData={patient} 
                onRowClick={() => {}} 
                setPatientData={() => {}} 
                handleData={() => {}}
              />
              <Box marginTop={1} marginBottom={8}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} variant='scrollable' scrollButtons='auto'>
                      {groupedTimeslots && Object.keys(groupedTimeslots).map((key, index) => (
                        <Tab value={key} key={index} label={
                          `${groupedTimeslots[key][0].therapyRx?.rx?.rxNumber}
                          (${groupedTimeslots[key][0].therapyRx?.frequency ? (groupedTimeslots[key][0].therapyRx?.frequency as TherapyFrequencyExt)?.text: '?'}x)`
                        } />
                      ))}
                    </TabList>
                  </Box>
                  {groupedTimeslots && Object.keys(groupedTimeslots).map((key, index) => (
                    <TabPanel value={key} sx={{ backgroundColor: mobiliTheme.cardBackground }}>
                      <TerminView
                        appointments={appointments.filter(app => app.timeSlots?.find(slot => slot.therapyRx?.rx?.id === key))}
                        setDataRefreshFlag={setDataRefreshFlag}
                        dataRefreshFlag={undefined}
                        patientId={patient.id}
                        blockedData={[]}
                        setblockedData={undefined}
                        patientAvailabilityData={[]}
                        setPatientAvailability={undefined}
                        isView={TerminViewConstants.TERMIN_VIEW}
                      />
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
              <Divider />
            </Typography>
          )}
        </Grid>
      </Drawer>
    </>
    
  );
})

export default CalendarDrawer;
