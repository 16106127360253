import React, { FC, useRef } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
// import BreadCrumbs from '../../components/utils/breadcrumbs/BreadCrumbs';

interface ILayout {
  title: string;
  children?: React.ReactNode;
}

export const Layout: FC<ILayout> = ({ title, children }) => {
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);

  const handleSearchClick = () => {
    navigate('/pages/search');
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" height="100vh">
    

        {/* Breadcrumbs */}
        {/* <BreadCrumbs /> */}

        {/* Main Content */}
        <Box
          ref={contentRef}
          flexGrow={1}
          overflow="auto"
          padding={2}
          bgcolor="background.default"
        >
          {children}
        </Box>

        {/* Footer with Scroll Buttons (Optional) */}
        {/* <Box display="flex" justifyContent="space-between" padding={1} bgcolor="grey.100">
          <Button variant="contained" onClick={scrollToTop}>
            Scroll to Top
          </Button>
          <Button variant="contained" onClick={scrollToBottom}>
            Scroll to Bottom
          </Button>
        </Box> */}
      </Box>
    </>
  );
};
