import { useLocalObservable } from "mobx-react-lite";
import React, { createContext, ReactNode } from "react";
import { Configuration, DocumentationApi, PatientsApi } from "../../api-client";
import { AuthContext } from "../Auth/auth.provider";
import useStore from "../../helpers/useStore";
import DocumentationStore from "./DocumentationStore";

interface DocumentationProviderProps {
  children: ReactNode;
}

export const DocumentationContext = createContext<DocumentationStore | null>(
  null
);

export const DocumentationProvider = ({
  children,
}: DocumentationProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL;
  const AuthenticationStore = useStore(AuthContext);
  const { authToken } = AuthenticationStore;

  const config = new Configuration({
    basePath: basePath,
    accessToken: authToken || "",
  });

  const apiClient = new DocumentationApi(config);

  const store = useLocalObservable(
    () => new DocumentationStore(apiClient, AuthenticationStore)
  );

  store.apiClient = apiClient;

  return (
    <DocumentationContext.Provider value={store}>
      {children}
    </DocumentationContext.Provider>
  );
};

export default DocumentationProvider;
