import React, { FC, useEffect, useState } from "react";
import { TopLevelPaper } from "../../../../themes/StyledComponents";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Button, Grid, Paper, TableContainer, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { AllTransactionMethods } from "../types/kasse.types";
import useStore from "../../../../helpers/useStore";
import { TransactionsContext } from "../../../../stores/Transactions/Transactions.provider";
import { useTransaction } from "../hooks/useTransaction";
import { InsuranceBillsPagination } from "../../Insurance/Base/components/InsuranceBillsPagination";
import TransactionTable from "./TransactionTable";
import { UserContext } from "../../../../stores/User/User.provider";
import { UserDto } from "../../../../api-client";

export const commonStyles = {
  bgcolor: "background.paper",
  border: 0,
  my: 1,
};

interface TransactionsDisplayContainerProps {
  transactionMethod: AllTransactionMethods;
}

const TransactionsDisplayContainer: FC<TransactionsDisplayContainerProps> = ({
  transactionMethod,
}) => {
  const transactionStore = useStore(TransactionsContext);
  const { getAllBankTransfer, getAllRechnung, getTransactionsByType, lastUpdate } =
    transactionStore;

  const UserStore = useStore(UserContext);
  const [user, setUser] = useState<UserDto | null>();

  useEffect(() => {
    UserStore.getUser()
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  }, [UserStore]);

  const accountId = user?.location?.id;

  const {
    paginatedData,
    allTransaction,
    page,
    setPage,
    limit,
    setLimit,
    searchValue,
    setSearchValue,
    handleStartDateChange,
    handleEndDateChange,
    currentRange,
  } = useTransaction({
    getAllBankTransfer,
    getAllRechnung,
    getTransactionsByType,
    transactionMethod,
    lastUpdate,
    accountId,
  });

  return (
    <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container>
          <Grid
            item
            xs={6}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: 60 }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="Suche nach Transaktionsnummer oder Betrag"
                  size="small"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  sx={{
                    height: 40,
                    minWidth: 300,
                  }}
                />

                <Button
                  onClick={() => {}}
                  sx={{
                    marginTop: 0.5,
                    height: 40,
                    minWidth: 80,
                  }}
                >
                  Neu
                </Button>
              </Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "1.6rem",
                  color: "#fff",
                  ml: "auto",
                }}
              >
                Kasse
              </Typography>
            </Box>
          </Grid>

          {/* Right side: Edit selected, date pickers */}
          <Grid
            item
            xs={6}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={2}
              sx={{ height: 60 }}
            >
              {/* Date Pickers */}
              <DatePicker
                label="Startdatum"
                value={currentRange.startDate}
                defaultValue={dayjs()}
                onChange={(date) => handleStartDateChange(date)}
                maxDate={currentRange.endDate || dayjs()}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
              <DatePicker
                label="Enddatum"
                value={currentRange.endDate}
                defaultValue={dayjs()}
                onChange={(date) => handleEndDateChange(date)}
                minDate={currentRange.startDate || dayjs()}
                maxDate={dayjs()}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {allTransaction.length > 0 ? (
          <>
            <TransactionTable
              transactions={allTransaction}
              transactionMethod={transactionMethod}
            />

            {paginatedData && paginatedData.meta.totalItems > 0 && (
              <InsuranceBillsPagination
                totalItems={paginatedData.meta.totalItems}
                totalPages={paginatedData.meta.totalPages}
                limit={limit}
                page={page}
                onPageChange={setPage}
                onLimitChange={(newLimit) => {
                  setLimit(newLimit);
                  setPage(1);
                }}
              />
            )}
          </>
        ) : (
          <Box
            sx={{
              ...commonStyles,
              maxHeight: "70vh",
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ flex: 1, overflow: "auto" }}
            >
              <Typography
                margin={2}
                variant="subtitle1"
              >
                Für den ausgewählten Status wurden keine Transaktionen gefunden.
              </Typography>
            </TableContainer>
          </Box>
        )}
      </LocalizationProvider>
    </TopLevelPaper>
  );
};

export default TransactionsDisplayContainer;
