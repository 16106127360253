import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, List, Typography, CircularProgress, Box } from "@mui/material";
import { CreateInitialEvaluationDto, GetInitialEvaluationDto } from "../../../api-client";
import useStore from "../../../helpers/useStore";
import { DocumentationContext } from "../../../stores/Documentation/Documentation.provider";
import InitialEvaluationCard from "./InitialEvaluationCard";
import { observer } from "mobx-react-lite";

interface InitialEvaluationListProps {
  patientId: number;
  limit: number;
}

const InitialEvaluationList=observer(({
  patientId,
  limit,
}: InitialEvaluationListProps)=> {
  const DocumentationStore = useStore(DocumentationContext);
  const {
    fetchInitialEvaluations,
    createInitialEvaluation,
    updateInitialEvaluation,
    resetInitialEvaulations,
    initialEvaluations,
  } = DocumentationStore;

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (patientId) {
      loadItems();
    }
  }, [patientId]);

  useEffect(() => {
    resetInitialEvaulations();
  }, []);

  const handleCreateInitialEvaluation = (initialEvaluation: CreateInitialEvaluationDto) => {
    createInitialEvaluation(initialEvaluation)
      .then(() => {})
      .catch((error) => {
        console.log("Error creating initial evaluation", error);
      });
  };

  const handleUpdateInitialEvaluation = (id: number, initialEvaluation: CreateInitialEvaluationDto) => {
    updateInitialEvaluation(id, initialEvaluation)
      .then(() => {})
      .catch((error) => {
        console.log("Error updating initial evaluation", error);
      });
  };

  const loadItems = async () => {
    try {
      setLoading(true);
      const response: GetInitialEvaluationDto[] = await fetchInitialEvaluations(patientId, limit, page);
      if (response.length) {
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <List>
        {initialEvaluations &&
          initialEvaluations.map((initialEvaluation) => (
            <InitialEvaluationCard
              patientId={patientId}
              key={initialEvaluation.id}
              initialEvaluation={initialEvaluation}
              onCreateInitialEvaluation={handleCreateInitialEvaluation}
              onUpdateInitialEvaluation={handleUpdateInitialEvaluation}
            />
          ))}
      </List>
      {hasMore && (
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" onClick={loadItems}>
              Load More
            </Button>
          )}
        </Box>
      )}
      {!hasMore && initialEvaluations.length === 0 && (
        <Typography variant="body1" align="center">
          No initial evaluations available.
        </Typography>
      )}
    </Box>
  );
});

export default InitialEvaluationList;
