// helper functions for schedule-based stats
import { startOfWeek, addWeeks, isEqual, isBefore } from 'date-fns';
import { ScheduleBasedStatsDto } from '../../../api-client';

interface GroupedData {
    [locationId: string]: ScheduleBasedStatsDto[]
}

interface TotalCountData {
    locationId: string, 
    date: Date, 
    totalCount: number
}

// function to calculate total number of Rxs by bin (week or day)
export const calculateScheduleBasedTotalRx = (scheduleData: ScheduleBasedStatsDto[], binType: 'week' | 'day'): TotalCountData[] => {
    // Array to store the results
    const totalCountData: TotalCountData[] = [];

    // Group by location
    const groupedData: GroupedData = Object.groupBy(scheduleData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        

        if (binType === 'week') {
            // generate the bins
            let currentBinStart: Date;
            let bins: Date[] = [];

            const firstEntryDate = new Date(groupedData[locationId][0].date);
            const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].date);

            currentBinStart = startOfWeek(firstEntryDate);
            bins.push(currentBinStart);

            for (let i = 1; isBefore(currentBinStart, lastEntryDate); i++) {
                currentBinStart = addWeeks(currentBinStart, 1); // to get next week
                bins.push(currentBinStart);
            }

            let nextBinStart: Date;
            bins.forEach((binStart) => {
                nextBinStart = addWeeks(binStart, 1);
                        
                const total = groupedData[locationId].filter(entry => 
                    new Date(entry.date) >= binStart && new Date(entry.date) < nextBinStart
                ).length;
            
                totalCountData.push({ 
                    locationId: locationId, 
                    date: binStart, 
                    totalCount: total 
                });
            })
        } else {
            // get unique dates
            const bins = [
                ...new Set(groupedData[locationId].map((entry) => new Date(entry.date).toISOString().split('T')[0]))
            ];

            bins.forEach((date) => {
                const total = groupedData[locationId].filter((entry) => new Date(entry.date).toISOString().split('T')[0] === date
                ).length;
            
                totalCountData.push({ 
                    locationId: locationId, 
                    date: new Date(date), 
                    totalCount: total 
                });
            });
        }
    });
    return totalCountData;
}


interface TotalValueData {
    locationId: string, 
    date: Date, 
    totalValue: number
}

// function to calculate total appointment value by bin (week or day)
export const calculateScheduleBasedTotalApptValue = (scheduleData: ScheduleBasedStatsDto[], binType: 'week' | 'day'): TotalValueData[] => {
    // Array to store the results
    const totalValueData: TotalValueData[] = [];

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(scheduleData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        // Generate bins
        let currentBinStart: Date;
        let bins: Date[] = [];

        const firstEntryDate = new Date(groupedData[locationId][0].date);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].date);

        if (binType === 'week') {
            currentBinStart = startOfWeek(firstEntryDate);
            bins.push(currentBinStart);

            // Generate bins for the following weeks
            for (let i = 1; isBefore(currentBinStart, lastEntryDate); i++) {
                currentBinStart = addWeeks(currentBinStart, 1); // to get next week
                bins.push(currentBinStart);
            }

            let nextBinStart: Date;
            bins.forEach((binStart) => {
                nextBinStart = addWeeks(binStart, 1);
            
                // calculate total value in current bin
                const total = groupedData[locationId].filter(entry => 
                    new Date(entry.date) >= binStart && new Date(entry.date) < nextBinStart
                ).reduce((total, entry) => total + entry.value, 0);

                totalValueData.push({
                    locationId: locationId,
                    date: binStart,
                    totalValue: total,
                });
            });   
        } else {
            const bins = [
                ...new Set(groupedData[locationId].map((entry) => new Date(entry.date).toISOString().split('T')[0]))
            ];

            bins.forEach((date) => {
                const total = groupedData[locationId].filter((entry) => new Date(entry.date).toISOString().split('T')[0] === date
                ).reduce((total, entry) => total + entry.value, 0);
            
                totalValueData.push({ 
                    locationId: locationId, 
                    date: new Date(date), 
                    totalValue: total 
                });
            });
        }
    });
    return totalValueData;
}


interface TotalGapData {
    locationId: string, 
    date: Date, 
    totalGaps: number
}

// function to calculate total number of gaps by bin (week or day)
export const calculateScheduleBasedTotalGaps = (scheduleData: ScheduleBasedStatsDto[], binType: 'week' | 'day'): TotalGapData[] => {
    // Array to store the results
    const totalGapsData: TotalGapData[] = [];

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(scheduleData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        // Generate bins
        let currentBinStart: Date;
        let bins: Date[] = [];

        const firstEntryDate = new Date(groupedData[locationId][0].date);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].date);

        if (binType === 'week') {
            currentBinStart = startOfWeek(firstEntryDate);
            bins.push(currentBinStart);

            // Generate bins for the following weeks
            for (let i = 1; isBefore(currentBinStart, lastEntryDate); i++) {
                currentBinStart = addWeeks(currentBinStart, 1); // to get next week
                bins.push(currentBinStart);
            }

            let nextBinStart: Date;
            bins.forEach((binStart) => {
                nextBinStart = addWeeks(binStart, 1);
            
                // calculate total gaps in current bin
                const total = groupedData[locationId].filter(entry => 
                    new Date(entry.date) >= binStart && new Date(entry.date) < nextBinStart
                ).reduce((total, entry) => total + entry.gaps, 0);

                totalGapsData.push({
                    locationId: locationId,
                    date: binStart,
                    totalGaps: total,
                });
            });
        } else {
            const bins = [
                ...new Set(groupedData[locationId].map((entry) => new Date(entry.date).toISOString().split('T')[0]))
            ];

            bins.forEach((date) => {
                const total = groupedData[locationId].filter((entry) => new Date(entry.date).toISOString().split('T')[0] === date
                ).reduce((total, entry) => total + entry.gaps, 0);
            
                totalGapsData.push({ 
                    locationId: locationId, 
                    date: new Date(date), 
                    totalGaps: total 
                });
            });
        }
    });
    return totalGapsData;
}