import { action, makeObservable, observable, reaction } from "mobx";
import {
  CreateTreatmentNoteDto,
  CreateInitialEvaluationDto,
  TreatmentNoteDTO,
  GetInitialEvaluationDto,
  DocumentationApi,
  TimeSlotDto,
  DoctorReportDto,
  CreateDoctorReportDto,
  DoctorReportTimeSlotDto,
  DoctorReportStausDto,
} from "../../api-client";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";

export default class  DocumentationStore {
  @observable initialEvaluations: GetInitialEvaluationDto[] = [];

  public apiClient: DocumentationApi;
  public authStore: AuthStore;
  // public doctorReports:DoctorReportDto[];

  public constructor(apiClient: DocumentationApi, authStore: AuthStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.authStore = authStore;
  }

  @action
  public getTreatmentNotes = async (patientId: number, limit: number, page: number) => {
    const { data } = (await this.apiClient.getTreatmentNotes(patientId, {
      params: { limit, page },
    })) as any;
    return data || [];
  };

  @action
  public fetchInitialEvaluations = async (patientId: number, limit: number, page: number) => {
    try {
      const response = await this.apiClient.getInitialEvaluation(patientId, {
        params: { limit, page },
      });
      const data = response.data as GetInitialEvaluationDto[];

      // this.initialEvaluations = [...this.initialEvaluations, ...data];
      this.initialEvaluations.push(...data);
      return data;
    } catch (error: any) {
      throw error;
    }
  };

  @action
  public getDoctorReport = async (id: string) => {
    const response = await this.apiClient.getDoctorReport(id);
    const data = response.data as DoctorReportDto;
    return data || [];
  };

  @action
  public getDoctorReportByRxId = async (rxId: string) => {
    const response = await this.apiClient.getDoctorReportByRxId(rxId);
    const data = response.data as DoctorReportDto;
    return data || [];
  };

  @action
  public getDoctorReportsForPatient = async (patientId: number) => {
    const response = await this.apiClient.getDoctorReportsForPatient(patientId);
    const data = response.data as unknown as DoctorReportDto[];
    return data || [];
  };

  @action
  public getDoctorReportFirstAndLastTimeSlot = async (rxId: string) => {
    const response = await this.apiClient.getFirstAndLastTimeSlot(rxId);
    const data = response.data as DoctorReportTimeSlotDto;
    return data || [];
  };

  @action
  public getDoctorReportStatus = async (rxId: string) => {
    const response = await this.apiClient.getDoctorReportStatus(rxId);
    const data = response.data as DoctorReportStausDto;
    return data;
  };

  @action
  public createInitialEvaluation = async (initialEvaluation: CreateInitialEvaluationDto) => {
    try {
      const response = await this.apiClient.createInitialEvaluation(initialEvaluation);
      const data = response.data as GetInitialEvaluationDto;
      this.initialEvaluations = [data, ...this.initialEvaluations];
      return data;
    } catch (error: any) {
      notificationStore.showMessage(error?.message, 'error',error);
      throw error;
    }
  };

  @action
  public createTreatmentNote = async (createTreatmentDto: CreateTreatmentNoteDto) => {
    try {
      const response = await this.apiClient.createTreatmentNote(createTreatmentDto);
      const data = response.data as TreatmentNoteDTO;

      return data;
    } catch (error: any) {
      console.log(error);
      notificationStore.showMessage(error?.message, 'error',error);

      throw error;
    }
  };

  @action
  public createDoctorReport = async (doctorReport: CreateDoctorReportDto) => {
    try {
      const response = await this.apiClient.postDoctorReport(doctorReport);
      const data = response.data as DoctorReportDto;
      notificationStore.showMessage("Arztbericht erfolgreich gespeichert", "success"); // Success feedback

      return data;
    } catch (error: any) {
      console.log(error);
      notificationStore.showMessage(error?.message, 'error',error);
      throw error;
    }
  };

  @action
  public updateInitialEvaluation = async (
    id: number,
    initialEvaluation: CreateInitialEvaluationDto
  ) => {
    try {
      const response = await this.apiClient.updateInitialEvaluation(id, initialEvaluation);
      const data = response.data as GetInitialEvaluationDto;
      const index = this.initialEvaluations.findIndex((ie) => ie.id == id);
      if (index > -1) {
        this.initialEvaluations[index] = data;
      }
      return data;
    } catch (error: any) {
      notificationStore.showMessage(error?.message, 'error',error);
      throw error;
    }
  };

  @action
  public updateTreatmentNote = async (id: number, createTreatmentDto: CreateTreatmentNoteDto) => {
    try {
      const response = await this.apiClient.updateTreatmentNote(id, createTreatmentDto);
      const data = response.data as TreatmentNoteDTO;

      return data;
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  };

  @action
  public updateDoctorReport = async (id: string, doctorReportDto: CreateDoctorReportDto) => {
    try {
      const response = await this.apiClient.updateDoctorReport(id, doctorReportDto);
      const data = response.data as DoctorReportDto;
      notificationStore.showMessage("Arztbericht erfolgreich heruntergeladen.","success")

      return data;
    } catch (error: any) {
      console.log(error);
      notificationStore.showMessage(error?.message, 'error',error);

      throw error;
    }
  };

  @action
  public requestDoctorReport = async (reportId:string) => {
    try{
    const { data } = (await this.apiClient.requestDoctorReport(reportId)) as any;
    return data || [];}
    catch (error:any) {
      console.log(error);
      notificationStore.showMessage(error?.message, 'error',error);

      throw error;
      
    }

  }

  @action
  public sendDoctorReportByEmail = async (reportId:string) => {
    try{
    const { data } = (await this.apiClient.sendDoctorReportByEmail(reportId)) as any;
    notificationStore.showMessage("Doctor Report sent successfully!!","success")
    return data || [];}
    catch (error:any) {
      console.log(error);
      notificationStore.showMessage(error?.message, 'error',error);

      throw error;
      
    }

  }

  @action
  public updateDoctorReportStatus = async (
    id: string,
    doctorReportStausDto: DoctorReportStausDto
  ) => {
    try {
      const response = await this.apiClient.updateDoctorReportStatus(id, doctorReportStausDto);
      notificationStore.showMessage("Status des Arztberichts erfolgreich aktualisiert!","success")
      const data = response.data as DoctorReportStausDto;

      return data;
    } catch (error: any) {
      console.log(error);
      notificationStore.showMessage("Der Status des Arztberichts konnte nicht aktualisiert werden!","error")

      throw error;
    }
  };

  public resetInitialEvaulations = () => {
    this.initialEvaluations = [];
  };
}
