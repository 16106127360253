import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { JsonMui } from "../../atoms/Json";
import { LineChart } from "@mui/x-charts";
import CumulativePatientsPlot from "./components/CumulativePatientsPlot";
import NewPatientsPlot from "./components/NewPatientsPlot";
import NewRxCountsPlot from "./components/NewRxCountsPlot";
import RxValuesPlot from "./components/RxValuesPlot";
import ScheduleBasedGapsPlot from "./components/ScheduleBasedGapsPlot";
import TherapistCollapsibleTable from "./components/TherapistTable";
import usePatientStats from "./hooks/usePatientStats";
import { useState } from "react";
import useRxStats from "./hooks/useRxStats";
import RxCountsPlot from "./components/RxCountsPlot";
import useScheduleBasedStats from "./hooks/useScheduleBasedStats";
import useTherapistStats from "./hooks/useTherapistStats";
import ScheduleBasedApptValuesPlot from "./components/ScheduleBasedApptValuesPlot";
import BinTypeSelector from "./components/BinTypeSelector";
import BIDateRangePicker from "./components/DateRangePicker";


export const BIDashboardPage = () => {
  const [patientBinType, setPatientBinType] = useState<'week' | 'month'>('week');
  const [rxBinType, setRxBinType] = useState<'week' | 'month'>('week');
  const [scheduleBinType, setScheduleBinType] = useState<'day' | 'week'>('day');

  const [scheduleStartDate, setScheduleStartDate] = useState<Date>(new Date('2024-06-01'));
  const [scheduleEndDate, setScheduleEndDate] = useState<Date>(new Date('2024-07-01'));
  const [therapistStartDate, setTherapistStartDate] = useState<Date>(new Date('2024-06-01'));
  const [therapistEndDate, setTherapistEndDate] = useState<Date>(new Date('2025-02-14'));

  const { patientCumulativeResults, patientCountResults } = usePatientStats(patientBinType); 
  const { totalRxCountResults, newRxCountResults, totalRxValueResults } = useRxStats(rxBinType);
  const { totalScheduleBasedRxCountResults, totalApptValueResults, totalGapsResults } = useScheduleBasedStats(scheduleBinType, scheduleStartDate, scheduleEndDate);
  const { therapistData } = useTherapistStats(therapistStartDate, therapistEndDate);
 
  return (
    <Grid container spacing={2} sx={{ height:"100%", overflowY: "auto"}}>
      <Grid item xs={6} sx={{border: "0px solid red" }}>
        <Card>
          <CardHeader title="Patient Stats" />
          <CardContent>
            <BinTypeSelector 
              binType={patientBinType} 
              onChange={setPatientBinType} 
              availableOptions={['week', 'month']} 
            />
            <CumulativePatientsPlot cumulativeData={patientCumulativeResults} />
            <NewPatientsPlot countData={patientCountResults} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sx={{ border: "0px solid red" }}>
        <CardHeader title="Rx Stats" />
        <CardContent>
          <RxCountsPlot countData={totalRxCountResults} />
          <BinTypeSelector 
            binType={rxBinType} 
            onChange={setRxBinType} 
            availableOptions={['week', 'month']} 
          />
          <NewRxCountsPlot countData={newRxCountResults}/>
          <RxValuesPlot rxValueData={totalRxValueResults} />
        </CardContent>
      </Grid>
      <Grid item xs={6}>
        <CardHeader title="Schedule Stats" />
        <CardContent>
          <BinTypeSelector 
            binType={scheduleBinType} 
            onChange={setScheduleBinType} 
            availableOptions={['week', 'day']} 
          />
          <label htmlFor="date-range-picker">Date Range: </label>
          <BIDateRangePicker
            startDate={scheduleStartDate}
            endDate={scheduleEndDate}
            onChange={({ startDate, endDate }) => {
              setScheduleStartDate(startDate)
              setScheduleEndDate(endDate)}}
            binType={scheduleBinType}         
          />
          <RxCountsPlot countData={totalScheduleBasedRxCountResults} />
          <ScheduleBasedApptValuesPlot scheduleValueData={totalApptValueResults} />
          <ScheduleBasedGapsPlot scheduleGapData={totalGapsResults} />
        </CardContent>
      </Grid>
      <Grid item xs={6}>
        <CardHeader title="Therapist Stats" />
        <CardContent>
        <label htmlFor="date-range-picker">Date Range: </label>
          <BIDateRangePicker 
            startDate={therapistStartDate} 
            endDate={therapistEndDate} 
            onChange={({startDate, endDate}) => {
              setTherapistStartDate(startDate)
              setTherapistEndDate(endDate)}}
          />
          <TherapistCollapsibleTable therapistData={therapistData} />
        </CardContent>
      </Grid>
    </Grid>
  );
};
