import React from "react";
import TreatmentNoteList from "./TreatmentNoteList";
import { Box, Typography } from "@mui/material";
import useStore from "../../../helpers/useStore";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";

interface TreatmentNotesProps {
  patientId: string;
}


const TreatmentNotes:React.FC<TreatmentNotesProps>=({ patientId }) =>{

  if (!patientId) {
    return (
      <Box textAlign="center" py={2}>
        <Typography variant="body1" color="textSecondary">
          No patient selected. Please select a patient to view treatment notes.
        </Typography>
      </Box>
    );
  }

  return (
      <TreatmentNoteList patientId={parseInt(patientId)} limit={5} />
  );
}
export default TreatmentNotes;
