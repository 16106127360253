import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { CreatePatientTransactionDto } from "../../../../api-client";
import useStore from "../../../../helpers/useStore";
import { TransactionsContext } from "../../../../stores/Transactions/Transactions.provider";
import {} from "../../../Transactions/Kasse/types/kasse.types";
import notificationStore from "../../../../stores/Notification/notificationStore";

const germanNumberFormatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface PayToPatientDialogProps {
  open: boolean;
  onClose: () => void;
  transaction: CreatePatientTransactionDto | null;
  accountId?: string;
  userId?: string;
  onPaymentSuccess: () => void;
}

const PayToPatientDialog: FC<PayToPatientDialogProps> = ({
  open,
  onClose,
  transaction,
  onPaymentSuccess,
}) => {
  const transactionsStore = useStore(TransactionsContext);
  const { createTransaction } = transactionsStore;

  const [loadingRows, setLoadingRows] = useState<Record<string, boolean>>({});

  const handleConfirm = async () => {
    if (!transaction) return;
    try {
      setLoadingRows((prev) => ({ ...prev, [transaction.rxId!]: true }));
      await createTransaction(transaction);
      onPaymentSuccess();
      onClose();
    } catch (error) {
      console.error("Error paying rechnung:", error);
      notificationStore.showMessage("Fehler beim Bezahlen der Rechnung", "error");
    } finally {
      setLoadingRows((prev) => ({ ...prev, [transaction.rxId!]: false }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Zahlung an den Patienten</DialogTitle>
      <DialogContent>
        {transaction && (
          <>
            <p>Fällig: {germanNumberFormatter.format(Math.abs(transaction.amount))} €</p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
        >
          {transaction && loadingRows[transaction.rxId ?? ""] ? (
            <>
              <CircularProgress
                size={16}
                color="inherit"
              />
              <span style={{ marginLeft: 8 }}>Bitte warten...</span>
            </>
          ) : (
            "Einreichen"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayToPatientDialog;
