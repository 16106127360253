import React, { forwardRef } from "react";

interface A5PrintComponentProps {
  billNumber: string;
  rxNumber: string;
  careProviderIk: string;
}

const A5PrintComponent = forwardRef<HTMLDivElement, A5PrintComponentProps>((props, ref) => {
  const { billNumber, rxNumber, careProviderIk } = props;

  const billLine: string = "146mm";
  const ikLine: string = "157mm";

  return (
    <div
      ref={ref}
      style={{
        width: "148mm",
        height: "210mm",
        position: "relative",
        fontFamily: "Courier New, monospace",
        fontSize: "16pt",
        backgroundColor: "transparent",
        letterSpacing: "1.8mm",
      }}
    >
      {/* Bill Number Position */}
      <div
        style={{
          position: "absolute",
          left: "7mm", // Fixed horizontal position
          top: billLine, // Fixed vertical position
        }}
      >
        {billNumber}
      </div>

      {/* IK and bill id line */}
      <div
        style={{
          position: "absolute",
          left: "7mm",
          top: ikLine,
        }}
      >
        <div>{careProviderIk}</div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "58mm",
          top: ikLine,
        }}
      >
        <div>{rxNumber}</div>
      </div>
    </div>
  );
});

export default A5PrintComponent;
