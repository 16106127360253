import { Fragment, useState } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface TherapistTableData {
  firstName: string | undefined,
  lastName: string | undefined,
  nickName: string | undefined,
  value: number,
  gaps: number,
  rxCount: number,
}
  
interface TherapistData {
  locationId: string,
  therapistCount: number,
  totalGaps: number,
  table: TherapistTableData[],
}

interface TableProps {
  therapistData: TherapistData[]
}

const TherapistCollapsibleTable: React.FC<TableProps> = ({ therapistData }) => {
    const [open, setOpen] = useState<{[key: string]: boolean}>({});
  
    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Location</TableCell>
              <TableCell align="center">Number of Therapists</TableCell>
              <TableCell align="center">Total Gaps</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {therapistData.map((row) => (
              <Fragment key={row.locationId}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen((prevState) => ({
                        ...prevState,
                        [row.locationId]: !prevState[row.locationId]
                      }))}
                    >
                      {open[row.locationId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.locationId}
                  </TableCell>
                  <TableCell align="center">{row.therapistCount}</TableCell>
                  <TableCell align="center">{row.totalGaps}</TableCell>
                </TableRow>
  
                {/* table for each location */}
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open[row.locationId]} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        {/* <Typography variant="h6" gutterBottom component="div">
                          Therapists in {row.location}
                        </Typography> */}
                        <Table size="small" aria-label="therapists">
                          <TableHead>
                            <TableRow>
                              <TableCell>First Name</TableCell>
                              <TableCell>Last Name</TableCell>
                              <TableCell>Nickname</TableCell>
                              <TableCell align="right">Appointment Value</TableCell>
                              <TableCell align="right">Gaps</TableCell>
                              <TableCell align="right">Rx Count</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.table.map((tableRow, index) => (
                              <TableRow key={index}>
                                <TableCell>{tableRow.firstName}</TableCell>
                                <TableCell>{tableRow.lastName}</TableCell>
                                <TableCell>{tableRow.nickName}</TableCell>
                                <TableCell align="right">{tableRow.value}</TableCell>
                                <TableCell align="right">{tableRow.gaps}</TableCell>
                                <TableCell align="right">{tableRow.rxCount}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
};

export default TherapistCollapsibleTable;