import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import FlatCard from "../../../../../atoms/FlatCard";
import { DoubleTreatmentRxDto, PatientDto, RxDto } from "../../../../../api-client";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { handleParseInput } from "../../../../../helpers/parseInput";
import { MasterTablesContext } from "../../../../../stores/MasterTables/MasterTables.provider";
import useStore from "../../../../../helpers/useStore";
import EditTable, { validateFrequency, validateTherapy } from "../../../../../molecules/Editable";
import Controls from "../../../../../atoms/Controls";
import { Add } from "@mui/icons-material";
import { observer, useObserver } from "mobx-react";
import { DoctorContext } from "../../../../../stores/Doctor/doctor.provider";
import DoctorDialog from "../../../../PatientDataEntryForm/Doctor";
import { LabelItem, ValueItem } from "../../../../../molecules/FlexList";
import notificationStore from "../../../../../stores/Notification/notificationStore";
import { RxStoreContext } from "../../../../../stores/Rx/rx.provider";
import { PatientsContext } from "../../../../../stores/Patients/patients.provider";
import ValidationCard, { formType } from "../../../../../molecules/ValidationCard";
import { UserContext } from "../../../../../stores/User/User.provider";
import RxFormsData from "../RxFormsData";
import WarningDialog from "../../../../../atoms/WarningDialog";

interface PrivateProps {
  selectedRow: any;
  patientValues: any;
  updateFormData?: any;
  handleData: any;
  openRx: boolean;
  onCloseRx: any;
  newRx: boolean | undefined;
  // therapyTableData:any;
}

const Private: React.FC<PrivateProps> = observer(
  ({ selectedRow, patientValues, handleData, updateFormData, openRx, onCloseRx, newRx }) => {
    const [selectedLANR, setSelectedLANR] = useState<string | null>(null);
    const [trackFormChange, setTrackFormChange] = useState(false);
    const [selectedBSNR, setSelectedBSNR] = useState<string | null>(null);
    const [selectedChipCard, setSelectedChipCard] = useState<string | null>(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);
    const [savingRx, setSavingRx] = useState(false);
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [fetchedData, setFetchedData] = useState(null);
    const PatientStore = useStore(PatientsContext);
    const { searchPatient } = PatientStore;
    const DoctorStore = useStore(DoctorContext);
    const { doctorOptions } = DoctorStore;
    const RxStore = useStore(RxStoreContext);
    const { saveRxs } = RxStore;
    const UserStore = useStore(UserContext);
    const { user } = UserStore;
    const MasterTableStore = useStore(MasterTablesContext);
    const { masterTablesData, getICD, getTherapyId } = MasterTableStore;
    const [formValues, setFormValues] = useState(selectedRow);
    // const [open, setOpen] = useState(true);
    const [filteredDiagnoseGroups, setFilteredDiagnoseGroups] = useState([]);

    const insurer = masterTablesData
      .filter((obj: any) => "insurer" in obj)
      .map((obj: any) => obj.insurer);
    const diagnoseGroups = masterTablesData
      .filter((obj: any) => "diagnosisGroups" in obj)
      .map((obj: any) => obj.diagnosisGroups);
    const rxType = masterTablesData
      .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
      .map((obj: any) => obj.rxType);

    const account = masterTablesData
      .filter((obj: any) => "account" in obj)
      .map((obj: any) => obj.account);

    const filterDiagnoseGroups = (rxtype: any) => {
      const filtered = diagnoseGroups[0].filter((option: any) => option.RxType?.id === rxtype);
      setFilteredDiagnoseGroups(filtered);
    };
    const [icdCodeEntries, setIcdCodeEntries] = useState(formValues.icdCodeEntries);
    const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);
    const [therapyTableData, setTherapyTableData] = useState([]);

    const tarifGroupId = "Privat";

    const handleOpenAddEditDialog = (lanr: string | null, bsnr: string | null) => {
      setSelectedLANR(lanr);
      setSelectedBSNR(bsnr);
      setAddEditDialogOpen(true);
    };
    const handleCloseAddEditDialog = (data: any) => {
      // Handle doctor data, LANR, and BSNR passed back from DoctorDialog
      console.log("Received doctor data:", data);
      setAddEditDialogOpen(false);

      // Here you can perform further actions with the received data
      if (data && "number" in data) {
        const { number, clinicNumber, contactInfo, id } = data;

        // Update states or perform other operations based on the received data
        setSelectedLANR(number);
        setSelectedBSNR(clinicNumber);
        setSelectedDoctor(contactInfo?.lastName);
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: number,
          practiceNumber: clinicNumber,
          doctorId: id,
        }));

        // For example, you can update doctor form values if needed
        // setDoctorFormValues(doctorData);
      }
    };

    const handleUnsavedChangesWarning = () => {
      handleSave();
    };
    const handleCloseRxDialog = () => {
      console.log("shjsakjsajj", unsavedChanges);
      if (unsavedChanges) {
        // If there are unsaved changes, set a state to open the warning dialog
        setShowunsavedChangesWarning(true);
      } else {
        onCloseRx();
      }
    };
    const handleCloseUnsavedChangesWarning = () => {
      onCloseRx();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowunsavedChangesWarning(false);
    };

    useEffect(() => {
      const fetchData = async () => {
        console.log("akjsakjsksak", selectedRow);

        const rxs = selectedRow.therapyRxs?.map((therapy: any) => ({
          id: therapy.id || uuidv4(),
          therapy: therapy.therapy?{
            id: `${tarifGroupId}_${therapy.therapy.abbreviation}`,
            ...therapy.therapy,
          } : { id: "", abbreviation: "" },
          amount: therapy.amount,
          frequency: therapy.frequency || { id: "", text: "" },
          isDoubled: therapy.isDoubled,
          doubledTreatmentIds: therapy.doubledTreatmentIds,
          rxSeries: therapy.rxSeries,
        }));

        const updatedformValues = {
          ...selectedRow,
          firstName: patientValues.firstName || "",
          lastName: patientValues.lastName || "",
          dob: patientValues.dob || "",

          insuranceNo: patientValues.insuranceNo || "",
          // Receiptpayer: selectedRow.payer.insurer || "",
          Receiptpayer:
            insurer[0]?.find((option: any) => option.id === selectedRow.Receiptpayer)?.name ||
            selectedRow.payer?.name,
          chipcard: selectedRow.chipcard || selectedRow.payer?.ik,
          doctorId:
            doctorOptions?.find((option: any) => option.id === selectedRow.doctorId)?.contactInfo
              .lastName || selectedRow.doctor?.contactInfo?.lastName,
          diagnosegroup:
            diagnoseGroups[0]?.find((option: any) => option.id === selectedRow.diagnosegroup)
              ?.code || selectedRow.diagnosisGroup?.code,
          tarifGroupId: "Privat",
          rxtype: "Privat/Selbstzahler",
          isHomeVisit:selectedRow.isHomeVisit || null,
          doctorData:
            (doctorOptions as any).find(
              (option: any) =>
                option.contactInfo.lastName === selectedRow.doctorId ||
                option.id === selectedRow.doctorId
            ) || selectedRow.doctor,
          accountId:
            account[0]?.find((option: any) => option.id === selectedRow.accountId)?.name ||
            selectedRow.account?.name,
          // status: selectedRow.status || "",
          signatureStatus: selectedRow.signatureStatus || "",
          insuranceStatus: selectedRow.insuranceStatus || "",
          therapyRxs: rxs,
          // rxtype:selectedRow.rxtype?.id || "",
          // accountId:selectedRow.account?.id || "",
          // chipcard: selectedRow.payer?.id || "",
          // doctorId: selectedRow.doctor?.contactInfo?.lastName || "",
          bsnr: selectedRow.practiceNumber || "",
          // diagnosegroup: selectedRow.diagnosisGroup?.code || "",
          icdCodeEntries: icdCodeEntries,
        };

        setFormValues(updatedformValues);
        const therapyTableData = updatedformValues.therapyRxs
          ? updatedformValues.therapyRxs.map((therapy: any) => ({
              id: therapy.id,
              date: selectedRow.date, // Use selectedRow.date if needed
              therapy: therapy.therapy.abbreviation,
              amount: therapy.amount,
              frequency: therapy.frequency?.text,
              isDoubled: therapy.isDoubled,
              doubledTreatmentIds: therapy.doubledTreatmentIds,
              rxSeries: therapy.rxSeries,
            }))
          : [];
        setTherapyTableData(therapyTableData);
        console.log("OPOKK", formValues, updatedformValues, doctorOptions);
      };
      fetchData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientValues.rxs, selectedRow]);
    console.log("TAzz<szszusu", therapyTableData);

    const handleDoctorChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue: any = event.target.value;
      setUnsavedChanges(true);
      setSelectedDoctor(selectedValue);
      console.log("SELECTED", selectedValue);
      // Autopopulate lanr and bsnr based on doctor selection
      const selectedDoctor: any = doctorOptions.find(
        (doctor: any) => doctor.contactInfo.lastName === selectedValue
      );

      if (selectedDoctor) {
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: selectedDoctor.number,
          practiceNumber: selectedDoctor.clinicNumber,
        }));
        setSelectedLANR(selectedDoctor.number);
        setSelectedBSNR(selectedDoctor.clinicNumber);
        console.log("SELECTED", selectedDoctor, formValues, selectedLANR);
      }

      // Filter doctor options based on selectedValue
      const filteredOptions: any = doctorOptions.filter(
        (doctor: any) => doctor.contactInfo.lastName === selectedValue
      );

      console.log("Filtered", filteredOptions, selectedValue);

      if (filteredOptions.length > 0) {
        // If filteredOptions has a value, set it as the fetchedData
        setFetchedData(filteredOptions[0]);
        // Update receiptForm with selected doctor
        // if (!isEditing) {
        setFormValues({
          ...formValues,
          doctorId: filteredOptions[0].id,
          practiceNumber: filteredOptions[0].clinicNumber,
          doctorNumber: filteredOptions[0].number,
        });
        // } else {
        //   setFormValues((prevFormValues: any) => ({
        //     ...prevFormValues,
        //     rxs: prevFormValues.rxs.map((rx: any) => ({
        //       ...rx,
        //       doctorId: filteredOptions[0].id,
        //       practiceNumber: filteredOptions[0].clinicNumber,
        //       doctorNumber: filteredOptions[0].number,
        //     })),
        //   }));
        // }
      } else {
        setFetchedData(null);
        setFormValues({
          ...formValues,
          doctorId: "",
          practiceNumber: null,
          doctorNumber: null,
        });
      }
    };

    const handleSave = async (event?: any) => {
      if (event) {
        setSavingRx(false);
        event.preventDefault(); // Prevent default form submission behavior
      }
      try {
        console.log("atzsashjsajjsadsm", formValues.therapyRxs, selectedRow);
        setSavingRx(true);
        const therapyRxs: any[] = [];
        const formTherapies=(formValues.therapyRxs || []) // Ensure formData.therapyRxs is an array or provide an empty array
        .filter((therapy:any) => therapy.therapy.id !== "")

        // Loop through tableData to collect therapy data
        for (const therapy of formTherapies) {
          // const tarifTherapy = getTherapyId("Privat", therapy.therapy);
          // if (!tarifTherapy) {
          //   notificationStore.showMessage(`Heilmittel ${therapy.therapy} nicht gefunden`, "error");
          //   continue;
          // }

          const therapyObj = {
            id: therapy.id || uuidv4(),
            amount: therapy.amount || "",
            therapy:
              {
                id: `${tarifGroupId}_${therapy?.therapy?.abbreviation}`,
                abbreviation: therapy?.therapy?.abbreviation,
              },
            frequency: therapy.frequency.id?therapy.frequency : {id:"1-3",text:"1-3"},
            isHomeVisit: therapy.isHomeVisit,
            doubledTreatmentIds: therapy.doubledTreatmentIds,
            isDoubled: therapy.isDoubled,
            rxSeries: therapy.rxSeries,
          };
          

          // Check if therapy data exists before adding
          if (!(therapyObj.frequency && therapyObj.frequency.id) || !(therapyObj.therapy && therapyObj.therapy.id) || !therapyObj.amount) {
            throw new Error(
              "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
            );
          }
          const validTherapy=validateTherapy(therapyObj.therapy.abbreviation,masterTablesData)
          const validFrequency=validateFrequency(therapyObj.frequency.id,masterTablesData)
          if(!validTherapy || !validFrequency){
            throw new Error("Bitte geben Sie eine gültige Therapie und Häufigkeit ein.")
          }

          therapyRxs.push(therapyObj);
        }
        console.log("tzzssjjsjxjs", therapyRxs);

        const updatedRxValues: any = {
          ...formValues,
          id: patientValues?.rxs?.find((rx: any) => rx.id === formValues.id)?.id || "",
          rxNumber: patientValues?.rxs?.find((rx: any) => rx.id === formValues.id)?.rxNumber || "",
          doctor: formValues.doctorData,
          accountId:
            account[0].find((option: any) => option.name === formValues.accountId)?.id ||
            formValues.accountId,
          rxtype: "Privat/Selbstzahler",
          diagnosegroup:
            diagnoseGroups[0].find((option: any) => option.code === formValues.diagnosegroup)?.id ||
            formValues.diagnosegroup,
          doctorId:
            (doctorOptions as any).find(
              (option: any) => option.contactInfo.lastName === formValues.doctorId
            )?.id || formValues.doctorId,
          Receiptpayer:
            insurer[0].find((option: any) => option.name === formValues.Receiptpayer)?.id ||
            formValues.Receiptpayer,
          therapyRxs: therapyRxs.filter((therapy: any) => therapy.therapy.id !== ""),
          tarifGroupId: "Privat",
        };

        // The `updatedRxValues` object now includes the processed `therapyRxs` array with the necessary validations

        // // Handle form submission logic here
        // const updatedFormData = {

        //   patient:{
        //     ...patientValues,
        //   firstName: formData.patient?.firstName || "",
        //   lastName: formData.patient?.lastName || "",
        //   dob: formData.patient?.dob || ""},
        //   rxs: updatedRxValues
        // };
        const updatedFormData = {
          ...patientValues,
          rxs: newRx
            ? [updatedRxValues] // If newRx is truthy, replace all rxs with updatedRxValues
            : patientValues?.rxs?.map((rx: any) =>
                rx.id === formValues.id ? updatedRxValues : rx
              ),
        };
        setUnsavedChanges(false);
        if (newRx) {
          updateFormData(updatedRxValues);
          console.log("ajjsakskkakak", updatedFormData, updatedRxValues, patientValues);
          setFormValues(updatedRxValues);
          //This for newRx setting rx form
          console.log("jsakakd.", updatedRxValues);
          // mainFormSubmit()
        } else {
          console.log("ajksakx", updatedRxValues);
          const response = await saveRxs(patientValues?.id, [
            updatedRxValues,
          ] as unknown as RxDto[]); // Use updatedFormData instead of formData
          setFormValues(updatedRxValues);
          handleData(updatedFormData);
          // await searchPatient({ id: patientValues.id });
          handleCloseRxDialog();
        }
      } catch (error: any) {
        console.error("Error saving Rx:", error);
        notificationStore.showMessage(
          error.message || "Fehler beim Speichern des Rezepts",
          "error",
          error
        );
      } finally {
        // Set loading back to false when the API call is complete
        setSavingRx(false);
      }
    };

    //   const handleOpen = () => {
    //     setOpen(true);
    //   };

    // const handleClose = () => {
    //   setOpen(false);
    // };
    const handletherapyTableDataChange = (data: DoubleTreatmentRxDto[]) => {
      console.log("datatttzz", data);
      // // Create a new array to hold the updated data
      const updatedData: any[] = [];
      data.forEach((item: any) => {
        // Divide the amount by 2 for the original item
        const updatedAmount = item.amount;

        // Update the original item with the new amount
        const updatedItem = {
          ...item,
          therapy: { id: item.therapy, abbreviation: item.therapy },
          frequency: { id: item.frequency, text: item.frequency },
          amount: updatedAmount,
          isDoubled: item.isDoubled,
        };

        // Always add the original item to the updated data array
        updatedData.push(updatedItem);

        // If the item has isDoubled: true, create a new row without isDoubled: true and with the updated amount
        if (item.isDoubled) {
          const newId = uuidv4();
          // const newDuplicateItem = { ...item, id: newId, amount: updatedAmount, isDoubled: false };

          // Add the new ID to the doubleTreatmentIds array
          updatedItem.doubledTreatmentIds =
            updatedItem.doubledTreatmentIds && updatedItem.doubledTreatmentIds.length > 0
              ? updatedItem.doubledTreatmentIds
              : [newId];
        }
      });
      // setFormValues((prevData: any) => ({
      //   ...prevData,
      //   therapyRxs: updatedData,
      // }));
      // Update the form values state and call setUnsaved(true) if the data has changed
      setFormValues((prevData: any) => {
        const newFormValues = {
          ...prevData,
          therapyRxs: updatedData,
        };
        const hasUnsavedChanges = prevData.therapyRxs?.some((prevItem: any, index: number) => {
          const updatedItem = updatedData[index];
          return (
            prevItem.abbreviation !== updatedItem.abbreviation ||
            prevItem.amount !== updatedItem.amount ||
            prevItem.frequency?.id !== updatedItem.frequency?.id ||
            prevItem.isDoubled !== updatedItem.isDoubled ||
            prevItem.rxSeries !== updatedItem.rxSeries
          );
        });

        // Usage
        if (prevData.therapyRxs && updatedData && hasUnsavedChanges) {
          setUnsavedChanges(true);
        }

        return newFormValues;
      });
      console.log("jkkkkk", formValues);
    };
  const [isHausbesuchSelected, setHausbesuchSelected] = useState<boolean | null>(formValues.isHomeVisit);
  console.log("auusuusiisi",formValues.isHomeVisit)
     const handleCheckboxChange = (event: any) => {
          const selectedValue = event.target.value; // "yes" or "no"
          
          if (selectedValue === "yes") {
            setHausbesuchSelected(true);
            // Set isHomeVisit to true if "yes" is selected
            handleInputChange({
              target: {
                name: "isHomeVisit",
                value: true,
              } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
            } as React.ChangeEvent<HTMLInputElement>);
          } else if (selectedValue === "no") {
            setHausbesuchSelected(false);
            // Set isHomeVisit to false if "no" is selected
            handleInputChange({
              target: {
                name: "isHomeVisit",
                value: false,
              } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
            } as React.ChangeEvent<HTMLInputElement>);
          } else {
            setHausbesuchSelected(null); // Set null if neither is selected
            // Set isHomeVisit to null if neither checkbox is selected
            handleInputChange({
              target: {
                name: "isHomeVisit",
                value: null,
              } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
            } as React.ChangeEvent<HTMLInputElement>);
          }
        };
    

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index?: any,
      icdEntryId?: string
    ) => {
      console.log("LUUZZ", event);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, value, checked, type, id } = event.target;
      setUnsavedChanges(true);

      const inputValue = type === "checkbox" ? checked : value;
      // setFormChanged(true);
      console.log("input", inputValue, checked);
      console.log("Event:", event.target.name, event.target.value, index);
      if (name === "date" && formValues.isPerpetual && value) {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          unvirtualize: true,
        }));
      }
      
      // Check if the input is for icdCodeEntries
      if (name.startsWith("icdCodeEntries")) {
        const inputElement = document.querySelector(`[name="${name}"]`) as
          | HTMLInputElement
          | HTMLTextAreaElement;
        const cursorPosition = inputElement?.selectionStart || 0;
        let updatedValue = value;
        let newCursorPosition = cursorPosition; // Initialize new cursor position

        // Check if the length is at least 4 and if a period needs to be inserted
        if (value.length >= 4) {
          // Check if the period already exists at the correct position
          if (!value.includes(".")) {
            // Insert a period after the third character if it's not already there
            updatedValue = value.slice(0, 3) + "." + value.slice(3);

            // Adjust cursor position if a period was inserted before the original cursor position
            if (cursorPosition > 3) {
              newCursorPosition = cursorPosition + 1; // Move cursor one position forward
            }
          }
        }

        // Update the form values with the correctly formatted value
        setFormValues((prevValues: any) => ({
          ...prevValues,
          icdCodeEntries: prevValues.icdCodeEntries.map((icdEntry: any, i: number) => {
            return i === index
              ? { id: icdEntryId || undefined, userText: updatedValue }
              : { id: icdEntry?.id, userText: icdEntry?.userText || "" };
          }),
        }));

        // Restore cursor position after the update
        setTimeout(() => {
          if (inputElement) {
            inputElement.selectionStart = inputElement.selectionEnd = newCursorPosition;
          }
        }, 0);
      }

      // if (name.startsWith("icdCodeEntries")) {
      //   setFormValues((prevValues: any) => ({
      //     ...prevValues,
      //     icdCodeEntries: prevValues.icdCodeEntries.map((icdEntry: any, i: number) => {
      //       return i === index ? { id:icdEntryId || undefined, userText: inputValue } : {id:icdEntry?.id,userText:icdEntry?.userText|| ""};
      //     }),
      //   }));
      // }
      if (name === "hasIndividualSymptoms") {
        // When the checkbox is selected, clear symptom codes and text
        // if (inputValue ) {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          a: false, // Clear symptom code
          b: false,
          c: false,
          d: false,
          symptoms: "",
          hasIndividualSymptoms: inputValue, // Clear symptoms text
        }));
        // }
      } else if (
        (name === "a" || name === "b" || name === "c" || name === "d") &&
        formValues?.hasIndividualSymptoms
      ) {
        // If the checkbox is selected, and symptom code is changed, uncheck the checkbox
        setFormValues((prevValues: any) => ({
          ...prevValues,
          hasIndividualSymptoms: false,
          [name]: inputValue,
        }));
      } else {
        console.log("InputValue", inputValue, id);
        // Update other form fields based on their names
        setFormValues((prevValues: any) => ({
          ...prevValues,
          [name]: id ? id : inputValue,
        }));
      }

      // setFormValues((prevValues: any) => ({
      //   ...prevValues,
      //   rxs: prevValues.rxs.map((rx: any) => ({
      //     ...rx,
      //     [name]: id ? id : inputValue,
      //   })),
      // }));
      if (name === "doctorNumber") {
        // Autopopulate doctor and bsnr based on LANR selection
        const selectedDoctor: any = doctorOptions.find(
          (doctor: any) => doctor.number === Number(value)
        );
        setFetchedData(selectedDoctor);
        console.log("UZZHHHH", selectedDoctor);
        if (selectedDoctor) {
          setFormValues((prevFormValues: any) => ({
            ...prevFormValues,
            doctorId: selectedDoctor.id,
            practiceNumber: selectedDoctor.clinicNumber,
          }));
          setSelectedDoctor(selectedDoctor.contactInfo.lastName);
          setSelectedBSNR(selectedDoctor.clinicNumber);
        }
      }

      // if (name === 'rxtype') {
      //   filterDiagnoseGroups(value);
      // }

      if (name === "rxtype") {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          diagnosegroup: null, // Clear diagnosegroup when rxtype changes
        }));
        filterDiagnoseGroups(value);
      }
      // if (name === "practiceNumber") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.clinicNumber === Number(value)
      //   );
      //   setFetchedData(selectedDoctor);
      //   if (selectedDoctor) {
      //     setFormValues((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       doctorId: selectedDoctor.id,
      //       doctorNumber: selectedDoctor.number,
      //     }));
      //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
      //     setSelectedLANR(selectedDoctor.number);
      //   }
      // }
      if (name === "Receiptpayer") {
        // Autopopulate doctor and bsnr based on LANR selection
        const selectedPayer: any = insurer[0].find(
          (option: any) => option.id === id || option.ik === value
        );
        console.log("UZZHHHH", selectedPayer);
        if (selectedPayer) {
          setFormValues((prevFormValues: any) => ({
            ...prevFormValues,
            chipcard: selectedPayer?.ik,
            tarifGroupId: selectedPayer?.tarifGroup.id,
          }));
          setSelectedChipCard(selectedPayer.ik);
          console.log("selectedPayer", selectedPayer.name);
        }
      }

      if (name === "chipcard") {
        const selectedPayer: any = insurer[0].find((option: any) => option.ik === value);
        console.log("UZZHHHH", selectedPayer);
        if (selectedPayer) {
          setFormValues((prevFormValues: any) => ({
            ...prevFormValues,
            Receiptpayer: selectedPayer.id,
            tarifGroupId: selectedPayer?.tarifGroup?.id,
          }));

          setSelectedPayer(selectedPayer.name);
          console.log("selectedPayer", selectedPayer.name);
        }
      }
      console.log("GTII", formValues);

      // else if (name === "doctor") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.contactInfo.firstNames===value

      //   );
      //   if (selectedDoctor) {
      //     setFormValues((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       lanr: selectedDoctor.number,
      //       bsnr: selectedDoctor.clinicNumber,
      //     }));
      //     setSelectedLANR(selectedDoctor.number);
      //   }
      //   console.log('DOCTOR',selectedDoctor,formValues,value,doctorOptions)
      // }
    };
    const handleAddInput = () => {
      setIcdCodeEntries([...icdCodeEntries, { userText: "" }]);

      // Update the formValues state as well to reflect the new entry
      setFormValues((prevValues: any) => ({
        ...prevValues,
        icdCodeEntries: [...prevValues.icdCodeEntries, { userText: "" }],
      }));
    };

    const handleICDCodeEntrySubmit = async (icdCode: any, index: number) => {
      try {
        const icdObject: any = await getICD(icdCode.userText);
        console.log("OOPP", icdObject);
        if (icdObject) {
          // If the API call is successful and returns an icdObject, update the icdCodeEntries state with the normCode
          setFormValues((prevValues: any) => {
            const updatedICDCodeEntries = prevValues.icdCodeEntries.map(
              (icdEntry: any, i: number) => {
                return i === index
                  ? { id: icdCode?.id || "", userText: icdCode.userText }
                  : icdEntry;
              }
            );

            const concatenatedDiagnosisText = prevValues.diagnosisText
              ? `${prevValues.diagnosisText} ${icdObject.text}` // Append the new icdObject.text to the existing diagnosisText
              : icdObject.text;
            return {
              ...prevValues,
              icdCodeEntries: updatedICDCodeEntries,
              diagnosisText: concatenatedDiagnosisText.trim(), // Set diagnosisText to the concatenated value
            };
          });
        }
      } catch (error) {
        console.error("Error fetching ICD object:", error);
      }
    };

    const treatmentColumns: any[] = [
      { field: "therapy", headerName: "Heilmittel", editable: true },
      { field: "amount", headerName: "Menge", editable: true },

      { field: "frequency", headerName: "Frequency", editable: true },
    ];
    const FormContent = (
      <Grid container spacing={2}>
        {/* Left Side */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FlatCard title="">
                <Controls.Input
                  name="name"
                  label="Name und Persönliche Daten"
                  value={`${patientValues?.salutation || ""} ${patientValues?.firstName} ${
                    patientValues?.lastName
                  }`}
                  disabled
                />
              </FlatCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.DatePicker
                name="date"
                label="Rezeptdatum"
                value={formValues?.date}
                onChange={handleInputChange}
                formatDate="dd.MM.yy"
              />
            </Grid>
            <Grid item xs={12}>
              <FlatCard title="Arzt">
                <Controls.Select
                  name="doctorNumber"
                  label="LANR"
                  value={selectedLANR || formValues?.doctorNumber}
                  onChange={handleInputChange}
                  options={doctorOptions?.map((doctor: any) => ({
                    label: doctor.number.toString(),
                    value: doctor.number.toString(),
                  }))}
                />
                <Button onClick={() => handleOpenAddEditDialog(selectedLANR, selectedBSNR)}>
                  <Add />
                  <Typography fontSize={"0.7rem"}>Arzt hinzufügen / bearbeiten</Typography>
                </Button>
                {isAddEditDialogOpen && (
                  <DoctorDialog
                    openAddEditDialog={isAddEditDialogOpen}
                    handleCloseAddEditDialog={handleCloseAddEditDialog}
                    doctorOptions={doctorOptions}
                    selectedLANR={formValues.doctorNumber}
                    selectedBSNR={formValues.practiceNumber}
                  />
                )}
                <Controls.Select
                  name="doctor"
                  label="Arzt"
                  id={1}
                  value={selectedDoctor || formValues.doctorId}
                  onChange={handleDoctorChange}
                  options={doctorOptions?.map((doctor: any) => ({
                    label: doctor.contactInfo.lastName.toString(),
                    value: doctor.contactInfo.lastName.toString(),
                    id: doctor.id.toString(),
                  }))}
                />
                <Controls.Input
                  name="practiceNumber"
                  label="BSNR"
                  value={selectedBSNR || formValues?.practiceNumber}
                  onChange={handleInputChange}
                />
              </FlatCard>
            </Grid>
            <Grid item xs={12} sx={{ border: "0px dotted black" }}>
              <FlatCard title="Validierung">
                <ValidationCard
                  patientData={patientValues}
                  selectedRx={
                    patientValues?.rxs?.filter(
                      (rx: any) => rx.rxNumber === selectedRow?.rxNumber
                    )[0]
                  }
                  form={formType.LONG}
                />
              </FlatCard>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Side */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FlatCard title="Behandlungen">
                <EditTable
                  initialColumns={treatmentColumns}
                  initialRows={therapyTableData}
                  onTableDataChange={handletherapyTableDataChange}
                  parseInput={handleParseInput}
                  masterTablesData={masterTablesData}
                />
              </FlatCard>
            </Grid>
            <Grid item xs={12}>
              <FlatCard title="Diagnose">
                {formValues.icdCodeEntries?.map((icdEntry: any, index: any) => (
                  <div key={`icd-${index}`}>
                    <Controls.Input
                      label={`ICD ${index + 1}`}
                      name={`icdCodeEntries-${index}`}
                      value={icdEntry.userText}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e, index, icdEntry?.id);
                      }}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Tab" || e.key === "Enter") {
                          e.preventDefault();
                          handleICDCodeEntrySubmit(icdEntry, index);
                        }
                      }}
                    />
                  </div>
                ))}
                <Button onClick={handleAddInput}>
                  <Add />
                  ICD Hinzufügen
                </Button>
                <Controls.TextArea
                  name="diagnosisText"
                  rows={4}
                  placeholder="Textdiagnose"
                  value={formValues?.diagnosisText}
                  onChange={handleInputChange}
                />
                <Grid item xs={3}>
                  <Typography variant="body1" sx={{ marginTop: "8px", align: "left" }}>
                    Hausbesuch
                  </Typography>
                  <FormControlLabel
                    label=""
                    control={
                      <>
                        <Checkbox
                          checked={formValues.isHomeVisit === true}
                          onChange={handleCheckboxChange}
                          name="visitTypeCheckboxJa"
                          value="yes"
                        />
                        <Typography variant="body1">ja</Typography>
                        <Checkbox
                          checked={formValues.isHomeVisit === false}
                          onChange={handleCheckboxChange}
                          name="visitTypeCheckboxNein"
                          value="no"
                        />
                        <Typography variant="body1">nein</Typography>
                      </>
                    }
                  />
                </Grid>

                <Typography color="red" align="left">
                  ggf. Therapieziele/weitere med. Befunde und hinweise
                </Typography>
                <Controls.TextArea
                  name="additionalNotes"
                  rows={4}
                  placeholder=""
                  value={formValues?.additionalNotes}
                  onChange={handleInputChange}
                />
              </FlatCard>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={savingRx}
          aria-labelledby="loading-modal-title"
          aria-describedby="loading-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <CircularProgress size={60} />
          </div>
        </Modal>
        {unsavedChangesWarning && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
            onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
            title="Änderungen speichern?"
            content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
            isConfirmSave
          />
        )}
      </Grid>
    );

    return useObserver(() => {
      return (
        <Dialog open={openRx} onClose={handleCloseRxDialog} maxWidth="lg" fullWidth>
          <DialogTitle>Private Dialog</DialogTitle>
          <DialogContent>{FormContent}</DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseRxDialog}>
              Abbrechen
            </Button>
            <Button onClick={handleSave} variant="contained">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      );
    });
  }
);

export default Private;
