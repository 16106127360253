import React from "react";
import {
  AccountBalance,
  AutoStories,
  CalendarMonth,
  Coronavirus,
  Dashboard,
  Face3,
  Group,
  Hail,
  History,
  Insights,
  PersonPin,
  ShoppingCart,
} from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Box, BoxProps, Button, Typography, withTheme } from "@mui/material";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useObserver } from "mobx-react";
import { mobiliTheme } from "../themes/mobiliTheme";
import zIndex from "@mui/material/styles/zIndex";

interface Menu {
  id: number;
  name: string;
  linkTo?: string;
  isActive?: boolean;
  isDisabled?: boolean;
}
type Menus = Menu[];

export interface SideBarProps extends BoxProps {
  isOpen: boolean;
  menus: Menus;
  onClick: () => void;
}

const StyledLink = {
  display: "inline-block",

  // boxSizing: "border-box",
  textDecoration: "none",
  color: mobiliTheme.palette.primary.main,
  onmouseover: {
    zIndex: 1,
  },
  cursor: "pointer",
  "&:hover": {
    backgroundColor: (props: any) => "light-10",
  },
};

const StyledRowContainer = styled(Box)`
  cursor: pointer;
  :hover {
    background-color: ${(props) => "light-10"};
  }
`;

const getIcon = (name: string, isActive?: boolean, isDisabled?: boolean) => {
  const styles = {
    fontSize: "2rem",
    color: isActive ? "secondary.dark" : "primary.main",
  };

  switch (name) {
    case "Dashboard":
      return <Dashboard sx={styles} />;
    case "Patient":
      return <PersonPin sx={styles} />;
    case "Rezept":
      // eslint-disable-next-line react/style-prop-object
      return <AutoStories sx={styles} />;
    case "Termin Plan":
      return <CalendarMonth sx={styles} />;
    case "Arzt":
      return <Hail sx={styles} />;
    case "Mitglied":
      return <Face3 sx={styles} />;
    case "Interessent":
      return <Group sx={styles} />;
    case "Kasse":
      return <AccountBalance sx={styles} />;
    case "Verkauf":
      return <ShoppingCart sx={styles} />;
    case "Administration":
      return <AdminPanelSettingsIcon sx={styles} />;
    case "History":
      return <History sx={styles} />;
    case "Controlling":
        return <Insights sx={styles} />;
    default:
      return "no icon";
  }
};

const SideBarList = ({ isOpen, menus, onClick }: SideBarProps) => {
  const location = useLocation();
  const pathName = location.pathname;

  return useObserver(() => {
    const isFilterActive = (linkTo?: string) => pathName === linkTo;

    return (
      <Box
        flexDirection={"column"}
        sx={{ display: "flex", flexWrap: "nowrap", flexDirection: "column" }}
      >
        <Box sx={{ paddingTop: 6.3 }} />
        {menus.map(
          ({ name, id, linkTo = "/", isActive = isFilterActive(linkTo), isDisabled }) =>
            !isDisabled && (
              <>
                {/* <StyledRowContainer title={name} key={id} onClick={onClick}> */}
                <Link onClick={onClick} to={linkTo} key={id} style={{ padding: 8, ...StyledLink }}>
                  {/* <Button sx={{ backgroundColor: "white" }}> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "small",
                      flexWrap: "nowrap",
                      border: "0px solid red",
                      flexGrow: 1,
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {getIcon(name, isActive, isDisabled)}
                    {isOpen && (
                      <Typography
                        color={isActive ? "secondary.main" : isDisabled ? "grey" : "primary.main"}
                        fontWeight={isActive ? 600 : 400}
                        paddingLeft={1.5}
                      >
                        {name}
                      </Typography>
                    )}
                  </Box>
                  {/* </Button> */}
                </Link>
                {/* </StyledRowContainer> */}
              </>
            )
        )}
      </Box>
    );
  });
};
export default SideBarList;
