import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { CreateBankTransferDto, GetRechnungDetailsDto } from "../../../../api-client";
import useStore from "../../../../helpers/useStore";
import { TransactionsContext } from "../../../../stores/Transactions/Transactions.provider";
import {
  recnungTransactionMethods,
  transactionTypes,
} from "../../../Transactions/Kasse/types/kasse.types";
import notificationStore from "../../../../stores/Notification/notificationStore";

const germanNumberFormatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface PayRechnungDialogProps {
  open: boolean;
  onClose: () => void;
  rechnung: GetRechnungDetailsDto | null;
  accountId?: string;
  userId?: string;
  onPaymentSuccess: () => void;
}

const PayRechnungDialog: FC<PayRechnungDialogProps> = ({
  open,
  onClose,
  rechnung,
  accountId,
  userId,
  onPaymentSuccess,
}) => {
  const transactionsStore = useStore(TransactionsContext);
  const { createBankTransfer } = transactionsStore;

  const [loadingRows, setLoadingRows] = useState<Record<string, boolean>>({});
  const [payAmount, setPayAmount] = useState<number>(0);

  useEffect(() => {
    if (rechnung && rechnung.due > 0) {
      setPayAmount(rechnung.due);
    } else {
      setPayAmount(0);
    }
  }, [rechnung]);

  const handleConfirm = async () => {
    if (!rechnung) return;
    try {
      const dto: CreateBankTransferDto = {
        method: recnungTransactionMethods.BANK_TRANSFER,
        text: "Bezahlung für Rechnung " + rechnung.rechnungNumber,
        type: transactionTypes.EINNAHME,
        amount: payAmount,
        rechnungAllocations: [
          {
            rechnungId: rechnung.id,
            paidAmount: payAmount,
          },
        ],
        accountId: accountId || "",
        userId: userId || "",
        patientId: Number(rechnung?.patientId) || undefined,
        rxId: rechnung?.rxId,
      };

      setLoadingRows((prev) => ({ ...prev, [rechnung.rxNumber]: true }));
      await createBankTransfer(dto);
      onPaymentSuccess();
      onClose();
    } catch (error) {
      console.error("Error paying rechnung:", error);
      notificationStore.showMessage("Fehler beim Bezahlen der Rechnung", "error");
    } finally {
      setLoadingRows((prev) => ({ ...prev, [rechnung.rxNumber]: false }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Rechnung Bezahlen</DialogTitle>
      <DialogContent>
        {rechnung && (
          <>
            <p>Rechnungnummer: {rechnung.rechnungNumber}</p>
            <p>Rezeptnummer: {rechnung.rxNumber}</p>
            <p>Fällig: {germanNumberFormatter.format(rechnung.due)} €</p>
            <TextField
              label="Bezahlbetrag"
              type="number"
              value={payAmount}
              onChange={(e) => setPayAmount(parseFloat(e.target.value))}
              fullWidth
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
        >
          {rechnung && loadingRows[rechnung.rxNumber ?? ""] ? (
            <>
              <CircularProgress
                size={16}
                color="inherit"
              />
              <span style={{ marginLeft: 8 }}>Bitte warten...</span>
            </>
          ) : (
            "Einreichen"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayRechnungDialog;
