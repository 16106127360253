import React, { useState } from "react";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import {
  Description,
  Cancel,
  Notes as NotesIcon,
  Close,
  Delete,
  Info,
  EventRepeat,
  ContentPaste,
  Done,
  Preview,
} from "@mui/icons-material";
import { Event, TimeSlotExt } from "./Calendar.type";
import { useNavigate } from "react-router-dom";
import AppointmentNotes from "./AppointmentNotes";
import EditIcon from '@mui/icons-material/Edit';
import AppointmentOverviewModal from "../../components/PatientOverview/RxInfo/AppointmentOverviewModal";

interface ContextMenuProps {
  event: Event | undefined;
  timeSlot: TimeSlotExt;
  open: boolean;
  continuous: boolean;
  mouseX: number | null;
  mouseY: number | null;
  onClose: () => void;
  onRemoveGoneFishing: (event: Event) => void;
  onRemoveEventTimeSlot: (event: Event) => void;
  onRemoveContinuousEventTimeSlot: (event: Event) => void;
  onRemoveLeaveOfAbsence: (event: Event) => void;
  onTimeSlotClick?: (clickInfo: TimeSlotExt) => void;
  onAddShelfEvent: (event: Event) => void;
  onMoveEvent: (event: Event) => void;
  onCancelAppointment: (timeSlot: TimeSlotExt) => void;
  onCancelContinuousAppointment: (timeSlot: TimeSlotExt) => void;
  onToggleAttended: (timeSlot: TimeSlotExt) => void;
  onEditAppointment: (timeSlot: TimeSlotExt) => void;
  onCloseNotesDialog: () => void;
  onAppointmentOverview: (patientId: number) => void;
}

export interface MousePosition {
  mouseX: null | number;
  mouseY: null | number;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  event,
  timeSlot,
  open,
  continuous,
  mouseX,
  mouseY,
  onClose,
  onRemoveGoneFishing,
  onRemoveEventTimeSlot,
  onRemoveContinuousEventTimeSlot,
  onRemoveLeaveOfAbsence,
  onTimeSlotClick,
  onMoveEvent,
  onAddShelfEvent,
  onCancelAppointment,
  onEditAppointment,
  onCancelContinuousAppointment,
  onToggleAttended,
  onCloseNotesDialog,
  onAppointmentOverview
}) => {
  const navigate = useNavigate();
  const [showNotesDialog, setShowNotesDialog] = useState<boolean>(false);
  const handleTimeSlotKeyDown = (e: any) => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      case "p":
        onPatientClick();
        break;
      case "v":
        onMoveEvent(event!);
        break;
      case "n":
        onNotesClick();
        break;
      case "r":
        onRxClick();
        break;
      case "x":
        console.log("Cancel");
        onCancelAppointment(timeSlot);
        break;
      case "w":
        console.log("Attended");
        onToggleAttended(timeSlot);
        break;
      case "b":
        console.log("Edit");
        onEditAppointment(timeSlot);
        break;

      default:
        break;
    }
  };

  const handleGoneFishingKeyDown = (e: any) => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      case "d":
        onRemoveGoneFishing(event!);
        break;
      default:
        break;
    }
  };

  const handleEventTimeSlotKeyDown = (e: any) => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      case "d":
        onRemoveEventTimeSlot(event!);
        break;
      default:
        break;
    }
  }

  const handleContinuousEventTimeSlotKeyDown = (e: any) => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      case "d":
        onRemoveContinuousEventTimeSlot(event!);
        break;
      default:
        break;
    }
  }

  const handleLeaveOfAbsenceKeyDown = (e: any) => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      case "d":
        onRemoveLeaveOfAbsence(event!);
        break;
      default:
        break;
    }
  }

  const handleDefualtKeyDown = (e: any) => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      case "i":
        onAddShelfEvent(event!);
        break;
      default:
        break;
    }
  };

  const onPatientClick = () => {
    navigate(`/patient?pId=${timeSlot.patient?.id}&termin`);
  }

  const onRxClick = () => {
    navigate(`/patient?pId=${timeSlot.patient?.id}&rxinfo&rxId=${timeSlot.therapyRx?.rx?.id}`);
  }

  const onNotesClick = () => {    
    setShowNotesDialog(true);
    onClose();
  }

  const handleShowOverviewAppointmentModal = () => {
    onClose();
    return onAppointmentOverview(timeSlot?.patient?.id!);
  };
  
  const onCloseNotesDialogEvent = () => {
    setShowNotesDialog(false);
    return onCloseNotesDialog();
  }

  switch (event?.type) {
    case "TimeSlot":
      return (
        <>
          <Menu
            keepMounted
            open={open}
            onClose={onClose}
            onKeyDown={handleTimeSlotKeyDown}
            anchorReference="anchorPosition"
            anchorPosition={
              mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
            }
          >
            <MenuList>
              <MenuItem onClick={onPatientClick}>
                <ListItemIcon>
                  <Info fontSize="small" />
                </ListItemIcon>
                <ListItemText>Patient</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  ⌘P
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleShowOverviewAppointmentModal}>
                <ListItemIcon>
                  <Preview fontSize="small" />
                </ListItemIcon>
                {/* LANGUAGE */}
                <ListItemText>Alle Termine</ListItemText>    
                <Typography variant="body2" color="text.secondary">
                  Ü
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => onMoveEvent(event)}>
                <ListItemIcon>
                  <EventRepeat fontSize="small" />
                </ListItemIcon>
                <ListItemText>Umziehen</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  V
                </Typography>
              </MenuItem>
              {!continuous && 
                <MenuItem onClick={onNotesClick}>
                  <ListItemIcon>
                    <NotesIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Notizen</ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    ⌘N
                  </Typography>
                </MenuItem>
              }
              <MenuItem onClick={onRxClick}>
                <ListItemIcon>
                  <Description fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rezept</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  ⌘R
                </Typography>
              </MenuItem>
              {timeSlot && !timeSlot.isCancelled&&  <MenuItem onClick={() => continuous ? onCancelContinuousAppointment(timeSlot) : onCancelAppointment(timeSlot)}>
                <ListItemIcon>
                  <Cancel fontSize="small" />
                </ListItemIcon>
                <ListItemText>Stornieren</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  ⌘X
                </Typography>
              </MenuItem>}
              {timeSlot && !timeSlot.isCancelled && <MenuItem onClick={() => onEditAppointment(timeSlot)}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Bearbeiten</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  B
                </Typography>
              </MenuItem>}
              {!continuous && (timeSlot && !timeSlot.isCancelled) &&
                <MenuItem onClick={() => onToggleAttended(timeSlot)}>
                  <ListItemIcon>
                    <Done fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ mr: 3 }} >Wahrgenommen</ListItemText>
                  <Typography variant="body2" color="text.secondary"  >
                    W
                  </Typography>
                </MenuItem>
              }
              <Divider />
              <MenuItem onClick={onClose}>
                <ListItemIcon>
                  <Close fontSize="small" />
                </ListItemIcon>
                <ListItemText>Close</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  Esc
                </Typography>
              </MenuItem>
            </MenuList>
          </Menu>
          {showNotesDialog && 
            <AppointmentNotes appointmentId={timeSlot.appointmentId!} showDialog={showNotesDialog} onClose={onCloseNotesDialogEvent}/>
          }
        </>
      );
    case "GoneFishing":
      return (
        <Menu
          keepMounted
          open={open}
          onClose={onClose}
          onKeyDown={handleGoneFishingKeyDown}
          anchorReference="anchorPosition"
          anchorPosition={
            mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
          }
        >
          <MenuList>
            <MenuItem onClick={() => onRemoveGoneFishing(event)}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘D
              </Typography>
            </MenuItem>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              <ListItemText>Close</ListItemText>
              <Typography variant="body2" color="text.secondary">
                Esc
              </Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      );
    case "EventTimeSlot":
      return (
        <Menu
          keepMounted
          open={open}
          onClose={onClose}
          onKeyDown={handleEventTimeSlotKeyDown}
          anchorReference="anchorPosition"
          anchorPosition={
            mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
          }
        >
          <MenuList>
            <MenuItem onClick={() => onRemoveEventTimeSlot(event)}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘D
              </Typography>
            </MenuItem>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              <ListItemText>Close</ListItemText>
              <Typography variant="body2" color="text.secondary">
                Esc
              </Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      );
      case "TravelTime":
        return (
          <Menu
            keepMounted
            open={open}
            onClose={onClose}
            onKeyDown={handleEventTimeSlotKeyDown}
            anchorReference="anchorPosition"
            anchorPosition={
              mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
            }
          >
            <MenuList>
              <MenuItem onClick={() => onRemoveEventTimeSlot(event)}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  ⌘D
                </Typography>
              </MenuItem>
              <MenuItem onClick={onClose}>
                <ListItemIcon>
                  <Close fontSize="small" />
                </ListItemIcon>
                <ListItemText>Close</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  Esc
                </Typography>
              </MenuItem>
            </MenuList>
          </Menu>
        );
    case "LeaveOfAbsence":
      return (
        <Menu
          keepMounted
          open={open}
          onClose={onClose}
          onKeyDown={handleLeaveOfAbsenceKeyDown}
          anchorReference="anchorPosition"
          anchorPosition={
            mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
          }
        >
          <MenuList>
            <MenuItem onClick={() => onRemoveLeaveOfAbsence(event)}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘D
              </Typography>
            </MenuItem>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              <ListItemText>Close</ListItemText>
              <Typography variant="body2" color="text.secondary">
                Esc
              </Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      );
    case "ShelfEvent":
      return (
        <Menu
          keepMounted
          open={open}
          onClose={onClose}
          onKeyDown={handleDefualtKeyDown}
          anchorReference="anchorPosition"
          anchorPosition={
            mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
          }
        >
          <MenuList>
            <MenuItem onClick={() => onAddShelfEvent(event!)}>
              <ListItemIcon>
                <ContentPaste fontSize="small" />
              </ListItemIcon>
              <ListItemText>Einfügen</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘I
              </Typography>
            </MenuItem>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              <ListItemText>Close</ListItemText>
              <Typography variant="body2" color="text.secondary">
                Esc
              </Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      );
    case "ContinuousEventTimeSlot":
      return (
        <Menu
          keepMounted
          open={open}
          onClose={onClose}
          onKeyDown={handleContinuousEventTimeSlotKeyDown}
          anchorReference="anchorPosition"
          anchorPosition={
            mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
          }
        >
          <MenuList>
            <MenuItem onClick={() => onRemoveContinuousEventTimeSlot(event)}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘D
              </Typography>
            </MenuItem>
            <MenuItem onClick={onClose}>
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              <ListItemText>Close</ListItemText>
              <Typography variant="body2" color="text.secondary">
                Esc
              </Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      );
      default:
      return null;
  }
};

export default ContextMenu;
