import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import AccountSelectionDialog from "./AccountSelectionDialog";
import useStore from "../../../../../helpers/useStore";
import { UserContext } from "../../../../../stores/User/User.provider";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

interface PaymentMethodDialogProps {
  open: boolean;
  handleClose: () => void;
}

const PaymentMethodDialog: FC<PaymentMethodDialogProps> = ({ open, handleClose }) => {
  const [openDialog, setOpenDialog] = useState({
    direct: false,
    indirect: false,
  });

  const UserStore = useStore(UserContext);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    UserStore.getUser()
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  }, [UserStore]);

  const userName = user?.firstName + " " + user?.lastName;
  const userID = user?.id;
  const userLocations = user?.location.accounts;

  console.log("User Locations", userLocations);

  const handleOpenDialog = (type: string) => {
    setOpenDialog({ ...openDialog, [type]: true });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Art des Zahlungsdienstes"}</DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          <Grid
            container
            spacing={1}
            justifyContent={"space-evenly"}
          >
            <Grid item>
              {<Button onClick={() => handleOpenDialog("indirect")}>INDIREKT</Button>}
              {openDialog.indirect && (
                <AccountSelectionDialog
                  paymentType="indirect"
                  isOpen={openDialog.indirect}
                  onCancel={handleClose}
                  userName={userName}
                  userId={userID}
                  userAccounts={userLocations}
                />
              )}
            </Grid>
            <Grid item>
              {<Button onClick={() => handleOpenDialog("direct")}>DIREKT</Button>}
              {openDialog.direct && (
                <AccountSelectionDialog
                  paymentType="direct"
                  isOpen={openDialog.direct}
                  onCancel={handleClose}
                  userName={userName}
                  userId={userID}
                  userAccounts={userLocations}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentMethodDialog;
