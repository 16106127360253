import { action, makeObservable, observable, reaction } from "mobx";
import {
  DoctorReportStausDto,
  StorageApi,
  UploadDocumentDto,
  UploadDocumentMetadataDto,
} from "../../api-client";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";

export default class StorageStore {
  public apiClient: StorageApi;
  public authStore: AuthStore;

  public constructor(apiClient: StorageApi, authStore: AuthStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.authStore = authStore;
  }

  @action
  public uploadingDocument = async (body: UploadDocumentDto) => {
    try {
      const response = await this.apiClient.uploadDocument(
        body.docuType,
        body.ownerType,
        body.ownerId,
        body.file,
        body.makeAccessible,
        body.rxId
      );
      notificationStore.showMessage("Document uploaded successfully!", "success");
  
      return response;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to upload Document";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
}
