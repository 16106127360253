import React, { FC, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Box,
  Collapse,
} from "@mui/material";
import { AllTransactionMethods } from "../types/kasse.types";
import {
  BaseTransactionDetailsDto,
  GetBankTransferDto,
  GetRechnungDetailsDto,
} from "../../../../api-client";
import "../../Insurance/DirectBilling/common/helpers/css/direct-bill-info.css";
import { formatDateUI } from "../../../../helpers/dateFormat";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { useDownload } from "../../../../hooks/useDownload";

interface TransactionTableProps {
  transactionMethod: AllTransactionMethods;
  transactions: any[];
}

const TransactionTable: FC<TransactionTableProps> = ({ transactionMethod, transactions }) => {
  // State to track which rows are open for RECHNUNG
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});
  const { handleDownloadPdf } = useDownload();

  const handleToggleRow = (rechnungNumber: string) => {
    setOpenRows((prev) => ({
      ...prev,
      [rechnungNumber]: !prev[rechnungNumber],
    }));
  };

  let headers: string[] = [];
  let renderRow: (item: any) => JSX.Element;

  switch (transactionMethod) {
    /**
     * CASH, EC, and LASTSCHRIFT share the BaseTransactionDetailsDto shape
     */
    case "CASH":
    case "EC":
    case "LASTSCHRIFT":
      headers = [
        "Transaktionnummer",
        "Rezeptnummer",
        "RezeptDatum",
        "Patient",
        "Motiv",
        "Typ",
        "Erstellt von",
        "Mandant",
        "Erstellt am",
        "Preis (€)",
        "Exportiert",
        "Dokumentieren",
      ];
      renderRow = (item: BaseTransactionDetailsDto) => (
        <TableRow key={item.transactionNumber}>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.transactionNumber}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.rx ? item.rx.rxNumber : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.rx?.rxDate ? formatDateUI(item.rx.rxDate) : "Keine Datum"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.patient ? `${item.patient.lastName}, ${item.patient.firstName}` : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.text}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.type}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.user ? `${item.user.lastName}, ${item.user.firstName}` : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.account ? item.account.name : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{formatDateUI(item.createdAt)}</TableCell>
          <TableCell
            sx={{ padding: "5px 0px 5px 5px", color: item.amount < 0 ? "red" : "inherit" }}
          >
            {item.amount < 0
              ? `(${Math.abs(Number(item.amount)).toFixed(2).replace(".", ",")} €)`
              : `${Number(item.amount).toFixed(2).replace(".", ",")} €`}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.wasExported ? "Yes" : "No"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.docDto && (
              <IconButton onClick={() => handleDownloadPdf(item.docDto!.id)}>
                <PictureAsPdfIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      );
      break;

    /**
     * BANK_TRANSFER uses GetBankTransferDto with AllocationsDto[]
     * We split out each allocation property into its own column
     */
    case "BANKTRANSFER":
      headers = [
        "Transaktionnummer",
        "Rezeptnummer",
        "RezeptDatum",
        "Patient",
        "Motiv",
        "Typ",
        "Erstellt von",
        "Mandant",
        "Erstellt am",
        "Preis (€)",
        "Exportiert",
        "Rechnungnummer",
        // "Dokumentieren",
      ];
      renderRow = (item: GetBankTransferDto) => (
        <TableRow key={item.transactionNumber}>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.transactionNumber}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.rxNumber || ""}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.rxDate ? formatDateUI(item.rxDate) : "Keine Datum"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.patientName}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.text}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.type}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.userName}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.accountName}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{formatDateUI(item.createdAt)}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {Number(item.amount).toFixed(2).replace(".", ",") + " €"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.wasExported ? "Yes" : "No"}
          </TableCell>

          {/* Allocation: Rechnung Number */}
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.allocations && item.allocations.length > 0
              ? item.allocations.map((alloc) => alloc.rechnungNumber).join(", ")
              : "None"}
          </TableCell>
          {/* <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {}
            <IconButton onClick={() => {}}>
              <PictureAsPdfIcon />
            </IconButton>
          </TableCell> */}
        </TableRow>
      );
      break;

    /**
     * RECHNUNG uses GetRechnungDetailsDto with BankTransferAllocationDto[]
     */
    case "RECHNUNG":
      headers = [
        "",
        "Rechnungnummer",
        "Rezeptnummer",
        "RezeptDatum",
        "Patient",
        "Motiv",
        "Erstellt von",
        "Mandant",
        "Erstellt am",
        "Gesaamtpreis (€)",
        "Bezahlt (€)",
        "Fällig (€)",
        "Dokumentieren",
      ];
      renderRow = (item: GetRechnungDetailsDto) => {
        const isOpen = openRows[item.rechnungNumber] || false;
        return (
          <React.Fragment key={item.rechnungNumber}>
            {/* Main row */}
            <TableRow>
              {/* Expand/Collapse Icon Cell */}
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                <IconButton
                  size="small"
                  onClick={() => handleToggleRow(item.rechnungNumber)}
                  aria-label="expand row"
                >
                  {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>

              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.rechnungNumber}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.rxNumber}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {item.rxDate ? formatDateUI(item.rxDate) : "Keine Datum"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {`${item.patientLastName}, ${item.patientFirstName}`}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.text || ""}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.userName || ""}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.accountName || ""}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {formatDateUI(item.createdAt)}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {Number(item.amount).toFixed(2).replace(".", ",") + " €"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {Number(item.totalPaid).toFixed(2).replace(".", ",") + " €"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {Number(item.due).toFixed(2).replace(".", ",") + " €"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                <IconButton onClick={() => handleDownloadPdf(item.docDto!.id)}>
                  <PictureAsPdfIcon />
                </IconButton>
              </TableCell>
            </TableRow>

            {/* Collapsible row (contains allocations) */}
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={headers.length}
              >
                <Collapse
                  in={isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box margin={1}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      Banküberweisungen
                    </Typography>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Transaktionnummer</TableCell>
                          <TableCell>Bezahlter Betrag (€)</TableCell>
                          <TableCell>Bezahlt am</TableCell>
                          <TableCell>Erstellt von</TableCell>
                          {/* <TableCell>Document</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.allocations && item.allocations.length > 0 ? (
                          item.allocations.map((alloc) => (
                            <TableRow key={alloc.transactionNumber}>
                              <TableCell>{alloc.transactionNumber}</TableCell>
                              <TableCell>
                                {Number(alloc.paidAmount).toFixed(2).replace(".", ",")} €
                              </TableCell>
                              <TableCell>{formatDateUI(alloc.createdAt)}</TableCell>
                              <TableCell>
                                {`${alloc.userLastName}, ${alloc.userFirstName}`}
                              </TableCell>
                              {/* <TableCell>
                                <IconButton onClick={() => {}}>
                                  <PictureAsPdfIcon />
                                </IconButton>
                              </TableCell> */}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2}>Keine Banküberweisungen vorhanden</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      };
      break;

    default:
      headers = ["No columns defined"];
      renderRow = () => <TableRow />;
  }

  const sortedTransactions = transactions.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => (
              <TableCell
                key={idx}
                sx={{ fontWeight: "bold" }}
                className="table-header-cell"
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTransactions && sortedTransactions.length > 0 ? (
            sortedTransactions.map((transaction) => renderRow(transaction))
          ) : (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <Typography align="center">
                  Für die ausgewählten Daten wurden keine Transaktionen gefunden.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionTable;
