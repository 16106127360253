import React from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

interface LoadingProps {
  showLoading: boolean;
  title?: string;
}

const Loading: React.FC<LoadingProps> = ({ showLoading, title }) => {
  return (
    <Backdrop open={showLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <CircularProgress color="inherit" />
      {title && <Typography sx={{ marginLeft: 2 }}>{title}</Typography>}
    </Backdrop>
  );
};

export default Loading;