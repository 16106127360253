import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { Grid, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { DoctorReportDto } from "../../../../api-client";


interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: any;
  editMode?: boolean;
}

const PrognosticAssessmentCard=({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps)=> {
  const [therapyAssessment, setTherapyAssessment] = useState("");

  useEffect(() => {
    if (doctorReport) {
      setTherapyAssessment(doctorReport.therapyAssessment!);
    }
  }, [doctorReport]);

  const handleChangeDoctorReport = (key: string, value: any) => {
    let updatedDoctorReport = Object.assign(doctorReport, {
      [key]: value,
    });

    setDoctorReport(updatedDoctorReport);
  };

  return (
    <DoctorReportCard title="Prognostische Einschätzung des Therapeutics" editMode={editMode}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Therapeutische Einschätzung"
            placeholder="Text eingeben..."
            multiline
            rows={4}
            value={therapyAssessment}
            onChange={(e) => {
              setTherapyAssessment(e.target.value);
              handleChangeDoctorReport("therapyAssessment", e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </DoctorReportCard>
  );
}

export default PrognosticAssessmentCard;
