import { action, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { RxApi, RxDto, RxSeriesDto } from "../../api-client";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";
import patientsStore from "../Patients/patientsStore"; // Import the PatientsStore

export default class RxStore {
  public apiClient: RxApi;
  public authStore: AuthStore;
  public patientsStore: patientsStore;

  public constructor(apiClient: RxApi, authStore: AuthStore, patientsStore: patientsStore) {
    this.apiClient = apiClient;
    this.authStore = authStore;
    this.patientsStore = patientsStore;
  }

  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  @observable
  public savedSeriesForPatient: any = [];

  @action
  public setSavedSeriesForPatient = (savedSeries: RxSeriesDto[]) => {
    this.savedSeriesForPatient = savedSeries;
  };

  public getRxWithNoAppointments = async (patientId: number) => {
    const { data } = (await this.apiClient.getRxLackingAppointments(patientId)) as any;
    return data || [];
  };

  public getAppStats = async (rxIds: string[]) => {
    try {
      const { data } = await this.apiClient.getAppStats(rxIds);
      return data;
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage =
        error.response?.data?.message || "Fehler beim Abrufen der Terminstatistiken";

      notificationStore.showMessage(errorMessage, "error", error);
      return [];
    }
  };

  public copyToContinous = async (rxId: string) => {
    const { data } = (await this.apiClient.copyToContinuous(rxId)) as any;
    return data || [];
  };

  public deleteRx = async (rxId: string) => {
    try {
      const { data } = (await this.apiClient.deleteRx(rxId, true)) as any;
      if (data) {
        notificationStore.showMessage("Rezept erfolgreich gelöscht!!", "success");
      }
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Rezept konnte nicht gelöscht werden!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  public saveRxs = async (patientId: number, rxDto: RxDto[]) => {
    try {
      const { data } = (await this.apiClient.patientSaveRxs(patientId, rxDto)) as any;
      // Call the updatePatientData action from PatientsStore
      await this.patientsStore.updatePatientData(patientId);

      // Check if data.message exists and is an array with at least one element
      if (data && data.length > 0 && data[0].messages) {
        const messages = data.flatMap((item: any) => item.messages.map((msg: any) => msg.message));
        // Display the first message from data.message
        notificationStore.showMessage(messages.join("\n"), "success");
      } else {
        // Display a default success message if no specific message is found
        notificationStore.showMessage("Rezepte erfolgreich gespeichert!", "success");
      }

      return data || [];
    } catch (error: any) {
      console.error("Error fetching rxs:", error);
      const errorMessage = error.response?.data?.message || "Fehler beim Speichern von Rezepten";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public updateprocessingStatus = async (
    rxId:string,
    status:string
  ) => {
    try {
      const { data } = (await this.apiClient.updateProcessingStatus(rxId,status)) as any;
      notificationStore.showMessage(
        data,
        "success"
      );
      await this.patientsStore.updatePatientData(this.patientsStore.filteredPatient);
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Fehler beim Aktualisieren des Status!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public getRxSeriesForPatient = async (patientId: number): Promise<RxSeriesDto[]> => {
    try {
      const { data } = await this.apiClient.getRxSeriesForPatient(patientId);

      return data;
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der Serien";

      notificationStore.showMessage(errorMessage, "error", error);
      return [];
    }
  };

  @action
  public getTherapyRxsSeriesForPatient = async (patientId: number) => {
    const { data } = (await this.apiClient.getTherapyRxsSeriesForPatient(patientId)) as any;
    return data || [];
  };

  @action
  public getAllRx = async (patientId: number) => {
    const { data } = (await this.apiClient.getAllRx(patientId)) as any;
    return data || [];
  };


  @action
  public addNewSeriesForPatient = async (patientId: number, rxSeriesDto: RxSeriesDto) => {
    try {
      const { data } = (await this.apiClient.addNewSeriesToPatient(patientId, rxSeriesDto)) as any;
      notificationStore.showMessage(
        "Neue Serie erfolgreich zum Patienten hinzugefügt!!",
        "success"
      );
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage =
        error.response?.data?.message || "Neue Serie konnte nicht hinzugefügt werden!!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public deleteSeries = async (seriesId: string) => {
    try {
      const { data } = (await this.apiClient.deleteSeries(seriesId)) as any;
      notificationStore.showMessage("Serie erfolgreich gelöscht!", "success");
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage =
        error.response?.data?.message || "Die Serie konnte nicht gelöscht werden!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public updateSeries = async (seriesId: string, patientId: number, updateData: RxSeriesDto) => {
    try {
      const { data } = (await this.apiClient.updateSeries(seriesId, patientId, updateData)) as any;
      notificationStore.showMessage("Serie erfolgreich aktualisiert!", "success");
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage =
        error.response?.data?.message || "Aktualisierung der Serie fehlgeschlagen!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public updateSeriesForTherapyRx = async (therapyRxId: string, seriesId: string) => {
    try {
      const { data } = (await this.apiClient.updateSeriesForTherapyRx(
        therapyRxId,
        seriesId
      )) as any;
      notificationStore.showMessage("Serie erfolgreich aktualisiert!", "success");
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage =
        error.response?.data?.message || "Aktualisierung der Serie fehlgeschlagen!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };


  @action
  public getRxByNumber = async (rxId: string):Promise<RxDto | null> => {
    try {
      const { data } = await this.apiClient.getRxByNumber(rxId) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage = error.response?.data?.message || "Rezept konnte nicht abgerufen werden!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public getRxPrice = async (rxNumber: string) => {
    try {
      const { data } = (await this.apiClient.getRxPrice(rxNumber)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage = error.response?.data?.message || "Preis konnte nicht abgerufen werden!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public updateIsTaxieren = async (rxId: string, isTaxieren: string) => {
    try {
      const { data } = (await this.apiClient.updateIsTaxieren(rxId, isTaxieren)) as any;
      return data || [];
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Fehler beim Aktualisieren des Taxierungsstatus!";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  }

  //   @action
  //   public postPatients = async (body:PatientDto) => {
  //     try{
  //     const { data } = (await this.apiClient.postPatients(body)) as any;
  //     return data || [];}
  //     catch (error) {
  //       this.resourceRequest = fromPromise.reject(error);

  //     }
}
