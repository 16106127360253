import Plot from "react-plotly.js";
import { Layout } from "plotly.js";
import * as d3 from "d3";


interface LineChartProps {
    countData: { locationId: string, date: Date, newOngoingRxs: number, newCompletedRxs: number }[];
}

const NewRxCountsPlot: React.FC<LineChartProps> = ({countData}) => {
    const groupedData = Object.groupBy(countData, ({ locationId }) => locationId);

    const traces: { ongoing: Plotly.Data[], completed: Plotly.Data[] } = {
        ongoing: [],
        completed: []
    };

    const numLocations = Object.keys(groupedData).length
    const colorPalette = d3.scaleOrdinal(d3['schemeTableau10']).range().slice(0, numLocations);

    Object.keys(groupedData).forEach((locationId, index) => {
        const color = colorPalette[index % colorPalette.length];

        const trace1: Plotly.Data = {
            x: groupedData[locationId]?.map(entry => entry.date),  
            y: groupedData[locationId]?.map(entry => entry.newOngoingRxs), 
            type: 'scatter',
            mode: 'lines+markers',
            name: locationId,
            line: { color: color },
        };

        traces.ongoing.push(trace1);


        const trace2: Plotly.Data = {
            x: groupedData[locationId]?.map(entry => entry.date),  
            y: groupedData[locationId]?.map(entry => entry.newCompletedRxs), 
            type: 'scatter',
            mode: 'lines+markers',
            name: locationId, 
            xaxis: 'x2',
            yaxis: 'y2',
            line: { color: color },
            showlegend: false,
            // cliponaxis: true         
        };

        traces.completed.push(trace2);
    });

    const layout: Partial<Layout> = {
        title: {
            text: 'Number of new Rxs',
        },
        grid: {rows: 2, columns: 1, pattern: 'independent'},
        height: 700,
        xaxis: {
            type: 'date',
            tickformat: '%d.%m.%Y',
            tickmode: 'linear',
            dtick: 'M1',
            // rangemode: 'tozero'
        },
        xaxis2: {
            type: 'date',
            tickformat: '%d.%m.%Y',
            tickmode: 'linear',
            dtick: 'M1',
        },
        yaxis: {
            domain: [0.6, 1],
            rangemode: 'tozero',
            // title: "in Behandlung"
        },
        yaxis2: {
            domain: [0, 0.4],
            rangemode: 'tozero',
            // title: "abgeschlossen"
        },
        showlegend: true,
        annotations: [
            {
                x: 0,   
                y: 1.025,    
                xref: 'paper',
                yref: 'paper',
                text: 'in Behandlung',
                font: {
                    weight: 700,
                }, 
                showarrow: false,
            },
            {
                x: 0,
                y: 0.4,
                xref: 'paper',
                yref: 'paper',
                text: 'abgeschlossen',  
                font: {
                    weight: 700,
                }, 
                showarrow: false,
            }
        ],
    };

    return <Plot data={[...traces.ongoing, ...traces.completed]} layout={layout} />;
}

export default NewRxCountsPlot;