import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { Button, Grid, InputAdornment, Switch, TextField, FormControlLabel, Typography } from "@mui/material";
import moment from "moment";
import { DoctorReportDto } from "../../../../api-client";
import { DatePicker } from "@mui/x-date-pickers";

interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: any;
  editMode?: boolean;
}

const TreatmentDiscontinuationCard = ({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps) => {
  const [treatmentTerminationDate, setTreatmentTerminationDate] = useState<Date | null>(null);
  const [treatmentTerminationAfter, setTreatmentTerminationAfter] = useState<number>(0);
  const [treatmentTerminationReason, setTreatmentTerminationReason] = useState("");
  const [isTreatmentDiscontinued, setTreatmentDiscontinued] = useState<boolean | null>(null);
  const dateTimeFormat = "YYYY-MM-DD"; // Store as YYYY-MM-DD

  useEffect(() => {
    if (doctorReport) {
      if (doctorReport.treatmentTerminationDate) {
        // Ensure it's a valid Date object
        setTreatmentTerminationDate(new Date(doctorReport.treatmentTerminationDate as any));
      }
      setTreatmentTerminationAfter(doctorReport.treatmentTerminationAfter || 0);
      setTreatmentTerminationReason(doctorReport.treatmentTerminationReason || "");
      setTreatmentDiscontinued(doctorReport.isTreatmentDiscontinued || false);
    }
  }, [doctorReport]);

  const handleChangeDoctorReport = (key: string, value: any) => {
    const updatedDoctorReport = { ...doctorReport, [key]: value };
    setDoctorReport(updatedDoctorReport);
  };

  return (
    <DoctorReportCard title="Behandlungsabbruch" editMode={editMode}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography>Behandlung abgebrochen</Typography>
          </Grid>
          <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isTreatmentDiscontinued || false}
                onChange={() => {
                  const newValue = !(isTreatmentDiscontinued || false);
                  setTreatmentDiscontinued(newValue);
                  handleChangeDoctorReport("isTreatmentDiscontinued", newValue);
                }}
              />
            }
            label=""
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            label="Behandlungsdatum"
            value={treatmentTerminationDate}
            onChange={(date: any) => {
              // Set the state as Date object (no formatting here)
              setTreatmentTerminationDate(date);

              // Store the date in YYYY-MM-DD format for the doctor report
              // const formattedDate = date ? moment(date).format("YYYY-MM-DD") : null;
              handleChangeDoctorReport("treatmentTerminationDate", date)
            }}
            disableFuture
            disabled={!isTreatmentDiscontinued}
            // format={dateTimeFormat} // Use 'inputFormat' for DatePicker to show full date
          />
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <TextField
              label="nach"
              value={treatmentTerminationAfter}
              // InputProps={{
              //   startAdornment: <InputAdornment position="start">Termineinheiten</InputAdornment>,
              // }}
              variant="outlined"
              fullWidth
              disabled={!isTreatmentDiscontinued}
              onChange={(e) => {
                const newValue = Math.max(0, parseInt(e.target.value, 10) || 0);
                setTreatmentTerminationAfter(newValue);
                handleChangeDoctorReport("treatmentTerminationAfter", newValue);
              }}
            />
          </Grid>

          <Grid item>
            <Button
             variant="contained"
              color="primary"
              onClick={() => {
                if (treatmentTerminationAfter > 0) {
                  const newValue = treatmentTerminationAfter - 1;
                  setTreatmentTerminationAfter(newValue);
                  handleChangeDoctorReport("treatmentTerminationAfter", newValue);
                }
              }}
              disabled={!isTreatmentDiscontinued}
            >
              -
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const newValue = treatmentTerminationAfter + 1;
                setTreatmentTerminationAfter(newValue);
                handleChangeDoctorReport("treatmentTerminationAfter", newValue);
              }}
              disabled={!isTreatmentDiscontinued}
            >
              +
            </Button>
            Termineinheiten
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Begründung"
            placeholder="Begründung eingeben"
            value={treatmentTerminationReason}
            onChange={(e) => {
              setTreatmentTerminationReason(e.target.value);
              handleChangeDoctorReport("treatmentTerminationReason", e.target.value);
            }}
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            disabled={!isTreatmentDiscontinued}
          />
        </Grid>
      </Grid>
    </DoctorReportCard>
  );
};

export default TreatmentDiscontinuationCard;
