import { useState } from "react";
import useStore from "../helpers/useStore";
import { StorageContext } from "../stores/Storage/Storage.provider";
import { UploadDocumentMetadataDto } from "../api-client";
import { Snackbar } from "@mui/material";
import notificationStore from "../stores/Notification/notificationStore";

const useFileUpload=() =>{
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");
  const StorageStore = useStore(StorageContext);
  const {uploadingDocument}=StorageStore;

  const uploadDocument = async (file: File, metadata: UploadDocumentMetadataDto) => {
    setIsUploading(true);
    setError("");
    try {
     await uploadingDocument({ ...metadata, file });
    } catch (error) {
      notificationStore.showMessage("Error while uploading Document","error")
    } finally {
      setIsUploading(false);
    }
  };

  return {
    isUploading,
    uploadDocument,
    error
  };
}
export default useFileUpload;
