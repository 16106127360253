import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { TextField, Typography, CardContent } from "@mui/material";
import { DoctorReportDto } from "../../../../api-client";
import { observer } from "mobx-react";


interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: any;
  editMode?: boolean;
}

const CurrentTherapyStatusCard=observer(({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps) =>{
  const [therapyStatus, setTherapyStatus] = useState("");

  useEffect(() => {
    if (doctorReport) {
      setTherapyStatus(doctorReport.therapyStatus!);
    }
  }, [doctorReport]);

  const handleChange = (value: string) => {
    setTherapyStatus(value);
    setDoctorReport({ ...doctorReport, therapyStatus: value });
  };

  return (
    <DoctorReportCard title="Stand der Therapie (aktueller Befund)" editMode={editMode}>
      <CardContent>
        <Typography variant="body2" style={{ marginBottom: "8px" }}>
          Anmerkungen zum Termin
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Text eingeben"
          value={therapyStatus}
          onChange={(e) => handleChange(e.target.value)}
          disabled={!editMode}
        />
      </CardContent>
    </DoctorReportCard>
  );
})

export default CurrentTherapyStatusCard;
