import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import useStore from "../../../helpers/useStore";
import { AppointmentDto, AppointmentQueryFilters } from "../../../api-client";
import TerminView, { formatGap } from "./TerminView";
import { TerminViewConstants } from "../TerminView";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";
import { formatDateUI, formatTime } from "../../../helpers/dateFormat";
import Controls from "../../../atoms/Controls";
import { backgroundColourCheck, getColorForRxNumber } from "../../../helpers/statusColor";
import { TherapyChip } from "../../../atoms/Chip";
import { visitTypeToIcon } from "../../../helpers/visitTypeMapping";
import { getStatusColor } from "./RxForms/statusColor";
import { EventBusy } from "@mui/icons-material";
import moment from "moment";

interface AppointmentOverviewModalProps {
  open: boolean;
  onClose: () => void;
  patientId: number;
}

const AppointmentOverviewModal: React.FC<AppointmentOverviewModalProps> = ({
  open,
  onClose,
  patientId,
}) => {
  const AppointmentStore = useStore(AppointmentContext);
  const { getFilteredAppointmentsForPatient } = AppointmentStore;

  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [appointments, setAppointmentsPatient] = useState<AppointmentDto[]>([]);
  const [dataRefreshFlag, setDataRefreshFlag] = useState(null);

  const initialFilters: AppointmentQueryFilters = {
    showOnlyFutureAppointments: false,
    showOnlyActiveRxs: true,
    showLastThreeMonths: false,
    rxIds:[]
  };

  const [appointmentFilters, setAppointmentFilters] =
    useState<AppointmentQueryFilters>(initialFilters);

  const handleClose = () => {
    setAppointmentFilters(initialFilters); // Reset to initial filters
    onClose();
  };

  // Fetch data based on filters (initial load and when filters change)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setAppointmentsLoading(true);
        const response = await getFilteredAppointmentsForPatient(patientId, appointmentFilters,true);
        if (response) setAppointmentsPatient(response);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setAppointmentsLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [appointmentFilters, open]);

  // Handle filter changes
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setAppointmentFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  // Get unique therapists from appointment time slots
  const uniqueTherapists = [
    ...new Map(
      appointments
        .flatMap((appt) => appt.timeSlots || [])
        .map((slot) => slot.user)
        .filter((user: any) => user) // Ensure `user` exists
        .map((user: any) => [user.id, user]) // Use Map to ensure uniqueness by `id`
    ).values(),
  ];

  const getValidStartDate = (appointment: any, index: number): Date => {
    if (index >= appointments.length - 1) {
      return appointment.start; // Return the start date of the appointment if it's the last one
    }
    return !appointment.end
      ? getValidStartDate(appointments[index + 1], index + 1)
      : appointment.start;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Alle Termine</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "4px",
        }}
      >
        {/* Filter checkboxes */}
        <div
          style={{
            // padding: "8px",
            // marginTop: "6px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>Filters:</Typography>
          <div style={{ display: "flex", border: "2px solid #ccc" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={appointmentFilters.showOnlyFutureAppointments}
                  onChange={handleFilterChange}
                  name="showOnlyFutureAppointments"
                />
              }
              sx={{ fontSize: 18, textWrap: "nowrap" }}
              label="Zukünftige Termine"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={appointmentFilters.showOnlyActiveRxs}
                  onChange={handleFilterChange}
                  name="showOnlyActiveRxs"
                />
              }
              sx={{ fontSize: 18, textWrap: "nowrap" }}
              label="Aktives Rezept"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={appointmentFilters.showLastThreeMonths}
                  onChange={handleFilterChange}
                  name="showLastThreeMonths"
                />
              }
              sx={{ fontSize: 18, textWrap: "nowrap" }}
              label="Termine der letzten 3 Monate"
            />
          </div>
        </div>

        {/* Unique Therapists */}
        <div
          style={{
            // border: "4px solid #ccc",
            // padding: "8px",
            marginTop: "6px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            textDecoration: "underline",
          }}
        >
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>Therapeut:</Typography>
          {uniqueTherapists.length > 0 ? (
            uniqueTherapists.map((therapist, index) => (
              <div key={therapist.id} style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <MobiliAvatar
                  user={therapist}
                  isTherapist
                  sx={{ width: 30, height: 30, fontSize: 13 }}
                />
              </div>
            ))
          ) : (
            <Typography>Für die aktuellen Filter wurden keine Therapeuten gefunden.</Typography>
          )}
        </div>

        {/* Scrollable Table Container */}
        {appointmentsLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : appointments.length === 0 ? (
          <Typography variant="h6" sx={{ textAlign: "center", marginTop: "70px", height: "500px" }}>
            Keine Termine gefunden
          </Typography>
        ) : (
          <Box sx={{ maxHeight: "900px", height: "800px", overflowY: "auto", marginTop: "20px" }}>
            <Table>
              <TableBody>
                {appointments.map((appointment: any, index) => {
                  // Calculate the gap between this appointment and the next one
                  const nextAppointment = appointments[index + 1];
                  const gapInDays =
                    nextAppointment && appointment.end
                      ? formatGap(
                          new Date(getValidStartDate(appointment, index)),
                          new Date(getValidStartDate(nextAppointment, index + 1))
                        )
                      : null;

                  return appointment.timeSlots && appointment.timeSlots.length > 0 ? (
                    appointment.timeSlots.map((slot: any, slotIndex: number) => (
                      <TableRow key={`${index}-${slotIndex}`}>
                        {/* Render the first cell only for the first timeslot */}
                        {slotIndex === 0 && (
                          <TableCell
                            rowSpan={appointment.timeSlots.length}
                            sx={{ width: "15%", borderBottom: "none", verticalAlign: "top" }}
                          >
                            <Controls.Chip
                              sx={{
                                fontSize: 18,
                                padding: "0px 0px",
                                borderRadius: "0.5em",
                                alignItems: "center",
                                display: "flex",
                              }}
                              label={
                                appointment.start && !appointment.unschedule
                                  ? formatDateUI(appointment.start)
                                  : "-"
                              }
                            />
                          </TableCell>
                        )}

                        {/* Render the timeslot-specific data */}
                        <TableCell sx={{ width: "15%", borderBottom: "none" }}>
                          <Controls.Chip
                            sx={{
                              fontSize: 18,
                              padding: "0px 0px",
                              borderRadius: "0.5em",
                              alignItems: "center",
                              display: "flex",
                            }}
                            label={
                              slot.start
                                ? `${formatTime(slot.start)} - ${
                                    slot.end ? formatTime(slot.end) : ""
                                  }`
                                : "nicht geplant"
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ width: "15%", borderBottom: "none" }}>
                          <Tooltip title={slot.therapyRx?.rx?.rxNumber}>
                            <Controls.Chip
                              label={slot.therapyRx?.rx?.rxNumber || "-"}
                              sx={{
                                backgroundColor: getColorForRxNumber(slot.therapyRx?.rx?.rxNumber),
                                fontSize: 18,
                                direction: "rtl",
                              }}
                              variant="outlined"
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ width: "15%", borderBottom: "none" }}>
                          {slot.therapyRx?.rx?.date ? (
                            <>
                              <Tooltip title={slot.therapyRx?.rx?.date}>
                                <Controls.Chip
                                  sx={{
                                    fontSize: 18,
                                    padding: "0px 0px",
                                    borderRadius: "0.5em",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                  label={moment(slot.therapyRx?.rx?.date).format("DD.MM.YYYY")}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <Tooltip title="No date">
                              <Controls.Chip
                                sx={{
                                  fontSize: 18,
                                  padding: "0px 0px",
                                  borderRadius: "0.5em",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                label={<EventBusy sx={{ color: "grey" }} />}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell sx={{ width: "15%", borderBottom: "none" }}>
                          <TherapyChip
                            therapyName={(slot?.therapyRx?.therapy as any)?.abbreviation || "-"}
                            sx={{ fontSize: 18 }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: "10%", borderBottom: "none" }}>
                          {slot?.user?.id ? (
                            <MobiliAvatar
                              user={slot?.user}
                              isTherapist
                              sx={{ width: 30, height: 30, fontSize: 13 }}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell sx={{ width: "10%", borderBottom: "none" }}>
                          <Tooltip title={`VisitFee: ${slot?.visitFee?.abbreviation || "-"}`}>
                            <div style={{ display: "inline-block" }}>
                              {visitTypeToIcon[appointment?.address?.type?.toLowerCase()] || "-"}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ width: "10%", borderBottom: "none" }}>
                          <Tooltip title={`${slot.therapyRx?.rx?.processingStatus || "-"}`}>
                            <Avatar
                              sx={{
                                width: 25,
                                height: 25,
                                marginLeft: "auto",
                                backgroundColor: getStatusColor(
                                  slot.therapyRx?.rx?.processingStatus
                                ),
                                "& > *": { display: "none" }, // Hide default content inside the Avatar
                              }}
                            >
                              <React.Fragment></React.Fragment>
                            </Avatar>
                          </Tooltip>
                        </TableCell>

                        {/* Render the last cell (gap in days) only for the first timeslot */}
                        {slotIndex === 0 && (
                          <TableCell
                            rowSpan={appointment.timeSlots.length}
                            sx={{
                              width: "10%",
                              borderBottom: "none",
                              verticalAlign: "top",
                              paddingTop: "8px",
                            }}
                          >
                            {(gapInDays || gapInDays === 0) && (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     alignItems: "center",
                              //     paddingTop: "10px",
                              //     paddingBottom: "10px",
                              //   }}
                              // >
                              <div style={{ display: "flex" }}>
                                <Avatar
                                  sx={{
                                    width: 25,
                                    height: 25,
                                    backgroundColor: backgroundColourCheck(gapInDays / 7),
                                  }}
                                >
                                  <React.Fragment></React.Fragment>
                                </Avatar>
                                <Typography
                                  variant="body1"
                                  component="p"
                                  sx={{ fontSize: 18, marginLeft: "5px" }}
                                >
                                  {gapInDays}
                                </Typography>
                              </div>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key={index}>
                      <TableCell colSpan={8}>Keine Zeitfenster gefunden</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text">
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentOverviewModal;
