import React from "react";
import { Button, Fab } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import styles from "../captureButton/FloatingButton.module.css"
interface FileCaptureProps {
  onCapture: (file: File) => void;
}

const FileCapture=({ onCapture }: FileCaptureProps)=> {
  const pickFile = async () => {
    try {
      // Use the File Picker to allow users to select a file
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "*/*"; // Accept any file type
      fileInput.onchange = async (event: Event) => {
        const target = event.target as HTMLInputElement;
        const file = target.files?.[0];
        if (file) {
          onCapture(file);
        } else {
          alert("No file was selected.");
        }
      };
      fileInput.click();
    } catch (error) {
      console.error("Error selecting file:", error);
    }
  };
  const buttonClass =  styles.camera

  return (
    <Fab
     className={`${styles.fab} ${buttonClass}`}
      color="primary"
    onClick={pickFile}
  >
    <CloudUploadOutlinedIcon/>
  </Fab>
  );
}
export default FileCapture;
