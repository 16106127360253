import { RxStatsDto } from "../../../api-client";
import { useEffect, useState } from 'react';
import useStore from "../../../helpers/useStore";
import { BIContext } from "../../../stores/BIDashboard/biDashboard.provider";
import { calculateNewRxs, calculateTotalRx, calculateTotalRxValue } from "../helpers/rxStatsHelpers";

interface TotalRxCountData {
  locationId: string, 
  date: Date, 
  totalCount: number
}

interface NewRxCountData {
  locationId: string, 
  date: Date, 
  newOngoingRxs: number, 
  newCompletedRxs: number
}

interface TotalRxValueData {
  locationId: string, 
  date: Date, 
  totalOngoingRxPrice: number, 
  totalCompletedRxPrice: number
}

const useRxStats = (binType: 'week' | 'month') => {
  const BIStore = useStore(BIContext); 
  const { getRxStats } = BIStore; 

  const [rxStats, setRxStats] = useState<RxStatsDto[]>([]);
  const [totalRxCountResults, setTotalRxCountResults] = useState<TotalRxCountData[]>([]);
  const [newRxCountResults, setNewRxCountResults] = useState<NewRxCountData[]>([]);
  const [totalRxValueResults, setTotalRxValueResults] = useState<TotalRxValueData[]>([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRxStats();
        if (response) {
          setRxStats(response);
  
          // calculate the stats if rxStats are set
          const totalRxCountResults = calculateTotalRx(response);
          const newRxCountResults = calculateNewRxs(response, binType);
          const totalRxValueResults = calculateTotalRxValue(response, binType);

          setTotalRxCountResults(totalRxCountResults);
          setNewRxCountResults(newRxCountResults);
          setTotalRxValueResults(totalRxValueResults);
          
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [binType]);

  return { totalRxCountResults, newRxCountResults, totalRxValueResults };
};

export default useRxStats;