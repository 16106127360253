import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { TextField, Grid } from "@mui/material";
import { DoctorReportDto } from "../../../../api-client";

interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: any;
  editMode?: boolean;
}

const SpecialFeatureCard=({
  doctorReport,
  setDoctorReport,
  editMode,
}: DoctorReportProps) =>{
  const [specialFeatures, setSpecialFeatures] = useState("");

  useEffect(() => {
    if (doctorReport) {
      setSpecialFeatures(doctorReport.specialFeatures!);
    }
  }, [doctorReport]);

  const handleChange = (value: string) => {
    setSpecialFeatures(value);
    setDoctorReport({ ...doctorReport, specialFeatures: value });
  };

  return (
    <DoctorReportCard title="Besonderheiten während des Behandlung" editMode={editMode}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Besonderheiten"
            placeholder="Text eingeben"
            multiline
            rows={4}
            value={specialFeatures}
            onChange={(e) => handleChange(e.target.value)}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </DoctorReportCard>
  );
}

export default SpecialFeatureCard;
