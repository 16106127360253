import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { observer, useObserver } from "mobx-react";

import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useStore from "../../../../../helpers/useStore";
import {
  NoteDto,
  RxDto,
  RxDtoApprovalStatusEnum,
  RxDtoProcessingStatusEnum,
} from "../../../../../api-client";
import Controls from "../../../../../atoms/Controls";
import FlatCard from "../../../../../atoms/FlatCard";
import WarningDialog from "../../../../../atoms/WarningDialog";
import { DoctorContext } from "../../../../../stores/Doctor/doctor.provider";
import { MasterTablesContext } from "../../../../../stores/MasterTables/MasterTables.provider";
import notificationStore from "../../../../../stores/Notification/notificationStore";
import { PatientsContext } from "../../../../../stores/Patients/patients.provider";
import { RxStoreContext } from "../../../../../stores/Rx/rx.provider";
import DoctorDialog from "../../../../PatientDataEntryForm/Doctor";

import classes from "./BG.module.css";
import ValidationCard, { formType } from "../../../../../molecules/ValidationCard";
import ValidationCardRxInfoPage from "../../../../../molecules/ValidationCardRxInfoPage";
import Notes from "../../../../ScheduleOverview/Notes/Notes";
import { NotesContext } from "../../../../../stores/Notes/notes.provider";
import { UserContext } from "../../../../../stores/User/User.provider";
import { validateFrequency, validateTherapy } from "../../../../../molecules/Editable";

interface BGProps {
  selectedRow: any;
  patientValues: any;
  handleData?: any;
  updateFormData?: any;
  readOnlyView?: boolean;
  openRx?: boolean;
  onCloseRx?: any;
  newRx: boolean | undefined;
  updateRxProcessingStatus?:(event:any)=>void;
}

const ScalableDiv = styled("div")(({ theme }) => ({
  transformOrigin: "top left",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.6)",
  },
  [theme.breakpoints.between("sm", "xl")]: {
    transform: "scale(0.65)",
  },
  [theme.breakpoints.up("xl")]: {
    transform: "scale(0.85)",
  },
}));

const ScalableComponentSimple = ({ children }: any) => {
  return <ScalableDiv>{children}</ScalableDiv>;
};

const BG: React.FC<BGProps> = observer(
  ({
    selectedRow,
    patientValues,
    handleData,
    updateFormData,
    readOnlyView,
    openRx,
    onCloseRx,
    newRx,
    updateRxProcessingStatus
  }) => {
    console.log("date debug 3", selectedRow.date);
    // const { selectedRow, patientValues, handleData, masterTablesData, readOnlyView } = props;
    const masterTablesStore = useStore(MasterTablesContext);
    const patientStore = useStore(PatientsContext);
    const { searchPatient } = patientStore;
    const { getICD, getTarifGroupIds, masterTablesData, getTherapyId } = masterTablesStore;
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);

    const RxStore = useStore(RxStoreContext);
    const { saveRxs, updateprocessingStatus } = RxStore;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const tarifGroupsMasterData = getTarifGroupIds();
    //For payer
    const insurer = masterTablesData
      .filter((obj: any) => "insurer" in obj)
      .map((obj: any) => obj.insurer);
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [selectedChipCard, setSelectedChipCard] = useState<string | null>(null);
    const diagnoseGroups = masterTablesData
      .filter((obj: any) => "diagnosisGroups" in obj)
      .map((obj: any) => obj.diagnosisGroups);

    const rxType = masterTablesData
      .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
      .map((obj: any) => obj.rxType);
    //  therapyOptions= therapyOptions[0].filter(
    //       (therapyObj: any) =>

    //         therapyObj.duration
    //     )

    const [checkboxState, setCheckboxState] = useState({
      stabilitätsgrad: false,
      limitierungen: false,
      opTechnik: false,
      wunden: false,
      erreger: false,
      sonstige: false,
      rücksprache: false,
    });

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setCheckboxState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    //For Doctor
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedBSNR, setSelectedBSNR] = useState<string | null>(null);
    const [selectedLANR, setSelectedLANR] = useState<string | null>(null);
    const DoctorStore = useStore(DoctorContext);
    const { doctorOptions } = DoctorStore;
    const [isSelectActive, setIsSelectActive] = useState(false);
    const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);
    const handleUnsavedChangesWarning = () => {
      handleSave();
    };
    const handleCloseRxDialog = () => {
      console.log("shjsakjsajj", unsavedChanges);
      if (unsavedChanges) {
        // If there are unsaved changes, set a state to open the warning dialog
        setShowunsavedChangesWarning(true);
      } else {
        onCloseRx();
      }
    };
    const handleCloseUnsavedChangesWarning = () => {
      onCloseRx();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowunsavedChangesWarning(false);
    };

    const account = masterTablesData
      .filter((obj: any) => "account" in obj)
      .map((obj: any) => obj.account);

    const handleDoctorChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue: any = event.target.value;
      setUnsavedChanges(true);
      setSelectedDoctor(selectedValue);
      // Autopopulate bsnr based on doctor selection
      const selectedDoctor: any = doctorOptions.find(
        (doctor: any) => doctor?.contactInfo.lastName === selectedValue
      );

      if (selectedDoctor) {
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: selectedDoctor.number,
          practiceNumber: selectedDoctor.clinicNumber,
          doctorData: selectedDoctor,
        }));
        setSelectedBSNR(selectedDoctor.clinicNumber);
        setSelectedLANR(selectedDoctor.number);
      }

      // Filter doctor options based on selectedValue
      const filteredOptions: any = doctorOptions.filter(
        (doctor: any) => doctor?.contactInfo?.lastName === selectedValue
      );

      if (filteredOptions?.length > 0) {
        // If filteredOptions has a value, set it as the fetchedData
        // setFetchedData(filteredOptions[0]);
        // Update receiptForm with selected doctor
        // if (!isEditing) {
        setFormValues({
          ...formValues,
          doctorId: filteredOptions[0].id,
          doctorNumber: filteredOptions[0].number,
          practiceNumber: filteredOptions[0].clinicNumber,
          doctorData: filteredOptions[0],
        });
        // } else {
        //   setFormValues((prevFormValues: any) => ({
        //     ...prevFormValues,
        //     rxs: prevFormValues.rxs.map((rx: any) => ({
        //       ...rx,
        //       doctorId: filteredOptions[0].id,
        //       practiceNumber: filteredOptions[0].clinicNumber,
        //       doctorNumber: filteredOptions[0].number,
        //     })),
        //   }));
        // }
      } else {
        // setFetchedData(null);
        setFormValues({
          ...formValues,
          doctorId: null,
          doctorNumber: null,
          practiceNumber: null,
        });
      }
    };

    const initialrxValues = {
      tarifGroupId: "BG",
      rxtype: "Berufsgenossenschaft",
      Receiptpayer: patientValues?.payer,
      signatureStatus: false,
      isPerpetual: false,
      doctorId: null,
      practiceNumber: null,
      doctorNumber: null,
      date: patientValues.date,
      isCopaymentExempted: false,
      isAccident: false,
      isBVG: false,
      icdCodeEntries: [{ userText: "" }, { userText: "" }],
      diagnosisText: "",
      diagnosegroup: null,
      hasIndividualSymptoms: false,
      therapyRxs: Array.from({ length: 4 }, (_, index) => ({
        id: uuidv4(),
        amount: "",
        frequency: { id: "", text: "" },
        therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
        isDoubled: false,
        doubledTreatmentIds: [],
        rxSeries: { label: "" },
      })),
      isHomeVisit: selectedRow.isHomeVisit || null,
      therapyReport: false,
      urgent: false,
      symptoms: "",
      therapyFrequency: "",
      amount: "",
      additionalNotes: "",
      doctorData: {} as any,
      duplicate: false,
      accidentDay: "",
      isLongTermRx: false,
      additionalNotes2: "",
      expectedTherapyLength: "",
      isPerpetualApproved: false,
      diagnosisText2: "",
      therapyStart: null,
      approvalStatus: RxDtoApprovalStatusEnum.Fehlt,
      processingStatus: RxDtoProcessingStatusEnum.InBehandlung,
    };

    const [formValues, setFormValues] = useState(initialrxValues);
    const [icdCodeEntries, setIcdCodeEntries] = useState(initialrxValues.icdCodeEntries);
    const [filteredTherapies, setFilteredTherapies] = useState([]);
    const UserStore = useStore(UserContext);
    const { user } = UserStore;
    const NotesStore = useStore(NotesContext);
    const { getRxNotes, updateNoteById, postNotes, fetchNotes } = NotesStore;
    const [rxNotes, setRxNotes] = useState<NoteDto[]>([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getRxNotes(selectedRow.id);

          setRxNotes(response);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }, [selectedRow.id, fetchNotes]);
    const updateNote = async (index: number, updatedCard: NoteDto) => {
      updatedCard.rxId = selectedRow.id;
      if (updatedCard.id) {
        await updateNoteById(updatedCard.id, user?.id as string, updatedCard);
      } else {
        await postNotes(user?.id as string, updatedCard);
      }
      const updatedNotes = [...rxNotes];
      updatedNotes[index] = updatedCard;
      setRxNotes(updatedNotes);
    };
    const therapyOptions = masterTablesData
      .filter((obj: any) => "therapy" in obj)
      .map((obj: any) => obj.therapy)[0]
      ?.filter((option: any) => option.tarifGroup.id === formValues.tarifGroupId);

    const filterTherapies = () => {
      const filtered = therapyOptions?.filter(
        (option: any) => option.tarifGroup.id === formValues.tarifGroupId
      );
      setFilteredTherapies(filtered);
    };

    useEffect(() => {
      filterTherapies();
    }, [formValues.rxtype, formValues.tarifGroupId]);

    // useEffect(() => {
    //   if (selectedRow) {
    //     // Populate the form data with values from selectedRow
    //     setformValues(selectedRow);
    //   } else {
    //     // Use empty values for form data
    //    setformValues(initialrxValues)
    //   }
    // }, [selectedRow]);
    // Initialize with two input boxes

    // Expose the submitForm function to the parent component

    const formattedRxData = (selectedRow: any, initialTherapyRxs: any) => {
      let icdCodeEntries;

      if (selectedRow.icdCodeEntries && selectedRow.icdCodeEntries.length > 0) {
        if (selectedRow.icdCodeEntries.length === 1) {
          icdCodeEntries = [...selectedRow.icdCodeEntries, { userText: "" }];
        } else {
          icdCodeEntries = selectedRow.icdCodeEntries;
        }
      } else {
        icdCodeEntries = [{ userText: "" }, { userText: "" }];
      }
      const updatedformValues = {
        ...selectedRow,
        firstName: patientValues.firstName || "",
        lastName: patientValues.lastName || "",
        dob: patientValues.dob || "",

        insuranceNo: patientValues.insuranceNo || "",
        // Receiptpayer: selectedRow.payer.insurer || "",
        Receiptpayer:
          insurer[0]?.find((option: any) => option.id === selectedRow.Receiptpayer)?.name ||
          selectedRow.payer?.name ||
          formValues?.Receiptpayer?.name,
        chipcard: selectedRow.chipcard || selectedRow.payer?.ik,
        doctorId:
          doctorOptions?.find((option: any) => option.id === selectedRow.doctorId)?.contactInfo
            .lastName || selectedRow.doctor?.contactInfo?.lastName,
        diagnosegroup:
          diagnoseGroups[0]?.find((option: any) => option.id === selectedRow.diagnosegroup)?.code ||
          selectedRow.diagnosisGroup?.code,
        rxtype:
          rxType[0]?.find((option: any) => option.id === selectedRow.rxtype)?.text ||
          selectedRow.rxtype?.text ||
          "Berufsgenossenschaft",
        doctorData:
          (doctorOptions as any).find(
            (option: any) =>
              option.contactInfo.lastName === selectedRow.doctorId ||
              option.id === selectedRow.doctorId
          ) || selectedRow.doctor,
        accountId:
          account[0]?.find((option: any) => option.id === selectedRow.accountId)?.name ||
          selectedRow.account?.name,
        // status: selectedRow.status || "",
        signatureStatus: selectedRow.signatureStatus || "",
        insuranceStatus: selectedRow.insuranceStatus || "",
        therapyRxs: initialTherapyRxs,
        // rxtype:selectedRow.rxtype?.id || "",
        // accountId:selectedRow.account?.id || "",
        // chipcard: selectedRow.payer?.id || "",
        // doctorId: selectedRow.doctor?.contactInfo?.lastName || "",
        bsnr: selectedRow.practiceNumber || "",
        // diagnosegroup: selectedRow.diagnosisGroup?.code || "",
        icdCodeEntries: icdCodeEntries,
      };
      return updatedformValues;
    };

    const updatedRxValuesToSave = () => {
      const updatedRxValues = {
        ...formValues,
        id: patientValues?.rxs.find((rx: any) => rx.id === selectedRow.id)?.id || "",
        rxNumber: patientValues?.rxs.find((rx: any) => rx.id === selectedRow.id)?.rxNumber || "",
        doctor: formValues.doctorData,
        rxtype:
          rxType[0].find((option: any) => option.text === formValues.rxtype)?.id ||
          formValues.rxtype,
        tarifGroupId: "BG",
        diagnosegroup:
          diagnoseGroups[0].find((option: any) => option.code === formValues.diagnosegroup)?.id ||
          formValues.diagnosegroup,
        doctorId:
          (doctorOptions as any).find(
            (option: any) => option.contactInfo.lastName === formValues.doctorId
          )?.id || formValues.doctorId,
        Receiptpayer:
          insurer[0].find((option: any) => option.name === formValues.Receiptpayer)?.id ||
          formValues.Receiptpayer,

        // therapyRxs:
        //   formValues.therapyRxs
        //     ?.filter((therapy: any) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
        //     .map((therapy: any) => ({

        //       frequency: therapy.frequency || "",

        //     })) || [],

        therapyRxs: (formValues.therapyRxs || []) // Ensure formValues.therapyRxs is an array or provide an empty array
          .filter((therapy) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
          .map((therapy: any) => {
            if (
              !(therapy.frequency && therapy.frequency.id) ||
              !(therapy.therapy && therapy.therapy.id) ||
              !therapy.amount
            ) {
              throw new Error(
                "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
              );
            }
            const validTherapy = validateTherapy(therapy.therapy.abbreviation, masterTablesData);
            const validFrequency = validateFrequency(therapy.frequency.id, masterTablesData);
            if (!validTherapy || !validFrequency) {
              throw new Error("Bitte geben Sie eine gültige Therapie und Häufigkeit ein.");
            }
            const insurer = masterTablesData
              .filter((obj: any) => "insurer" in obj)
              .map((obj: any) => obj.insurer); // Extract the insurer array
            // const therapyName = therapy.therapy.abbreviation.toUpperCase();
            const tarifTherapy = getTherapyId(
              formValues.tarifGroupId,
              therapy.therapy.abbreviation
            );

            return {
              ...therapy,
              therapy: tarifTherapy?{ id: tarifTherapy, ...therapy.therapy }: {
                id: "",
                abbreviation: "",
              },
            };
          }),
      };
      return updatedRxValues;
    };

    // const handleCloseRxDialog=()=>{
    //   if(setFormData){
    //     const updatedValues=updatedRxValuesToSave()
    //     setFormData(updatedValues)
    //   }
    //   onCloseRx()
    // }
    const [isHausbesuchSelected, setHausbesuchSelected] = useState<boolean | null>(
      formValues.isHomeVisit
    );
    const handlevisitCheckboxChange = (event: any) => {
      const selectedValue = event.target.value; // "yes" or "no"

      if (selectedValue === "yes") {
        setHausbesuchSelected(true);
        // Set isHomeVisit to true if "yes" is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: true,
          } as unknown as EventTarget & HTMLInputElement, // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      } else if (selectedValue === "no") {
        setHausbesuchSelected(false);
        // Set isHomeVisit to false if "no" is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: false,
          } as unknown as EventTarget & HTMLInputElement, // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      } else {
        setHausbesuchSelected(null); // Set null if neither is selected
        // Set isHomeVisit to null if neither checkbox is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: null,
          } as unknown as EventTarget & HTMLInputElement, // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        console.log("selectedRow and Patient Value", selectedRow, " and", patientValues.rxs);
        if (selectedRow.rxNumber) {
          // selectedRow = patientValues?.rxs.find((rx: any) => rx.id === selectedRow.id);
          // console.log(
          //   "After checking selectedRow and Patient Value",
          //   selectedRow,
          //   " and",
          //   patientValues.rxs
          // );
          let rxs: [] = [];

          console.log("selectedRow TherapyRXS", selectedRow.theapyRxs);
          selectedRow.therapyRxs = selectedRow?.therapyRxs?.flatMap((therapy: any) => {
            // Extract therapyRxs from each rx, or default to an empty array
            // Map therapyRxs to the desired format
            console.log("Therapy Log DATA", therapy, therapy.frequency);
            console.log("date debug 2: ", selectedRow.date);
            return {
              id: therapy.id || uuidv4(),
              date: selectedRow.date,
              therapy: therapy.therapy.id
                ? therapy.therapy
                : {
                    id: therapy?.therapy,
                    abbreviation: therapy?.therapy,
                    description:
                      therapyOptions?.find((therapyObj: any) => therapyObj.id === therapy?.therapy)
                        .description || "",
                  },
              amount: therapy.amount,
              frequency: therapy.frequency
                ? therapy.frequency.id
                  ? therapy.frequency
                  : { id: therapy?.frequency, text: therapy?.frequency }
                : null,
              isDoubled: therapy.isDoubled,
              doubledTreatmentIds: therapy.doubledTreatmentIds,
              rxSeries: therapy.rxSeries,
            };
          });

          const initialTherapyRxs = Array.from({ length: 4 }, (_, index) => {
            if (index < selectedRow.therapyRxs.length) {
              // If index is within the length of formValues.therapyRxs, use existing data
              return selectedRow.therapyRxs[index];
            } else {
              // Otherwise, add an empty row
              return {
                id: uuidv4(),
                amount: "",
                frequency: { id: "", text: "", prefferedValue: "" },
                therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
                isDoubled: false,
                doubledTreatmentIds: [],
                // rxSeries:{}
              };
            }
          });
          // Update form data based on fetched data
          const updatedformValues = formattedRxData(selectedRow, initialTherapyRxs);

          // Update form data state
          setFormValues(updatedformValues);

          // Optionally, return the updated form data
          return updatedformValues;
        }
        // Make API request to fetch patient data based on patient ID or any other identifier
        else {
          console.log("Thshkjaskdkkd", selectedRow);
          const therapyRxs: any =
            selectedRow.therapyRxs && selectedRow.therapyRxs.length
              ? Array.from({ length: 4 }, (_, index) => ({
                  ...selectedRow.therapyRxs[index],
                  id: selectedRow.therapyRxs[index]?.id || uuidv4(),
                  amount: selectedRow.therapyRxs[index]?.amount || "",
                  frequency: selectedRow.therapyRxs[index]?.frequency
                    ? selectedRow.therapyRxs[index].frequency
                    : { id: "", text: "" },

                  therapy: selectedRow.therapyRxs[index]?.therapy
                    ? selectedRow.therapyRxs[index].therapy
                    : { id: "", abbreviation: "" },
                }))
              : initialrxValues.therapyRxs;
          const updatedformValues = formattedRxData(selectedRow, therapyRxs);

          // Set form values using therapytableData or default to initialrxValues
          setFormValues(updatedformValues);
        }
      };
      fetchData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, patientValues]);
    console.log("ahsajsjakskska", formValues);
    // const updateRxProcessingStatus = async (event: any) => {
    //   const response = await updateprocessingStatus(selectedRow.id, event?.target.value);
    // };

    const handleOpenAddEditDialog = (lanr: string | null, bsnr: string | null) => {
      setSelectedLANR(lanr);
      setSelectedBSNR(bsnr);
      setAddEditDialogOpen(true);
    };
    const handleIsPerpetual = () => {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        isPerpetualApproved: true,
      }));
      setOpenModal(false);
    };

    const handleCloseAddEditDialog = (data: any) => {
      // Handle doctor data, LANR, and BSNR passed back from DoctorDialog
      setAddEditDialogOpen(false);

      // Here you can perform further actions with the received data
      if (data && "number" in data) {
        const { number, clinicNumber, contactInfo, id } = data;

        // Update states or perform other operations based on the received data
        setSelectedLANR(number);
        setSelectedBSNR(clinicNumber);
        setSelectedDoctor(contactInfo?.lastName);
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: number,
          practiceNumber: clinicNumber,
          doctorId: id,
          doctorData: data,
        }));

        // For example, you can update doctor form values if needed
        // setDoctorFormValues(doctorData);
      }
    };

    // const [isHausbesuchSelected, setHausbesuchSelected] = useState<boolean | null>(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const handleModalClose = () => {
      setOpenModal(false);
      setFormValues((prevValues: any) => ({
        ...prevValues,
        isPerpetualApproved: false,
      }));
    };

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index?: any,
      idValue?: string
    ) => {
      console.log("asisdkkd", event);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, value, checked, type, id } = event.target;
      setUnsavedChanges(true);

      const inputValue = type === "checkbox" ? checked : value;

      if (name === "date" ){
        console.log("setting date",value)
      }
      if (name === "date" && formValues.isPerpetual && value) {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          unvirtualize: true,
        }));
      }

      const updatedTherapyRxs = [...formValues.therapyRxs];

      if (name === "therapy") {
        // const selectedTherapy = therapyOptions[0]?.find(
        //   (therapyObj: any) => therapyObj.lineItem.concat("(", therapyObj.abbreviation, ")") === value
        // );
        const selectedTherapy = therapyOptions?.find(
          (therapyObj: any) => therapyObj.abbreviation === value
        );

        if (selectedTherapy) {
          updatedTherapyRxs[index] = {
            ...updatedTherapyRxs[index],
            therapy: {
              ...updatedTherapyRxs[index].therapy,
              id: selectedTherapy.id,
              abbreviation: selectedTherapy.abbreviation,
              description: selectedTherapy.description || "",
              price: selectedTherapy.price || "",
            },
            frequency: selectedTherapy.frequency,
          };

          setFormValues((prevformValues: any) => ({
            ...prevformValues,
            therapyRxs: updatedTherapyRxs,
          }));
        }
      }

      if (name === "amount") {
        updatedTherapyRxs[index] = {
          ...updatedTherapyRxs[index],
          amount: value,
        };

        setFormValues((prevformValues) => ({
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        }));
      }
      if (name === "rxSeries") {
        // Update therapyRxs based on rowIndex and therapyId
        const updatedTherapyRxs = formValues.therapyRxs.map((therapy: any, index: number) => {
          if (index === index && therapy.id === idValue) {
            return {
              ...therapy,
              rxSeries: { id: id, label: value },
            };
          }
          return therapy;
        });

        // Update the formValues state with the updated therapyRxs
        setFormValues((prevformValues) => ({
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        }));
      }
      if (name === "frequency") {
        updatedTherapyRxs[index] = {
          ...updatedTherapyRxs[index],
          frequency: {
            id: value,
            text: value,
          },
        };

        setFormValues((prevformValues) => ({
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        }));
      }
      if (insurer[0]) {
        if (name === "Receiptpayer") {
          // Autopopulate doctor and bsnr based on LANR selection

          const selectedPayer: any = insurer[0].find(
            (option: any) => option.id === id || option.ik === value
          );
          console.log("UZZHHHH", selectedPayer);
          if (selectedPayer) {
            setFormValues((prevFormValues: any) => ({
              ...prevFormValues,
              chipcard: selectedPayer?.ik,
              tarifGroupId: selectedPayer?.tarifGroup?.id,
            }));
            setSelectedChipCard(selectedPayer.ik);
            console.log("selectedPayer", selectedPayer.name);
          }
        }

        if (name === "chipcard") {
          const selectedPayer: any = insurer[0].find((option: any) => option.ik === value);
          console.log("UZZHHHH", selectedPayer);
          if (selectedPayer) {
            setFormValues((prevFormValues: any) => ({
              ...prevFormValues,
              Receiptpayer: selectedPayer.id,
              tarifGroupId: selectedPayer?.tarifGroup?.id,
            }));

            setSelectedPayer(selectedPayer.name);
            console.log("selectedPayer", selectedPayer.name);
          }
        }
      }

      if (name.startsWith("icdCodeEntries")) {
        const inputElement = document.querySelector(`[name="${name}"]`) as
          | HTMLInputElement
          | HTMLTextAreaElement;
        const cursorPosition = inputElement?.selectionStart || 0;
        let updatedValue = value;
        let newCursorPosition = cursorPosition; // Initialize new cursor position

        // Check if the length is at least 4 and if a period needs to be inserted
        if (value.length >= 4) {
          // Check if the period already exists at the correct position
          if (!value.includes(".")) {
            // Insert a period after the third character if it's not already there
            updatedValue = value.slice(0, 3) + "." + value.slice(3);

            // Adjust cursor position if a period was inserted before the original cursor position
            if (cursorPosition > 3) {
              newCursorPosition = cursorPosition + 1; // Move cursor one position forward
            }
          }
        }

        // Update the form values with the correctly formatted value
        setFormValues((prevValues: any) => ({
          ...prevValues,
          icdCodeEntries: prevValues.icdCodeEntries.map((icdEntry: any, i: number) => {
            return i === index
              ? { id: idValue || undefined, userText: updatedValue }
              : { id: icdEntry?.id, userText: icdEntry?.userText || "" };
          }),
        }));

        // Restore cursor position after the update
        setTimeout(() => {
          if (inputElement) {
            inputElement.selectionStart = inputElement.selectionEnd = newCursorPosition;
          }
        }, 0);
      }

      if (name === "rxtype") {
        setFormValues((prevValues) => ({
          ...prevValues,
          diagnosegroup: null, // Clear diagnosegroup when rxtype changes
        }));
        filterDiagnoseGroups(value);
      } else {
        console.log("InputValue", inputValue, id);
        // Update other form fields based on their names
        setFormValues((prevValues: any) => ({
          ...prevValues,
          [name]: id ? id : inputValue,
        }));
      }
    };
    // useEffect(() => {
    //   if (unsavedChanges === false) {
    //     onCloseRx();  // Trigger close only when unsavedChanges is false
    //   }
    // }, [unsavedChanges]);

    const handleSave = async (event?: any) => {
      if (event) {
        setLoading(false);
        event.preventDefault(); // Prevent default form submission behavior
      }
      // setUnsavedChanges(false);
      try {
        console.log("azajjaj", formValues.therapyRxs);
        setLoading(true);
        const updatedRxValues = updatedRxValuesToSave();
        // // Handle form submission logic here
        // const updatedformValues = {

        //   patient:{
        //     ...patientValues,
        //   firstName: formValues.patient?.firstName || "",
        //   lastName: formValues.patient?.lastName || "",
        //   dob: formValues.patient?.dob || ""},
        //   rxs: updatedRxValues
        // };
        const updatedformValues = {
          ...patientValues,
          rxs: newRx
            ? [updatedRxValues]
            : patientValues?.rxs.map((rx: any) =>
                rx.id === selectedRow.id ? updatedRxValues : rx
              ),
        };
        console.log("Final782848", updatedRxValues);

        console.log("Final", formValues, selectedRow, updatedformValues);
        setUnsavedChanges(false);
        console.log("UAISKKKD");
        if (newRx) {
          console.log("ajjsjajaupdated", updatedRxValues);
          updateFormData(updatedRxValues);
          setFormValues(updatedRxValues);
        } else {
          const response = await saveRxs(patientValues.id, [updatedRxValues] as unknown as RxDto[]); // Use updatedformValues instead of formValues
          handleData(updatedformValues);
          setFormValues(updatedRxValues);
          // setUnsavedChanges(false);
          // await searchPatient({ id: patientValues.id });
          handleCloseRxDialog();
        }
      } catch (error: any) {
        console.error("Error saving Rx:", error);
        notificationStore.showMessage(
          error.message || "Fehler beim Speichern des Rezepts",
          "error",
          error
        );
      } finally {
        // Set loading back to false when the API call is complete
        setLoading(false);
      }
    };
    const isAnyCheckboxChecked =
      Object.values(checkboxState).some((checked) => checked) ||
      Boolean(formValues?.additionalNotes);
    const [therapyRxs, setTherapyRxs] = useState(formValues.therapyRxs); // State to manage therapyRxs array
    const componentRef = useRef(null);
    const handleDuplicateRow = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      console.log("handleDuplicateRow called", { index, value }, event);
      setUnsavedChanges(true);
      setFormValues((prevformValues) => {
        const updatedTherapyRxs = [...prevformValues.therapyRxs];
        const therapy = updatedTherapyRxs[index];

        if (therapy) {
          // Validate the amount
          if (!therapy.amount || (therapy.amount as unknown as number) % 2 !== 0) {
            notificationStore.showMessage(
              "Bitte fügen Sie einen gültigen Betrag hinzu.",
              "warning"
            );
            return prevformValues; // Return the previous state without changes
          }
          // Check for duplicate therapies (you need to define your comparison logic)
          const isDuplicate = updatedTherapyRxs.some((othertherapy, otherIndex) => {
            if (otherIndex !== index) {
              // Compare relevant properties
              return therapy.therapy.abbreviation === othertherapy.therapy.abbreviation;
            }
            return false;
          });
          if (isDuplicate) {
            // Show an alert for duplicate therapies
            notificationStore.showMessage(
              "Die Behandlung ist bereit<s beendet, so dass kein Duplikat zulässig ist.",
              "warning"
            );
            return prevformValues;
          }

          therapy.isDoubled = value as any;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          (therapy.doubledTreatmentIds = (
            therapy.doubledTreatmentIds && therapy.doubledTreatmentIds.length > 0
              ? therapy.doubledTreatmentIds
              : [uuidv4()]
          ) as any),
            (therapy.amount = therapy.amount);
        }

        return {
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        };
      });
    };

    const [filteredDiagnoseGroups, setFilteredDiagnoseGroups] = useState([]);

    const filterDiagnoseGroups = (rxtype: any) => {
      const filtered = diagnoseGroups[0].filter((option: any) => option.RxType?.id === rxtype);
      setFilteredDiagnoseGroups(filtered);
    };

    const FormContent = (
      <>
        {/* Left Side */}
        <Grid
          container
          spacing={1}
        >
          {!readOnlyView ? (
            <Grid
              item
              xs={12}
              md={3}
            >
              <FlatCard title="Arzt">
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Controls.Select
                      name="doctor"
                      label="Arzt aus der Liste wählen"
                      id={1}
                      value={selectedDoctor || formValues.doctorId}
                      onChange={handleDoctorChange}
                      options={doctorOptions?.map((doctor: any) => ({
                        label: doctor.contactInfo.lastName.toString(),
                        value: doctor.contactInfo.lastName.toString(),
                        id: doctor.id.toString(),
                      }))}
                      disabled={readOnlyView}
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                      }}
                    />
                  </Grid>
                  {!readOnlyView && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Button onClick={() => handleOpenAddEditDialog(selectedLANR, selectedBSNR)}>
                        Arzt hinzufügen / bearbeiten
                      </Button>
                    </Grid>
                  )}
                  {!readOnlyView && (
                    <>
                      <Grid
                        item
                        xs={12}
                      >
                        <Controls.RadioGroup
                          label={
                            <Typography sx={{ textAlign: "left" }}>
                              Arztunterschrift vorhanden
                            </Typography>
                          }
                          name="signatureStatus"
                          value={formValues?.signatureStatus}
                          options={[
                            { id: "nicht kontrolliert", label: "nicht kontrolliert" },
                            { id: "vorhanden", label: "vorhanden" },
                            { id: "fehlend", label: "fehlend" },
                          ]}
                          disabled={readOnlyView}
                          onChange={handleInputChange}
                          sx={{
                            width: "100%",
                            paddingLeft: "1rem",
                            textAlign: "left",
                            fontSize: "0.7rem",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Controls.RadioGroup
                          label={
                            <Typography sx={{ textAlign: "left" }}>Genehmigungsstatus</Typography>
                          }
                          name="approvalStatus"
                          value={formValues?.approvalStatus || RxDtoApprovalStatusEnum.Fehlt}
                          options={Object.values(RxDtoApprovalStatusEnum).map((value) => ({
                            id: value,
                            label: value,
                          }))}
                          disabled={readOnlyView}
                          onChange={handleInputChange}
                          sx={{
                            width: "100%",
                            paddingLeft: "1rem",
                            textAlign: "left",
                            fontSize: "0.7rem",
                          }}
                        />
                      </Grid>
                      <Controls.Checkbox
                        name="isPerpetualApproved"
                        label="Langfristgenehmigung"
                        value={formValues?.isPerpetualApproved}
                        onChange={handleInputChange}
                        disabled={readOnlyView}
                      />
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                  >
                    <Controls.DatePicker
                      name="date"
                      label="Rezeptdatum"
                      value={formValues?.date}
                      onChange={handleInputChange}
                      formatDate="dd.MM.yy"
                      disabled={readOnlyView}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        className={classes.typography}
                        sx={{ marginRight: "8px" }}
                      >
                        Hausbesuch
                      </Typography>
                      <FormControlLabel
                        label=""
                        control={
                          <>
                            <Checkbox
                              checked={formValues.isHomeVisit === true}
                              onChange={handlevisitCheckboxChange}
                              disabled={readOnlyView}
                              name="visitTypeCheckboxJa"
                              value="yes"
                            />
                            <Typography variant="body1">ja</Typography>
                            <Checkbox
                              checked={formValues.isHomeVisit === false}
                              onChange={handlevisitCheckboxChange}
                              disabled={readOnlyView}
                              name="visitTypeCheckboxNein"
                              value="no"
                            />
                            <Typography variant="body1">nein</Typography>
                          </>
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>

                {isAddEditDialogOpen && (
                  <DoctorDialog
                    openAddEditDialog={isAddEditDialogOpen}
                    handleCloseAddEditDialog={handleCloseAddEditDialog}
                    doctorOptions={doctorOptions}
                    selectedLANR={formValues.doctorNumber}
                    selectedBSNR={formValues.practiceNumber}
                  />
                )}
              </FlatCard>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={3}
              sx={{ border: "0px dashed green" }}
            >
              <FlatCard
                title="Allgemein"
                sx={{ marginBottom: "2px" }}
              >
                <Grid
                  container
                  spacing={0}
                  justifyContent="space-between"
                >
                  {/* First Row */}
                  <Grid
                    item
                    xs={5}
                  >
                    <Typography
                      textAlign="left"
                      sx={{ fontSize: "0.8em" }}
                    >
                      Rezept-Typ:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                  >
                    <Controls.Chip
                      label={formValues.rxtype || "-"}
                      sx={{ width: "100%", fontSize: "0.7rem" }}
                    />
                  </Grid>

                  {/* Third Row */}
                  <Grid
                    item
                    xs={5}
                  >
                    <Typography
                      textAlign="left"
                      style={{ fontSize: "0.8rem" }}
                    >
                      Rx Location:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                  >
                    <Controls.Chip
                      label={
                        formValues.isHomeVisit === true
                          ? "Haus/Heim"
                          : formValues.isHomeVisit === false
                          ? "Praxis"
                          : "-"
                      }
                      sx={{ width: "100%", fontSize: "0.7rem" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Controls.Select
                      name="processingStatus"
                      label="Status"
                      onChange={updateRxProcessingStatus}
                      value={formValues?.processingStatus || RxDtoProcessingStatusEnum.InBehandlung}
                      options={Object.values(RxDtoProcessingStatusEnum).map((value) => ({
                        value: value,
                        label: value,
                      }))}
                      noTriggerChange={true}
                    />
                  </Grid>
                </Grid>
              </FlatCard>
              <FlatCard
                title="Notizen"
                sx={{
                  paddingTop: "5px",
                  marginBottom: "8px",
                  textAlign: "left",
                }}
              >
                <Notes
                  notes={rxNotes}
                  setNotes={updateNote}
                  maxVisibleNotes={3}
                />
              </FlatCard>

              <FlatCard
                title="Arzt"
                sx={{ paddingTop: "5px", marginBottom: "8px", textAlign: "left" }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.8rem" }}
                >
                  {`${formValues?.doctorData?.contactInfo?.salutation || "Dr."} ${
                    formValues?.doctorData?.contactInfo?.firstNames || ""
                  } ${formValues?.doctorData?.contactInfo?.lastName || ""}`}
                  <br />
                  {`Address:${formValues?.doctorData?.contactInfo?.addressLine1 || ""}`}

                  {`${formValues?.doctorData?.contactInfo?.addressLine2 || ""}`}
                  <br />
                  {`Tel: ${formValues?.doctorData?.doctor?.contactInfo?.phone1 || ""}`}
                  <br />
                  {`LANR: ${formValues?.doctorData?.doctor?.number || ""}`}
                  <br />
                  {`BSNR: ${formValues?.doctorData?.doctor?.clinicNumber || ""}`}
                </Typography>
              </FlatCard>
              <FlatCard title="Validierung">
                <ValidationCard
                  patientData={patientValues}
                  selectedRx={formValues}
                  form={formType.SHORT}
                />
              </FlatCard>
            </Grid>
          )}

          {/* Right Side */}
          <Grid
            item
            xs={12}
            md={6}
          >
            <ScalableComponentSimple occupiedHeight={readOnlyView ? 0.8 : 1.2}>
              <Container
                sx={{ border: "0px solid yellow" }}
                className={classes.container}
                ref={componentRef}
              >
                <Grid
                  container
                  spacing={1}
                >
                  {/* Form content */}
                  <Grid
                    item
                    xs={8}
                    sx={{ border: "1px solid black" }}
                  >
                    {/* <Grid container spacing={2}> */}

                    <Grid
                      item
                      xs={12}
                    >
                      <Typography className={classes.typography}>
                        Unfallversicherungsträger
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Controls.Select
                        disabled={readOnlyView}
                        name="Receiptpayer"
                        label=""
                        id={1}
                        value={selectedPayer || formValues.Receiptpayer}
                        onChange={handleInputChange}
                        options={insurer[0]?.map((option: any) => ({
                          label: option.ik.toString(),
                          value: option.name.toString(),
                          id: option.id.toString(),
                        }))}
                      />
                      <Divider
                        sx={{
                          flexGrow: 1,
                          //   background: attentionColors.immediateAttention,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography className={classes.typography}>
                        Name, Vorname des Versicherten
                      </Typography>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={4}
                        >
                          <Controls.Input
                            name=""
                            label=""
                            value={patientValues?.lastName}
                            onChange={handleInputChange}
                            style={{ width: "100%", margin: "0px", background: "white" }}
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                        >
                          <Controls.Input
                            name=""
                            label=""
                            value={patientValues?.firstName}
                            onChange={handleInputChange}
                            style={{ width: "100%", margin: "0px", background: "white" }}
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          xs={5}
                        >
                          <Typography className={classes.typography}>Geburtsdatum</Typography>
                          <Controls.DatePicker
                            name=""
                            label=""
                            value={patientValues.dob}
                            onChange={handleInputChange}
                            disabled
                            variant="standard"
                            style={{
                              width: "100%",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "10px",
                              "& .MuiInputBase-input": {
                                padding: 0,
                                margin: 0,
                                paddingRight: "0px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{
                          flexGrow: 1,
                          //   background: attentionColors.immediateAttention,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      {" "}
                      <Typography className={classes.typography}>
                        Vollständige Anschrift
                      </Typography>{" "}
                      <Controls.Input
                        name=""
                        label=""
                        value={`${patientValues?.street || "-"} ${patientValues?.city || ""} ${
                          patientValues?.zip || ""
                        } ${patientValues?.country || ""}`}
                        onChange={handleInputChange}
                        style={{ width: "100%", margin: "0px", background: "white" }}
                        disabled={readOnlyView}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                    >
                      {" "}
                      <Typography className={classes.typography}>Telefon-Nr.</Typography>{" "}
                      <Controls.Input
                        label=""
                        name=""
                        value={
                          patientValues?.mobile || patientValues?.phone1 || patientValues?.phone2
                        }
                        onChange={handleInputChange}
                        disabled={readOnlyView}
                        // required
                        // error={errors.contact}
                        style={{
                          borderBottom: "2px solid grey",
                          background: "white",
                          // paddingLeft: "5px",
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={4}>
                              <Typography>Kostenträgerkennung</Typography>
                              <div style={{ height: "30px" }}>
                                <Controls.Select
                                  //   disabled={readOnlyView}
                                  name="chipcard"
                                  label=""
                                  id={1}
                                  value={selectedChipCard || formValues?.chipcard}
                                  onChange={handleInputChange}
                                  options={insurer[0]?.map((insurer: any) => ({
                                    label: insurer.insurer.toString(),
                                    value: insurer.ik.toString(),
                                  }))}
                                  style={{
                                    width: "100%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    fontSize: "10px",
                                  }}
                                  disabled={readOnlyView}
                                />
                              </div>
                            </Grid>
      
                            <Grid item xs={4}>
                              <Typography>Versicherten-Nr.</Typography>
                              <Controls.Input
                                // disabled={readOnlyView}
                                name="insuranceN"
                                label=""
                                value={formValues?.insuranceNo}
                                onChange={handleInputChange}
                                style={{ width: "100%", margin: "0px" }}
                                disabled={readOnlyView}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>Status</Typography>
                              <Controls.Select
                                // disabled={readOnlyView}
                                name="insuranceStatus"
                                label=""
                                value={formValues?.insuranceStatus}
                                onChange={handleInputChange}
                                options={[
                                  { label: "10000", value: "10000" },
                                  { label: "30000", value: "30000" },
                                  { label: "50000", value: "50000" },
                                ]}
                                style={{
                                  width: "100%",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontSize: "10px",
                                }}
                                disabled={readOnlyView}
                              />
                            </Grid>
                          </Grid>
                          <Divider
                            sx={{
                              flexGrow: 1,
                              //   background: attentionColors.immediateAttention,
                            }}
                          />
                        </Grid> */}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ border: "1px solid black" }}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography className={classes.typography}>
                        {" "}
                        Sie dürfen Leistungen dieser Verordnung erbringen und abrechnen, wenn Sie
                        die Regelungen der Vereinbarung zwischen den physiotherapeutischen
                        Berufs-verbänden und den Verbänden der UV-Träger - in gültiger Fassuran-
                        anerken-nen.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ paddingTop: "10px" }}
                    >
                      <Divider />
                      <Controls.DatePicker
                        name="accidentDay"
                        label={
                          <Typography className={classes.typography}>
                            Unfalltag und ggf. Aktenzeichen des Unfallversicherungsträgers
                          </Typography>
                        }
                        value={formValues?.accidentDay}
                        onChange={handleInputChange}
                        formatDate="dd.MM.yyyy"
                        disabled={readOnlyView}
                        style={{
                          width: "100%",
                          padding: "0px",
                          margin: "0px",
                          fontSize: "10px",
                          "& .MuiInputBase-input": {
                            padding: 0,
                            margin: 0,
                            paddingRight: "0px",
                          },
                        }}
                      />{" "}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "2px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography className={classes.typography}>
                      1.Diagnose(n), die die Physiotherapie begründen
                    </Typography>
                    <Controls.TextArea
                      name="diagnosisText"
                      rows={4}
                      placeholder="Textdiagnose"
                      value={formValues?.diagnosisText}
                      onChange={handleInputChange}
                      disabled={readOnlyView}
                      style={{ background: "white", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "2px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography className={classes.typography}>
                      2. Weitere Diagnose(n) und Kontextfaktoren, die für die Physiotherapie
                      relevant sein können
                    </Typography>
                    <Controls.TextArea
                      name="diagnosisText2"
                      rows={4}
                      placeholder="Textdiagnose2"
                      value={formValues?.diagnosisText2}
                      onChange={handleInputChange}
                      disabled={readOnlyView}
                      style={{ background: "white", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ borderBottom: "1px solid black" }}
                >
                  <Typography className={classes.typography}> 3.Therapiehinweise</Typography>
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={5}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "0px !important",
                            marginBottom: "0px !important",
                          }}
                        >
                          <Controls.Checkbox
                            name="stabilitätsgrad"
                            label={
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  textAlign: "left",
                                  whiteSpace: "nowrap",
                                  maxWidth: "calc(100% - 50px)", // Adjust based on the expected checkbox width
                                }}
                              >
                                Stabilitätsgrad(z.B.belastungsstabil 20kg bis):
                              </Typography>
                            }
                            value={checkboxState.stabilitätsgrad}
                            onChange={handleCheckboxChange}
                            disabled={readOnlyView}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Controls.Checkbox
                            name="limitierungen"
                            label={
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                  maxWidth: "calc(100% - 50px)", // Adjust based on the expected checkbox width
                                }}
                              >
                                Weitere Limitierungen (z. B. Bewegungsausmaß):
                              </Typography>
                            }
                            value={checkboxState.limitierungen}
                            onChange={handleCheckboxChange}
                            disabled={readOnlyView}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Controls.Checkbox
                            name="opTechnik"
                            label={
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                  maxWidth: "calc(100% - 50px)", // Adjust based on the expected checkbox width
                                }}
                              >
                                Angewandte OP-Technik:
                              </Typography>
                            }
                            value={checkboxState.opTechnik}
                            onChange={handleCheckboxChange}
                            disabled={readOnlyView}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Controls.Checkbox
                            name="wunden"
                            label={
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                  maxWidth: "calc(100% - 50px)", // Adjust based on the expected checkbox width
                                }}
                              >
                                Therapieeinschränkende Wunden:
                              </Typography>
                            }
                            value={checkboxState.wunden}
                            onChange={handleCheckboxChange}
                            disabled={readOnlyView}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Controls.Checkbox
                            name="erreger"
                            label={
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                  maxWidth: "calc(100% - 50px)", // Adjust based on the expected checkbox width
                                }}
                              >
                                Multiresistente Erreger (Typ und Lokalisation):
                              </Typography>
                            }
                            value={checkboxState.erreger}
                            onChange={handleCheckboxChange}
                            disabled={readOnlyView}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Controls.Checkbox
                            name="sonstige"
                            label={
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                  maxWidth: "calc(100% - 50px)", // Adjust based on the expected checkbox width
                                }}
                              >
                                Sonstige (z. B. orthopädietechnische Versorgung, Sprachbarriere
                                etc.):
                              </Typography>
                            }
                            value={checkboxState.sonstige}
                            onChange={handleCheckboxChange}
                            disabled={readOnlyView}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Controls.Checkbox
                            name="rücksprache"
                            label={
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  textAlign: "left",
                                  maxWidth: "calc(100% - 50px)", // Adjust based on the expected checkbox width
                                }}
                              >
                                Bitte um telefonische Rücksprache durch Therapeutin/Therapeuten.
                                (Telefon-Nr. und Zeitfenster angeben):
                              </Typography>
                            }
                            value={checkboxState.rücksprache}
                            onChange={handleCheckboxChange}
                            disabled={readOnlyView}
                          />
                        </div>
                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ padding: "2px" }}
                    >
                      {isAnyCheckboxChecked && (
                        <Controls.TextArea
                          name="additionalNotes"
                          rows={3}
                          placeholder=""
                          value={formValues?.additionalNotes}
                          onChange={handleInputChange}
                          disabled={readOnlyView}
                          style={{ background: "white", width: "100%" }}
                        />
                      )}{" "}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "2px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography className={classes.typography}>
                      4. Konkrete Therapieziele (z. B. Steigerung ROM, funktioneller Kraftaufbau,
                      Gangschule etc.)
                    </Typography>
                    <Controls.TextArea
                      name="additionalNotes2"
                      rows={3}
                      placeholder=""
                      value={formValues?.additionalNotes2}
                      onChange={handleInputChange}
                      disabled={readOnlyView}
                      style={{ background: "white", width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "2px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Table
                      sx={{
                        borderCollapse: "collapse",
                        width: "100%",
                        border: "1px solid red",
                        background: "white",
                        fontSize: "10px", // Adjust font size for compactness
                        tableLayout: "auto", // Allows table and cells to adjust width based on content
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              borderRight: "1px solid black",
                              padding: "2px",
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                            className={classes.typography}
                          >
                            5. Leistungsziffern (Übersicht s. Folgeseite)
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid black",
                              padding: "2px",
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                            className={classes.typography}
                          >
                            Anzahl der Behandlungen insgesamt (max. Behandlungszeit 4 Wochen
                            Ausnahme: Langzeitverordnung s. Nr. 9)
                          </TableCell>
                          <TableCell
                            sx={{
                              borderLeft: "1px solid black",
                              padding: "2px",
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                            className={classes.typography}
                          >
                            Behandlungstage pro Woche
                          </TableCell>
                        </TableRow>
                        {formValues?.therapyRxs.map((therapy: any, rowIndex: any) => (
                          <TableRow key={rowIndex}>
                            <TableCell
                              sx={{
                                padding: "2px",
                                whiteSpace: "nowrap",
                                borderRight: "1px solid black",
                              }}
                            >
                              <Controls.Select
                                name="therapy"
                                id={1}
                                label=""
                                value={therapy.therapy.abbreviation}
                                onChange={(event: any) => handleInputChange(event, rowIndex)}
                                options={filteredTherapies
                                  ?.filter(
                                    (therapyObj: any) => therapyObj.duration && therapyObj.lineItem
                                  )
                                  ?.map((option: any) => ({
                                    // label: option.lineItem.concat("(", option.abbreviation, ")")?.toString(),
                                    // value: option.lineItem.concat("(", option.abbreviation, ")")?.toString(),
                                    label: option.lineItem?.toString(),
                                    value: option.abbreviation?.toString(),
                                    id: option.id?.toString(),
                                  }))}
                                sx={{
                                  fontSize: "10px",
                                  minWidth: "100px", // Minimum width to ensure usability
                                  padding: "0px !important",
                                }}
                                disabled={readOnlyView}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "2px",
                                whiteSpace: "nowrap",
                                borderRight: "1px solid black",
                              }}
                            >
                              <Controls.Input
                                name="amount"
                                label=""
                                value={therapy.amount}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleInputChange(event, rowIndex, therapy.id)
                                }
                                disabled={readOnlyView}
                                sx={{
                                  fontSize: "10px",
                                  minWidth: "60px",
                                  padding: "0px !important",
                                }} // Minimum width to ensure usability
                              />
                            </TableCell>
                            <TableCell sx={{ padding: "2px", whiteSpace: "nowrap" }}>
                              <Controls.Input
                                name="frequency"
                                label=""
                                value={therapy.frequency?.id}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleInputChange(event, rowIndex, therapy.id)
                                }
                                disabled={readOnlyView}
                                sx={{ fontSize: "10px", minWidth: "60px" }} // Minimum width to ensure usability
                              />
                            </TableCell>
                            <TableCell sx={{ padding: "2px", whiteSpace: "nowrap" }}>
                              <Controls.Checkbox
                                name="doubled"
                                value={therapy.isDoubled}
                                label={
                                  <Typography sx={{ fontSize: "8px" }}>Doppelbehandlung</Typography>
                                }
                                disabled={!therapy.therapy.abbreviation || readOnlyView}
                                onChange={(event: any) => handleDuplicateRow(rowIndex, event)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "10px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    <Table
                      sx={{
                        borderCollapse: "collapse",
                        width: "100%",
                        border: "1px solid red",
                        background: "white",
                      }}
                    >
                      <TableBody sx={{ maxHeight: "200px", overflowY: "scroll" }}>
                        <TableRow>
                          {" "}
                          <TableCell
                            sx={{ borderLeft: "1px solid red" }}
                            className={classes.typography}
                          >
                            6. Leistungszifferen (Übersicht s. Folgeseite)
                          </TableCell>
                          <TableCell sx={{ borderLeft: "1px solid red" }}>
                            Anzahl der Behandlungen insgesamt (max. Behandlungszeit 4 Wochen
                            Ausnahme: Langzeitverordnung s. Nr. 9)
                          </TableCell>
                          <TableCell sx={{ borderLeft: "1px solid red" }}>
                            Behandlungstage pro Woche
                          </TableCell>
                        </TableRow>
                        {/* {formValues?.therapyRxs.map((therapy:any, rowIndex:any) => (
                                      <TableRow
                                        key={rowIndex}
                                        // style={{ border: "1px solid red", height: "1px" }}
                                      >
      
                                        <TableCell
                                          sx={{
                                            padding: "0px",
                                            width: "50px",
                                            height: "1px",
                                          }}
                                        >
                                          <Controls.Select
                                          
                                            name="therapy"
                                            id={1}
                                            label=""
                                            value={therapy.therapy.abbreviation }
                                            onChange={(event: any) => handleInputChange(event, rowIndex)}
                                            // options={therapyOptions[0]
                                            //   ?.filter((therapyObj: any) => therapyObj.duration)
                                            //   ?.map((therapy: any) => ({
                                            //     label: therapy?.abbreviation?.toString(),
                                            //     value: therapy?.abbreviation?.toString(),
                                            //   }))}
                                            options={therapyOptions[0]?.filter((therapyObj: any) => therapyObj.duration && therapyObj.lineItem)?.map((option: any) => ({
                                              label: option.abbreviation.toString(),
                                              value: option.lineItem.concat("(",option.abbreviation,")")?.toString(),
                                              id: option.id?.toString(),
                                            }))}
                                            style={{
                                              width: "250px",
                                              marginTop: "0px",
                                              marginBottom: "0px",
                                              fontSize: "10px",
                                            }}
                                            disabled={readOnlyView}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0px",
                                          //   width: "30px",
                                            height: "1px",
                                            borderLeft: "1px solid red",
                                          }}
                                        >
                                          <Controls.Input
                                            name="amount"
                                            
                                            label=""
                                            value={therapy.amount}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              handleInputChange(event, rowIndex, therapy.id);
                                            }} // Pass rowIndex to handleInputChange
                                            disabled={readOnlyView}
                                           
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0px",
                                          //   width: "30px",
                                            height: "1px",
                                            borderLeft: "1px solid red",
                                          }}
                                        >
                                        <Controls.Input
                                            name="frequency"
                                            
                                            label=""
                                            value={therapy.frequency?.id}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              handleInputChange(event, rowIndex, therapy.id);
                                            }} // Pass rowIndex to handleInputChange
                                            disabled={readOnlyView}
                                           
                                          /></TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0px",
                                            width: "30px",
                                            height: "1px",
                                          }}
                                        >
                                          <Controls.Checkbox
                                            name="doubled"
                                            value={therapy.isDoubled}
                                            label={
                                              <Typography sx={{ fontSize: "10px" }}>
                                                Doppelbehandlung
                                              </Typography>
                                            }
                                            disabled={!therapy.therapy.abbreviation || readOnlyView}
                                            onChange={(event: any) => {
                                              handleDuplicateRow(rowIndex, event);
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0px",
                                            width: "30px",
                                            height: "1px",
                                          //   borderLeft: "1px solid red",
                                          }}
                                        >
                                       
                                        </TableCell>
                                        
                                      </TableRow>
                                    ))} */}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "10px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography className={classes.typography}>
                      7.Voraussichtliche Gesamttherapiedauer
                    </Typography>
                    <Controls.TextArea
                      name="expectedTherapyLength"
                      rows={3}
                      placeholder=""
                      value={formValues?.expectedTherapyLength}
                      onChange={handleInputChange}
                      disabled={readOnlyView}
                      style={{ background: "white", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "10px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={4}
                  >
                    <Typography className={classes.typography}>
                      8.Physiotherapiebeginn am
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                  >
                    <Typography sx={{ textAlign: "left", fontSize: "12px", whiteSpace: "normal" }}>
                      (spätestens innerhalb von 14 Tagen nach diesem Datum.)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Controls.DatePicker
                      name="therapyStart"
                      label=""
                      value={formValues?.therapyStart}
                      onChange={handleInputChange}
                      formatDate="dd.MM.yyyy"
                      disabled={readOnlyView}
                      style={{
                        width: "100%",
                        padding: "0px",
                        margin: "0px",
                        fontSize: "10px",
                        "& .MuiInputBase-input": {
                          padding: 0,
                          margin: 0,
                          paddingRight: "0px",
                        },
                      }}
                    />{" "}
                  </Grid>
                  {/* <Grid item xs={12}> */}
                  <Controls.Checkbox
                    name="urgent"
                    label={
                      <Typography
                        sx={{
                          fontSize: "12px",
                          whiteSpace: "normal",
                          textAlign: "left",
                        }}
                      >
                        Bei dringendem Behandlungsbedarf spätestens innerhalb von 7 tagen nach
                        diesem Datum.
                      </Typography>
                    }
                    value={formValues.urgent}
                    onChange={handleInputChange}
                    disabled={readOnlyView}
                  />
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography className={classes.typography}>9.</Typography>
                    <Controls.Checkbox
                      name="isLongTermRx"
                      label={
                        <Typography
                          sx={{
                            fontSize: "12px",
                            whiteSpace: "normal",
                            textAlign: "left",
                          }}
                          className={classes.typography}
                        >
                          Langzeitverordnung (bis max. 6 Monate)-Die erforderliche Kostenzusage ist
                          vor Behandlungsbeginn vom
                        </Typography>
                      }
                      value={formValues.isLongTermRx}
                      onChange={(e: any) => {
                        handleInputChange(e);
                        setOpenModal(!openModal);
                      }}
                      disabled={readOnlyView}
                    />
                  </Grid>

                  <Typography sx={{ textAlign: "left", fontSize: "12px", whiteSpace: "normal" }}>
                    Leistungserbringenden oder von der versicherten Person gemäß Rahmenvertrag
                    Physiotherapie einzuholen.
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ paddingTop: "2px", borderBottom: "1px solid black" }}
                >
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography sx={{ textAlign: "left", fontSize: "12px", whiteSpace: "normal" }}>
                      Datum
                    </Typography>
                    <Controls.DatePicker
                      name="date"
                      label=""
                      value={formValues?.date}
                      onChange={handleInputChange}
                      formatDate="dd.MM.yy"
                      disabled={readOnlyView}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                  >
                    <Typography sx={{ textAlign: "left", fontSize: "12px", whiteSpace: "normal" }}>
                      Name, Anschrift und Telefon-Nr. der Durchgangsärztin/des Durchgangsarztes
                    </Typography>
                    <Controls.TextArea
                      name="doctor"
                      rows={3}
                      placeholder=""
                      value={
                        `${formValues.doctorData?.contactInfo?.salutation || ""} ${
                          formValues.doctorData?.contactInfo?.firstNames || ""
                        } ${formValues.doctorData?.contactInfo?.lastName || ""}\n` +
                        `${formValues.doctorData?.contactInfo?.addressLine1 || ""}\n` +
                        `${formValues.doctorData?.contactInfo?.addressLine2 || ""}\n` +
                        `${formValues.doctorData?.contactInfo?.zip || ""}\n` +
                        `Tel: ${formValues.doctorData?.contactInfo?.phone1 || ""}\n` + // Added "Tel:" label
                        `BSNR: ${formValues.doctorData?.clinicNumber || ""}` // Added "BSNR:" label
                      }
                      onChange={handleInputChange}
                      disabled
                      style={{ flex: 1, width: "100%", background: "white" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </ScalableComponentSimple>
          </Grid>
          {!readOnlyView && selectedRow && (
            <Grid
              item
              xs={12}
              md={3}
              sx={{ border: "0px dotted black" }}
            >
              <FlatCard title="Validierung">
                <ValidationCard
                  patientData={patientValues}
                  selectedRx={
                    // patientValues?.rxs?.filter(
                    //   (rx: any) => rx.rxNumber === selectedRow?.rxNumber
                    // )[0]
                    formValues
                  }
                  form={formType.LONG}
                />
              </FlatCard>
            </Grid>
          )}
        </Grid>
        {openModal && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleModalClose} // Function to close the dialog
            onContinue={handleIsPerpetual} // Function to continue despite unsaved changes
            title="Liegt eine Genehmigung  vor?"
            content={
              <>
                <Typography>
                  {" "}
                  Eine Langzeitgenehmigung ist erforderlich. Wähle die ensprechende
                  Genehmigungsstatus aus.
                </Typography>
                <Controls.RadioGroup
                  label=""
                  name="approvalStatus"
                  value={formValues?.approvalStatus || RxDtoApprovalStatusEnum.Fehlt}
                  options={Object.values(RxDtoApprovalStatusEnum).map((value) => ({
                    id: value,
                    label: value,
                  }))}
                  disabled={readOnlyView}
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    paddingLeft: "1rem",
                    textAlign: "left",
                    fontSize: "0.7rem",
                  }}
                />
              </>
            }
          />
        )}
        {unsavedChangesWarning && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
            onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
            title="Änderungen speichern?"
            content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
            isConfirmSave
          />
        )}
      </>
    );
    return useObserver(() => {
      return !readOnlyView ? (
        <Dialog
          open={openRx as boolean}
          onClose={handleCloseRxDialog}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle>BG Dialog</DialogTitle>
          <DialogContent>{FormContent}</DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseRxDialog}
              disabled={loading}
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={loading}
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        FormContent
      );
    });
  }
);

export default BG;
